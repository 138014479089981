import Vue from "vue";

const state = {
  dragndrop: false,
  dragndropfields: [],
  lastDragged: {},
  dragndropSizeState: 0,
  lastClickedFree: {},
};

const getters = {
  dragndropfields: state => state.dragndropfields,
};

const mutations = {
  mutateDragnDrop(state, value) {
    state.dragndrop = value;
  },
  mutateDragnDropFields(state, value) {
    state.dragndropfields = value;
  },
  addDragnDropField: (state, newField) => {
    if(state.dragndropfields && state.dragndropfields.length > 0) {
      state.dragndropfields.push(newField);
    } else {
      state.dragndropfields = [];
      Vue.set(state.dragndropfields, 0, newField);
    }
  },
  removeDragnDropField: (state, index) => {
    state.dragndropfields.splice(index, 1);
  },
  dropAllDragnDropFields: (state) => {
    state.dragndropfields = [];
  },
  mutateSingleField(state, params) {
    Vue.set(state.dragndropfields, params.index, { text: params.text, index: params.index });
  },
  mutateLastDragged(state, value) {
    state.lastDragged = value;
  },
  mutateLastClickedFree(state, value) {
      if(state.lastClickedFree === value) {
          state.lastClickedFree = undefined;
      } else {
          state.lastClickedFree = value;
      }
  },
  mutateSizeState(state, value) {
    state.dragndropSizeState = value;
  }
};

const actions = {
  setdragndrop({ commit, state }, value) {
    commit("mutateDragnDrop", value)
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
