<template>
    <!-- TODO: split in separate components, like import a parentalLetter and a sickNote component in this widget
    & e.g. make the list elements like v-for parentalLetter or v-for sickNotes should be a sickNoteListElement-component-->
    <div>
        <progress-bar
            title="Hochladen"
            :show-progress="showAttachmentUploadProgress"
            :progress="attachmentUploadProgress"
            :abort-progress="fileXmlHttpRequest ? () => { fileXmlHttpRequest.abort() }: () => {}"
        />

        <name-file
            v-model="showNameFile"
            :original-name="originalFileName"
            :callback="fileNameCallback"
        />

        <!-- widget -->
        <v-row class="mx-0">
            <div class="widgetContainer whiteBg">
                <!--#region header -->
                <div class="header">
                    <v-row class="headerRow mx-2 d-flex align-center justify-space-between">
                        <v-col
                            cols="9"
                            md="8"
                            lg="8"
                            class="pa-0 pl-2 d-flex align-center"
                            style="font-size: larger"
                        >
                            <img
                                v-if="currentLetter === null && archivedLetter === null && currentSickNote === null"
                                :src="mailIcon"
                                class="iconToWhite"
                                height="20"
                                alt=""
                            >
                            <v-btn
                                v-else
                                class="optionBtn mr-1"
                                x-small
                                color="rgba(248, 248, 248, 0.4)"
                                @click="currentLetter = null; archivedLetter = null; collapseLetter = false; currentSickNote = null"
                            >
                                <img
                                    :src="leftIcon"
                                    class="icon iconToWhite"
                                    alt="Zurück"
                                >
                            </v-btn>

                            <h1 class="ml-1">
                                {{ currentSickNote ? 'Entschuldigung' : (currentLetter && role === 'teacher' ? 'Elternbrief' : 'Mitteilungen') }}
                            </h1>
                        </v-col>

                        <v-col
                            v-if="!currentLetter && !currentSickNote"
                            cols="3"
                            md="4"
                            lg="3"
                            class="pointer pa-0 pr-2 d-flex align-center justify-end"
                        >
                            <img
                                aria-hidden="true"
                                :src="infoIcon"
                                style="height: 15px;"
                                alt="Info vorlesen"
                                @click="role === 'teacher' ? readText(widgetInfoTeacher) : readText(widgetInfoParent)"
                            >

                            <!-- create button and create menu -->
                            <div>
                                <v-menu
                                    offset-y
                                    left
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            class="optionBtn ml-2"
                                            x-small
                                            dark
                                            color="rgba(248, 248, 248, 0.4)"
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            <v-icon :alt="role === 'teacher' ? 'Elternbrief erstellen' : 'Entschuldigung erstellen'">
                                                mdi-plus
                                            </v-icon>
                                        </v-btn>
                                    </template>

                                    <v-list>
                                        <v-list-item
                                            v-if="role === 'teacher'"
                                            class="hoverableElement"
                                            @click="createLetterDialog = true"
                                        >
                                            <v-list-item-title>
                                                Elternbrief
                                            </v-list-item-title>
                                        </v-list-item>

                                        <v-list-item
                                            v-else-if="role === 'parent'"
                                            class="hoverableElement"
                                            @click="handleClickCreateSickNote"
                                        >
                                            <v-list-item-title>
                                                Entschuldigung
                                            </v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </div>
                        </v-col>

                        <div
                            v-else-if="currentLetter"
                            class="d-flex align-center"
                        >
                            <img
                                :src="bearbeitenIcon"
                                alt="Brief bearbeiten"
                                class="icon iconToWhite pr-2 pointer"
                                @click="setEditLetter(currentLetter)"
                            >

                            <img
                                :src="deleteIcon"
                                alt="Brief löschen"
                                class="icon iconToWhite pr-2 pointer"
                                @click="openConfirmDelDialog(currentLetter, false)"
                            >
                        </div>
                    </v-row>
                </div>
                <!--#endregion -->

                <!-- looking at specific letter -->
                <MessagesLetter
                    v-if="currentLetter && currentSickNote === null"
                    :current-letter="currentLetter"
                    :current-sick-note="currentSickNote"
                    :collapse-letter="collapseLetter"
                    :signed-current-letter="signedCurrentLetter"
                    :not-signed-current-letter="notSignedCurrentLetter"
                    :settings="settings"
                    @collapseLetter="setCollapseLetter"
                />

                <!-- looking at specific sick note -->
                <MessagesSickNote
                    v-else-if="currentSickNote"
                    :current-sick-note="currentSickNote"
                    :role="role"
                    :settings="settings"
                    @handleAcceptSickNote="handleAcceptSickNote"
                    @handleDeclineSickNote="handleDeclineSickNote"
                />

                <!--#region category switcher & messages -->
                <div v-else>
                    <div class="ma-4 d-flex justify-center">
                        <v-btn-toggle
                            v-model="messageCategory"
                            dense
                            mandatory
                            borderless
                            style="width: 100% !important; background-color: #F5F5F5; border-radius: 8px"
                        >
                            <v-btn
                                :dark="messageCategory === 0"
                                class="text-none"
                                style="width: 50% !important"
                                @click="handleClickCategory(0)"
                            >
                                Gesendet
                            </v-btn>
                            <v-btn
                                :dark="messageCategory === 1"
                                class="text-none"
                                style="width: 50% !important"
                                @click="handleClickCategory(1)"
                            >
                                Eingang
                                <v-badge
                                    v-if="calcUnseenMsgs > 0"
                                    :content="calcUnseenMsgs"
                                    inline
                                    color="flila"
                                />
                            </v-btn>
                        </v-btn-toggle>
                    </div>

                    <!-- messages -->
                    <div class="d-flex justify-center">
                        <vue-custom-scrollbar
                            class="scroll-area-messages"
                            settings="settings"
                        >
                            <div v-if="messageCategory === 0">
                                <div
                                    v-for="msg in sentMessages"
                                    :key="msg._id"
                                    class="ek-listElement d-flex align-center justify-space-between px-2 mx-4 my-2"
                                    @click="handleClickSentMessage(msg)"
                                >
                                    <v-badge
                                        v-if="role === 'teacher'"
                                        style="width: 100%"
                                        color="frot"
                                        offset-y="-4"
                                        offset-x="24"
                                        left
                                        overlap
                                    >
                                        <template v-slot:badge>
                                            {{ msg.requiresSignature ? msg.parentalLetterResponses.length : msg.seenBy.length }}/{{ msg.targetAccounts.length }}
                                        </template>

                                        <div class="d-flex align-center justify-space-between">
                                            <p>
                                                {{ msg.title }}
                                            </p>

                                            <p class="mr-1">
                                                {{ new Date(msg.createdAt).toLocaleDateString('de-DE', {day: '2-digit', month: '2-digit'}) }}
                                            </p>
                                        </div>
                                    </v-badge>

                                    <div
                                        v-else
                                        class="d-flex align-center justify-space-between"
                                        style="width: 100%"
                                    >
                                        <p>
                                            {{ msg.title }}
                                        </p>

                                        <div class="d-flex">
                                            <v-icon
                                                v-if="msg.accepted || msg.accepted === false"
                                                :color="msg.accepted ? 'gruen' : 'frot'"
                                                class="mr-1"
                                            >
                                                {{ msg.accepted ? 'mdi-check' : 'mdi-close' }}
                                            </v-icon>

                                            <p class="mr-1">
                                                {{ new Date(msg.createdAt).toLocaleDateString('de-DE', {day: '2-digit', month: '2-digit'}) }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div v-else>
                                <div
                                    v-for="msg in incomingMessages"
                                    :key="msg._id"
                                    class="ek-listElement d-flex justify-space-between align-center px-2 mx-4 my-2"
                                    @click="handleClickIncomingMessage(msg)"
                                >
                                    <div
                                        v-if="role === 'teacher' && !(msg.accepted === true || msg.accepted === false)"
                                        id="ek-newIndicator"
                                    />
                                    <div
                                        v-if="msg.pupil"
                                        class="d-flex"
                                        style="width: 75%"
                                    >
                                        <p
                                            v-if="windowWidth > 1100"
                                            class="ek-badge d-flex align-center ml-1 mr-2"
                                        >
                                            Entschuldigung
                                        </p>
                                        <v-tooltip
                                            v-else
                                            bottom
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon
                                                    class="d-flex align-center ml-1 mr-2"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                >
                                                    mdi-emoticon-sick
                                                </v-icon>
                                            </template>

                                            <span>Entschuldigung</span>
                                        </v-tooltip>

                                        <p class="ellipsis">
                                            {{ msg.pupil.name + ' ' + msg.pupil.lastName }}
                                        </p>
                                    </div>

                                    <p v-else>
                                        {{ msg.title }}
                                    </p>

                                    <p class="mr-1">
                                        {{ new Date(msg.createdAt).toLocaleDateString('de-DE', {day: '2-digit', month: '2-digit'}) }}
                                    </p>
                                </div>
                            </div>
                        </vue-custom-scrollbar>
                    </div>
                </div>
                <!--#endregion -->
            </div>
            <!--#endregion -->
        </v-row>

        <!--#region dialogs -->
        <!-- universal confirm dialog -->
        <v-dialog
            v-model="confirmDialog"
            width="400"
        >
            <div style="height: 80vh; padding-top: 55px">
                <v-card>
                    <v-card-title>
                        <p
                            tabindex="0"
                            style="width: fit-content"
                        >
                            {{ confirmType[confirmDialogType].title }}
                        </p>
                    </v-card-title>

                    <v-card-text>
                        <p
                            tabindex="0"
                            style="width: fit-content"
                        >
                            {{ confirmType[confirmDialogType].text }}
                        </p>
                    </v-card-text>

                    <v-card-actions class="d-flex justify-end">
                        <v-btn
                            class="optionBtn ml-2"
                            x-small
                            color="rot"
                            dark
                            @click="handleConfirmDialogDecline"
                        >
                            <img
                                :src="schliessenIcon"
                                class="icon iconToWhite"
                                alt="abbrechen"
                            >
                        </v-btn>

                        <v-btn
                            class="optionBtn"
                            x-small
                            color="fhellgruen"
                            dark
                            @click="handleConfirmDialogAccept"
                        >
                            <img
                                :src="fertigIcon"
                                class="icon iconToWhite"
                                alt="bestätigen"
                            >
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </div>
        </v-dialog>

        <!-- TEACHER | create/edit letter dialog -->
        <v-dialog
            v-model="createLetterDialog"
            persistent
            width="400"
            @click:outside="checkForChanges"
        >
            <div style="height: 80vh; overflow: hidden; border-radius: 0 0 15px 15px;">
                <div class="header">
                    <v-row class="headerRow mx-2 d-flex align-center justify-space-between">
                        <v-col
                            cols="9"
                            md="8"
                            lg="8"
                            class="pa-0 pl-2 d-flex align-center"
                            style="font-size: larger"
                        >
                            <img
                                v-show="!selectReceiver"
                                :src="mailIcon"
                                class=" mr-2 icon iconToWhite"
                                alt=""
                            >

                            <v-btn
                                v-show="selectReceiver"
                                class="optionBtn mx-1"
                                x-small
                                color="rgba(248, 248, 248, 0.4)"
                                @click="selectReceiver = false"
                            >
                                <img
                                    :src="fertigIcon"
                                    class="icon iconToWhite"
                                    alt="Empfänger bestätigen"
                                >
                            </v-btn>

                            <p class="ellipsis">
                                {{ selectReceiver ? 'Empfänger auswählen' : editLetter ? 'Elternbrief bearbeiten' : 'Neuer Elternbrief' }}
                            </p>
                        </v-col>
                        <v-col
                            cols="3"
                            md="4"
                            lg="3"
                            class="pa-0 pr-2 d-flex align-center justify-end"
                        >
                            <v-btn
                                class="optionBtn"
                                x-small
                                color="#AAC937"
                                :disabled="!(receiverAccounts.length && title && (containsPoll ? pollValid : true))"
                                @click="handleClickLetterDialog"
                            >
                                <img
                                    :src="fertigIcon"
                                    class="icon iconToWhite"
                                    alt="fertig"
                                >
                            </v-btn>

                            <v-btn
                                class="optionBtn ml-2"
                                x-small
                                color="rgba(230, 45, 45, 0.4)"
                                @click="checkForChanges"
                            >
                                <img
                                    :src="schliessenIcon"
                                    class="icon iconToWhite"
                                    alt="schließen"
                                >
                            </v-btn>
                        </v-col>
                    </v-row>
                </div>

                <div
                    class="whiteBg py-2"
                    style="height: calc(80vh - 55px);"
                >
                    <vue-custom-scrollbar
                        class="scroll-area"
                        settings="settings"
                    >
                        <!-- create page -->
                        <div v-show="!selectReceiver">
                            <v-row
                                cols="12"
                                class="dialogRow"
                            >
                                <v-col class="pa-0 d-flex align-center justify-space-between">
                                    <p class="headlineText">
                                        Empfänger ({{ this.receiverAccounts.length }})
                                    </p>

                                    <v-btn
                                        class="optionBtn"
                                        @click="selectReceiver = true"
                                    >
                                        <img
                                            :src="sucheIcon"
                                            class="icon"
                                            alt="suchen"
                                        >
                                    </v-btn>
                                </v-col>
                            </v-row>

                            <p
                                v-if="receiverAccounts.length < 1"
                                class="own-red-text px-2"
                            >
                                Mindestens ein Empfänger auswählen
                            </p>
                            <p
                                v-else
                                class="px-2"
                            >
                                {{ displayReceivers(receiverAccounts) }}
                            </p>

                            <hr class="greyLine mx-2">

                            <v-row
                                cols="12"
                                class="dialogRow mt-4"
                            >
                                <v-col class="pa-0 d-flex align-center justify-space-between">
                                    <p class="headlineText">
                                        Titel
                                    </p>

                                    <v-btn
                                        class="optionBtn"
                                        @click="editTitle = !editTitle"
                                    >
                                        <img
                                            :src="editTitle ? fertigIcon : bearbeitenIcon"
                                            class="icon"
                                            alt="Titel bearbeiten"
                                        >
                                    </v-btn>
                                </v-col>
                            </v-row>

                            <p
                                v-if="!title"
                                class="own-red-text px-2"
                            >
                                Titel erforderlich
                            </p>

                            <v-row
                                v-if="editTitle"
                                class="dialogRow"
                            >
                                <v-textarea
                                    v-model="title"
                                    rows="1"
                                    class="ma-0 pa-0"
                                    auto-grow
                                    autofocus
                                    hide-details
                                />
                            </v-row>
                            <p
                                v-if="title !== '' && !editTitle"
                                class="pa-2 pt-0"
                            >
                                {{ title }}
                            </p>
                            <hr class="greyLine mx-2">

                            <v-row
                                cols="12"
                                class="dialogRow mt-4"
                            >
                                <v-col class="pa-0 d-flex align-center justify-space-between">
                                    <p class="headlineText">
                                        Inhalt
                                    </p>

                                    <v-btn
                                        class="optionBtn"
                                        @click="editBody = !editBody"
                                    >
                                        <img
                                            :src="editBody ? fertigIcon : bearbeitenIcon"
                                            class="icon"
                                            alt="Inhalt bearbeiten"
                                        >
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-row
                                v-if="editBody"
                                class="dialogRow"
                            >
                                <AdvancedQuillEditor
                                    v-model="body"
                                    placeholder="Der Text dieses Elternbriefes"
                                />
                            </v-row>
                            <div
                                v-if="body !== '' && !editBody"
                                class="ma-0 pa-0 px-2"
                                v-html="urlify(body)"
                            />

                            <v-row
                                cols="12"
                                class="dialogRow mt-4"
                            >
                                <v-col
                                    cols="11"
                                    class="pa-0 d-flex align-center"
                                >
                                    <p class="greyText mb-0">
                                        Brief enthält Umfrage
                                    </p>
                                </v-col>

                                <v-col
                                    cols="1"
                                    class="pa-0"
                                >
                                    <v-checkbox
                                        v-model="containsPoll"
                                        color="#AAC937"
                                        hide-details
                                        class="ma-0 pa-0"
                                    />
                                </v-col>
                            </v-row>

                            <v-row
                                cols="12"
                                class="dialogRow"
                            >
                                <Poll
                                    v-if="containsPoll"
                                    @pollValid="pollValid = true"
                                    @pollInvalid="pollValid = false"
                                />
                            </v-row>

                            <hr class="greyLine mx-2">

                            <v-row
                                v-if="editLetter"
                                cols="12"
                                class="dialogRow mt-4"
                            >
                                <p class="headlineText">
                                    Ursprüngliche Dateien
                                </p>
                                <p>Ist der Lösch-Button der jeweiligen Datei rot, wird die Datei beim bearbeiteten Brief nicht mehr als Anhang genutzt.</p>
                            </v-row>

                            <!-- if editing letter show the original files first & handle deleting differently -->
                            <div v-if="editLetter">
                                <div
                                    v-for="file in filesFromOriginalLetter"
                                    :key="file._id"
                                    class="fileRow d-flex justify-space-between align-center py-2 px-4"
                                >
                                    <p class="greyText ellipsis">
                                        {{ file.uploadedName }}
                                    </p>

                                    <v-btn
                                        class="optionBtn ml-2 pr-4"
                                        :style="file.toBeDeleted ? 'background-color: red' : 'background-color: white'"
                                        @click="markFileForDeletion(file)"
                                    >
                                        <img
                                            :src="deleteIcon"
                                            :class="file.toBeDeleted ? 'iconToWhite' : ''"
                                            class="icon"
                                            alt="Datei entfernen"
                                        >
                                    </v-btn>
                                </div>
                            </div>

                            <v-row
                                cols="12"
                                class="dialogRow mt-4"
                            >
                                <v-col class="pa-0 d-flex align-center justify-space-between">
                                    <p class="headlineText">
                                        Datei(en) anhängen
                                    </p>

                                    <v-btn
                                        class="optionBtn"
                                        @click="clickElement('fileInput')"
                                    >
                                        <img
                                            :src="hochladenIcon"
                                            class="icon"
                                            alt=""
                                        >
                                    </v-btn>

                                    <input
                                        id="fileInput"
                                        ref="fileInput"
                                        type="file"
                                        hidden
                                        multiple
                                        @change="fileInputChange"
                                    >
                                </v-col>
                            </v-row>

                            <!-- files for creating letter OR newly added ones when editing letter -->
                            <div
                                v-for="file in attachedFiles"
                                :key="file._id"
                                class="fileRow d-flex justify-space-between align-center py-2 px-4"
                            >
                                <p class="greyText ellipsis">
                                    {{ file.name }}
                                </p>

                                <v-btn
                                    class="optionBtn ml-2 pr-4"
                                    style="background-color: white"
                                    @click="removeFile(file)"
                                >
                                    <img
                                        :src="deleteIcon"
                                        class="icon"
                                        alt="Datei entfernen"
                                    >
                                </v-btn>
                            </div>

                            <hr class="greyLine mt-2 mx-2">

                            <!-- signature -->
                            <v-row
                                cols="12"
                                class="dialogRow mt-4"
                            >
                                <v-col
                                    cols="11"
                                    class="pa-0 d-flex align-center"
                                >
                                    <p class="headlineText">
                                        Unterschriftspflichtig?
                                    </p>
                                </v-col>

                                <v-col
                                    cols="1"
                                    class="pa-0"
                                >
                                    <v-checkbox
                                        v-model="requiresSignature"
                                        color="#AAC937"
                                        hide-details
                                        class="ma-0 pa-0"
                                    />
                                </v-col>
                            </v-row>
                        </div>
                    </vue-custom-scrollbar>

                    <!-- select receiver(s) page -->
                    <div
                        v-show="selectReceiver"
                        class="pa-2"
                    >
                        <div class="d-flex align-center">
                            <img
                                :src="searchIcon"
                                alt="Suche"
                                class="icon mr-2"
                            >

                            <v-text-field
                                v-model="searchTreeView"
                                class="ma-0 pt-0 pb-2"
                                hide-details
                                clearable
                            />
                        </div>
                        <hr class="greyLine">

                        <vue-custom-scrollbar
                            class="scroll-area-treeview"
                            settings="settings"
                        >
                            <v-treeview
                                v-model="receiverAccounts"
                                :items="possibleReceivers"
                                item-children="participants"
                                item-key="_id"
                                item-text="displayName"
                                :search="searchTreeView"
                                selected-color="#AAC937"
                                selectable
                                dense
                                class="mt-2"
                                return-object
                            >
                                <template v-slot:prepend="{ item }">
                                    <div class="d-flex align-center">
                                        <img
                                            v-if="item.gender"
                                            :src="pupilIcon"
                                            :class="item.gender === 'w' ? 'iconToFemale' : (item.gender === 'm' ? 'iconToMale' : 'iconToDiverse')"
                                            height="20"
                                            alt=""
                                        >
                                        <img
                                            v-else
                                            :src="groupIcon"
                                            alt=""
                                        >
                                    </div>
                                </template>
                            </v-treeview>
                        </vue-custom-scrollbar>
                    </div>
                </div>
            </div>
        </v-dialog>
        <!--#endregion -->

        <!--#region PARENT dialogs -->
        <!-- PARENT | view/sign letter dialog -->
        <v-dialog
            v-model="showLetterDialog"
            width="400"
        >
            <div style="height: 80vh; overflow: hidden;">
                <div class="header">
                    <v-row class="headerRow mx-2 d-flex align-center justify-space-between">
                        <v-col
                            cols="9"
                            md="8"
                            lg="8"
                            class="pa-0 pl-2 d-flex align-center"
                            style="font-size: larger"
                        >
                            <img
                                aria-hidden="true"
                                :src="mailIcon"
                                class="icon iconToWhite mr-1"
                                alt=""
                            >
                        </v-col>
                        <v-col
                            cols="3"
                            md="4"
                            lg="3"
                            class="pa-0 d-flex align-center justify-end"
                        >
                            <v-btn
                                v-if="role === 'parent' & letter.requiresSignature && !letterSignedByMe"
                                id="dialogAcceptButton"
                                class="optionBtn"
                                x-small
                                color="fhellgruen"
                                :disabled="!signaturePhoto"
                                aria-label="Fertig"
                                @click="sendParentResponse"
                            >
                                <img
                                    aria-hidden="true"
                                    :src="fertigIcon"
                                    class="icon iconToWhite"
                                    alt="Fertig"
                                >
                            </v-btn>

                            <v-btn
                                id="dialogCloseButton"
                                class="optionBtn ml-2"
                                x-small
                                color="rgba(248, 248, 248, 0.4)"
                                @click="closeShowLetterDialog"
                            >
                                <img
                                    :src="schliessenIcon"
                                    class="icon iconToWhite"
                                    alt="Schließen"
                                >
                            </v-btn>
                        </v-col>
                    </v-row>
                </div>


                <div
                    class="whiteBg py-2"
                    style="border-radius: 0 0 15px 15px;"
                >
                    <vue-custom-scrollbar
                        class="scroll-area"
                        settings="settings"
                    >
                        <v-row
                            cols="12"
                            class="dialogRow"
                        >
                            <v-col class="pa-0 d-flex align-center justify-space-between">
                                <p class="headlineText">
                                    Titel
                                </p>

                                <v-btn
                                    v-if="role === 'parent'"
                                    aria-hidden="true"
                                    class="optionBtn"
                                    @click="readText(letter.title)"
                                >
                                    <img
                                        :src="lautsprecherIcon"
                                        class="icon"
                                        alt="Titel vorlesen"
                                    >
                                </v-btn>
                            </v-col>
                        </v-row>

                        <p class="greyText px-2">
                            {{ letter.title }}
                        </p>

                        <hr
                            aria-hidden="true"
                            class="greyLine mx-2"
                        >

                        <div v-if="letter.content">
                            <v-row
                                cols="12"
                                class="dialogRow mt-4"
                            >
                                <v-col class="pa-0 d-flex align-center justify-space-between">
                                    <p class="headlineText">
                                        Inhalt
                                    </p>

                                    <v-btn
                                        v-if="role === 'parent'"
                                        aria-hidden="true"
                                        class="optionBtn"
                                        @click="readText(letter.content)"
                                    >
                                        <img
                                            :src="lautsprecherIcon"
                                            class="icon"
                                            alt="Inhalt vorlesen"
                                        >
                                    </v-btn>
                                </v-col>
                            </v-row>

                            <p
                                id="letterContent"
                                class="greyText px-2"
                                v-html="urlify(letter.content)"
                            />

                            <hr
                                aria-hidden="true"
                                class="greyLine mx-2"
                            >
                        </div>

                        <!-- poll -->
                        <div
                            v-if="letter.poll"
                            class="px-2"
                        >
                            <poll :poll-id="letter.poll" />
                        </div>

                        <hr
                            aria-hidden="true"
                            class="greyLine mx-2"
                        >

                        <div v-if="letter.files">
                            <v-row
                                v-if="letter.files.length > 0"
                                cols="12"
                                class="dialogRow mt-4"
                            >
                                <v-col class="pa-0 d-flex align-center">
                                    <p class="headlineText">
                                        Anhang
                                    </p>
                                </v-col>
                            </v-row>
                            <div
                                v-for="(file, index) in letter.files"
                                :key="file._id"
                                class="d-flex justify-space-between align-center mx-2"
                                :class="index === letter.files.length-1 ? '' : 'mb-2'"
                            >
                                <p class="greyText ellipsis">
                                    {{ file.uploadedName }}
                                </p>
                                <v-btn
                                    class="actionBtn"
                                    x-small
                                    @click="downloadAttachment(letter._id, letter.files[index])"
                                >
                                    <img
                                        :src="downloadIcon"
                                        class="icon"
                                        alt="Datei herunterladen"
                                    >
                                </v-btn>
                            </div>
                        </div>

                        <!-- signature -->
                        <div
                            v-if="role === 'parent' && letter.requiresSignature"
                            class="pt-4 pb-2"
                        >
                            <v-row
                                cols="12"
                                class="dialogRow mt-4"
                            >
                                <v-col class="pa-0 d-flex align-center">
                                    <p class="headlineText">
                                        Unterschrift
                                    </p>
                                </v-col>
                            </v-row>

                            <div
                                v-if="!letterSignedByMe"
                                class="d-flex"
                            >
                                <div style="width: 50%">
                                    <img
                                        :src="signaturePointer"
                                        style="margin-left: -20px"
                                        alt=""
                                    >
                                </div>

                                <div
                                    style="width: 50%"
                                    class="px-0 mx-2"
                                >
                                    <div class="d-flex justify-end mb-2">
                                        <input
                                            id="signatureFileInput"
                                            ref="signatureFileInput"
                                            type="file"
                                            hidden
                                            @change="changeSignatureFileInput"
                                        >

                                        <img
                                            v-if="signaturePhoto"
                                            id="signaturePicture"
                                            :src="signaturePhoto"
                                            alt="Bild ihrer Unterschrift"
                                        >

                                        <v-btn
                                            class="actionBtn mr-2"
                                            x-small
                                            @click="clickElement('signatureFileInput')"
                                        >
                                            <img
                                                :src="fileUploadIcon"
                                                class="icon"
                                                alt="Datei als Antwort hochladen"
                                            >
                                        </v-btn>

                                        <!-- Signature Component which brings its own button -->
                                        <SignatureDialog
                                            @signatureDone="signatureDone"
                                            @signatureAbort="this.setFocusOn('dialogAcceptButton');"
                                        />
                                    </div>

                                    <hr
                                        v-if="!signaturePhoto"
                                        aria-hidden="true"
                                        class="greyLine"
                                    >
                                </div>
                            </div>

                            <div
                                v-else
                                class="px-2"
                            >
                                Sie haben diesen Brief bereits unterschrieben.
                            </div>
                        </div>
                    </vue-custom-scrollbar>
                </div>
            </div>
        </v-dialog>

        <!-- PARENT | create sick note dialog -->
        <v-dialog
            v-model="createSickNoteDialog"
            max-width="600px"
            persistent
            @click:outside="checkForSickNoteChanges"
        >
            <v-card
                class="cardStyle"
            >
                <v-card-title class="d-flex align-center justify-space-between">
                    <p>Entschuldigung erstellen</p>
                    <v-btn
                        icon
                        @click="checkForSickNoteChanges"
                    >
                        <v-icon style="transform: rotate(45deg)">
                            mdi-plus
                        </v-icon>
                    </v-btn>
                </v-card-title>

                <vue-custom-scrollbar
                    class="scroll-area-sickNote"
                    :settings="settings"
                >
                    <v-card-text>
                        <h1 class="formHeader">
                            Angaben zum Kontext
                        </h1>

                        <div class="d-flex justify-space-between wrapToCol">
                            <v-select
                                v-model="sickNoteReceiver"
                                :items="childTeachers"
                                :item-text="el => el.name.slice(0, 1) + ' ' + el.lastName"
                                item-value="account"
                                :menu-props="vSelectMenuProps"
                                no-data-text="Empfänger konnten nicht geladen werden"
                                filled
                                dense
                                hide-details
                                placeholder="Empfänger"
                                class="textFieldPadRight inputNoUnderline"
                                style="width: 50%"
                            />

                            <v-select
                                v-model="sickNotePupil"
                                :items="children"
                                :item-text="el => el.name + ' ' + el.lastName"
                                item-value="_id"
                                :menu-props="vSelectMenuProps"
                                no-data-text="Personen konnten nicht geladen werden"
                                filled
                                dense
                                hide-details
                                placeholder="Entschuldigte Person"
                                class="inputNoUnderline"
                                style="width: 50%"
                            />
                        </div>

                        <v-select
                            v-model="sickReasonIndex"
                            :items="sickReasons"
                            item-text="text"
                            item-value="id"
                            :menu-props="vSelectMenuProps"
                            no-data-text="Gründe konnten nicht geladen werden"
                            filled
                            dense
                            hide-details
                            placeholder="Entschuldigungsgrund"
                            class="padTop"
                            @change="sickNote = sickReasons[sickReasonIndex].description; sickReason = sickReasons[sickReasonIndex].text"
                        />

                        <h1 class="formHeader">
                            Angaben zum Inhalt
                        </h1>

                        <div class="d-flex justify-space-between wrapToCol">
                            <v-menu
                                offset-y
                                max-width="290"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="sickFrom"
                                        :type="sickFrom ? 'date' : ''"
                                        filled
                                        readonly
                                        dense
                                        hide-details
                                        placeholder="Krank von"
                                        class="textFieldPadRight"
                                        v-bind="attrs"
                                        v-on="on"
                                    />
                                </template>

                                <v-date-picker
                                    v-model="sickFrom"
                                    locale="de-DE"
                                    first-day-of-week="1"
                                    no-title
                                    show-week
                                    :allowed-dates="weekdaysOnly"
                                />
                            </v-menu>

                            <v-menu
                                offset-y
                                max-width="290"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="sickTill"
                                        :type="sickTill ? 'date' : ''"
                                        filled
                                        readonly
                                        dense
                                        hide-details
                                        placeholder="Krank bis"
                                        v-bind="attrs"
                                        v-on="on"
                                    />
                                </template>

                                <v-date-picker
                                    v-model="sickTill"
                                    locale="de-DE"
                                    first-day-of-week="1"
                                    no-title
                                    show-week
                                    :allowed-dates="weekdaysOnly"
                                />
                            </v-menu>
                        </div>

                        <v-textarea
                            v-model="sickNote"
                            class="padTop"
                            filled
                            dense
                            hide-details
                            auto-grow
                            placeholder="Beschreibung der Krankheit"
                        />

                        <h1
                            v-if="sickNoteFiles.length > 0"
                            class="formHeader"
                        >
                            Anhänge ({{ sickNoteFiles.length }})
                        </h1>
                        <h1
                            v-else
                            class="formHeader"
                        >
                            Anhänge
                        </h1>
                        <div
                            v-for="(file, index) in sickNoteFiles"
                            :key="file._id"
                            class="ek-listElement d-flex align-center px-2 ma-2"
                            :class="index === sickNoteFiles.length-1 ? 'mb-4' : ''"
                        >
                            <p>{{ file.name }}</p>
                        </div>
                        <v-btn
                            elevation="0"
                            @click="clickElement('sickNoteFileInput')"
                        >
                            + ärztliches Attest hochladen
                        </v-btn>

                        <input
                            id="sickNoteFileInput"
                            ref="sickNoteFileInput"
                            type="file"
                            hidden
                            multiple
                            @change="sickNoteInputChange"
                        >

                        <h1 class="formHeader">
                            Unterschrift
                        </h1>

                        <input
                            id="signatureSickNoteInput"
                            ref="signatureSickNoteInput"
                            type="file"
                            hidden
                            @change="changeSignatureFileInput"
                        >

                        <!-- Signature Component which brings its own button -->
                        <SignatureDialog
                            :sick-note="true"
                            @signatureDone="signatureSickNoteDone"
                            @signatureAbort="this.setFocusOn('eK-button-disabled');"
                        />
                    </v-card-text>
                </vue-custom-scrollbar>

                <v-card-actions class="d-flex justify-end pa-0">
                    <v-btn
                        id="eK-button-disabled"
                        :disabled="validateCreateSickNote()"
                        dark
                        elevation="0"
                        color="gruen"
                        class="text-none"
                        @click="handleSendSickNote"
                    >
                        Absenden
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!--#endregion -->
        <!--#endregion -->
    </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";

import * as backend from "../api/backend";
import {bus} from '@/main';

import vueCustomScrollbar from "vue-custom-scrollbar";
import AdvancedQuillEditor from "./Utils/AdvancedQuillEditor";
import ProgressBar from "@/components/ProgressBar";
import NameFile from "@/components/NameFile";
import Poll from "@/components/Poll";
import SignatureDialog from "./SignatureDialog";
import MessagesLetter from "@/components/MessagesLetter";
import MessagesSickNote from "@/components/MessagesSickNote";

import mailIcon from '@/assets/Icons/letter.svg';
import infoIcon from "@/assets/Icons/info-2-weiß-89.svg";
import lautsprecherIcon from "@/assets/Icons/lautsprecher-13.svg";
import downloadIcon from "@/assets/Icons/runterladen-14.svg";
import schliessenIcon from '@/assets/Icons/abbrechen-08.svg'
import fertigIcon from '@/assets/Icons/fertig-74.svg';
import sucheIcon from '@/assets/Icons/suche-26.svg';
import bearbeitenIcon from '@/assets/Icons/bearbeiten-komplimentär-grau-88.svg';
import hochladenIcon from '@/assets/Icons/hochladen-15.svg';
import fileUploadIcon from '@/assets/Icons/hochladen-15.svg';
import signaturePointer from '@/assets/Icons/roter-Balken-Unterschrift-125.svg';
import pupilIcon from '@/assets/Icons/profile.svg';
import groupIcon from '@/assets/Icons/gruppen-25.svg';
import leftIcon from '@/assets/Icons/links-filled-10.svg';
import deleteIcon from '@/assets/Icons/papierkorb-110.svg';
import searchIcon from '@/assets/Icons/lupe-18.svg';

export default {
    name: "Elternbrief",
    components: {
        AdvancedQuillEditor,
        MessagesLetter,
        MessagesSickNote,
        SignatureDialog,
        vueCustomScrollbar,
        ProgressBar,
        NameFile,
        Poll,
    },
    props: {
        role: { required: true },
        childId: { required: false },
    },
    data() {
        return {
            //#region icons
            mailIcon,
            infoIcon,
            lautsprecherIcon,
            downloadIcon,
            schliessenIcon,
            fertigIcon,
            sucheIcon,
            bearbeitenIcon,
            hochladenIcon,
            signaturePointer,
            pupilIcon,
            groupIcon,
            leftIcon,
            deleteIcon,
            searchIcon,
            fileUploadIcon,
            //#endregion

            settings: {
                suppressScrollY: false,
                suppressScrollX: true,
                wheelPropagation: false,
            },
            vSelectMenuProps: { bottom: true, offsetY: true },  // use this to have the v-select dropdown underneath
            showAttachmentUploadProgress: false,
            attachmentUploadProgress: 0.0,
            showNameFile: false,
            fileNameCallback: () => {},
            originalFileName: '',

            widgetInfoParent: 'Hier können Sie Ihre gesendeten und erhaltenen Mitteilungen ansehen. Sie können neue Entschuldigungen erstellen.',
            widgetInfoTeacher: 'Hier können Sie Ihre gesendeten und erhaltenen Mitteilungen ansehen. Sie können neue Elternbriefe erstellen.',
            showLetterDialog: false,            // opens letter for parent to sign
            letter: '',
            collapseLetter: false,              // TEACHER if looking at specific letter and you collapsed it

            // universal confirm dialog
            confirmDialog: false,
            confirmDialogType: 0, // 0: discard sickNote changes, 1: discard letter changes, 2: delete letter, 3: send letter , 4: edit letter
            confirmType: [
                { type: 0, title: 'Änderungen verwerfen?', text: 'Möchten Sie die Änderungen an der Entschuldigung verwerfen?' },
                { type: 1, title: 'Änderungen verwerfen?', text: 'Möchten Sie die Änderungen am Elternbrief verwerfen?' },
                { type: 2, title: 'Löschen bestätigen', text: 'Möchten Sie den Elternbrief wirklich löschen?' },
                { type: 3, title: 'Abschicken bestätigen', text: 'Möchten Sie den Elternbrief wirklich abschicken?' },
                { type: 4, title: 'Bearbeitung bestätigen', text: 'Wenn Sie den Elternbrief bearbeiten werden die vorhandenen Unterschriften gelöscht und der Brief erneut an die Empfänger versendet. Sind Sie sicher, dass sie den aktualisierten Brief neu versenden wollen?' },
            ],

            //#region sickNote dialog
            createSickNoteDialog: false,
            childTeachers: [],
            children: [],
            sickNoteReceiver: '',
            sickNotePupil: '',
            sickReasons: [
                { id: '0', text: 'Krankheit', description: 'Sehr (geehrte/r Frau/Herr Nachname), \n' +
                        'aufgrund (Krankheitsbeschreibung) muss mein Kind (Name) für den oben angegebenen ' +
                        'Zeitraum vom Schulunterricht fernbleiben. Hiermit bitte ich, das Fehlen zu entschuldigen. \n \n' +
                        'Mit freundlichen Grüßen, \n' +
                        '(Name), (Ort) den (Datum)' },
                { id: '1', text: 'Arztbesuch', description: 'Sehr (geehrte/r Frau/Herr Nachname), \n' +
                        '(Name) hat am (Datum) um (Uhrzeit) einen (Art des Arzttermins). ' +
                        'Ich möchte mein Kind hiermit für den Arzttermin entschuldigen. ' +
                        'Ein Attest vom Arzt bringt mein Kind am nächsten Schultag mit. \n \n' +
                        'Mit freundlichen Grüßen, \n' +
                        '(Name), (Ort) den (Datum)' },
                { id: '2', text: 'Befreiung Schulsport', description: 'Sehr (geehrte/r Frau/Herr Nachname), \n' +
                        'aufgrund (Verletzungs-/Krankheitsbeschreibung) kann mein Kind (Name) für den oben ' +
                        'angegebenen Zeitraum nicht am Schulsport teilnehmen. Anbei sehen Sie das ärztliche Attest.\n \n' +
                        'Mit freundlichen Grüßen, \n' +
                        '(Name), (Ort) den (Datum)' },
                { id: '3', text: 'Beurlaubung', description: 'Sehr (geehrte/r Frau/Herr Nachname), \n' +
                        'hiermit möchte ich mein Kind (Name) für den oben angegebenen Zeitraum ' +
                        'beurlauben. Der Grund ist (Grund für Beurlaubung). ' +
                        'Den verpassten Unterrichtsstoff werden wir mit (ihm/ihr) nachholen. \n' +
                        'Ich bitte hiermit vielmals um Verständnis. \n \n' +
                        'Mit freundlichen Grüßen, \n' +
                        '(Name), (Ort) den (Datum)' },
                { id: '4', text: 'Sonstiges', description: 'Sehr (geehrte/r Frau/Herr Nachname), \n' +
                        '(Entschuldigung) \n \n' +
                        'Mit freundlichen Grüßen, \n' +
                        '(Name), (Ort) den (Datum)' },
            ],
            sickReasonIndex: '',
            sickReason: '',
            sickFrom: '',
            sickTill: '',
            sickNote: '',
            sickNoteFiles: [],
            signatureXmlHttpRequest: null,
            currentSickNote: null,              // which sickNote you're currently looking at, if looking at one
            //#endregion

            messageCategory: 1,                  // 0 = sent, 1 = incoming, 2 = archive
            sentMessages: [],
            incomingMessages: [],

            // message categories for TEACHER, TODO: use categories above when redesigning TEACHER widget
            activeLetters: [],                  // letters not been signed by all receivers
            archivedLetters: [],                // letters signed by all receivers

            possibleReceivers: [],              // list of all groups the teacher leads
            searchTreeView: '',                 // used for searching the possible receivers in the treeview

            createLetterDialog: false,
            receiverAccounts: [],               // list with IDs of all the selected receivers
            title: '',
            body: '',
            attachedFiles: [],
            requiresSignature: false,

            editLetter: null,                   // true if you edit an existing letter
            filesFromOriginalLetter: [],        // for handling deleting when editing letter

            editTitle: null,
            editBody: null,
            containsPoll: null,
            currentLetter: null,                // which letter you're currently looking at, if looking at one
            letterSignedByMe: null,
            signedCurrentLetter: [],            // who signed the letter you're currently looking at
            notSignedCurrentLetter: [],         // who didn't sign current letter
            archivedLetter: null,               // true if looking at groups, otherwise references which archived letter to show
            selectReceiver: null,
            signaturePhoto: null,               // holds the photo for a signature response of parent
            letterToDelete: {                   // which letter to delete, because I need two params and a confirm dialog
                letterToDel: null,
                list: null                      // true means the letter is in archived- (currently not implemented), false it's in sentMessages list
            },

            pollValid: false,                   // to disable send letter btn until poll is valid

            signatureFile: [],                  // holds file to send as letter response or sick Note signature
            fileXmlHttpRequest: null,
        };
    },
    computed: {
        ...mapState('translation', [ 'targetLang' ]),
        ...mapState('util', ['windowWidth']),

        calcUnseenMsgs() {
            let seen = 0;
            let msg;

            if (this.role === 'teacher') {
                for (let i = 0; i < this.incomingMessages.length; i++) {
                    msg = this.incomingMessages[i]
                    if (msg.accepted === true || msg.accepted === false) {
                        seen++;
                    }
                }
            }
            else if (this.role === 'parent') {
                for (let i = 0; i < this.incomingMessages.length; i++) {
                    msg = this.incomingMessages[i]
                    if (msg.requiresSignature && msg.parentalLetterResponses.some((msg) => msg.account === this.childId)) {
                        seen++;
                    } else if (!msg.requiresSignature && msg.seenBy.includes(this.childId)) {
                        seen++;
                    }
                }
            }

            return this.incomingMessages.length - seen;
        },
    },
    async mounted() {
        bus.$off('pollCreated');
        bus.$on('pollCreated', (pollId) => {this.confirmSendLetter(true, pollId)});

        await this.getMessages();

        if (this.role === 'teacher') {
            await this.getPossibleReceivers();
        }
    },
    methods: {
        ...mapActions('translation', [ 'setTranslatedText', 'showTranslation', 'translateToTargetLang' ]),
        ...mapActions('parentalLetter', [ 'getAllParentalLetters', 'createLetter', 'setSeenBy', 'getParentalLetterList', 'getSignature', 'updateParentalLetter', 'deleteParentalLetter', 'deleteParentalLetterFile' ]),
        ...mapActions('sickNote', [ 'createSickNote', 'getSickNotes', 'getSickNote', 'acceptSickNote', 'declineSickNote' ]),
        ...mapActions('poll', [ 'createPoll' ]),
        ...mapActions('parent', [ 'getChildTeacher', 'getMeParent' ]),
        ...mapActions('pupils', [ 'getPupil' ]),
        ...mapActions('teachers', [ 'getMeTeacher' ]),
        ...mapMutations('snackbar', [ 'showSnackbar' ]),

        //#region helpers
        async setFocusOn(elementId) {
            await this.$nextTick();
            document.getElementById(elementId).focus();
        },

        clickElement(element) {
            document.getElementById(element).click();
        },

        // only allow weekdays to be selected in date picker
        weekdaysOnly(val) {
            let date = new Date(val);
            //saturday is weekday 0, sunday is weekday 6, so monday-friday is 1-5
            return date.getDay() > 0 && date.getDay() < 6;
        },

        sortDateNewToOld(a, b) {
            return a.createdAt < b.createdAt ? 1 : (a.createdAt > b.createdAt ? -1 : 0);
        },
        //#endregion

        //#region get data
        async getMessages() {
            let allLetters;
            let allSickNotes = await this.getSickNotes();

            if (this.role === 'teacher') {
                allLetters = await this.getAllParentalLetters();
                let me = await this.getMeTeacher();

                this.sentMessages = allLetters.filter(letter => letter.account === me.account);
                this.incomingMessages = allSickNotes.filter(msg => msg.receiver === me.account);
            } else if (this.role === 'parent') {
                // sick notes
                let me = await this.getMeParent();
                this.sentMessages = allSickNotes.filter(msg => msg.author === me.account);
                this.incomingMessages = allSickNotes.filter(msg => !this.sentMessages.includes(msg));

                // parental letters
                this.incomingMessages = this.incomingMessages.concat(await this.getAllParentalLetters({ pupilAccount: this.childId }));
            }
            // sort arrays by latest to oldest
            this.sentMessages.sort(this.sortDateNewToOld);
            this.incomingMessages.sort(this.sortDateNewToOld);
        },

        async getLetters() {
            let allLetters = [];
            this.activeLetters = [];
            this.archivedLetters = [];

            if (this.role === 'teacher') {
                try {
                    allLetters = await this.getAllParentalLetters();
                    this.archivedLetters = allLetters.filter(letter => (letter.requiresSignature ? letter.parentalLetterResponses.length : letter.seenBy.length) === letter.targetAccounts.length);
                    this.activeLetters = allLetters.filter(letter => !this.archivedLetters.includes(letter));
                } catch (e) {
                    console.error(e);
                }
            }
            else if (this.role === 'parent') {
                allLetters = await this.getAllParentalLetters({ pupilAccount: this.childId });
                this.incomingMessages = allLetters;
            }
            else {
                console.error("Unsupported role!");
            }
        },

        async downloadAttachment(letterId, file) {
            try {
                const res = await backend.getParentalLetterFile(letterId, file._id);
                let blob = await res.blob();
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = url;
                a.download = file.uploadedName;
                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();
                setTimeout(function(){
                    a.remove();
                    window.URL.revokeObjectURL(url);
                }, 1000);
            }
            catch (e) {
                console.error(e);
            }
        },
        //#endregion

        handleClickLetterDialog() {
            this.editLetter ? this.confirmDialogType = 4 : this.confirmDialogType = 3;
            this.confirmDialog = true;
        },

        handleConfirmDialogDecline() {
            switch (this.confirmDialogType) {
                case 2:
                    this.confirmDialog = false;
                    this.letterToDelete.letterToDel = null;
                    this.letterToDelete.list = null;
                    break;
                default:
                    this.confirmDialog = false;
            }
        },

        handleConfirmDialogAccept() {
            switch (this.confirmDialogType) {
                case 0:
                    this.clearInputs();
                    break;
                case 1:
                    this.resetCreateLetter();
                    break;
                case 2:
                    this.deleteLetter();
                    break;
                case 3:
                    this.confirmSendLetter();
                    break;
                case 4:
                    this.confirmEditLetter();
                    break;
            }
        },

        async handleClickSentMessage(message) {
            // sent message as teacher is parental letter
            if (this.role === 'teacher') {
                this.setCurrentLetter(message);
            }
            // sent message as parent is sick note
            else if (this.role === 'parent') {
                this.currentSickNote = await this.getSickNote(message._id);
            } else {
                console.warn('role not supported');
            }
        },

        async handleClickIncomingMessage(message) {
            // incoming message as teacher is sick note
            if (this.role === 'teacher') {
                this.currentSickNote = await this.getSickNote(message._id);
            }
            // incoming message as parent is parental letter
            else if (this.role === 'parent') {
                await this.openLetter(message);
            } else {
                console.warn('role not supported');
            }
        },

        closeShowLetterDialog() {
            this.showLetterDialog = false;
            this.letter = '';
        },

        handleClickCategory(category) {
            this.messageCategory = category;
        },

        setCollapseLetter(value) {
            this.collapseLetter = value;
        },

        //#region Text to Speech
        async readText(textToRead) {
            if ('speechSynthesis' in window) {
                // Speech Synthesis supported 🎉
            } else {
                // Speech Synthesis Not Supported 😣
                alert("Sorry, your browser doesn't support text to speech!");
                return
            }

            let tmp = document.createElement('div');
            tmp.innerHTML = textToRead;
            let text  = tmp.textContent || tmp.innerText;

            if (this.targetLang !== 'de') {
                text = await this.translateToTargetLang({
                    targetLang: this.targetLang,
                    textToTranslate: text
                });
            }

            if (window.speechSynthesis.speaking) {
                window.speechSynthesis.cancel();
                this.showTranslation(false);
            } else {
                this.setTranslatedText(text);
                this.showTranslation(true);

                if (this.showTextTimeout) {
                    clearTimeout(this.showTextTimeout);
                    this.showTextTimeout = null;
                }

                this.showTextTimeout = setTimeout(() => {
                    this.showTranslation(false);
                    this.showTextTimeout = null;
                }, 15000)

                let msg = new SpeechSynthesisUtterance();
                msg.text = text;
                if (this.isLangPackageAvailable()) {
                    msg.lang = this.targetLang;
                    window.speechSynthesis.speak(msg);
                }
            }
        },

        isLangPackageAvailable() {
            for (let i = 0; i < window.speechSynthesis.getVoices().length; i++) {
                if (window.speechSynthesis.getVoices()[i].lang.includes(this.targetLang)) {
                    return true;
                }
            }

            return false;
        },
        //#endregion

        //#region TEACHER methods
        displayReceivers(list) {
            let returnString;
            let names = list.map(item => item.name.slice(0,1) + '. ' + item.lastName);
            names.sort()

            names.forEach(function(name, i) {
                if (i === 0) {
                    returnString = name
                } else {
                    returnString += ', ' + name
                }
            })

            return returnString;
        },

        setCurrentLetter(letter) {
            this.currentLetter = letter;
            this.signedCurrentLetter = [];
            this.notSignedCurrentLetter = [];

            for (let i = 0; i < letter.targetAccounts.length; i++) {
                const currentTarget = letter.targetAccounts[i];
                let fittingResponse = null;

                if (letter.requiresSignature) {
                    fittingResponse = letter.parentalLetterResponses.find(item => item.account === currentTarget._id)
                } else {
                    fittingResponse = letter.seenBy.find(item => item === currentTarget._id)
                }

                if (fittingResponse) {
                    this.signedCurrentLetter.push({
                        ...fittingResponse,
                        name: currentTarget.name,
                        lastName: currentTarget.lastName,
                    });
                } else {
                    this.notSignedCurrentLetter.push(currentTarget);
                }
            }
        },

        // sets field 'displayName' to group.name for treeview
        async getPossibleReceivers() {
            try {
                let addItemText = await this.getParentalLetterList();
                for (let i = 0; i < addItemText.length; i++) {
                    addItemText[i].displayName = addItemText[i].name
                }
                this.possibleReceivers = addItemText;
            } catch (e) {
                console.error(e);
            }
        },

        urlify(text) {
            // eslint-disable-next-line no-useless-escape
            var urlRegex = /(([a-z]+:\/\/)?(([a-z0-9\-]+\.)+([a-z]{2}|aero|arpa|biz|com|software|coop|edu|gov|info|int|jobs|mil|museum|name|nato|net|org|pro|travel|local|internal|de))(:[0-9]{1,5})?(\/[a-z0-9_\-\.~]+)*(\/([a-z0-9_\-\.]*)(\?[a-z0-9+_\-\.%=&amp;]*)?)?(#[a-zA-Z0-9!$&'()*+.=-_~:@/?]*)?)(\s+|$)/gi;
            return text.toString().replace(urlRegex, function(url) {
                url = url.includes('http') ? url : 'http://' + url;
                return '<a href="' + url + '" target="_blank" rel="noopener noreferrer">' + url + '</a>';
            })
            // or alternatively
            // return text.replace(urlRegex, '<a href="$1">$1</a>')
        },

        // used by click outside and close button
        checkForChanges() {
            let changes = 0;
            if (this.body !== '') {
                changes++;
            }
            if (this.title !== '') {
                changes++;
            }
            if (this.receiverAccounts.length !== 0) {
                changes++;
            }
            if (this.attachedFiles.length !== 0) {
                changes++;
            }
            if (this.requiresSignature !== false) {
                changes++;
            }

            if (changes > 0) {
                this.confirmDialogType = 1;
                this.confirmDialog = true;
            } else {
                this.resetCreateLetter();
            }
        },

        async confirmSendLetter(calledWithPoll, pollIdEmitted) {
            this.confirmDialog = false;
            this.createLetterDialog = false;

            if (this.containsPoll) {
                // calling a method in child (Poll), this works by using the Event Bus, which is created in
                // main.js with 'export const bus = new Vue();'
                // child uses bus.$on to listen to this event
                bus.$emit('createPollAndEmit');
                // now set containsPoll to false, MessagesWidget listens to emit of 'pollCreated' and calls this method again
                this.containsPoll = false;
            } else {
                let letter;
                if (calledWithPoll) {
                    letter = {
                        targetAccounts: this.receiverAccounts,
                        title: this.title,
                        content: this.body,
                        requiresSignature: this.requiresSignature,
                        pollId: pollIdEmitted,
                    }
                } else {
                    letter = {
                        targetAccounts: this.receiverAccounts,
                        title: this.title,
                        content: this.body,
                        requiresSignature: this.requiresSignature,
                    }
                }

                const formData = new FormData();
                const files = this.$refs.fileInput.files;

                let resLetter = await this.createLetter(letter);

                if (files.length > 0) {
                    for (let i = 0; i < this.attachedFiles.length; i++) {
                        formData.append("file", files[i], files[i].name);
                    }

                    this.fileXmlHttpRequest = backend.postParentalLetterFile(resLetter._id, files);

                    this.fileXmlHttpRequest.onerror = (e) => {
                        console.error("attachment upload error:", e);
                        this.showAttachmentUploadProgress = false;
                        this.fileXmlHttpRequest = null;
                        this.$refs.fileInput.value = "";
                    };

                    this.fileXmlHttpRequest.onabort = () => {
                        console.warn("attachment upload aborted");
                        this.showAttachmentUploadProgress = false;
                        this.fileXmlHttpRequest = null;
                        this.$refs.fileInput.value = "";
                    };

                    this.fileXmlHttpRequest.addEventListener("load", () => {
                        if (resLetter.status === 201 && this.fileXmlHttpRequest.status === 201) {
                            this.showSnackbar({ message: 'Elternbrief erfolgreich verschickt.'})
                        } else if (this.fileXmlHttpRequest.status !== 201) {
                            this.showSnackbar({ message: 'Beim Hochladen des Anhangs ist ein Fehler aufgetreten.', color: 'error'});
                        } else {
                            this.showSnackbar({ message: 'Beim Versenden des Briefs ist ein Fehler aufgetreten.', color: 'error'});
                        }

                        this.showAttachmentUploadProgress = false;
                        this.fileXmlHttpRequest = null;
                        this.$refs.fileInput.value = "";
                    });

                    this.fileXmlHttpRequest.upload.addEventListener("progress", (e) => {
                        this.attachmentUploadProgress = (e.loaded / e.total) * 100;
                    });

                    this.attachmentUploadProgress = 0.0;
                    this.showAttachmentUploadProgress = true;
                    this.fileXmlHttpRequest.send(formData);
                }

                this.resetCreateLetter();
                await this.getMessages();
            }
        },
        fileInputChange() {
            let input = this.$refs.fileInput;
            this.attachedFiles = [];
            for (let i = 0; i < input.files.length; i++) {
                this.attachedFiles.push(input.files[i]);
            }
        },

        removeFile(file) {
            this.attachedFiles.splice(this.attachedFiles.indexOf(file), 1);
        },

        setEditLetter(letter) {
            this.letter = letter;
            this.editLetter = true;
            this.receiverAccounts = letter.targetAccounts;
            this.title = letter.title;
            this.body = letter.content;
            this.filesFromOriginalLetter = letter.files;
            // add a field to mark files if they are supposed to be deleted
            for (let i = 0; i < this.filesFromOriginalLetter.length; i++) {
                this.filesFromOriginalLetter[i].toBeDeleted = false;
            }
            this.requiresSignature = letter.requiresSignature;
            this.createLetterDialog = true;
        },

        markFileForDeletion(file) {
            let index = this.filesFromOriginalLetter.indexOf(file);
            this.filesFromOriginalLetter[index].toBeDeleted = !this.filesFromOriginalLetter[index].toBeDeleted;

            // forces a re-render so styling of btn is correct (red /w icon or regular)
            this.editTitle = true;
            this.editTitle = false;
        },

        async confirmEditLetter() {
            let successPatchLetter = false;
            let successDeleteFileFromOriginalLetter = false;

            let letter = {
                id: this.letter._id,
                targetAccounts: this.receiverAccounts,
                title: this.title,
                content: this.body,
                requiresSignature: this.requiresSignature,
            }

            let resLetter = await this.updateParentalLetter(letter);

            if (resLetter.status === 200) {
                successPatchLetter = true;
            }

            // delete files from original letter if toBeDeleted is true
            let filesToBeDeletedCounter = 0;
            let arrayLengthBeforeDeleting = this.filesFromOriginalLetter.length;
            for (let i = 0; i < this.filesFromOriginalLetter.length; i++) {
                if (this.filesFromOriginalLetter[i].toBeDeleted === true) {
                    filesToBeDeletedCounter++;
                    await backend.deleteParentalLetterFile(this.letter._id, this.filesFromOriginalLetter[i]._id);
                    this.filesFromOriginalLetter.splice(i, 1);
                }
            }
            if (arrayLengthBeforeDeleting - filesToBeDeletedCounter === this.filesFromOriginalLetter.length) {
                successDeleteFileFromOriginalLetter = true;
            }

            const formData = new FormData();
            const files = this.$refs.fileInput.files;

            for (let i = 0; i < this.attachedFiles.length; i++) {
                formData.append("file", files[i], files[i].name);
            }

            this.fileXmlHttpRequest = backend.postParentalLetterFile(this.letter._id, files);

            this.fileXmlHttpRequest.onerror = (e) => {
                console.error("attachment upload error:", e);
                this.showAttachmentUploadProgress = false;
                this.fileXmlHttpRequest = null;
                this.$refs.fileInput.value = "";
            };

            this.fileXmlHttpRequest.onabort = () => {
                console.warn("attachment upload aborted");
                this.showAttachmentUploadProgress = false;
                this.fileXmlHttpRequest = null;
                this.$refs.fileInput.value = "";
            };

            this.fileXmlHttpRequest.addEventListener("load", () => {
                if (successPatchLetter && successDeleteFileFromOriginalLetter && this.fileXmlHttpRequest.status === 201) {
                    this.showSnackbar({ message: 'Elternbrief erfolgreich editiert.'});
                } else {
                    this.showSnackbar({ message: 'Beim Editieren des Briefs ist ein Fehler aufgetreten.', color: 'error'});
                }

                this.showAttachmentUploadProgress = false;
                this.fileXmlHttpRequest = null;
                this.$refs.fileInput.value = "";
            });

            this.fileXmlHttpRequest.upload.addEventListener("progress", (e) => {
                this.attachmentUploadProgress = (e.loaded / e.total) * 100;
            });

            this.attachmentUploadProgress = 0.0;
            this.showAttachmentUploadProgress = true;
            this.fileXmlHttpRequest.send(formData);

            this.editLetter = false;
            this.confirmDialog = false;
            this.resetCreateLetter();
            await this.getLetters();
        },

        // opens the dialog and sets the two params
        openConfirmDelDialog(letter, list) {
            this.confirmDialogType = 2;
            this.confirmDialog = true;
            this.letterToDelete.letterToDel = letter;
            this.letterToDelete.list = list;
        },

        // deletes letter if dialog is confirmed, splicing is to remove letter from list rather than do backend request
        async deleteLetter() {
            this.confirmDialog = false;

            let res = await this.deleteParentalLetter(this.letterToDelete.letterToDel._id);
            if (res.status === 200) {
                this.currentLetter = null;
                this.showSnackbar({ message: 'Elternbrief "' + this.letterToDelete.letterToDel.title + '" erfolgreich gelöscht.'})

                if (this.letterToDelete.list) {
                    // TODO: implement when archive category is also implemented
                    // this.archivedLetters.splice(this.archivedLetters.indexOf(this.letterToDelete.letterToDel), 1);
                } else if (!this.letterToDelete.list) {
                    this.sentMessages.splice(this.sentMessages.indexOf(this.letterToDelete.letterToDel), 1);
                } else {
                    console.warn("Please specify list");
                }
            } else {
                this.showSnackbar({ message: 'Beim Löschen des Briefs ist ein Fehler aufgetreten.', color: 'error'})
            }
        },

        resetCreateLetter() {
            this.confirmDialog = false;
            this.createLetterDialog = false;
            this.title = '';
            this.body = '';
            this.requiresSignature = false;
            this.receiverAccounts = [];
            this.attachedFiles = [];
            this.editTitle = null;
            this.editBody = null;
            this.selectReceiver = false;
            this.searchTreeView = '';
            this.containsPoll = false;
        },

        async handleAcceptSickNote(sickNoteId) {
            let res = await this.acceptSickNote(sickNoteId);
            if (res.status === 200) {
                this.showSnackbar({ message: 'Entschuldigung erfolgreich akzeptiert.'});
                // also set accepted in incomingMessages so it does no longer show the newIndicator
                let index = this.incomingMessages.map(e => e._id).indexOf(this.currentSickNote._id);
                let newItem = this.incomingMessages[index];
                newItem.accepted = true;
                // using splice so it's reactive and calcUnseenMsgs is recalculated
                this.incomingMessages.splice(index, 1, newItem);
            } else {
                this.showSnackbar({ message: 'Beim Annehmen der Entschuldigung ist ein Fehler aufgetreten.'})
            }
        },
        async handleDeclineSickNote(sickNoteId) {
            let res = await this.declineSickNote(sickNoteId);
            if (res.status === 200) {
                this.showSnackbar({ message: 'Entschuldigung erfolgreich abgelehnt.'});
                // also set accepted in incomingMessages so it does no longer show the newIndicator
                let index = this.incomingMessages.map(e => e._id).indexOf(this.currentSickNote._id);
                let newItem = this.incomingMessages[index];
                newItem.accepted = false;
                // using splice so it's reactive and calcUnseenMsgs is recalculated
                this.incomingMessages.splice(index, 1, newItem);
            } else {
                this.showSnackbar({ message: 'Beim Ablehnen der Entschuldigung ist ein Fehler aufgetreten.'})
            }
        },
        //#endregion

        //#region PARENT methods
        //#region sick note methods
        async handleClickCreateSickNote() {
            this.childTeachers = await this.getChildTeacher(this.childId);
            let me = await this.getMeParent();
            let pupils = me.pupils;
            pupils.map(async (pupil) => { let item = await this.getPupil(pupil); this.children.push(item) });
            this.createSickNoteDialog = true;
        },

        sickNoteInputChange() {
            let input = this.$refs.sickNoteFileInput;
            this.sickNoteFiles = [];
            for (let i = 0; i < input.files.length; i++) {
                this.sickNoteFiles.push(input.files[i]);
            }
        },

        async handleSendSickNote() {
            let body = {
                receiver: this.sickNoteReceiver,
                pupil: this.sickNotePupil,
                from: this.sickFrom,
                till: this.sickTill,
                title: this.sickReason,
                content: this.sickNote,
            };
            let sickNote = await this.createSickNote(body);

            const formData = new FormData();
            const files = this.$refs.sickNoteFileInput.files;

            if (files.length > 0) {
                for (let i = 0; i < this.sickNoteFiles.length; i++) {
                    formData.append("file", files[i], files[i].name);
                }

                this.fileXmlHttpRequest = backend.postSickNoteFile(sickNote._id, files);

                this.fileXmlHttpRequest.onerror = (e) => {
                    console.error("attachment upload error:", e);
                    this.showAttachmentUploadProgress = false;
                    this.fileXmlHttpRequest = null;
                    this.$refs.sickNoteFileInput.value = "";
                };

                this.fileXmlHttpRequest.onabort = () => {
                    console.warn("attachment upload aborted");
                    this.showAttachmentUploadProgress = false;
                    this.fileXmlHttpRequest = null;
                    this.$refs.sickNoteFileInput.value = "";
                };

                this.fileXmlHttpRequest.addEventListener("load", async () => {
                    if (sickNote.status === 201 && this.fileXmlHttpRequest.status === 201) {
                        await this.sendSickNoteSignature(sickNote);
                    } else if (this.fileXmlHttpRequest.status !== 201) {
                        this.showSnackbar({ message: 'Beim Hochladen des Anhangs ist ein Fehler aufgetreten.', color: 'error'});
                    } else {
                        this.showSnackbar({ message: 'Beim Versenden der Entschuldigung ist ein Fehler aufgetreten.', color: 'error'});
                    }

                    this.showAttachmentUploadProgress = false;
                    this.fileXmlHttpRequest = null;
                    this.$refs.sickNoteFileInput.value = "";
                });

                this.fileXmlHttpRequest.upload.addEventListener("progress", (e) => {
                    this.attachmentUploadProgress = (e.loaded / e.total) * 100;
                });

                this.attachmentUploadProgress = 0.0;
                this.showAttachmentUploadProgress = true;
                this.fileXmlHttpRequest.send(formData);
            } else {
                await this.sendSickNoteSignature(sickNote);
            }

            this.clearInputs();
            await this.getMessages();
        },

        async sendSickNoteSignature(sickNote) {
            let fileBlob = this.signatureFile;
            if (!fileBlob && this.signaturePhoto) {
                const fileFetch = await fetch(this.signaturePhoto);
                fileBlob = await fileFetch.blob();
            }

            await this.createSickNoteSignature(fileBlob, 'signature.png', sickNote);
        },

        async createSickNoteSignature(file, fileName, sickNote) {
            const formData = new FormData();
            formData.append("file", file, fileName);

            this.signatureXmlHttpRequest = backend.uploadSickNoteSignature(sickNote._id, file);

            this.signatureXmlHttpRequest.onerror = (e) => {
                console.error("signature response upload error:", e);
                this.showAttachmentUploadProgress = false;
                this.signatureXmlHttpRequest = null;
                this.$refs.signatureSickNoteInput.value = "";
            };

            this.signatureXmlHttpRequest.onabort = () => {
                console.warn("signature response upload aborted");
                this.showAttachmentUploadProgress = false;
                this.signatureXmlHttpRequest = null;
                this.$refs.signatureSickNoteInput.value = "";
            };

            this.signatureXmlHttpRequest.addEventListener("load", () => {
                if (this.signatureXmlHttpRequest.status === 200) {
                    this.showSnackbar({ message: 'Entschuldigung erfolgreich versendet.'});
                } else {
                    this.showSnackbar({message: 'Beim Hochladen der Unterschrift ist ein Fehler aufgetreten.', color: 'error'});
                }

                this.showAttachmentUploadProgress = false;
                this.signatureXmlHttpRequest = null;
                this.$refs.signatureSickNoteInput.value = "";
            });

            this.signatureXmlHttpRequest.upload.addEventListener("progress", (e) => {
                this.attachmentUploadProgress = (e.loaded / e.total) * 100;
            });

            this.attachmentUploadProgress = 0.0;
            this.showAttachmentUploadProgress = true;
            this.signatureXmlHttpRequest.send(formData);
        },

        clearInputs() {
            this.confirmDialog = false;
            this.createSickNoteDialog = false;
            this.sickNoteReceiver = '';
            this.sickNotePupil = '';
            this.sickFrom = '';
            this.sickTill = '';
            this.sickReasonIndex = '';
            this.sickReason = '';
            this.sickNote = '';
            this.sickNoteFiles = [];
        },

        // used by click outside and close button
        checkForSickNoteChanges() {
            let changes = 0;
            if (this.sickNoteReceiver !== '') {
                changes++;
            }
            if (this.sickNotePupil !== '') {
                changes++;
            }
            if (this.sickReason !== '') {
                changes++;
            }
            if (this.sickFrom !== '') {
                changes++;
            }
            if (this.sickTill !== '') {
                changes++;
            }
            if (this.sickNote !== '') {
                changes++;
            }
            if (this.sickTill !== '') {
                changes++;
            }
            if (this.sickNoteFiles.length !== 0) {
                changes++;
            }

            if (changes > 0) {
                this.confirmDialogType = 0; // 0 = discard sickNote changes
                this.confirmDialog = true;
            } else {
                this.clearInputs();
            }
        },

        /**
         * checks if all required fields are valid
         * @returns {boolean} true if there is an error, it's used for :disabled
         */
        validateCreateSickNote() {
            let error = false;

            if (!(this.sickNoteReceiver.length > 0)) {
                error = true;
            }
            if (!this.sickNotePupil) {
                error = true;
            }
            if (!this.sickFrom) {
                error = true;
            }
            if (!this.sickTill) {
                error = true;
            }
            if (this.sickReason.replace(/\s/g, '') === '') {
                error = true;
            }
            if (this.sickNote.replace(/\s/g, '') === '') {
                error = true;
            }
            if (!(this.signatureFile.size > 0)) {
                error = true;
            }

            return error;
        },

        async signatureSickNoteDone(imgData) {
            this.signaturePhoto = imgData;
            const signatureFile = await fetch(imgData);
            this.signatureFile = await signatureFile.blob();

            this.setFocusOn('eK-button-disabled');
        },
        //#endregion

        async signatureDone(imgData, sendLetter, letter) {
            this.signaturePhoto = imgData;
            const signatureFile = await fetch(imgData);
            this.signatureFile = await signatureFile.blob();

            if (sendLetter) {
                this.letter = letter;
                await this.sendParentResponse();
            }

            this.setFocusOn('dialogAcceptButton');
        },

        async openLetter(letter) {
            this.letter = letter;

            let responses = this.letter.parentalLetterResponses.map(response => response.account);
            this.letterSignedByMe = responses.some(id => id === this.childId);

            this.showLetterDialog = true;
            await this.$nextTick()
            document.getElementById("dialogCloseButton").focus();
            let result;

            this.letter.seenByMe = this.letter.seenBy.some((account) => account === this.childId);

            if (this.role === 'parent' && !this.letter.seenByMe) {
                let body = {
                    letterId: letter._id,
                    childId: this.childId,
                };
                result = await this.setSeenBy(body);
                // push childId into seenBy array so calcUnseenMsgs is newly computed and thus badge updated
                if (result === 200) {
                    this.incomingMessages[this.incomingMessages.findIndex(msg => msg._id === this.letter._id)].seenBy.push(this.childId);
                }
            }
        },

        changeSignatureFileInput() {
            let input = this.$refs.signatureFileInput;
            this.signatureFile = input.files[0];

            if (this.signatureFile) {
                const name = this.signatureFile.name;
                this.originalFileName = name.substring(0, name.lastIndexOf("."));

                this.fileNameCallback = (newFileName) => {
                    this.showNameFile = false;
                    this.createFileResponse(this.signatureFile, newFileName, this.letter);
                }
                this.showNameFile = true;
            }
        },

        async sendParentResponse() {
            let fileBlob = this.signatureFile;
            if (!fileBlob && this.signaturePhoto) {
                const fileFetch = await fetch(this.signaturePhoto);
                fileBlob = await fileFetch.blob();
            }

            await this.createFileResponse(fileBlob, 'signature.png', this.letter);
            this.showLetterDialog = false;

            this.setFocusOn('Elternbrief-' + this.letter._id);
        },

        async createFileResponse(file, fileName, letter) {
            const formData = new FormData();
            formData.append("file", file, fileName);
            formData.append("account", this.childId);

            this.fileXmlHttpRequest = backend.createResponse(letter._id, file);

            this.fileXmlHttpRequest.onerror = (e) => {
                console.error("file response upload error:", e);
                this.showAttachmentUploadProgress = false;
                this.fileXmlHttpRequest = null;
                this.$refs.signatureFileInput.value = "";
            };

            this.fileXmlHttpRequest.onabort = () => {
                console.warn("file response upload aborted");
                this.showAttachmentUploadProgress = false;
                this.fileXmlHttpRequest = null;
                this.$refs.signatureFileInput.value = "";
            };

            this.fileXmlHttpRequest.addEventListener("load", () => {
                if (this.fileXmlHttpRequest.status === 201) {
                    this.showSnackbar({message: 'Datei erfolgreich hochgeladen'});
                } else {
                    this.showSnackbar({message: 'Beim Hochladen der Datei ist ein Fehler aufgetreten.', color: 'error'});
                }

                this.showAttachmentUploadProgress = false;
                this.fileXmlHttpRequest = null;
                this.$refs.signatureFileInput.value = "";
            });

            this.fileXmlHttpRequest.upload.addEventListener("progress", (e) => {
                this.attachmentUploadProgress = (e.loaded / e.total) * 100;
            });

            this.attachmentUploadProgress = 0.0;
            this.showAttachmentUploadProgress = true;
            this.fileXmlHttpRequest.send(formData);

            await this.getLetters();
        },
        //#endregion
    },
}
</script>

<style lang="scss">
.cardStyle {
    .v-card__text {
        // unify border-radius
        .v-btn {
            border-radius: 8px;
        }
        .v-input__control>.v-input__slot {
            border-radius: 8px;
        }

        // remove border bottom line/underline from all the inputs in v-cards with this class
        .v-input__control>.v-input__slot:before, .v-input__control>.v-input__slot:after {
            border-style: none !important;
        }
    }
}
</style>

<style lang="scss" scoped>
.formHeader {
    font-size: 16px;
    margin-top: 40px !important;
    margin-bottom: 20px !important;
}

.v-btn {
    text-transform: none !important;
}

.hoverableElement:hover {
    background-color: #F1F1F1 !important;
}

.v-btn-toggle > .v-btn.v-btn--active {
    border-radius: 8px;
    color: white;
}

.cardStyle {
    height: 90vh;
    overflow: hidden;
    border-radius: 8px;
    padding: 40px;

    .v-card__title {
        margin: 0;
        padding: 0;
    }
    .v-card__text {
        margin: 0;
        padding: 0;

        .textFieldPadRight {
            padding-right: 30px;
        }

        .padTop {
            padding-top: 15px;
        }
    }
    .v-card__action {
        margin: 40px 0 0;
        padding: 0 !important;
    }
}

.ek-listElement {
    cursor: pointer;
    width: calc(100% - 32px);
    height: 48px;
    border-radius: 8px;
    border: solid #BDBDBD 0;
    box-shadow: 0 2px 8px 1px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 2px 8px 1px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 2px 8px 1px rgba(0, 0, 0, 0.2);
}

.ek-badge {
    height: 24px;
    color: white !important;
    background-color: var(--v-grau-base);
    border-radius: 8px;
    padding: 0 8px;
    margin-right: 4px;
}

#ek-newIndicator {
    position: absolute;
    left: 16px;
    width: 8px;
    height: 48px;
    border-radius: 8px 0 0 8px;
    background-color: var(--v-flila-base);
}

.ek-green-text {
    color: var(--v-gruen-base);
}

.ek-red-text {
    color: var(--v-frot-base);
}

// custom disabled button, it uses an id and important as the regular vuetify button styling already uses disabled
// https://stackoverflow.com/a/63762909
#eK-button-disabled.v-btn--disabled {
    opacity: 0.5;
    background-color: var(--v-grau-base) !important;
    color: white !important;
}

.icon {
    height: 20px;
}

.iconToWhite {
    filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%) hue-rotate(36deg) brightness(104%) contrast(105%);
}

.iconToFemale {
    filter: brightness(0) saturate(100%) invert(40%) sepia(81%) saturate(2866%) hue-rotate(326deg) brightness(92%) contrast(97%);
}

.iconToMale {
    filter: brightness(0) saturate(100%) invert(36%) sepia(17%) saturate(1992%) hue-rotate(192deg) brightness(93%) contrast(80%);
}

.iconToDiverse {
    filter: brightness(0) saturate(100%) invert(15%) sepia(52%) saturate(5688%) hue-rotate(281deg) brightness(93%) contrast(89%);
}

.whiteBg {
    background-color: #ffffff;
}

.greyText {
    color: var(--v-dunkelgrau-base);
}

.own-red-text {
    color: var(--v-editorWrong-base);
}

.pointer {
    cursor: pointer;
}

.notSigned {
    background-color: rgba(212, 0, 0, 0.3);
}

.signed {
    background-color: rgba(43, 171, 109, 0.3);
}

.scroll-area {
    position: relative;
    margin: auto;
    max-height: calc(80vh - 55px);
}

.scroll-area-responses {
    position: relative;
    margin: auto;
    max-height: 50vh;
    /*border-radius: 0 0 15px 15px;*/
}

.scroll-area-treeview {
    position: relative;
    margin: auto;
    max-height: calc(80vh - 120px);
}

.scroll-area-sickNote {
    position: relative;
    margin: auto;
    max-height: calc(90vh - 160px);
}

.scroll-area-messages {
    position: relative;
    margin: auto;
    width: 100%;
    max-height: calc(75vh - 36px - 55px);
}

.ellipsis {
    text-overflow: ellipsis;
    /* Required for text-overflow to do anything */
    white-space: nowrap;
    overflow: hidden;
}

.widgetContainer {
    height: auto;
    width: 100%;
    max-width: 30vw;
    min-height: 80vh;
    max-height: 80vh;
    border-radius: 15px;
    box-shadow: 1px 5px 5px silver;
    margin: auto auto 5em;
}

.header {
    background-color: var(--v-flila-base);
    border-radius: 15px 15px 0 0;
    padding-top: 10px;
    padding-bottom: 10px;
    color: white;
}

.headerRow {
    max-width: 100%;
    height: 35px;
}

.sectionHeaderCol {
    padding-top: 0;
    padding-bottom: 0;
}

.letters {
    p {
        color: var(--v-dunkelgrau-base);
    }
}

.activeLetter {
    background-color: var(--v-hellgrau-base);
    border-radius: 12px;
}

.actionBtn {
    color: white;
    border-radius: 8px;
    min-width: 30px !important;
    width: 30px !important;
    height: 30px !important;
}

.optionBtn {
    border-radius: 8px;
    min-width: 35px !important;
    width: 35px !important;
    height: 35px !important;
}

.thinHr {
    height: 0;
    border: none;
    border-bottom: 1px solid;
    color: var(--v-dunkelgrau-base);
    background-color: var(--v-dunkelgrau-base);
}

.greyLine {
    color: var(--v-hellgrau-base);
    background-color: var(--v-hellgrau-base);
    border: 1px solid;
}

.headlineText {
    color: var(--v-dunkelgrau-base);
    margin-bottom: 0;
    font-weight: bold;
}

.dialogRow {
    width: 99%;
    padding: 0 8px;
    margin: 0.5em auto 0.5em auto;
}

.fileRow {
    background-color: var(--v-hellgrau-base);
}

#signaturePicture {
    width: auto;
    min-width: 40px;
    height: 50px;
}

#letterContent {
    white-space: pre-line;
    max-width: 100%;
    word-break: normal;
}

@media only screen and (max-width: 900px) {
    .widgetContainer {
        width: 90%;
        max-width: none;
    }
}

@media only screen and (max-width: 600px) {
    .cardStyle {
        padding: 16px;
    }

    .scroll-area-sickNote {
        max-height: calc(90vh - 110px);
    }

    .cardStyle .v-card__text .textFieldPadRight {
        padding-right: 15px;
    }

    .wrapToCol {
        flex-direction: column;
        gap: 15px;

        .v-select, .v-text-field {
            width: 100% !important;
            padding-right: 0 !important;
        }
    }
}
</style>
