<template>
    <div>
        <Header style="position: fixed; width: 100%; z-index: 100;" />
        <keep-alive>
            <router-view style="width: calc(100% - 56px); margin-left: 56px; margin-top: 100px" />
        </keep-alive>
        <ContractVerwaltung v-if="showDialogGDPR" />
        <VideoTutorial
            v-if="videoTutorial"
            :video-tutorial="videoTutorial"
            @close="videoTutorialClosed = true"
        />
    </div>
</template>

<script>
import Header from "../components/Verwaltung/Header";
import VideoTutorial from "../components/Tutorial/VideoTutorial.vue";
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex';
import ContractVerwaltung from '../components/Verwaltung/ContractVerwaltung.vue';
import * as backend from "../api/backend";

export default {
    name: 'Main',
    components: {
        Header,
        VideoTutorial,
        ContractVerwaltung,
    },
    data: () => ({
        videoTutorialClosed: false,
    }),
    computed: {
        ...mapGetters('videoTutorial', ['videoTutorialsByFilename']),
        ...mapGetters("maintainer", ["showDialogGDPR"]),
        videoTutorial() {
            const tutorial = this.videoTutorialsByFilename['Verwaltung_15Min_erklaert.mp4'];
            if (tutorial?.seeAgain && !this.videoTutorialClosed) {
                return tutorial;
            }
            return false;
        }
    },
    async mounted() {
    },
    methods: {
        ...mapMutations("maintainer", ["setShowDialogGDPR"]),
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
</style>
