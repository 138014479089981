<template>
    <BaseMenu
        :items="neededItems"
        :show-list="showList"
        header-color="green"
        header-title="Speichern unter"
        @close="$emit('close')"
        @itemClicked="onItemSelected"
    >
        <!-- Add custom data here -->
        <div>
            Custom data
        </div>
    </BaseMenu>
</template>

<script>
import BaseMenu from "@/components/Editortools/Toolbar/Menus/BaseMenu";
import hochladenIcon from "@/assets/Icons/hochladen-15.svg";
import verlassenIcon from "@/assets/Icons/exit-20.svg";
import infoIcon from "@/assets/Icons/info-2-121.svg";

export default {
    name: 'CreatorSaveMenu',
    components: {BaseMenu},
    props: {
        mode: {required: true, type: String},
        isNextPupilUploadAvailable: { required: false, type: Boolean, default: false }
    },
    data() {
        return {
            showList: true,
            items: [
                {icon: hochladenIcon, text: 'Privat speichern', val: 0},
            ],

            optItems: [
                {icon: hochladenIcon, text: 'Geteilt speichern', val: 1},
                {icon: hochladenIcon, text: 'PDF-Export', val: 2},
                {icon: verlassenIcon, text: 'Verlassen', val: 3, color: 'red'}
            ],
        }
    },
    computed: {
        neededItems() {
            if (this.mode === 'teacher') {
                return [{
                    icon: infoIcon,
                    text: 'Nächste Abgabe',
                    val: 4,
                    disabled: !this.isNextPupilUploadAvailable,
                    tooltip: 'Falls es eine weitere Abgabe in dieser Fachstunde zu korrigieren gibt, können Sie diese über diesen Knopf öffnen.'
                }, {
                    icon: verlassenIcon, text: 'Verlassen', val: 3, color: 'red'
                }];
            }
            if (this.mode === 'pupil') {
                return this.items.concat([{icon: verlassenIcon, text: 'Verlassen', val: 3, color: 'red'}]);
            }
            return this.items.concat(this.optItems);
        }
    },
    methods: {
        onItemSelected(item) {
            switch (item.val) {
                case 0:
                    this.$emit('onSaveInPrivateFolder');
                    break;
                case 1:
                    this.$emit('onSaveInSharedFolder');
                    break;
                case 2:
                    this.$emit('onExport');
                    break;
                case 3:
                    this.$emit('onLeave');
                    break;
                case 4:
                    this.$emit('onNextPupilUpload');
                    break;
            }
        }
    }
}
</script>

<style scoped>

</style>
