<template>
    <div>
        <!-- teacher View -->
        <div
            v-if="showTeacherUI || showCorrectionUI || showViewerUI"
            id="calculationOuter"
            ref="calculationOuter"
            class="calculationOuter"
            @click="$emit('focusMe')"
        >
            <v-container
                id="calculationInner"
                ref="calculationInner"
                fluid
                class="pa-0"
            >
                <div style="display: flex; flex-direction: column; flex-wrap: nowrap">
                    <!-- + und - Rechnungen -->
                    <div v-if="selectedOperator === '+' || selectedOperator === '-'">
                        <div
                            v-for="rowIndex in rows.length"
                            :key="rowIndex"
                            style="display: flex; flex-direction: row; justify-content: flex-end"
                        >
                            <span
                                v-if="rowIndex > 1"
                                style="font-size: xx-large; max-height: 30px; margin-top: -6px"
                                :style="maxSize(rowIndex-1)"
                            >{{ selectedOperator }}</span>
                            <div
                                v-for="index2 in rows[rowIndex - 1].length"
                                :key="index2"
                                style="float: right"
                            >
                                <span style="border-style: solid; border-width: 1px; font-size: x-large">{{ rows[rowIndex - 1][index2 - 1] }}</span>
                            </div>
                        </div>
                    </div>
                    <!-- * Rechnungen -->
                    <div
                        v-else-if="selectedOperator === '*'"
                        style="display: flex; flex-direction: row; justify-content: flex-end; flex-wrap: nowrap"
                    >
                        <div
                            v-for="rowIndex in rows.length"
                            :key="rowIndex"
                            style="display: flex; flex-direction: row; justify-content: flex-end"
                        >
                            <span
                                v-if="rowIndex > 1"
                                style="font-size: xx-large; max-height: 30px"
                            >{{ selectedOperator }}</span>
                            <div
                                v-for="index2 in rows[rowIndex - 1].length"
                                :key="index2"
                                style="float: right"
                            >
                                <span style="border-style: solid; border-width: 1px; font-size: x-large">{{ rows[index - 1][index2 - 1] }}</span>
                            </div>
                        </div>
                    </div>
                    <!-- / Rechnungen -->
                    <div
                        v-else-if="selectedOperator === '/'"
                        style="display: flex; flex-direction: row; justify-content: flex-end; flex-wrap: nowrap"
                    >
                        <div
                            v-for="rowIndex in rows.length"
                            :key="rowIndex"
                            style="display: flex; flex-direction: row; justify-content: flex-end"
                        >
                            <span
                                v-if="index > 1"
                                style="font-size: xx-large; max-height: 30px"
                            >{{ selectedOperator }}</span>
                            <div
                                v-for="index2 in rows[index - 1].length"
                                :key="index2"
                                style="float: right"
                            >
                                <span style="border-style: solid; border-width: 1px; font-size: x-large">{{ rows[index - 1][index2 - 1] }}</span>
                            </div>
                        </div>
                    </div>
                    <!-- Übertrag -->
                    <div
                        v-for="(el, uebertragIndex) in uebertrag"
                        :key="uebertragIndex"
                        style="display: flex; flex-direction: row; justify-content: flex-end"
                    >
                        <span
                            v-if="el !== 0"
                            style=""
                        >{{ el }}</span>
                        <span
                            v-else
                            style="width: 30px"
                        />
                    </div>

                    <hr aria-hidden="true">

                    <!-- Ergebnis -->
                    <div style="display: flex; flex-direction: row; flex-wrap: nowrap; justify-content: flex-end">
                        <div
                            v-for="(el, resultIndex) in result"
                            :key="resultIndex"
                        >
                            <span style="border-style: solid; border-width: 1px; font-size: x-large">{{ el }}</span>
                        </div>
                    </div>
                </div>
                <!-- config -->
                <div
                    v-if="!lockedTeacherComponent"
                    class="mt-3"
                >
                    <div style="display: flex; align-items: center">
                        <div class="">
                            <v-btn
                                width="20"
                                height="20"
                                min-width="20"
                                fab
                                @click="removeRow"
                            >
                                -
                            </v-btn>
                            <span class="mx-1">{{ rows.length }}</span>
                            <v-btn
                                width="20"
                                height="20"
                                min-width="20"
                                fab
                                @click="addRow"
                            >
                                +
                            </v-btn>
                        </div>
                        <span class="mt-2 ml-4">Operatoren:</span>
                        <v-select
                            v-model="selectedOperator"
                            type="text"
                            class="ml-4"
                            style="margin-top: 2px; max-width: 50px"
                            hide-details
                            dense
                            :items="calculationOperators"
                        />
                    </div>
                    <div
                        class="mt-1"
                        style="display: flex"
                    >
                        <span class="mt-2">Zahlenraum:</span>
                        <div style="width: 75px">
                            <v-select
                                v-model="range"
                                dense
                                class="ml-2"
                                style="width: 75px !important; min-width: 0px; margin-top: 2px"
                                hide-details
                                :items="randomRange"
                            />
                        </div>
                        <v-btn
                            class="ml-6"
                            style="width: 35px; height: 35px"
                            fab
                            @click="randomizeInput"
                        >
                            <img
                                style="height: 17px"
                                :src="aktualisierenIcon"
                            >
                        </v-btn>
                    </div>
                </div>
                <div
                    class="mt-3"
                    style="float: right"
                >
                    <v-btn
                        v-if="!lockedTeacherComponent"
                        color="success"
                        class="ml-6"
                        style="width: 35px; height: 35px"
                        @click="execRowCalculationConfirm"
                    >
                        <img
                            class="iconToWhite"
                            alt="Fertig"
                            style="height: 17px"
                            :src="fertigIcon"
                        >
                    </v-btn>
                    <v-btn
                        v-else-if="lockedTeacherComponent"
                        class="ml-6"
                        style="width: 35px; height: 35px"
                        @click="lockedTeacherComponent = false"
                    >
                        <img
                            style="height: 17px"
                            alt="Bearbeiten"
                            :src="bearbeitenIcon"
                        >
                    </v-btn>
                </div>
            </v-container>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import addIcon from "../../../../assets/Icons/hinzufuegen-07.svg";
import fertigIcon from "../../../../assets/Icons/fertig-74.svg";
import aktualisierenIcon from "../../../../assets/Icons/aktualisieren-107.svg";
import papierkorbIcon from "../../../../assets/Icons/papierkorb-109.svg";
import bearbeitenIcon from "../../../../assets/Icons/bearbeiten-normal-41.svg";
import eyeIconClosed from "../../../../assets/Icons/eye-off.svg";
import eyeIconOpen from "../../../../assets/Icons/eye.svg";

export default {
    name: 'EditorRowCalculation',
    props: {
        config: { type: Object, required: true },
        state: { type: String, required: true },
        index: { type: Number, required: true },
        targetLang: { type: String, required: false, default: 'de' },
        translate: { type: Boolean, required: false, default: false },
        exportMode: { type: Boolean, required: false, default: false },
        tabIndex: { type: Number, required: false, default: 0 },
        canvasWidth: {type: Number, required: true},
        canvasHeight: {type: Number, required: true},
    },
    data() {
        return {
            calculationOperators: ['+','-','*','/'],
            selectedOperator: '+',
            rows: [
                [9,5],
                [3,6,6],
            ],

            range: 10,
            randomRange: [10, 100, 1000],
            lockedTeacherComponent: false,
            aktualisierenIcon,
            fertigIcon,
            bearbeitenIcon,
        };
    },
    computed: {
        ...mapGetters("auth",["accountRole"]),

        result() {
            let calculation = [];
            for(let index = 0; index < this.rows.length; index++) {
                let row = '';
                for(let index2 = 0; index2 < this.rows[index].length; index2++) {
                    row = row + this.rows[index][index2] + ''
                }
                calculation.push(row);
            }
            

            let result = parseInt(calculation[0]);
            for(let index = 1; index < calculation.length; index++) {
                switch (this.selectedOperator) {
                    case '+':
                        result = result + parseInt(calculation[index]);
                        break;
                    case '-':
                        result = result - parseInt(calculation[index]);
                        break;
                    case '*':
                        result = result * parseInt(calculation[index]);
                        break;
                    case '/':
                        result = result / parseInt(calculation[index]);
                        break;
                }
            }
            let resultArray = result.toString().split('');
            
            return resultArray;
        },

        uebertrag() {
            let calculation = [];
            for(let index = 0; index < this.rows.length; index++) {
                let row = '';
                for(let index2 = 0; index2 < this.rows[index].length; index2++) {
                    row = row + this.rows[index][index2] + ''
                }
                calculation.push(row);
            }
            

            let rowArrays = [];
            for(let index = 0; index < calculation.length; index++) {
                rowArrays.push(calculation[index].split(''));
            }
            

            // Die Größe des längsten Arrays der Rechnung herausfinden
            let maxLength = 0;
            for(let index = 0; index < rowArrays.length; index++) {
                if(rowArrays[index].length > maxLength) {
                    maxLength = rowArrays[index].length;
                }
            }

            // Die restlichen Arrays mit nullen auffüllen
            for(let index = 0; index < rowArrays.length; index++) {
                if(rowArrays[index].length < maxLength) {
                    
                    let rowArraysCurrentLength = rowArrays[index].length;
                    for(let index2 = 0; index2 < maxLength - rowArraysCurrentLength; index2++) {
                        rowArrays[index].unshift('0');
                    }
                }
            }
            

            let uebertrag = [];
            for(let indexCol = rowArrays[0].length-1; indexCol >= 0; indexCol--) {
                let columnResult = 0;
                for(let indexRow = 0; indexRow < rowArrays.length; indexRow++) {
                    columnResult += parseInt(rowArrays[indexRow][indexCol])

                }
                // if(indexCol !== rowArrays[0].length-1) {
                //     columnResult += uebertrag[indexCol]
                // }
                columnResult = columnResult + '';
                let rowResultArray = columnResult.split('');
                for(let i = 0; i < rowResultArray.length - 1; i++) {
                    this.$set(uebertrag, i + rowArrays[0].length - indexCol, parseInt(rowResultArray[i]))
                    // this.$set(this.rows, i, number.toString().split('').map(Number));
                    //     uebertrag.splice([i] = ( parseInt(rowResultArray[i]));
                }
            }
            return [0, 0]
        },

        showTeacherUI() {
            return !this.exportMode && this.state === 'creator';
        },
        showCorrectionUI() {
            // New Modes viewerTeacher and viewerPupil to make sure pupils dont see corrections early
            return this.state === 'teacher' || this.state === 'viewerTeacher';
        },
        showViewerUI() {
            return this.state === 'viewer' || this.state === 'viewerPupil';
        },
    },
    watch: {
        numbers: {
            handler(val, oldVal) {
                this.recalculate();
                this.saveState();
            },
            deep: true
        },
        operators: {
            handler(val, oldVal) {
                this.recalculate();
                this.saveState();
            },
            deep: true
        },
        resultInput: {
            handler(val, oldVal) {
                this.saveState();
            },
        },
        numbersInput: {
            handler(val, oldVal) {
                this.saveState();
            },
            deep: true
        },
        operatorsInput: {
            handler(val, oldVal) {
                this.saveState();
            },
            deep: true
        },

    },
    mounted() {
        
    },
    methods: {
        ...mapActions('pupils', [ 'getMePupil' ]),

        maxSize(index) {
            let max = 0;
            for(let i = 0; i < this.rows.length; i++) {
                if(this.rows[i].length > max) {
                    max = this.rows[i].length;
                }
            }
            max = max - this.rows[index].length
            return 'margin-right: ' + max * 17 + 'px';
        },

        addRow() {
            let number = this.getRandomIntInclusive(0, this.range);
            this.rows.push(number.toString().split('').map(Number));
        },

        removeRow() {
            if(this.rows.length > 2) {
                this.rows.pop();
            }
        },

        randomizeInput() {
            for(let i = 0; i < this.rows.length; i++) {
                let number = this.getRandomIntInclusive(0, this.range);
                this.$set(this.rows, i, number.toString().split('').map(Number));
            }
        },

        getRandomIntInclusive(min, max) {
            min = Math.ceil(min);
            max = Math.floor(max);
            return Math.floor(Math.random() * (max - min +1)) + min;
        },

        saveState() {
            
            // save values by overwriting the calcConfig properties
            // which get saved in the db schoolSubjectAppointmentUploadMessage
            this.config.calcConfig.numbers = this.numbers;
            this.config.calcConfig.numbersInput = this.numbersInput;
            this.config.calcConfig.numbersHidden = this.numbersHidden;
            this.config.calcConfig.operators = this.operators;
            this.config.calcConfig.operatorsInput = this.operatorsInput;
            this.config.calcConfig.operatorsHidden = this.operatorsHidden;
            this.config.calcConfig.result = this.result;
            this.config.calcConfig.resultInput = this.resultInput;
            this.config.calcConfig.resultHidden = this.resultHidden;
        },

        execRowCalculationConfirm() {
            this.lockedTeacherComponent = true;
            // ensure intial history
            if(!this.config.calcConfig) {
                this.config.calcConfig = {};
            }
            // deepCopy the intial State which gets uploaded as history
            const calcCopy = {...this.config.calcConfig};
            // then save new inputs under calcConfig
            this.saveState();
            this.$emit('history', { calcConfig: calcCopy });
        },
    }
}
</script>

<style scoped lang="scss">
    .hiddenForPupil {
      border-style: dotted;
      border-width: 5px;
      border-color: rgba(107, 105, 105, 0.15);
    }

    .answerCorrect {
      border-style: dotted;
      border-width: 5px;
      border-color: rgba(0, 168, 76, 0.507);
    }

    .answerWrong {
      border-style: dotted;
      border-width: 5px;
      border-color: rgba(255, 0, 0, 0.349);
    }

    .spanToTextfield {
      box-shadow: 0px 3px 1px -2px #0003, 0px 2px 2px 0px #00000024, 0px 1px 5px 0px #0000001f;
    }

    .noClick {
        pointer-events: none;
    }

    .calculationOuter {
        z-index: 7 !important;
        //max-width: 300px; > Caused resize issue
    }
    /* ----------------------------------------------
    * Generated by Animista on 2021-1-19 12:22:3
    * Licensed under FreeBSD License.
    * See http://animista.net/license for more info.
    * w: http://animista.net, t: @cssanimista
    * ---------------------------------------------- */

    /**
     * ----------------------------------------
     * animation heartbeat
     * ----------------------------------------
     */
    @-webkit-keyframes heartbeat {
        from {
            -webkit-transform: scale(1);
            transform: scale(1);
            -webkit-transform-origin: center center;
            transform-origin: center center;
            -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
        }
        10% {
            -webkit-transform: scale(0.91);
            transform: scale(0.91);
            -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
        }
        17% {
            -webkit-transform: scale(0.98);
            transform: scale(0.98);
            -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
        }
        33% {
            -webkit-transform: scale(0.87);
            transform: scale(0.87);
            -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
        }
        45% {
            -webkit-transform: scale(1);
            transform: scale(1);
            -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
        }
    }

    .heartbeat {
        -webkit-animation: heartbeat 2s ease-in-out 2s infinite both;
        animation: heartbeat 2s ease-in-out 2s infinite both;
    }

    @keyframes heartbeat {
        from {
            -webkit-transform: scale(1);
            transform: scale(1);
            -webkit-transform-origin: center center;
            transform-origin: center center;
            -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
        }
        10% {
            -webkit-transform: scale(0.91);
            transform: scale(0.91);
            -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
        }
        17% {
            -webkit-transform: scale(0.98);
            transform: scale(0.98);
            -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
        }
        33% {
            -webkit-transform: scale(0.87);
            transform: scale(0.87);
            -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
        }
        45% {
            -webkit-transform: scale(1);
            transform: scale(1);
            -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
        }
    }

    .iconToWhite {
        filter: brightness(1000%);
    }

    .playButton {
        text-transform: inherit;
        color: white;
    }

    .notesButtonsImage {
        height: 30px;
        filter: brightness(0%);
    }
</style>
