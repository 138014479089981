<template>
    <div
        id="clickArea"
        :style="'top:' + (y - 100) + 'px; left:' + (x - 100) + 'px'"
    />
</template>

<script>

import {mapActions, mapGetters, mapState} from 'vuex';

export default {
    name: "Magnifier",
    components: {
    },
    props: {

    },
    data() {
        return {
            x: 0,
            y: 0,
            keys: [],
            magnifier: null,
            magnifierActive: false,
            zoom: 2,
        };
    },
    computed: {

    },
    async mounted() {
        this.magnifier = new this.HTMLMagnifier({ width: 400, height: 400, zoom: this.zoom });

        this.magnifier.show();
        this.magnifier.setPosition((this.x), (this.y));
        this.dragElement(document.getElementById("clickArea"));

    },
     beforeDestroy() {
         this.magnifier.hide();
         delete this.magnifier;
     },
    methods: {
        ...mapActions('translation', [ 'setTranslatedText', 'showTranslation' ]),

        dragElement(elmnt) {
            let _this = this;
            var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;

            let lastMove = null;
            if (document.getElementById(elmnt.id + "header")) {
                // if present, the header is where you move the DIV from:
                document.getElementById(elmnt.id + "header").onmousedown = dragMouseDown;
                document.getElementById(elmnt.id + "header").ontouchstart = dragMouseDown;
            } else {
                // otherwise, move the DIV from anywhere inside the DIV:
                elmnt.onmousedown = dragMouseDown;
                elmnt.ontouchstart = dragMouseDown;
            }

            function dragMouseDown(e) {
                e = e || window.event;
                e.preventDefault();
                // get the mouse cursor position at startup:
                pos3 = e.clientX;
                pos4 = e.clientY;
                lastMove = e;
                document.onmouseup = closeDragElement;
                document.ontouchend = closeDragElement;
                // call a function whenever the cursor moves:
                document.onmousemove = elementDrag;
                document.ontouchmove = elementDrag;
            }

            function elementDrag(e) {
                e = e || window.event;
                lastMove = e;
                if(e.type !== 'touchmove') {
                        e.preventDefault();
                }

                // calculate the new cursor position:
                pos1 = pos3 - (e.clientX ? e.clientX : e.touches[0].clientX);
                pos2 = pos4 - (e.clientY ? e.clientY : e.touches[0].clientY);
                pos3 = (e.clientX ? e.clientX : e.touches[0].clientX);
                pos4 = (e.clientY ? e.clientY : e.touches[0].clientY);
                // set the element's new position:
                elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
                elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
                _this.magnifier.setPosition((elmnt.offsetLeft - pos1), (elmnt.offsetTop - pos2));
            }

            function closeDragElement(e) {
                // stop moving when mouse button is released:
                document.onmouseup = null;
                document.onmousemove = null;
                document.ontouchend = null;
                document.ontouchmove = null;
                _this.click(e.clientX ? e.clientX : lastMove.touches[0].clientX, e.clientY ? e.clientY : lastMove.touches[0].clientY);
                lastMove = null;
            }
        },

        click(x,y) {
                const target = document.elementFromPoint(x, y);
                target.classList.add("noClick");
            // document.elementFromPoint(event.clientX, event.clientY).focus();

            let rect = target.getBoundingClientRect();

            let centerx = rect.left;
            let centery = rect.top;

            let xinside = x - centerx; //x position within the element from the center.
            let yinside = y - centery;  //y position within the element from the center.

            const ele =  document.elementFromPoint(centerx + (xinside / this.zoom), centery + (yinside / this.zoom));

            if(ele) {
                ele.focus();
                ele.click();
            }
            event.target.classList.remove("noClick");
        },

        HTMLMagnifier(options) {

            const _this = this;

            _this.options = Object.assign({ zoom: 2, shape: 'square', width: 200, height: 200 }, options);

            const magnifierTemplate = `<div class="magnifier" style="display: none;position: fixed;overflow: hidden;background-color: white;border: 1px solid #555;border-radius: 4px;z-index:10000; pointer-events:none; touch-action:none;">
                                       <div class="magnifier-content" style="top: 0px;left: 0px;margin-left: 0px;margin-top: 0px;overflow: visible;position: absolute;display: block;transform-origin: left top;-moz-transform-origin: left top;-ms-transform-origin: left top;-webkit-transform-origin: left top;-o-transform-origin: left top;user-select: none;-moz-user-select: none;-webkit-user-select: none;padding-top: 0px; pointer-events:none; touch-action:none;"></div>
                                       <div class="magnifier-glass" style="position: absolute;top: 0px;left: 0px;width: 100%;height: 100%;opacity: 0.0;-ms-filter: alpha(opacity=0);background-color: white;cursor: move;pointer-events:none; touch-action:none;"></div>
                                     </div>`;

            const MutationObserver = window.MutationObserver || window.WebKitMutationObserver;

            let magnifier, magnifierContent;
            let observerObj;
            let syncTimeout;
            let isVisible = false;
            let magnifierBody;
            let events = {};

            function setPosition(element, left, top) {
                element.style.left = left + 'px';
                element.style.top = top + 'px';
            }

            function setDimensions(element, width, height) {
                element.style.width = width + 'px';
                element.style.height = height + 'px';
            }

            function setupMagnifier() {
                switch(_this.options.shape) {
                    case 'square':
                        setDimensions(magnifier, _this.options.width, _this.options.height);
                        break;
                    case 'circle':
                        setDimensions(magnifier, _this.options.width, _this.options.height);
                        magnifier.style.borderRadius = '50%';
                        break;
                }
                magnifierContent.style.WebkitTransform =
                    magnifierContent.style.MozTransform =
                        magnifierContent.style.OTransform =
                            magnifierContent.style.MsTransform =
                                magnifierContent.style.transform = 'scale(' + _this.options.zoom + ')';
                //magnifierContent.style.transformOrigin = 'center center';
            }

            function isDescendant(parent, child) {
                let node = child;
                while (node != null) {
                    if (node == parent) {
                        return true;
                    }
                    node = node.parentNode;
                }
                return false;
            }

            function syncContent() {
                if (isVisible) {
                    prepareContent();
                    syncViewport();
                    syncScrollBars();
                }
            }

            function syncContentQueued() {
                if (isVisible) {
                    window.clearTimeout(syncTimeout);
                    syncTimeout = window.setTimeout(syncContent, 100);
                }
            }

            function domChanged() {
                if (isVisible) {
                    syncContentQueued();
                }
            }

            function unBindDOMObserver() {
                if (observerObj) {
                    observerObj.disconnect();
                    observerObj = null;
                }
                if (document.removeEventListener) {
                    document.removeEventListener('DOMNodeInserted', domChanged, false);
                    document.removeEventListener('DOMNodeRemoved', domChanged, false);
                }
            }

            function bindDOMObserver() {
                if (MutationObserver) {
                    observerObj = new MutationObserver(function(mutations, observer) {
                        for(let i = 0; i < mutations.length; i++) {
                            if (!isDescendant(magnifier, mutations[i].target)) {
                                domChanged();
                                break;
                            }
                        }
                    });
                    observerObj.observe(document, {childList:true, subtree:true, attributes:true});
                } else
                if (document.addEventListener) {
                    document.addEventListener('DOMNodeInserted', domChanged, false);
                    document.addEventListener('DOMNodeRemoved', domChanged, false);
                }
            }

            function triggerEvent(event, data) {
                const handlers = events[event];
                if (handlers) {
                    for(let i = 0; i < handlers.length; i++) {
                        handlers[i].call(_this, data);
                    }
                }
            }

            function syncViewport() {
                const x1 = magnifier.offsetLeft;
                const y1 = magnifier.offsetTop;
                const x2 = document.body.scrollLeft;
                const y2 = document.body.scrollTop;
                const left = -x1 * _this.options.zoom - x2 * _this.options.zoom;
                const top = -y1 * _this.options.zoom - y2 * _this.options.zoom;
                setPosition(magnifierContent, left, top);
                triggerEvent('viewPortChanged', magnifierBody);
            }

            function removeSelectors(container, selector) {
                const elements = container.querySelectorAll(selector);
                if (elements.length > 0) {
                    for(let i = 0; i < elements.length; i++) {
                        elements[i].parentNode.removeChild(elements[i]);
                    }
                }
            }

            function prepareContent() {
                magnifierContent.innerHTML = '';
                const bodyOriginal = document.body;
                const bodyCopy = bodyOriginal.cloneNode(true);
                const color = bodyOriginal.style.backgroundColor;
                if (color) {
                    magnifier.css('background-color', color);
                }
                bodyCopy.style.cursor = 'auto';
                bodyCopy.style.paddingTop = '0px';
                bodyCopy.setAttribute('unselectable', 'on');
                const canvasOriginal = bodyOriginal.querySelectorAll('canvas');
                const canvasCopy = bodyCopy.querySelectorAll('canvas');
                if (canvasOriginal.length > 0) {
                    if (canvasOriginal.length === canvasCopy.length) {
                        for(let i = 0; i < canvasOriginal.length; i++) {
                            let ctx = canvasCopy[i].getContext('2d');
                            ctx.drawImage(canvasOriginal[i], 0, 0);
                        }
                    }
                }
                removeSelectors(bodyCopy, 'script');
                removeSelectors(bodyCopy, 'audio');
                removeSelectors(bodyCopy, 'video');
                removeSelectors(bodyCopy, '.magnifier');
                triggerEvent('prepareContent', bodyCopy);
                magnifierContent.appendChild(bodyCopy);
                const width = document.body.clientWidth;
                const height = document.body.clientHeight;
                setDimensions(magnifierContent, width, height);
                magnifierBody = magnifierContent.querySelector('body');
                triggerEvent('contentUpdated', magnifierBody);
            }

            function initScrollBars() {
                triggerEvent('initScrollBars', magnifierBody);
            }

            function syncScroll(ctrl) {
                const selectors = [];
                if (ctrl.getAttribute) {
                    if (ctrl.getAttribute('id')) {
                        selectors.push('#' + ctrl.getAttribute('id'));
                    }
                    if (ctrl.className) {
                        selectors.push('.' + ctrl.className.split(' ').join('.'));
                    }
                    for(let i = 0; i < selectors.length; i++) {
                        let t = magnifierBody.querySelectorAll(selectors[i]);
                        if (t.length == 1) {
                            t[0].scrollTop  = ctrl.scrollTop;
                            t[0].scrollLeft = ctrl.scrollLeft;
                            return true;
                        }
                    }
                } else
                if (ctrl == document) {
                    syncViewport();
                }
                return false;
            }

            function syncScrollBars(e) {
                if (isVisible) {
                    if (e && e.target) {
                        syncScroll(e.target);
                    } else {
                        let scrolled = [];
                        let elements = document.querySelectorAll('div');
                        for(let i = 0; i < elements.length; i++) {
                            if (elements[i].scrollTop > 0) {
                                scrolled.push(elements[i]);
                            }
                        }
                        for(let i = 0; i < scrolled.length; i++) {
                            if (!isDescendant(magnifier, scrolled[i])) {
                                syncScroll(scrolled[i]);
                            }
                        }
                    }
                    triggerEvent('syncScrollBars', magnifierBody);
                }
            }

            function init() {
                const div = document.createElement('div');
                div.innerHTML = magnifierTemplate;
                magnifier = div.querySelector('.magnifier');
                document.body.appendChild(magnifier);
                magnifierContent = magnifier.querySelector('.magnifier-content');
                if (window.addEventListener) {
                    window.addEventListener('resize', syncContent, false);
                    window.addEventListener('scroll', syncScrollBars, true);
                }
            }

            _this.setZoom = function(value) {
                _this.options.zoom = value;
                setupMagnifier();
            };

            _this.setShape = function(shape, width, height) {
                _this.options.shape = shape;
                if (width) {
                    _this.options.width = width;
                }
                if (height) {
                    _this.options.height = height;
                }
                setupMagnifier();
            };

            _this.setWidth = function(value) {
                _this.options.width = value;
                setupMagnifier();
            };

            _this.setHeight = function(value) {
                _this.options.height = value;
                setupMagnifier();
            };

            _this.getZoom = function() {
                return _this.options.zoom;
            };

            _this.getShape = function() {
                return _this.options.shape;
            };

            _this.getWidth = function() {
                return _this.options.width;
            };

            _this.getHeight = function() {
                return _this.options.height;
            };

            _this.isVisible = function() {
                return isVisible;
            };

            _this.on = function(event, callback) {
                events[event] = events[event] || [];
                events[event].push(callback);
            };

            _this.syncScrollBars = function(event) {
                syncScrollBars();
            };

            _this.syncContent = function(event) {
                syncContentQueued();
            };

            _this.hide = function(event) {
                unBindDOMObserver();
                magnifierContent.innerHTML = '';
                magnifier.style.display = 'none';
                isVisible = false;
            };

            _this.setPosition = function(x,y) {
                setPosition(magnifier, x, y);
                syncViewport();
                setupMagnifier();
            };

            _this.show = function(event) {
                let left, top;
                if (event) {
                    left = event.pageX - 20;
                    top = event.pageY - 20;
                } else {
                    left = 200;
                    top = 200;
                }
                setupMagnifier();
                prepareContent();
                setPosition(magnifier, left, top);
                magnifier.style.display = '';
                syncViewport();
                syncScrollBars();
                initScrollBars();
                bindDOMObserver();
                isVisible = true;
            };

            init();

            return _this;
        }

    },
};
</script>

<style lang="scss" scoped>
#pointer {
    position: fixed;
    height: 20px;
    width: 20px;
    z-index: 9999999999;
    background-color: var(--v-error-base);
    pointer-events: none;
    border-radius: 50px;
    -webkit-animation: heartbeat 1.5s ease-in-out infinite both;
    animation: heartbeat 1.5s ease-in-out infinite both;
}

#clickArea {
    position: fixed;
    height: 400px;
    width: 400px;
    z-index: 9999999999;
}

.noClick {
    pointer-events: none;
    touch-action: none;
}

/* ----------------------------------------------
* Generated by Animista on 2021-1-28 15:30:54
* Licensed under FreeBSD License.
* See http://animista.net/license for more info.
* w: http://animista.net, t: @cssanimista
* ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation heartbeat
 * ----------------------------------------
 */
@-webkit-keyframes heartbeat {
    from {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transform-origin: center center;
        transform-origin: center center;
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
    10% {
        -webkit-transform: scale(0.91);
        transform: scale(0.91);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
    17% {
        -webkit-transform: scale(0.98);
        transform: scale(0.98);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
    33% {
        -webkit-transform: scale(0.87);
        transform: scale(0.87);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
    45% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
}
@keyframes heartbeat {
    from {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transform-origin: center center;
        transform-origin: center center;
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
    10% {
        -webkit-transform: scale(0.91);
        transform: scale(0.91);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
    17% {
        -webkit-transform: scale(0.98);
        transform: scale(0.98);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
    33% {
        -webkit-transform: scale(0.87);
        transform: scale(0.87);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
    45% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
}

</style>
