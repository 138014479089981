<template>
    <div
        class="SupportContainer"
        :style="UI_tablet ? 'height: 100% !important' : ''"
    >
        <!-- Header -->
        <div
            style="display: flex; flex-direction: row; justify-content: space-around; padding-bottom: 17px; border-bottom: 2px solid #8c8c8c99"
        >
            <p style="font-size: x-large; margin-top: 20px">
                Support-Chat
            </p>
            <v-btn
                style="position: absolute; right: 12px; top: 20px"
                text
                @click="setSupportPageOpen(false)"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
            <div
                v-if="role === 'moderator'"
                style="position: absolute; left: 20px; top: 20px"
            >
                <v-icon>mdi-face-agent</v-icon>
                <p>Moderator</p>
            </div>
        </div>
        <!-- Default View of Topics and Menus -->
        <div
            v-if="!chatView"
            style="display: flex; flex-direction: column; flex-grow: 1;"
        >
            <!-- User Input/Search -->
            <input
                v-model="searchInput"
                type="text"
                style="
                    margin-top: 20px; 
                    margin-left: 20px; 
                    margin-right: 20px; 
                    background-color: #f5f5f5;
                    height: 48px;
                    padding: 8px;
                    border-radius: 4px;
                    box-shadow: #00000069 1px 2px 3px 0px;"
                placeholder="Welches Problem haben Sie?"
            >
            <!-- All Entries -->
            <div style="padding-left: 20px; flex-grow: 1;">
                <vue-custom-scrollbar
                    class="scroll-area"
                    :settings="settings"
                >
                    <div v-if="myTopics.length > 0 && searchInput.length === 0">
                        <p
                            style="font-size: medium; margin-top: 20px"
                        >
                            {{ 'Meine Fragen (' + myTopics.length + ')' }}
                        </p>
                        <div style="margin-bottom: 10px" />
                        <!-- DIV: Open MyTopics -->
                        <div
                            v-for="(el, index) in myTopics"
                            :key="index + 'myTopics'"
                        >
                            <v-btn
                                style="display: flex; flex-direction: row; justify-content: flex-start; align-items: center; height: 75px; width: 100%;"
                                :ripple="{ center: true }"
                                @click="openSupportChatTopic(myTopics, index)"
                            >
                                <v-icon>mdi-chat-outline</v-icon>
                                <div
                                    style="display: flex; flex-direction: column; align-items: flex-start;"
                                >
                                    <p
                                        class="text-capitalize"
                                        style="margin-left: 20px; margin-top: 20px; font-size: medium;"
                                    >
                                        {{ el.title }}
                                    </p>
                                    <div
                                        style="display: flex; flex-direction: row; padding-top: 10px; margin-left: 20px"
                                    >
                                        <p
                                            class="text-capitalize"
                                            style="font-size: small"
                                        >
                                            {{ el.messagesLength + ' Beiträge' }}
                                        </p>
                                        <p
                                            v-if="el.unreadMessageCounter > 0"
                                            class="text-none"
                                            style="font-size: small; color: red; margin-left: 5px"
                                        >
                                            {{ '(' + el.unreadMessageCounter + ' ungelesen)' }}
                                        </p>
                                    </div>
                                </div>
                            </v-btn>
                        </div>
                    </div>
                    <div v-if="searchInput.length === 0">
                        <p
                            style="font-size: medium; margin-top: 20px"
                        >
                            {{ 'Alle Einträge (' + allTopics.length + ')' }}
                        </p>
                        <div style="margin-bottom: 10px" />
                        <!-- DIV: open from All Topics -->
                        <div
                            v-for="(el, index) in allTopics"
                            :key="index + 'allTopics'"
                        >
                            <v-btn
                                style="display: flex; flex-direction: row; justify-content: flex-start; align-items: center; height: 75px; width: 100%;"
                                :ripple="{ center: true }"
                                @click="openSupportChatTopic(allTopics, index)"
                            >
                                <v-icon>mdi-chat-outline</v-icon>
                                <div
                                    style="display: flex; flex-direction: column; align-items: flex-start;"
                                >
                                    <p
                                        class="text-capitalize"
                                        style="margin-left: 20px; margin-top: 20px; font-size: medium;"
                                    >
                                        {{ el.title }}
                                    </p>
                                    <div
                                        style="display: flex; flex-direction: row; padding-top: 10px; margin-left: 20px"
                                    >
                                        <p
                                            class="text-capitalize"
                                            style="font-size: small"
                                        >
                                            {{ el.messagesLength + ' Beiträge' }}
                                        </p>
                                        <p
                                            v-if="el.unreadMessageCounter > 0"
                                            class="text-none"
                                            style="font-size: small; color: red; margin-left: 5px"
                                        >
                                            {{ '(' + el.unreadMessageCounter + ' ungelesen)' }}
                                        </p>
                                    </div>
                                </div>
                            </v-btn>
                        </div>
                        <div style="height: 100px" />
                    </div>
                    <div v-else>
                        <p
                            style="font-size: medium; margin-top: 20px"
                        >
                            {{ 'Passende Einträge (' + similarTopics.length + ')' }}
                        </p>
                        <div style="margin-bottom: 10px" />
                        <!-- DIV: open from Similar Topics -->
                        <div
                            v-for="(el, index) in similarTopics"
                            :key="index + 'similarTopics'"
                        >
                            <v-btn
                                style="display: flex; flex-direction: row; justify-content: flex-start; align-items: center; height: 75px; width: 100%;"
                                :ripple="{ center: true }"
                                @click="openSupportChatTopic(similarTopics, index)"
                            >
                                <v-icon>mdi-chat-outline</v-icon>
                                <div
                                    style="display: flex; flex-direction: column; align-items: flex-start;"
                                >
                                    <p
                                        class="text-capitalize"
                                        style="margin-left: 20px; margin-top: 20px; font-size: medium;"
                                    >
                                        {{ el.title }}
                                    </p>
                                    <div
                                        style="display: flex; flex-direction: row; padding-top: 10px; margin-left: 20px"
                                    >
                                        <p
                                            class="text-capitalize"
                                            style="font-size: small"
                                        >
                                            {{ el.messagesLength + ' Beiträge' }}
                                        </p>
                                        <p
                                            v-if="el.unreadMessageCounter > 0"
                                            class="text-none"
                                            style="font-size: small; color: red; margin-left: 5px"
                                        >
                                            {{ '(' + el.unreadMessageCounter + ' ungelesen)' }}
                                        </p>
                                    </div>
                                </div>
                            </v-btn>
                        </div>
                        <!-- DIV: Create new Topic -->
                        <v-btn
                            v-if="searchInput.length > 0"
                            style="display: flex; flex-direction: row; justify-content: flex-start; align-items: center; height: 75px; width: 100%;"
                            :ripple="{ center: true }"
                            @click="createSupportChatTopic(searchInput)"
                        >
                            <v-icon>mdi-plus-circle-outline</v-icon>
                            <p
                                class="text-capitalize"
                                style="margin-left: 20px; font-size: medium"
                            >
                                Neuer Chat zu diesem Thema
                            </p>
                        </v-btn>
                        <div style="height: 100px" />
                    </div>
                </vue-custom-scrollbar>
            </div>
            <!-- Bottom Toolbar -->
            <div
                style="display: flex; flex-direction: column; z-index: 2; background-color: white; padding-bottom: 20px"
            >
                <p
                    style="font-size: medium; margin-top: 20px; padding-left: 20px"
                >
                    Weitere Hilfestellungen:
                </p>
                <div
                    style="display: flex; flex-direction: column; gap: 12px; justify-content: space-between; margin-top: 20px; padding-left: 20px; padding-right: 20px"
                >
                    <v-btn
                        text
                        class="greyBG"
                        width="60%"
                        @click="toggleFaq(); setSupportPageOpen(false)"
                    >
                        <img
                            :src="fragezeichenIcon"
                            alt
                            height="25"
                        >
                        <p
                            class="ml-2 ma-0 text-uppercase"
                            style="font-size: large"
                        >
                            FAQ
                        </p>
                    </v-btn>
                    <v-btn
                        text
                        class="greyBG"
                        width="60%"
                        @click="$emit('resetTutorial'); setSupportPageOpen(false)"
                    >
                        <img
                            :src="tutorialIcon"
                            alt="Fragezeichensymbol"
                            height="25"
                        >
                        <p
                            class="ml-2 ma-0 text-capitalize"
                            style="font-size: large"
                        >
                            Tutorial
                        </p>
                    </v-btn>
                    <v-btn
                        text
                        class="greyBG"
                        width="60%"
                        @click="openVideosupport()"
                    >
                        <img
                            :src="videoIcon"
                            alt="Videosymbol"
                            height="25"
                        >
                        <p
                            class="ml-2 ma-0 text-capitalize"
                            style="font-size: large"
                        >
                            Videosupport
                        </p>
                    </v-btn>
                </div>
            </div>
        </div>
        <!-- Live Chat Window -->
        <div
            v-else-if="chatView"
            style="flex-grow: 1"
        >
            <!-- Top Navigation Pane -->
            <div
                class="greyBG"
                style="width: 100%; display: flex; flex-direction: row; justify-content: space-between; align-items: center; padding-top: 5px; padding-bottom: 5px;"
            >
                <v-btn
                    text
                    style="margin-right: 5px; margin-left: 5px"
                    @click="chatView = false; leaveSupportChatTopic()"
                >
                    <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <p>{{ chatLog.title }}</p>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            text
                            style="margin-right: 5px"
                            v-on="on"
                            @click="deleteSupportChatTopic(chatLog.title)"
                        >
                            <v-icon>mdi-delete</v-icon>
                        </v-btn>
                    </template>
                    <span>Diskussion löschen</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            text
                            style="margin-right: 5px"
                            v-on="on"
                        >
                            <v-icon>mdi-eye</v-icon>
                        </v-btn>
                    </template>
                    <span>Für alle Nutzer sichtbar</span>
                </v-tooltip>
            </div>
            <!-- Chat Messages -->
            <vue-custom-scrollbar
                id="ChatLogContainer"
                class="scroll-area-chat"
                :settings="settings"
            >
                <v-spacer style="margin-top: 10px" />
                <div
                    v-for="(message, index) in chatLog.messages"
                    :key="index + 'message'"
                    :ref="'chatMessageRef-' + index"
                    style="display: flex; flex-direction: column"
                >
                    <div
                        style="display: flex; flex-direction: row; justify-content: space-between; margin-left: 5%; margin-right: 5%"
                    >
                        <div
                            style="display: flex; flex-direction: row; align-items: center; margin-bottom: 10px"
                        >
                            <div v-if="role === 'moderator'">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-icon
                                            v-if="message.role === 'schoolAccount' || message.role === undefined"
                                            x-large
                                            v-on="on"
                                        >
                                            mdi-account-circle
                                        </v-icon>
                                    </template>
                                    <span>{{ message.authorPK ? message.authorPK : 'Missing Identifier' }}</span>
                                </v-tooltip>
                            </div>
                            <v-icon
                                v-else-if="message.role === 'schoolAccount' || message.role === undefined"
                                x-large
                            >
                                mdi-account-circle
                            </v-icon>
                            <v-icon
                                v-if="message.role === 'moderator'"
                                x-large
                            >
                                mdi-face-agent
                            </v-icon>
                            <p style="font-size: large; margin-left: 10px">
                                {{ message.author }}
                            </p>
                        </div>
                        <p>{{ message.timestamp }}</p>
                    </div>
                    <div
                        style="display: flex; flex-direction: row; margin-left: 5%; margin-right: 5%; margin-bottom: 5%"
                    >
                        <p
                            class="greyBG pa-3"
                            style="border-radius: 3px"
                            :class="message.role === 'moderator' ? 'moderatorBG' : ''"
                        >
                            {{ message.content }}
                        </p>
                    </div>
                </div>
            </vue-custom-scrollbar>
            <!-- Bottom Input Area -->
            <div
                class="greyBG"
                style="position: absolute; bottom: 0; width: 100%"
            >
                <div style="display: flex; flex-direction: row">
                    <v-text-field
                        v-model="messageInput"
                        rounded
                        placeholder="Nachricht eingeben..."
                        height="20px"
                        style="margin: 10px; background-color: white"
                        @keypress.enter="sendMessage()"
                    />
                    <div
                        v-if="messageInput.length === 0 && false"
                        style="display: flex; flex-direction row; align-items: center"
                    >
                        <v-btn
                            text
                            style="margin-right: 5px"
                        >
                            <v-icon>mdi-paperclip</v-icon>
                        </v-btn>
                        <v-btn
                            text
                            style="margin-right: 10px"
                        >
                            <v-icon>mdi-microphone</v-icon>
                        </v-btn>
                    </div>
                    <div
                        v-else
                        style="display: flex; flex-direction row; align-items: center"
                    >
                        <v-btn
                            text
                            style="margin-right: 10px"
                            @click="sendMessage()"
                        >
                            <v-icon>mdi-send</v-icon>
                        </v-btn>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import tutorialIcon from "@/assets/Icons/tutorial.svg";
import fragezeichenIcon from "@/assets/Icons/question-mark.svg";
import videoIcon from '@/assets/Icons/kamera-video-72.svg';
import vueCustomScrollbar from "vue-custom-scrollbar";
import io from "socket.io-client";

export default {
    name: "SupportChat",
    components: {
        vueCustomScrollbar,
    },
    data() {
        return {
            person: {},
            author: '',
            role: 'schoolAccount',

            allTopics: [],
            myTopics: [],
            similarTopics: [],

            today: new Date(),

            // state management
            chatView: false,
            insideAsyncRoom: false,
            connected: false,

            // ui size
            screenX: 0,
            screenY: 0,
            UI_tablet: false,

            // chat
            socket: null,
            chatLog: {
                title: 'Topic',
                messages: [],
            },
            messageInput: '',

            searchInput: '',

            // scrollbar
            settings: {
                suppressScrollY: false,
                suppressScrollX: true,
                wheelPropagation: false,
            },

            // icons
            tutorialIcon,
            fragezeichenIcon,
            videoIcon,
        };
    },
    computed: {
        ...mapState('util', ['windowWidth', 'windowHeight']),
    },
    watch: {
        searchInput: function (newVal, oldVal) {
            if (this.allTopics.length > 0) {
                this.refreshSearchList();
            }
        },

    },
    async mounted() {
        this.evalScreenSize();
        // todo nice to have: figure out common low-end iphone resolution and implement UI_phone
        if (this.screenY <= 954) {
            this.UI_tablet = true;
        }
        this.person = await this.getMeTeacher();
        // console.log(this.person);
        // quick and dirty: add flag 'supportChatModerator: true' on teacherModel
        // to gain moderator access. this is not yet verified with DocReg.
        if (this.person.supportChatModerator) {
            this.author = 'EKLARA Support';
            this.authorPK = this.author; // share PK for now => share unread Msg counter among team
            this.role = 'moderator';
        } else {
            this.author = this.person.name.charAt(0) + '. ' + this.person.lastName;
            this.authorPK = this.person.account + '@' + this.person.school,
                this.role = 'schoolAccount';
        }
        this.socket = io("https://backend.dr1.eklara.de", {
            withCredentials: true,
            extraHeaders: {
                'author-pk': this.authorPK,
            },
            path: '/supportChat',
        });
        this.messageInput = '';
        this.socket.on('connect', () => {
            this.chatView = false;
            this.connected = true;
            this.socket.onAny((event, ...args) => {
                // console.log(`Recieved event ${event}: ${args}`);
                switch (event) {
                    case 'message':
                        this.appendMessage(args);
                        break;
                    case 'allTopics':
                        this.allTopics = args[0];
                        // console.log(this.allTopics);
                        this.allTopics = this.allTopics.map((topic) => {
                            topic.unreadMessageCounter = topic.messagesLength;
                            topic.seenBy.forEach((client) => {
                                if (client.authorPK === this.authorPK) {
                                    topic.unreadMessageCounter = topic.messagesLength - client.counter;
                                }
                            })
                            // console.log('unreadMessageCounter: '+topic.unreadMessageCounter)
                            return topic;
                        })
                        this.myTopics = [];
                        // eslint-disable-next-line no-case-declarations
                        let leaveRoom = true;
                        this.allTopics.forEach((el) => {
                            if (el.authorPK === this.authorPK) {
                                this.myTopics.push(el);
                            }
                            if (this.chatView) {
                                if (this.chatLog.title === el.title) {
                                    leaveRoom = false;
                                }
                            }
                        });
                        // currently open room was not found in refreshed List of Topics
                        // therefore it has to be deleted (catch this in proper event when rewriting)
                        if (this.chatView && leaveRoom && !this.insideAsyncRoom) {
                            this.chatView = false;
                            this.showSnackbar({
                                message: 'Der Chat wurde gelöscht.'
                            })
                        }
                        // ensure newly created room can be found everywhere to avoid confusion
                        this.refreshSearchList();
                        this.insideAsyncRoom = false;
                        break;
                    case 'chatLog':
                        // console.log(args[0].messages);
                        this.chatLog.messages = args[0].messages;
                        break;
                }
            });
            this.refreshLoop();
        });
        this.socket.on('disconnect', () => {
            this.chatView = false;
            this.connected = false;
            this.socket.removeAllListeners();
            this.socket.offAny(); // possibly redundant with the above
        });
    },
    async unmounted() {
        this.connected = false;
        this.socket.removeAllListeners();
        this.socket.offAny(); // possibly redundant with the above
    },
    methods: {
        ...mapMutations("support", ["setSupportPageOpen"]),
        ...mapMutations('snackbar', ['showSnackbar']),
        ...mapActions("faq", ["toggleFaq"]),
        ...mapActions("teachers", ["getMeTeacher"]),

        openSupportChatTopic(list, index) {
            this.chatView = true;
            this.chatLog.title = list[index].title;
            this.socket.emit('join', this.chatLog.title);
        },

        leaveSupportChatTopic() {
            this.socket.emit('leave', this.chatLog.title);
            this.insideAsyncRoom = false;
            this.refresh();
        },
        openVideosupport() {
             window.location.href = "https://schule.eklara.de/join-external/6178016ef0f80f001abe7aa5?token=vys0gtp84ltj";
        },
        createSupportChatTopic(searchInput) {
            this.insideAsyncRoom = true;
            this.socket.emit('create', {
                author: this.author,
                searchInput: this.searchInput,
            });
            this.chatView = true;
            this.chatLog = {
                title: searchInput,
                messages: [
                ],
            };
        },

        deleteSupportChatTopic(title) {
            this.socket.emit('delete', {
                title: title,
            })
            this.chatView = false;
        },

        sendMessage() {
            let timestamp = this.today.getDate() + '.' + (this.today.getMonth() + 1) + '.' + this.today.getFullYear();
            this.socket.emit('message', {
                topic: this.chatLog.title,
                content: this.messageInput,
                timestamp: timestamp,
                author: this.author,
                authorPK: this.authorPK,
                role: this.role,
            });
            this.appendMessageSelf(this.messageInput, timestamp);
            this.messageInput = '';
        },

        appendMessage(args) {
            this.chatLog.messages.push({
                author: args[0][0].author,
                authorPK: args[0][0].authorPK,
                role: args[0][0].role,
                timestamp: args[0][0].timestamp,
                content: args[0][0].content,
            });
        },

        appendMessageSelf(args, timestamp) {
            this.chatLog.messages.push({
                author: this.author,
                role: this.role,
                timestamp: timestamp,
                content: args,
            });
        },

        refreshSearchList() {
            this.similarTopics = this.searchTopics(this.allTopics).slice(0, 5);
        },

        refresh() {
            this.socket.emit('refresh');
        },

        refreshLoop() {
            if (this.connected) {
                this.refresh();
                setTimeout(this.refreshLoop, 60000);
            } else {
                return;
            }
        },

        searchTopics(allTopics) {

            // if left empty, reset to alphabetical sorting
            if (this.searchInput === '') {
                // sort alphabetically, descending
                return allTopics.sort((a, b) => a.title.localeCompare(b.title));
            }

            function editDistance(s1, s2) {
                s1 = s1.toLowerCase();
                s2 = s2.toLowerCase();

                var costs = new Array();
                for (var i = 0; i <= s1.length; i++) {
                    var lastValue = i;
                    for (var j = 0; j <= s2.length; j++) {
                        if (i == 0)
                            costs[j] = j;
                        else {
                            if (j > 0) {
                                var newValue = costs[j - 1];
                                if (s1.charAt(i - 1) != s2.charAt(j - 1))
                                    newValue = Math.min(Math.min(newValue, lastValue),
                                        costs[j]) + 1;
                                costs[j - 1] = lastValue;
                                lastValue = newValue;
                            }
                        }
                    }
                    if (i > 0)
                        costs[s2.length] = lastValue;
                }
                return costs[s2.length];
            }

            function similarity(s1, s2) {
                let longer = s1;
                let shorter = s2;
                if (s1.length < s2.length) {
                    longer = s2;
                    shorter = s1;
                }
                let longerLength = longer.length;
                if (longerLength === 0) {
                    return 1.0;
                }
                return (longerLength - editDistance(longer, shorter)) / parseFloat(longerLength);
            }
            // generate similarity of every item in loaded library
            for (let topic of allTopics) {
                topic.similarity = similarity(topic.title, this.searchInput);
            }
            // sort by descending similarity
            return allTopics.sort((a, b) => parseFloat(b.similarity) - parseFloat(a.similarity));
        },

        evalScreenSize() {
            let win = window;
            let doc = document;
            let docElem = doc.documentElement;
            let body = doc.getElementsByTagName('body')[0];
            this.screenX = win.innerWidth || docElem.clientWidth || body.clientWidth;
            this.screenY = win.innerHeight || docElem.clientHeight || body.clientHeight;
        },

    },
}
</script>
<style scoped>
.SupportContainer {
    width: max(33vw, 400px);
    height: 100%;
    background-color: white;
    z-index: 100;
    position: absolute;
    right: 0px;
    top: 0px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;

    display: flex;
    flex-direction: column;
    overflow: auto;
}

.greyBG {
    background-color: var(--v-hellgrau-base);
}

.moderatorBG {
    background-color: #48cfe5cc;
}

.scroll-area {
    position: relative;
    max-height: calc(80vh - 75px);
}

.scroll-area-chat {
    position: relative;
    max-height: calc(80vh);
}
</style>