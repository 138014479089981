<template>
    <div>
        <td style="width: 100%; display: block">
            <!-- create new appointment -->
            <v-menu
                v-if="!appointment"
                v-model="menu"
                :close-on-content-click="false"
                :nudge-width="70"
                :width="400"
                :max-width="400"
                offset-x
                :tile="false"
            >
                <template
                    v-if="!copyMode"
                    v-slot:activator="{ attrs }"
                >
                    <div
                        v-bind="attrs"
                        class="fach pa-2 white--text"
                        style="width: 100%; display: block"
                        @click="checkGroup"
                    >
                        <v-row class="ml-2 mr-1 font-weight-medium">
                            <div
                                class="innerFach pa-3 mr-2"
                                style="color: var(--v-fgrau-base);"
                            >
                                <img
                                    v-if="getSrc()"
                                    class="iconSelect"
                                    :src="getSrc()"
                                >
                                <div
                                    v-else
                                    class="iconSelect"
                                    style="border-radius: 5px"
                                />
                                {{ thisSubject ? thisSubject.name : "Hinzufügen" }}
                            </div>
                        </v-row>
                    </div>
                </template>

                <template
                    v-else
                    v-slot:activator="{ attrs }"
                >
                    <div
                        v-bind="attrs"
                        class="fach pa-2 white--text"
                        style="width: 100%; display: block"
                        @click="pasteAppointmentDataAndSave"
                    >
                        <v-row class="ml-2 mr-1 font-weight-medium">
                            <div
                                class="innerFach pa-3 mr-2"
                                style="color: var(--v-fgrau-base);"
                            >
                                <img
                                    v-if="getSrc()"
                                    class="iconSelect"
                                    :src="getSrc()"
                                >
                                <div
                                    v-else
                                    class="iconSelect"
                                    style="border-radius: 5px"
                                />
                                {{ "Einfügen" }}
                            </div>
                        </v-row>
                    </div>
                </template>

                <v-card>
                    <div
                        class="fach white--text pb-3 pt-5"
                        :style="'background-color: ' + (thisSubject ? thisSubject.color : '#CACACA')"
                    >
                        <v-row
                            class="ml-5 font-weight-medium"
                            style="font-size: large"
                        >
                            <v-icon
                                class="schoolIcon"
                                x-small
                            >
                                fas fa-school
                            </v-icon>
                            <v-icon
                                class="cancelIcon"
                                dark
                                @click="cancel"
                            >
                                fas fa-times
                            </v-icon>
                        </v-row>
                    </div>

                    <v-row class="mx-1">
                        <v-col
                            cols="5"
                            class="textGrau"
                        >
                            Fach:
                        </v-col>
                        <v-col cols="7">
                            <select
                                v-model="thisSubject"
                                class="input"
                            >
                                <option
                                    v-for="(item, index) in subjects"
                                    :key="index"
                                    selected
                                    :value="item"
                                    class="textGrau"
                                >
                                    {{ item.name }}
                                </option>
                            </select>
                        </v-col>

                        <v-col
                            cols="5"
                            class="textGrau"
                        >
                            Lehrer:
                        </v-col>
                        <v-col cols="7">
                            <v-select
                                v-if="menu"
                                v-model="selectedTeachers"
                                :disabled="!thisSubject"
                                :items="teachers"
                                item-value="_id"
                                :item-text="item => item.name + ' ' + item.lastName"
                                class="input"
                                multiple
                                hide-details
                                return-object
                            />
                        </v-col>
                    </v-row>
                    <v-row class="mx-1 pb-0">
                        <v-col class="textGrau pb-0">
                            Wiederholung:
                        </v-col>
                    </v-row>
                    <v-row class="mx-1 py-0">
                        <v-col
                            cols="6"
                            class="py-0"
                        >
                            <v-checkbox
                                v-model="weekly"
                                style="margin-top: 0 !important"
                                label="Wöchentlich bis"
                            />
                        </v-col>
                        <v-col
                            cols="6"
                            class="py-0"
                        >
                            <v-menu
                                v-model="dtPickerMenu"
                                :disabled="!weekly"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                :nudge-width="70"
                                :nudge-right="10"
                                :width="400"
                                :max-width="400"
                                offset-y
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="weeklyTill"
                                        label="Wöchentlich bis"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        :disabled="!weekly"
                                        v-bind="attrs"
                                        :class="{ errorInput: fields.weeklyField }"
                                        v-on="on"
                                    />
                                </template>
                                <v-date-picker
                                    v-model="weeklyTill"
                                    first-day-of-week="1"
                                    @input="dtPickerMenu = false"
                                />
                            </v-menu>
                        </v-col>
                    </v-row>
                    <v-btn
                        dark
                        class="mb-3 mt-4"
                        style="
              background-color: #6995cd;
              border-radius: 8px;
              width: 50%;
              margin-left: auto !important;
              margin-right: auto !important;
              display: block;
            "
                        tile
                        @click="saveAppointment"
                    >
                        <v-icon
                            style="margin-left: -20px"
                            class="mr-4"
                        >
                            mdi-content-save
                        </v-icon>Speichern
                    </v-btn>
                    <v-btn
                        class="my-0 textGrau"
                        style="
              background-color: var(--v-hellgrau-base);
              border-radius: 8px;
              width: 50%;
              margin-left: auto !important;
              margin-right: auto !important;
              display: block;
            "
                        tile
                        dark
                        @click="cancel"
                    >
                        <v-icon
                            style="margin-left: -20px"
                            class="mr-4"
                        >
                            mdi-close
                        </v-icon>Abbrechen
                    </v-btn>
                    <br>
                </v-card>
            </v-menu>

            <!-- update existing appointment -->
            <v-menu
                v-else
                v-model="menu"
                :close-on-content-click="false"
                :nudge-width="70"
                :width="400"
                :max-width="400"
                offset-x
                :tile="false"
            >
                <template v-slot:activator="{ attrs }">
                    <div
                        v-bind="attrs"
                        class="fach pa-2 white--text"
                        style="width: 100%; display: block"
                        @click="checkGroup"
                    >
                        <v-row class="ml-2 mr-1 font-weight-medium">
                            <div
                                class="innerFach pa-3 mr-2"
                                style="color: var(--v-dunkelgrau-base);"
                                :style="copyMode && copyModeOriginFach ? 'border: thick solid red;': ''"
                            >
                                <img
                                    :src="getColorIcon()"
                                    class="subjectIcon"
                                >
                                <img
                                    v-if="getSrc()"
                                    class="iconSelect"
                                    :src="getSrc()"
                                >
                                <div
                                    v-else
                                    class="iconSelect"
                                />
                                {{ appointment.schoolSubject.name }}
                                <v-tooltip
                                    v-if="copyMode && copyModeOriginFach"
                                    bottom
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-btn
                                            class="copyButton"
                                            icon
                                            small
                                            v-on="on"
                                            @click.stop.native="stopCopyMode"
                                        >
                                            <v-icon>mdi-close</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Kopiermodus beenden</span>
                                </v-tooltip>
                                <v-btn
                                    v-else-if="copyMode && !copyModeOriginFach"
                                    class="copyButton"
                                    icon
                                    small
                                />
                                <v-btn
                                    v-else
                                    class="copyButton"
                                    icon
                                    small
                                    @click.stop.native="changeCopyMode"
                                >
                                    <v-icon>mdi-content-copy</v-icon>
                                </v-btn>
                            </div>
                        </v-row>
                    </div>
                </template>

                <v-card>
                    <div
                        class="fach white--text pb-3 pt-5"
                        :style="'background-color: ' + (thisSubject ? thisSubject.color : '#CACACA')"
                    >
                        <v-row
                            class="ml-5 font-weight-medium"
                            style="font-size: large"
                        >
                            <v-icon
                                class="schoolIcon"
                                x-small
                            >
                                fas fa-school
                            </v-icon>
                            <v-icon
                                class="cancelIcon"
                                dark
                                @click="cancel"
                            >
                                fas fa-times
                            </v-icon>
                        </v-row>
                    </div>

                    <v-row class="mx-1">
                        <v-col
                            cols="5"
                            class="textGrau"
                        >
                            Fach:
                        </v-col>
                        <v-col cols="7">
                            <select
                                v-model="thisSubject"
                                class="input textGrau"
                            >
                                <option
                                    v-for="(item, index) in subjects"
                                    :key="index"
                                    selected
                                    :value="item"
                                    class="textGrau"
                                >
                                    {{ item.name }}
                                </option>
                            </select>
                        </v-col>

                        <v-col
                            cols="5"
                            class="textGrau"
                        >
                            Lehrer:
                        </v-col>
                        <v-col cols="7">
                            <v-select
                                v-model="selectedTeachers"
                                :disabled="!thisSubject"
                                :items="teachers"
                                item-value="_id"
                                :item-text="item => item.name + ' ' + item.lastName"
                                class="input"
                                multiple
                                hide-details
                                return-object
                            />
                        </v-col>
                    </v-row>
                    <div>
                        <v-row class="mx-1 pb-0">
                            <v-col class="textGrau pb-0">
                                Wiederholung:
                            </v-col>
                        </v-row>
                        <v-row class="mx-1 py-0">
                            <v-col
                                cols="6"
                                class="py-0"
                            >
                                <v-checkbox
                                    v-model="weekly"
                                    style="margin-top: 0 !important"
                                    label="Wöchentlich bis"
                                />
                            </v-col>
                            <v-col
                                cols="6"
                                class="py-0"
                            >
                                <v-menu
                                    v-model="dtPickerMenu"
                                    :disabled="!weekly"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    :nudge-width="70"
                                    :nudge-right="10"
                                    :width="400"
                                    :max-width="400"
                                    offset-y
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="weeklyTill"
                                            label="Wöchentlich bis"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            :disabled="!weekly"
                                            v-bind="attrs"
                                            :class="{ errorInput: fields.weeklyField }"
                                            v-on="on"
                                        />
                                    </template>
                                    <v-date-picker
                                        v-model="weeklyTill"
                                        first-day-of-week="1"
                                        @input="dtPickerMenu = false"
                                    />
                                </v-menu>
                            </v-col>
                        </v-row>
                    </div>
                    <v-btn
                        dark
                        class="mb-3 mt-4"
                        style="
              background-color: #6995cd;
              border-radius: 8px;
              width: 50%;
              margin-left: auto !important;
              margin-right: auto !important;
              display: block;
            "
                        tile
                        @click="updateAppointment"
                    >
                        <v-icon
                            style=""
                            class="mr-1"
                        >
                            mdi-content-save
                        </v-icon>Speichern
                    </v-btn>
                    <v-btn
                        dark
                        class="mb-3 mt-4"
                        style="
              background-color: #6995cd;
              border-radius: 8px;
              width: 50%;
              margin-left: auto !important;
              margin-right: auto !important;
              display: block;
            "
                        tile
                        @click="deleteDialog = true"
                    >
                        <v-icon
                            style=""
                            class="mr-1"
                        >
                            mdi-delete
                        </v-icon>Löschen
                    </v-btn>
                    <br>
                </v-card>
            </v-menu>
        </td>

        <!-- Delete dialog -->
        <v-dialog
            v-model="deleteDialog"
            persistent
            max-width="400"
        >
            <v-card>
                <v-card-title class="headline">
                    Termin löschen?
                </v-card-title>
                <v-card-text>
                    Sind sie sicher, dass sie diesen Termin löschen möchten? <br>
                    <v-radio-group
                        v-if="appointment && appointment.rule"
                        v-model="deleteRecurringAppointment"
                    >
                        <v-radio
                            :value="'onlyThis'"
                            label="Nur diesen Termin"
                        />
                        <v-radio
                            :value="'thisAndFollowing'"
                            label="Diesen Termin und alle folgenden"
                        />
                        <v-radio
                            :value="'allOfThem'"
                            label="Alle Termine"
                        />
                    </v-radio-group>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn
                        color=""
                        text
                        @click="deleteDialog = false"
                    >
                        Abbrechen
                    </v-btn>
                    <v-btn
                        color="red red-1"
                        text
                        @click="deleteAction"
                    >
                        Löschen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="weeklyCopyOverlay"
            persistent
            max-width="400"
        >
            <v-card>
                <v-card-title class="headline">
                    Termin wöchentlich Eintragen?
                </v-card-title>
                <v-card-text>
                    Sind sie sicher, dass sie diesen Termin bis
                    {{ copyMode ? copyTill.getDate() +"."+ (copyTill.getMonth()+1) +"."+copyTill.getFullYear() : '' }}
                    einfügen möchten? <br>
                    <v-checkbox
                        v-model="confirmWeeklyCopy"
                        label="Ja, ich möchte diesen Termin wöchentlich einfügen!"
                    />
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn
                        color=""
                        text
                        @click="weeklyCopyOverlay = false"
                    >
                        Abbrechen
                    </v-btn>
                    <v-btn
                        color="success"
                        text
                        @click="pasteAppointmentWeeklyDataAndSave"
                    >
                        Akzeptieren
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import beitretenIcon from "../../assets/Icons/beitretensvg-06.svg";
import beitretenKameraIcon from "../../assets/Icons/kamera-video-73.svg";
import buchzeichenIcon from "../../assets/Icons/lesezeichen-01-43.svg";
import cancelIcon from "../../assets/Icons/abbrechen-08.svg";
// import kopierenIcon from "../../assets/Icons/";

import bioIcon from "../../assets/Icons/f-biologie-35.svg";
import chemieIcon from "../../assets/Icons/f-chemie-37.svg";
import deutschIcon from "../../assets/Icons/f-deutsch-29.svg";
import englischIcon from "../../assets/Icons/f-englisch-30.svg";
import erdkundeIcon from "../../assets/Icons/f-erdkunde-31.svg";
import geschichteIcon from "../../assets/Icons/f-geschichte-40.svg";
import matheIcon from "../../assets/Icons/f-mathe-32.svg";
import musikIcon from "../../assets/Icons/f-musik-84.svg";
import physikIcon from "../../assets/Icons/f-physik-36.svg";
import politikIcon from "../../assets/Icons/f-politik-34.svg";
import religionIcon from "../../assets/Icons/f-religion-39.svg";
import sportIcon from "../../assets/Icons/f-sport-38.svg";
import wirtschaftIcon from "../../assets/Icons/f-wirtschaft-33.svg";

import colorfcd20a from "../../assets/Icons/lesezeichen-gelb-47.svg";
import colorf0b423 from "../../assets/Icons/lesezeichen-orange-48.svg";
import colorff6941 from "../../assets/Icons/lesezeichen-rot-49.svg";
import color91c364 from "../../assets/Icons/lesezeichen-hellgrün-50.svg";
import color91c887 from "../../assets/Icons/lesezeichen-mintgrün-51.svg";
import colord7875f from "../../assets/Icons/lesezeichen-braun-52.svg";
import colorebaabe from "../../assets/Icons/lesezeichen-rosa-53.svg";
import color9b91c8 from "../../assets/Icons/lesezeichen-lila-54.svg";
import color6ec3dc from "../../assets/Icons/lesezeichen-blau-55.svg";
import color9bcdc3 from "../../assets/Icons/lesezeichen-minze-56.svg";
import colorb2b2b2 from "../../assets/Icons/lesezeichen-hellgrau-43.svg";
import color707070 from "../../assets/Icons/lesezeichen-dunkelgrau-44.svg";

export default {
  name: "Fach",
  components: {},
  props: {
    subject: { required: false, default: null },
    id: { required: false, default: null },
    teacher: { required: false, default: null },
    group: { required: false, default: null },
    day: { required: false, default: null },
    timeslot: { required: false, default: null },
    appointment: { required: false, default: null },
    requestGroup: { required: true },
  },
  data() {
    return {
      menu: false,
      deleteDialog: false,
      deleteRecurringAppointment: 'onlyThis',
      subjects: [],
      weekly: false,
      weeklyTill: "",
        weeklyFrom: "",
      fields: {
        weeklyField: false,
      },
      dtPickerMenu: false,
      thisSubject: null,
      thisTeacher: null,
      teachers: [],
      selectedTeachers: [],

        rule: null,
      copyObject: {},
      isCopyMode: false,
      copyModeOriginFach: false,
      weeklyCopyOverlay: false,
      confirmWeeklyCopy: false,
      copyTill: new Date(),

      groups: [],
      thisGroup: {},

      beitretenIcon,
      beitretenKameraIcon,
      buchzeichenIcon,
      cancelIcon,

      bioIcon,
      chemieIcon,
      deutschIcon,
      englischIcon,
      erdkundeIcon,
      geschichteIcon,
      matheIcon,
      musikIcon,
      physikIcon,
      politikIcon,
      religionIcon,
      sportIcon,
      wirtschaftIcon,

      colorfcd20a,
      colorf0b423,
      colorff6941,
      color91c364,
      color91c887,
      colord7875f,
      colorebaabe,
      color9b91c8,
      color6ec3dc,
      color9bcdc3,
      colorb2b2b2,
      color707070,
    };
  },
  computed: {
    ...mapGetters('subjects', [ 'getSubjectInvalidStatus' ]),
    ...mapGetters('copypaste', ['getCopyOfSubject', 'getCopyOfTeachers', 'isCopyModeActive']),
      ...mapState('copypaste', ['copyMode', 'copySourceId', 'copiedWeeklyTill']),
  },

  watch: {
    subject() {
      this.thisSubject = this.subject;
    },
    teacher() {
      this.thisTeacher = this.teacher;
    },
    thisSubject() {
      if (this.thisSubject && this.thisSubject.teachers) {
        this.thisTeacher = this.thisSubject.teachers
        this.teachers = this.thisSubject.teachers;
      }
    },
    thisGroup(){
      this.thisGroup = this.group;
    },
    getSubjectInvalidStatus(newVal, oldVal) {
      if (newVal && !oldVal) {
        this.requestSubjects(true);
      }
    },
      copyMode() {

          if(this.copyMode === false) {
              this.copyModeOriginFach = false;
              if(this.appointment === null){
                this.thisSubject = null;
              }
          }
      },
  },
  async mounted() {
    this.copyModeOriginFach = false;
    this.thisSubject = this.subject;
    this.thisTeacher = this.teacher;
    if(this.appointment) {

      this.selectedTeachers = this.appointment.teachers;
      if(this.appointment.rule) {
          this.weekly = true;
          this.rule = await this.getAppointmentRuleById(this.appointment.rule);
          this.weeklyFrom = this.rule.startDate.split('T')[0];
          this.weeklyTill = this.rule.endDate.split('T')[0];
      }

        if(this.appointment._id === this.copySourceId) {
            this.copyModeOriginFach = true;
        }
    }
  },
  methods: {
    ...mapActions('subjects', [ 'getSubjects', 'setSubjectListStatus' ]),
    ...mapActions('appointments', [ 'createAppointment', 'editAppointment', 'deleteAppointment', 'getAppointments',
      'deleteAppointmentFromRule', 'deleteAppointmentByRule', 'getAppointmentRuleById']),
    ...mapActions('copypaste', ['toggleCopyMode', 'setAppointmentData', 'getCopiedFachData', 'setCopyModeOriginFachId', 'turnOffCopyMode']),
    ...mapActions("groups", ["getGroup"]),
    ...mapMutations("snackbar", ["showSnackbar"]),

    getSrc() {
      return this.thisSubject
        ? this[this.thisSubject.icon]
        : this.appointment
        ? this[this.appointment.schoolSubject.icon]
        : null;
    },

    getColorIcon() {
      return this.thisSubject
        ? this["color" + this.thisSubject.color.substring(1)]
        : this.appointment
        ? this["color" + this.appointment.schoolSubject.color.substring(1)]
        : this.colorb2b2b2;
    },
    checkGroup() {
      if (!this.group) {
        this.menu = false;
        this.showSnackbar({ message: 'Bitte wählen Sie zuerst eine Gruppe aus.', color: 'error'});
      } else {
        this.requestSubjects();
        this.menu = true;
      }
    },
    cancel() {
      this.thisSubject = this.subject;
      this.thisTeacher = this.teacher;
      this.menu = false;
    },
    async requestSubjects(update = false) {
      this.subjects = await this.getSubjects(update);
      if (this.appointment) {
        this.thisSubject = this.subjects.find((el) => {
          return el._id == this.appointment.schoolSubject._id;
        });
        this.thisTeacher = this.thisSubject.teachers.find((el) => {
          return el._id == this.appointment.teacher._id;
        });
      }
      this.setSubjectListStatus(false);
    },

    validateInput() {
      let result = true;

      this.fields.weeklyField = false;

      if (this.weeklyTill.replace(/\s/g, "") === "" || this.isWeeklyInPast()) {
        this.fields.weeklyField = true;
        result = false;
      }
      return result;
    },
    isWeeklyInPast() {
      const tillDate = new Date(this.weeklyTill);
      const now = new Date();
      return tillDate.toString() !== "Invalid Date" && tillDate < now;
    },
    async updateAppointment() {
      if (!this.thisTeacher || !this.thisSubject || this.selectedTeachers.length === 0) {
        this.showSnackbar({ message: 'Bitte füllen Sie alle benötigten Felder aus!', color: 'error'});
        return;
      } else if (this.weekly) {
          if (new Date(this.weeklyTill).getTime() <= new Date(this.appointment.day).getTime()) {
              this.snackbarText = "Bitte wählen Sie ein Datum das nach dem Beginn der Schulstunde liegt";
              this.snackbar = true;
              return;
          }
      }
        let data = null;
        if(this.weekly) {
            let ruleFormatted = this.rule;
            ruleFormatted.startDate = new Date(this.day);
            ruleFormatted.endDate = new Date(this.weeklyTill);
            data = {
                _id: this.appointment._id,
                schoolSubject: this.thisSubject._id,
                teachers: this.selectedTeachers,
                groupId: this.group._id,
                day: this.day,
                timeslot: this.timeslot._id,
                rule: ruleFormatted,
            };
        } else {
            data = {
                _id: this.appointment._id,
                schoolSubject: this.thisSubject._id,
                teachers: this.selectedTeachers,
                groupId: this.group._id,
                day: this.day,
                timeslot: this.timeslot._id,
            };
        }
      let response = await this.editAppointment(data);
      if (Number.isInteger(response)) {
          this.showSnackbar({ message: 'Etwas ist schiefgelaufen - Bitte aktualisieren Sie die Seite!', color: 'error'});
      } else {
        this.showSnackbar({ message: 'Gespeichert!'});
        this.requestGroup();
        this.menu = false;
      }
    },

    async changeCopyMode() {
      this.copyModeOriginFach = true;
      this.setCopyModeOriginFachId(this.appointment._id);

      this.toggleCopyMode();

      if(this.copyMode){
       await this.copyAppointmentFromPlanToStore();
      }
    },

      stopCopyMode() {
          let temp = {
              isCopy: null,
              copiedAppointment: null,
              copiedSubject: null,
              copiedTeachers: null,
              copiedIsWeekly: null,
              copiedWeeklyTill: null,
          };

          if(!this.appointment._id){
            this.subject = null;
          }
          this.turnOffCopyMode();
          this.setAppointmentData(temp);
          this.copyModeOriginFach = false;
          this.setCopyModeOriginFachId(null);
      },

    async copyAppointmentFromPlanToStore(){
      let temp = {
        isCopy: true,
        copiedAppointment: this.appointment,
        copiedSubject: this.appointment.schoolSubject,
        copiedTeachers: this.appointment.teachers,
        copiedIsWeekly: this.weekly,
        copiedWeeklyFrom: this.weeklyFrom,
        copiedWeeklyTill: this.weeklyTill,
      };

      this.setAppointmentData(temp);
    },
    sortByDateDesc(lhs,rhs) {
      return lhs < rhs ? 1 : lhs > rhs ? -1 : 0;
    },
    sortByDateAsc(lhs,rhs){
      return lhs > rhs ? 1 : lhs < rhs ? -1 : 0;
    },

    async pasteAppointmentDataAndSave(){
        let temp = await this.getCopiedFachData();

      if(temp.isCopy){
          if(!temp.copiedIsWeekly) {
              this.thisTeacher = temp.copiedSubject.teachers;
              this.thisSubject = temp.copiedSubject;
              this.selectedTeachers = temp.copiedTeachers;
              await this.saveAppointment();
          } else {
              this.copyTill =  new Date(temp.copiedWeeklyTill);
              this.weeklyCopyOverlay = true;
          }
      }else{
        this.showSnackbar({ message: 'Bitte Kopieren Sie die Daten aus einem bereits Erstellten Termin.', color: 'error'});
      }
    },

      async pasteAppointmentWeeklyDataAndSave() {
          let temp = await this.getCopiedFachData();
          if(temp.isCopy){
              this.thisTeacher = temp.copiedSubject.teachers;
              this.thisSubject = temp.copiedSubject;
              this.selectedTeachers = temp.copiedTeachers;
              if(this.confirmWeeklyCopy) {
                  this.weekly = temp.copiedIsWeekly;
                  this.weeklyTill = temp.copiedWeeklyTill;
                  this.copyTill = temp.copiedWeeklyTill;
              }
              await this.saveAppointment();
          } else {
            this.showSnackbar({ message: 'Bitte Kopieren Sie die Daten aus einem bereits Erstellten Termin.', color: 'error'});
          }
      },

    async saveAppointment() {
      if (!this.thisTeacher || !this.thisSubject || this.selectedTeachers.length === 0) {
        this.showSnackbar({ message: 'Bitte füllen Sie alle benötigten Felder aus!', color: 'error'});
        return;
      } else if (this.weekly) {
        if (!this.validateInput()) {
          this.showSnackbar({ message: 'Das ausgewählte Datum muss in der Zukunft liegen!', color: 'error'});
          return;
        }
      }
      let data = {
        schoolSubject: this.thisSubject._id,
        teachers: this.selectedTeachers,
        groupId: this.group._id,
        day: this.day,
        timeslot: this.timeslot._id,
        ...(this.weekly
          ? {
              repeat: {
                interval: "weekly",
                from: new Date(this.day),
                until: new Date(this.weeklyTill),
              },
            }
          : {}),
      };
      let response = await this.createAppointment(data);
      if (Number.isInteger(response)) {
        if (response === 409) {
          this.showSnackbar({ message: 'Der Termin konnte nicht erstellt werden.', color: 'error'});
        } else {
          this.showSnackbar({ message: 'Ein unerwarteter Fehler ist aufgetretten!', color: 'error'});
        }
        this.snackbar = true;
      } else {
        this.showSnackbar({ message: 'Gespeichert!'});
        this.requestGroup();
        this.menu = false;
      }
    },

    /**
     * Method to delete one appointment or a repeating appointment
     * pretty nifty i know :)
     * @returns {Promise<void>} nothing of importance
     */
    async deleteAction() {

      let snackbar409Text = 'Der Termin konnte nicht gelöscht werden.'
      let response;

      const requestParams = {type: 'onlyThis', appointment: this.appointment};

      if (this.appointment.rule
              && (this.deleteRecurringAppointment === 'thisAndFollowing'
                      || this.deleteRecurringAppointment === 'allOfThem')){
          requestParams.type = this.deleteRecurringAppointment;
          snackbar409Text = 'Die Termine konnten nicht gelöscht werden.'
      }

      response = await this.deleteAppointment(requestParams);

      if (Number.isInteger(response)) {
        if (response === 409) {
          this.showSnackbar({ message: snackbar409Text, color: 'error'});
        } else {
          this.showSnackbar({ message: 'Ein unerwarteter Fehler ist aufgetreten!', color: 'error'});
        }
      } else {
        this.showSnackbar({ message: 'Gespeichert!'});
        this.thisSubject = null;
        this.requestGroup();
        this.menu = false;
      }
      this.deleteDialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
input[type="date"] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  min-height: 1.6rem;
}

.fach {
  cursor: pointer;
}

.innerFach {
  position: relative;
  border-radius: 10px;
  box-shadow: 1px 2px 3px silver;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: #FFF;
}

.textGrau {
  color: var(--v-dunkelgrau-base);
}

.scroll-area {
  position: relative;
  margin: auto;
  max-height: 100px;
}

.scroll-area2 {
  position: relative;
  margin: auto;
  max-height: 100px;
}

.scroll-area3 {
  position: relative;
  margin: auto;
  max-height: 50px;
}

.input {
  display: block;
  width: 100%;
  max-width: 100%;
  border-radius: 5px;
  border-width: 0;
  color: var(--v-dunkelgrau-base);
  padding: 1px 5px 1px 5px;
  border: none !important;
  background-color: var(--v-hellgrau-base);
  overflow: hidden;
  appearance: none;
}

.input[disabled] {
  color: grey;
  background-color: grey;
}

.iconSelect {
  display: inline-flex;
  width: 24px;
  height: 24px;
  padding: 2px;
  margin-bottom: -6px;
  border-radius: 10px;
  margin-right: 2px;
  background-color: rgba(234, 234, 234, 0.7);
}

.button {
  width: 90%;
  margin-left: auto !important;
  margin-right: auto !important;
  display: block;
}

.schoolIcon {
  margin-right: 5px;
  margin-bottom: 2px;
  background-color: #ffffff55;
  padding: 5px;
}

.cancelIcon {
  margin-left: auto;
  margin-right: 40px;
}

.subjectIcon {
  position: absolute;
  height: 25px;
  top: 0;
  right: 10px;
}

.copyButton {
  position: absolute;
  top: 12px;
  right: 40px;
}
</style>
