<template>
    <div>
        <v-dialog
            v-model="openDialog"
            :fullscreen="openInFullscreen"
            internal-activator
            min-width="1100px"
        >
            <v-card style="min-height: 80vh !important;">
                <v-card-title
                    id="templateBrowserHeader"
                    class="d-flex justify-space-between align-center"
                >
                    <div style="width: 15%; min-width: 175px">
                        <v-text-field
                            outlined
                            hide-details
                            dense
                            background-color="weiss"
                            placeholder="Vorlage suchen..."
                            append-icon="mdi-magnify"
                            @input="searchInputChanged"
                        />
                    </div>
                    <p class="fontXLarge">
                        Vorlage wählen
                    </p>
                    <div
                        style="width: 15%; min-width: 175px"
                        class="d-flex justify-end"
                    >
                        <v-btn
                            x-small
                            width="45px"
                            height="45px"
                            @click="close"
                        >
                            <img
                                width="35px"
                                height="35px"
                                :src="closeIcon"
                                alt="Vorlagenbrowser schließen"
                            >
                        </v-btn>
                    </div>
                </v-card-title>
                <v-card-text id="templateBrowserContent">
                    <div
                        v-if="currentFolderTree.length < 2"
                        id="templateBrowserPart"
                    >
                        <p class="ml-2 mb-3 fontLarge">
                            Formatvorlagen
                        </p>

                        <!-- List part -->
                        <div
                            class="d-flex flex-wrap justify-space-evenly align-center"
                            style="max-width: 100%;"
                        >
                            <EditorTemplateBrowserItem
                                v-for="(item, index) in displayableTemplateList"
                                :key="`templateDoc${index}${item._id}`"
                                :item="item"
                                :type="item.type"
                                class="ma-1"
                                style="width: 275px"
                                @templateSelected="templateSelected"
                            />
                        </div>
                    </div>
                    <div
                        v-if="mode === 'creator'"
                        id="docRegistryPart"
                    >
                        <div
                            class="d-flex justify-start align-center"
                            :style="`${currentFolderTree.length > 1 ? 'padding-bottom: 10px; margin-bottom: 20px; border-bottom: 1px solid var(--v-grau-base);' : ''}`"
                        >
                            <v-btn
                                v-if="currentFolderTree.length > 1"
                                text
                                @click="changeCurrentFolder(currentFolderTree[currentFolderTree.length - 2])"
                            >
                                <v-icon style="color: var(--v-dunkelgrau-base)">
                                    fas fa-chevron-left
                                </v-icon>
                            </v-btn>
                            <p class="ml-2 mb-3 fontLarge">
                                Arbeitsblätter
                                <span
                                    v-for="(folder, index) in currentFolderTree"
                                    :key="`folder${folder._id}${index}`"
                                    class="directoryPath"
                                    @click="changeCurrentFolder(folder)"
                                >{{ folder.parent ? `/ ${folder.name}` : 'Online Bibliothek' }}</span>
                            </p>
                        </div>

                        <!-- List part -->
                        <div
                            v-if="displayableDocRegistryElements.items && displayableDocRegistryElements.items.length"
                            class="d-flex flex-wrap justify-space-evenly align-center"
                            style="max-width: 100%;"
                        >
                            <EditorTemplateBrowserItem
                                v-for="(item, index) in displayableDocRegistryElements.items"
                                :key="`templateDoc${index}${item._id}`"
                                :item="item"
                                :type="item.itemType"
                                class="ma-1"
                                style="width: 275px"
                                @folderSelected="(callback) => changeCurrentFolder(item, callback)"
                                @itemSelected="(callback) => documentRegistryDocumentSelected(item, callback)"
                                @loadFullscreenPreview="loadFullscreenPreview"
                            />
                        </div>
                        <div
                            v-else-if="loadingRegistry"
                            class="d-flex justify-center align-center"
                        >
                            <v-progress-circular
                                indeterminate
                                size="100"
                            />
                        </div>
                        <div v-else>
                            <p>Es konnten keine Dateien in diesem Ordner der Online-Bibliothek gefunden werden.</p>
                        </div>
                    </div>
                </v-card-text>
                <v-card-actions
                    class="d-flex justify-end align-center"
                    style="bottom: 0; position: absolute; width: 100%; padding: 16px 24px 10px;"
                >
                    <!-- Button zum Schließen oder Editor verlassen wenn ein neues Dokument erstellt wird -->
                    <!-- <v-btn
                        depressed
                        width="15%"
                        min-width="150px"
                        style="text-transform: inherit"
                        @click="leaveEditorWhenCreatingNewDocument"
                    >
                        Editor verlassen
                    </v-btn>-->
                    <v-btn
                        depressed
                        style="text-transform: inherit"
                        @click="close"
                    >
                        Abbrechen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <div v-if="fullscreenPreviewEnabled">
            <v-dialog
                v-model="fullscreenPreviewEnabled"
                scrollable
                style="overflow: hidden"
                transition="dialog-bottom-transition"
                max-width="80vw"
            >
                <div
                    id="previewDialog"
                    ref="previewDialog"
                    style="display: flex; flex-direction: column"
                >
                    <div
                        width="100%"
                        style="position: sticky; top: 0px; display:flex; justify-content: space-between; align-items: center; background-color: white; z-index: 10; height: 7vh"
                        class="pa-5"
                    >
                        <v-btn
                            class="textTransformNone"
                            style="background-color: #ffffff !important; elevation: 0; box-shadow: none; cursor: default"
                            :ripple="false"
                        >
                            <v-icon>mdi-file</v-icon>
                            <span>{{ previewSelection.name }}</span>
                        </v-btn>
                        <v-btn
                            style="background-color: #ffffff !important; elevation: 0; box-shadow: none"
                            @click="() => { fullscreenPreviewEnabled = false }"
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </div>
                    <v-card style="background-color: #707070; overflow: auto">
                        <v-card-text
                            class="pa-0"
                            :style="previewPDF ? 'display: flex; justify-content: space-around' : ''"
                        >
                            <!-- <div class="text-h2 pa-12">{{ previewSelection }}</div> -->
                            <v-img
                                v-if="!previewPDF"
                                :src="preview"
                                contain
                                aspect-ratio="1.4"
                                :alt="previewSelection.name || previewSelection.uploadedName"
                                :lazy-src="img_eklaraLogo"
                            >
                                <div class="fill-height bottom-gradient" />
                                <template v-slot:placeholder>
                                    <v-row
                                        class="fill-height fill-width ma-0"
                                        align="center"
                                        justify="center"
                                    >
                                        <v-progress-circular
                                            indeterminate
                                            color="grey"
                                        />
                                    </v-row>
                                </template>
                            </v-img>
                            <object
                                v-else
                                :data="preview + '#toolbar=0&navpanes=0&scrollbar=0&view=Fit'"
                                style="height: 80vh; width: 80vw"
                            />
                        </v-card-text>
                    </v-card>
                </div>
            </v-dialog>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import EditorTemplateBrowserItem from "@/components/Editor/EditorTemplateBrowserItem";
import previewTemplateBlank from '@/assets/Icons/Temp/preview-blank-page-editor-template-browser.png';
import previewTemplateLiniert from '@/assets/Icons/Temp/preview-liniert-page-editor-template-browser.png';
import previewTemplateKariert from '@/assets/Icons/Temp/preview-kariert-page-editor-template-browser.png';
import previewTemplateHaus from '@/assets/Icons/Temp/preview-haus-page-editor-template-browser.png';
import previewDefaultWorksheet from '@/assets/Icons/Temp/preview-default-ab-editor-template-browser.png'
import closeIcon from '@/assets/Icons/abbrechen-08.svg'
import img_eklaraLogo from "@/assets/EditorLibraryMock/eklara_logo.png";
import * as backend from "@/api/backend";

export default {
    name: 'EditorTemplateBrowser',
    components: { EditorTemplateBrowserItem },
    props: {
        activator: { required: true, type: Boolean },
        mode: { required: true, type: String },
    },
    data: () => ({
        internalActivator: false,
        currentFolder: {},
        folderCallback: () => { },
        loadingRegistry: false,

        currentFolderTree: [],
        templateSearchString: '',

        //#region Template Stuff
        normalTemplateList: [
            { _id: 1, type: 'template', itemType: 'blank', name: 'DIN A4 Leer', preview: previewTemplateBlank },
            { _id: 4, type: 'template', itemType: 'defaultWorksheet', name: '4:3 eKlara Blatt', preview: previewDefaultWorksheet },
            { _id: 5, type: 'template', itemType: 'folie', name: '4:3 Leer', preview: previewTemplateBlank },
            { _id: 6, type: 'template', itemType: 'kariert', name: 'DIN A4 Kariert', preview: previewTemplateKariert },
            { _id: 7, type: 'template', itemType: 'liniert', name: 'DIN A4 Liniert', preview: previewTemplateLiniert },
            { _id: 8, type: 'template', itemType: 'haus', name: 'DIN A4 Hauslinien', preview: previewTemplateHaus },
        ],
        //#endregion

        //#region preview data
        preview: null,
        previewPDF: false,
        previewSelection: null,
        fullscreenPreviewEnabled: false,
        //#endregion

        //#region Icons
        closeIcon,
        img_eklaraLogo,
        //#endregion
    }),
    computed: {
        ...mapState('documentRegistry', [
            'documentRegistryRootFolder',
            'documentRegistryDocuments',
        ]),
        ...mapGetters('documentRegistry', [
            'documentRegistryDocumentsById',
            'documentRegistryFoldersById',
        ]),
        ...mapState('util', ['windowWidth']),
        openDialog() {
            return this.activator || this.internalActivator
        },
        openInFullscreen() {
            return this.windowWidth < 1100;
        },
        displayableTemplateList() {
            // if (this.mode === 'creator') {
            //     return this.normalTemplateList.concat(this.creatorOnlyTemplates);
            // }
            return this.normalTemplateList;
        },
        displayableDocRegistryElements() {
                const currentFolder = this.currentFolder 
                    ? { ...this.currentFolder }
                    : { items: [ ]};

                const returnFolder = { ...currentFolder };
                returnFolder.items = [];
                const folders = [];
                const files = [];

                if (currentFolder.items) {
                    for (let i = 0; i < currentFolder.items.length; i++) {
                        let item = { ...currentFolder.items[i] };

                        let data = null;
                        if (item.itemType === 'Folder') {
                            data = { ...this.documentRegistryFoldersById[item.value] };
                            if (Object.keys(data).length) {
                                item.type = 'folder';
                                item.items = data.items;
                                item.name = data.name;
                                item.parent = data.parent;
                                folders.push(item);
                            }
                        } else {
                            data = { ...this.documentRegistryDocumentsById[item.value] };
                            if (Object.keys(data).length) {
                                item = { ...item, ...data };
                                item.type = 'file';
                                files.push(item);
                            }
                        }
                    }

                    folders.sort((a, b) => {
                        a = a.name.toLowerCase();
                        b = b.name.toLowerCase();
                        if (this.sortAlphabeticalDescending) {
                            return (a < b) ? 1 : (a > b) ? -1 : 0;
                        } else {
                            return (a < b) ? -1 : (a > b) ? 1 : 0;
                        }
                    })
                    files.sort((a, b) => {
                        a = (a.originalname || a.name).toLowerCase();
                        b = (b.originalname || b.name).toLowerCase();
                        if (this.sortAlphabeticalDescending) {
                            return (a < b) ? 1 : (a > b) ? -1 : 0;
                        } else {
                            return (a < b) ? -1 : (a > b) ? 1 : 0;
                        }
                    });

                    if (this.templateSearchString) {
                        return {
                            items: files
                                .filter(doc => doc.name.includes(this.templateSearchString))
                        };
                    }

                    returnFolder.items = folders.concat(files);
                }
                // else {
                //     // Set Loading
                // }

                return returnFolder;
            }
    },
    async created() {
        this.loadingRegistry = true;
        await this.loadDocumentRegistryDocuments();
        await this.loadDocumentRegistryRootFolder();
        await this.loadDocumentRegistryFolders();
        this.currentFolder = this.documentRegistryRootFolder;
        this.currentFolderTree.push(this.documentRegistryRootFolder);
        this.loadingRegistry = false;
    },
    updated() {
        if (this.displayableDocRegistryElements.items && this.folderCallback) {
            this.folderCallback();
            this.$nextTick(() => {
                this.folderCallback = () => { };
            });
        }
    },
    methods: {
        ...mapActions('documentRegistry', [
            'loadDocumentRegistryDocuments',
            'loadDocumentRegistryRootFolder',
            'loadDocumentRegistryFolders'
        ]),

        //#region General
        close() {
            this.internalActivator = false;
            this.$emit('close');
        },
        searchInputChanged(input) {
            this.templateSearchString = input;
        },
        //#endregion

        //#region Methods to return result to parent
        templateSelected(template) {
            this.$emit('templateSelected', template);
        },
        changeCurrentFolder(folder, callback) {
            this.currentFolder = folder
            if (!this.currentFolderTree.includes(folder)) {
                this.currentFolderTree.push(folder)
            } else {
                //find index
                const folderIndex = this.currentFolderTree.findIndex(item => item._id === folder._id);
                // delete everything after index
                this.currentFolderTree.splice(folderIndex + 1);
            }
            this.folderCallback = callback;
        },
        documentRegistryDocumentSelected(document, callback) {
            this.$emit('documentRegistryDocumentSelected', document);
        },
        //#endregion

        //#region Fullscreen Preview
        async loadFullscreenPreview(item) {
            this.previewSelection = item;

            const thumbnailResponse = await backend.getDocumentRegistryDocumentFile(
                item.value,
                item.files[0]);
            let thumb = await thumbnailResponse.blob();
            this.previewPDF = false;

            if (thumb) {
                this.preview = URL.createObjectURL(thumb);
                if (thumb.type === 'application/pdf') {
                    this.previewPDF = true;
                }
            } else {
                this.preview = img_eklaraLogo;
            }

            this.fullscreenPreviewEnabled = true;
        }
    }
}
</script>

<style scoped lang="scss">
#templateBrowserHeader {
    background-color: var(--v-hellgrau-base);
}

#templateBrowserContent {
    font-size: 1rem !important;

    p {
        color: black;
    }
}

#templateBrowserPart {
    padding: 1em;
}

#docRegistryPart {
    padding: 1em;
    margin-bottom: 60px;
}

.fontXLarge {
    font-size: x-large !important;
}

.fontLarge {
    font-size: large !important;
}

.directoryPath {
    border-radius: 6px;
    cursor: pointer;

    :hover {
        background-color: var(--v-hellgrau-base) !important;
    }
}
</style>
