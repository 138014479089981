<template>
    <div
        ref="editorImageLibrary"
        style="min-height: calc(100vh - 240px); height: calc(100vh - 240px);"
    >
        <v-text-field
            v-model="searchInput"
            placeholder="Stichworte mit Komma trennen"
            outlined
            dense
            height="50"
            background-color="hellgrau"
            style="max-height: 45px; border-radius: 5px; margin: 4px;"
            hide-details
            @input="textFieldInputChanged"
        />
        <!-- List of tags -->
        <div
            class="pa-1"
            style="margin-bottom: 30px"
        >
            <v-chip
                v-for="(entry, index) in searchTerms"
                :key="`term${entry}`"
                close
                close-icon="mdi-close"
                label
                @click:close="removeSearchTerm(index)"
            >
                {{ entry }}
            </v-chip>
        </div>
        <EditorImageLibraryItem
            v-for="item in displayableImages"
            :key="item._id"
            class="mx-auto my-2 d-flex flex-column align-center justify-center"
            style="width: 150px; height: 150px; background-color: var(--v-hellgrau-base); border-radius: 5px"
            tabindex="17"
            draggable="true"
            :image-library-image="item"
            @addImageField="(src, blob) => $emit('addImageField', src, blob)"
        />

        <v-btn
            v-if="countDisplayableImages < imageLibraryIndex.length"
            class="mx-auto my-2 d-flex flex-column align-center justify-center"
            style="width: 150px; color: #fff; margin-bottom: 8px"
            elevation="3"
            tabindex="17"
            color="#f07d32"
            @click="loadMoreImages"
        >
            mehr laden
        </v-btn>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import EditorImageLibraryItem from './EditorImageLibraryItem';

export default {
    name: "EditorImageLibrary",
    components: {
        EditorImageLibraryItem,
    },
    props: {
        // Needed for the IntersectionObserver
        // in order to load more images when the user scrolled to the bottom
        // of the image list
        refEditorLibraryContainer: {
            required: false,
            type: HTMLDivElement,
        },
    },
    data: () => ({
        searchInput: '',
        countDisplayableImages: 10,
        searchTerms: [],
    }),
    computed: {
        ...mapState('imageLibrary', ['imageLibraryIndex', 'imageLibraryTags']),
        displayableImages() {
            // Search images
            let slicedImageLibrary = this.imageLibraryIndex.slice();
            if (this.searchTerms.length) {
                slicedImageLibrary = slicedImageLibrary
                    .filter(item => item.tags
                        .some(tag => this.searchTerms.includes(tag)));
            }
            const foundImages = this.searchLibrary(slicedImageLibrary, this.searchInput);

            // Limit amount of displayable images
            const limitedImages = foundImages.slice(0, this.countDisplayableImages);

            return limitedImages;
        }
    },
    watch: {
    },
    mounted() {
        this.loadImageLibraryIndex();
    },
    methods: {
        ...mapActions('imageLibrary', ['loadImageLibraryIndex', 'getImageLibraryImage']),
        loadMoreImages() {
            this.countDisplayableImages += 10;
        },
        textFieldInputChanged(input) {
            if (input.includes(',')) {
                const currentSearchTerms = input.split(/\s*,+\s*/);
                if (currentSearchTerms.length) {
                    this.searchTerms.push(currentSearchTerms[0].trim());
                    this.searchInput = '';
                }
            }
        },
        removeSearchTerm(index) {
            this.searchTerms.splice(index, 1);
        },
        searchLibrary(library, searchTerm) {

            // if left empty, reset to alphabetical sorting
            if (searchTerm === '' || searchTerm.length < 3) {
                // sort alphabetically, descending
                return library.sort((a, b) => a.title.localeCompare(b.title));
            }

            // https://stackoverflow.com/a/36566052
            // using a stackoverflow similarity based algorithm as placeholder
            // since this search will have to change depending on central repository specs
            // and how the tagging infrastructure will be set up on a later date

            function editDistance(s1, s2) {
                s1 = s1.toLowerCase();
                s2 = s2.toLowerCase();

                var costs = new Array();
                for (var i = 0; i <= s1.length; i++) {
                    var lastValue = i;
                    for (var j = 0; j <= s2.length; j++) {
                        if (i == 0)
                            costs[j] = j;
                        else {
                            if (j > 0) {
                                var newValue = costs[j - 1];
                                if (s1.charAt(i - 1) != s2.charAt(j - 1))
                                    newValue = Math.min(Math.min(newValue, lastValue),
                                        costs[j]) + 1;
                                costs[j - 1] = lastValue;
                                lastValue = newValue;
                            }
                        }
                    }
                    if (i > 0)
                        costs[s2.length] = lastValue;
                }
                return costs[s2.length];
            }

            function similarity(s1, s2) {
                let longer = s1;
                let shorter = s2;
                if (s1.length < s2.length) {
                    longer = s2;
                    shorter = s1;
                }
                let longerLength = longer.length;
                if (longerLength === 0) {
                    return 1.0;
                }
                return (longerLength - editDistance(longer, shorter)) / parseFloat(longerLength);
            }
            // generate similarity of every item in loaded library
            for (let item of library) {
                item.similarity = similarity(item.title, searchTerm);
            }
            // sort by descending similarity
            return library.sort((a, b) => parseFloat(b.similarity) - parseFloat(a.similarity));
        }
    },
}
</script>
