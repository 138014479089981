import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import 'typeface-roboto/index.css';

Vue.use(Vuetify, {
  iconfont: 'mdi'
});

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#6996cd',
        secondary: '#8cbe46',
        success: '#8cbe46',
        error: '#e6231e',

        //TODO replace color codes with variables in all components
        //HOW TO USE: if the Vue component supports color prop use it like color="gruen"
        //otherwise create a class and use like background-color: var(--v-gruen-base);
        weiss: '#f8f8f8',           //Standard Hintergrundfarbe
        hellgrau: '#eaeaea',        //Buttonfarbe #1
        grau: '#dbdbdb',            //Buttonfarbe #3
        dunkelgrau: '#707070',      //Schriftfarbe (#4)

        gelb: '#fac837',
        hellorange: '#f5a032',
        orange: '#f07d32',
        pink: '#e6415a',
        rot: '#e6231e',

        hellblau: '#6996cd',
        blau: '#505faa',
        gruen: '#8cbe46',
        editorprimary: '#8cbe46',
        editorWrong: '#E62D2D',
        editorNeutral: '#538ECB',
        editorGrey: '#ededed',
        dunkelgruen: '#3caa69',

        //fach colors
        fgelb: '#f5be2d',
        forange: '#f0a03c',
        fpink: '#e67dff',
        frot: '#d50000',

        fhellblau: '#009ae4',
        fblau: '#3f4fb3',
        fhellgruen: '#2fb277',
        fgruen: '#0b8348',

        fmittelblau: '#7587c7',
        flila: '#94519B',
        fgrau: '#bdbdbd',
        fdunkelgrau: '#636363',
      },
    },
  },
});
