<template>
    <v-dialog
        v-model="openDialog"
        internal-activator
        overlay-opacity=".9"
        overlay-color="black"
        width="unset"
        max-width="90%"
        @click:outside="close"
    >
        <v-card
            class="mx-auto"
            style="min-height: 70vh !important; max-width: 75vw; background-color: transparent !important;; overflow-y: hidden"
        >
            <v-card-title
                class="d-flex justify-space-between align-center"
            >
                <div style="width: 10%" />
                <p
                    :class="`${windowWidth > 900 ? 'fontXLarge' : 'fontLarge'} white--text`"
                >
                    {{ `${videoTutorial.title}` }}
                </p>
                <div
                    style="width: 10%"
                    class="d-flex justify-end"
                >
                    <v-btn
                        x-small
                        text
                        width="30px"
                        height="30px"
                        class="pa-1"
                        style="overflow: hidden"
                        @click="close"
                    >
                        <img
                            width="30px"
                            height="30px"
                            style="filter: brightness(1000%); transform: scale(3);"
                            :src="closeIcon"
                            alt="Videotutorial schließen"
                        >
                    </v-btn>
                </div>
            </v-card-title>
            <v-card-text class="d-flex flex-column justify-center align-center pb-0">
                <div
                    style="height: 70% !important;"
                >
                    <video
                        style="width: 100% !important; height: auto !important;"
                        controls
                        autoplay
                    >
                        <source
                            :src="videoLink"
                            type="video/mp4"
                        >
                    </video>
                </div>

                <div class="d-flex align-center mt-4">
                    <v-checkbox
                        v-model="dontShowVideoAgain"
                        dark
                        hide-details
                        class="mt-0 pt-0"
                        label="Nicht nochmal anzeigen"
                    />
                    <v-btn
                        v-if="accountRole === 'pupil' || accountRole === 'parent'"
                        class="optionBtn ml-4"
                        @click="readText($event)"
                    >
                        <img
                            :src="speakerIcon"
                            class="icon"
                            alt="Text vorlesen"
                        >
                    </v-btn>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import closeIcon from '@/assets/Icons/abbrechen-08.svg'
import speakerIcon from "@/assets/Icons/lautsprecher-13.svg";
import speakerOffIcon from "@/assets/Icons/lautsprecher-aus-89.svg";
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import { getBackendAddress } from '../../util/getBackendAddress';

export default {
    name: 'VideoTutorial',
    props: {
        videoTutorial: { required: true, type: Object },
    },
    data: () =>({
        openDialog: true,
        dontShowVideoAgain: false,

       //#region Icons
        closeIcon,
        speakerIcon,
        // eslint-disable-next-line vue/no-unused-properties
        speakerOffIcon,
       //#endregion
    }),
    computed: {
        ...mapState('util', ['windowWidth']),
        ...mapState('translation', [ 'targetLang' ]),
        ...mapGetters('auth',[ 'accountRole' ]),
        videoLink() {
            return `${getBackendAddress()}/document-registry/video-tutorial/${this.videoTutorial.filename}`
        },
    },
    methods: {
        ...mapMutations('videoTutorial', [ 'setTutorialSeeAgain' ]),
        ...mapActions('translation', [ 'setTranslatedText', 'translateToTargetLang' ]),

        close() {
            this.setTutorialSeeAgain({ filename: this.videoTutorial.filename, seeAgain: !this.dontShowVideoAgain});
            this.$emit('close');
        },

        // Text to Speech
        async readText(event) {
            if ('speechSynthesis' in window) {
                // Speech Synthesis supported 🎉
            } else {
                // Speech Synthesis Not Supported 😣
                alert("Sorry, your browser doesn't support text to speech!");
                return
            }

            let text = 'Nicht nochmal anzeigen';

            if (this.targetLang !== 'de') {
                text = await this.translateToTargetLang({
                    targetLang: this.targetLang,
                    textToTranslate: text
                });
            }

            if (window.speechSynthesis.speaking) {
                window.speechSynthesis.cancel();
                event.target.src = speakerIcon;
            } else {
                this.setTranslatedText(text);

                if (this.showTextTimeout) {
                    clearTimeout(this.showTextTimeout);
                    this.showTextTimeout = null;
                }

                this.showTextTimeout = setTimeout(() => {
                    this.showTextTimeout = null;
                }, 15000)

                event.target.src = speakerOffIcon;
                let msg = new SpeechSynthesisUtterance();
                msg.text = text;
                if (this.isLangPackageAvailable()) {
                    msg.lang = this.targetLang;
                    window.speechSynthesis.speak(msg);
                } else {
                    event.target.src = speakerIcon;
                }

                msg.onend = function() {
                    event.target.src = speakerIcon;
                }
            }
        },

        isLangPackageAvailable() {
            for (let i = 0; i < window.speechSynthesis.getVoices().length; i++) {
                if (window.speechSynthesis.getVoices()[i].lang.includes(this.targetLang)) {
                    return true;
                }
            }

            return false;
        },
    }
}
</script>

<style scoped lang="scss">
.fontXLarge {
    font-size: x-large !important;
}

.fontLarge {
    font-size: large !important;
}

.icon {
    height: 20px;
}

.optionBtn {
    border-radius: 8px;
    min-width: 35px !important;
    width: 35px !important;
    height: 35px !important;
}
</style>
