<template>
    <div
        @click="downloadDocumentPDF"
        @dragstart="dragDocument"
    >
        <embed
            v-if="backgroundPdfUri"
            :src="backgroundPdfUri"
            style="max-width:140px; max-height:95px; margin-top: 6px"
            type="application/pdf"
        >
        <img
            v-else-if="thumbnailUri"
            :src="thumbnailUri"
            style="max-width:140px; max-height:95px; margin-top: 6px"
        >
        <v-img
            v-else
            :src="eklaraLogo"
            max-height="95px"
            max-width="140px"
            class="my-2"
            :alt="documentRegistryDocument.title"
            :lazy-src="eklaraLogo"
        >
            <template v-slot:placeholder>
                <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                >
                    <v-progress-circular
                        indeterminate
                        color="grey"
                    />
                </v-row>
            </template>
        </v-img>

        <p
            style="text-align: center; line-break: anywhere"
            class="my-2"
            v-text="documentRegistryDocument.name"
        />
    </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import * as backend from '../../../../../api/backend';
import eklaraLogo from '@/assets/login-logo.png'

export default {
    name: 'EditorDocumentRegistryItem',
    props: {
        documentRegistryDocument: {
            required: true,
            type: Object,
        }
    },
    data: () => ({
        eklaraLogo,
        backgroundPdfUri: null,
        thumbnailUri: null,
        fileURLs: [],
    }),
    computed: {
        // ...mapState('documentRegistry', ['documentRegistryIndex']),
    },
    async mounted() {
        // const backgroundPdfResponse = await backend.getDocumentRegistryDocumentFile(
        //     this.documentRegistryDocument._id,
        //     this.documentRegistryDocument.structure.backgroundPdf);
        // const backgroundPdfBlob = await backgroundPdfResponse.blob();
        // const backgroundPdfArray = new Uint8Array(await backgroundPdfBlob.arrayBuffer());
        // this.backgroundPdfUri = URL.createObjectURL(backgroundPdfBlob);

        const thumbnailResponse = await backend.getDocumentRegistryDocumentFile(
            this.documentRegistryDocument._id,
            this.documentRegistryDocument.thumbnail);
        const thumbnailBlob = await thumbnailResponse.blob();
        const thumbnailArray = new Uint8Array(await thumbnailBlob.arrayBuffer());
        this.thumbnailUri = URL.createObjectURL(thumbnailBlob);
    },
    async unmounted() {
        URL.revokeObjectUrl(this.backgroundPdfUri);
        URL.revokeObjectUrl(this.thumbnailUri);
        this.fileURLs.forEach(async (url) => {
            URL.revokeObjectURL(url);
        });
    },
    methods: {
        ...mapActions('documentRegistry', ['get']),
        async downloadDocumentPDF() {
            const registryDocument = this.documentRegistryDocument;
            // Method for demo purpuoses
            // Simply download first file in document
            // In the future we need another way to reference PDF document
            
            const fileResponse = await backend.getDocumentRegistryDocumentFile(
                registryDocument._id,
                registryDocument.structure.backgroundPdf);
            
            const fileBlob = await fileResponse.blob();
            const fileArray = new Uint8Array(await fileBlob.arrayBuffer());
            const fileUrl = URL.createObjectURL(fileBlob);
            
            this.$emit('onAddDocFromLibrary', fileArray);
            this.fileURLs.push(fileUrl);
        },

        async dragDocument(event) {
            const registryDocument = this.documentRegistryDocument;
            
            

            const payload = {
                type: 'registryDocument',
                id: registryDocument._id,
                structure: registryDocument.structure
            };

            const stringPayload = JSON.stringify(payload);
            

            event.dataTransfer.setData('text/plain', stringPayload);
        },
    },
}
</script>