<template>
    <div>
        <EditorBaseComponent
            v-if="mode === 'teacher'"
            ref="editorBaseComponent"
            :mode="mode"
            :save-function="saveCorrection"
            :loading-active="loadingActive"
            :loading-message="loadingMessage"
            :save-in-private-folder-function="saveNewPrivateDocument"
            :events="editorEvents"
            @close="close"
        />
        <EditorBaseComponent
            v-else-if="mode === 'pupil'"
            ref="editorBaseComponent"
            :mode="mode"
            :save-function="savePupilDocument"
            :save-in-private-folder-function="saveNewPrivateDocumentAsPupil"
            :loading-active="loadingActive"
            :loading-message="loadingMessage"
            :events="editorEvents"
            @close="close"
        />
        <EditorBaseComponent
            v-else-if="mode === 'creator'"
            ref="editorBaseComponent"
            :mode="mode"
            :save-function="saveCreatorDocument"
            :save-in-private-folder-function="saveNewPrivateDocument"
            :save-in-shared-folder-function="saveNewSharedDocument"
            :loading-active="loadingActive"
            :loading-message="loadingMessage"
            :events="editorEvents"
            @close="close"
        />
        <EditorBaseComponent
            v-else-if="mode === 'viewer'"
            ref="editorBaseComponent"
            :mode="mode"
            :save-function="() => {}"
            :save-in-private-folder-function="() => {}"
            :save-in-shared-folder-function="() => {}"
            :loading-active="false"
            :loading-message="''"
            :events="editorEvents"
            @close="close"
        />
        <div v-else>
            Fehler: Editormodus {{ mode }} nicht gefunden
        </div>
        <!-- Name File for those empty pages that can be uploaded -->
        <NameFile
            v-model="showNameFile"
            :original-name="uploadFileName"
            :callback="fileNameCallback"
            :show-is-assignment="showIsAssignmentCheckbox"
            :group="currentlyOpenAppointment ? currentlyOpenAppointment.group : null"
        />
    </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex';
import NameFile from "./NameFile";
import EditorBaseComponent from "@/components/Editor/EditorBaseComponent";

const NewFileType = {
    APPOINTMENT: 0,
    PRIVATE_FOLDER: 1,
    SHARED_FOLDER: 2
}

export default {
    name: 'EditLehrer',
    components: {
        EditorBaseComponent,
        NameFile,
    },
    data: () => ({
        mode: '',
        loadingActive: false,
        loadingMessage: 'Dokument wird geladen...',

        // Name File Upload Items
        showNameFile: false,
        uploadFileName: null,
        showIsAssignmentCheckbox: true,
        fileNameCallback: () => {},

        // remembers if previous save-dest was appointment or private
        savedToVFS : false,

        // Events to give to EditorBaseComponent instead of calling them per $ref
        editorEvents: [],
    }),
    computed: {
        ...mapGetters('auth', [ 'accountRole' ]),
        ...mapGetters('util', [ 'currentlyOpenAppointment' ]),
    },
    mounted() {
        this.mode = this.$route.params.mode;

        // Set RouteId for Videotutorial call since routename was changed to just editor
        // (we should improve this in the future)
    },
    methods: {
        ...mapActions('files', [
            'safeCurrentFile',
            'patchCurrentAppointmentUploadFile',
            'patchCurrentPrivateUploadFile',
            'safeNewPdfFile',
            'safeNewPrivateFolderFile',
            'safeNewSharedFolderFile',
        ]),
        ...mapActions('teacherFolder', ['updateVFSFolderUpload_Legacy']),
        ...mapActions('appointments', ['getAppointments']),
        ...mapMutations("snackbar", ["showSnackbar"]),

        /**
         * Method to toggle Loading Animation in Base Component
         */
        setLoading(loadingActive, loadingMessage) {
            this.loadingActive = loadingActive;
            this.loadingMessage = loadingMessage;
        },

        async close() {
            this.getAppointments(true);
            this.editorEvents.push('dispose');
            // this.$refs.editorBaseComponent.dispose();

            // Handle go back correctly in here
            // Doesnt need router.back(), editor is only called from plan atm
            // so it just needs role check, and query is kept
            const name = `${this.accountRole === 'teacher' ? 'lehrer' : 'schueler'}.plan`;
            await this.$router.push({ name });

            this.setLoading(false);
        },

        /**
         * Method given to EditorBaseComponent to handle Saving Communication with outside
         * @param fileData base64 data from canvas pages
         * @param appointmentId read from URL
         * @param fileId if there is one, also read from URL
         * @param fileName to keep correct name or set a new one if file isn't set
         * @param loadNext flag that indicates whether the basecomponent
         *  should be closed after saving or if it loads the next document (default false)
         */
        async saveCorrection({fileData, appointmentId, fileId, fileName, loadNext}) {
            this.setLoading(true, "Korrektur wird gespeichert...");
            try {
                const response = await this.safeCurrentFile({
                    userGroup: 'teacher',
                    data: fileData,
                    fileType: 'pdf',
                    appointmentId: appointmentId,
                    fileId: fileId,
                    fileName: fileName,
                });

                if (response.status === 200
                    || response.status === 201
                    || response.status === 202
                    || response.status === 204) {
                    this.showSnackbar({ message: "Korrektur wurde gespeichert", color: "success" });
                    const responseJson = await response.json();
                    await this.$refs.editorBaseComponent.safeAllEditorMessages(responseJson._id);
                    this.getAppointments(true);
                    if (!loadNext) {
                        await this.close();
                    }
                } else {
                    this.showSnackbar({ message: "Korrektur konnte nicht gespeichert werden. Bitte versuche es später nochmal." });
                }
                this.setLoading(false);
            } catch (e) {
                this.showSnackbar({ message: "Korrektur konnte nicht gespeichert werden. Bitte versuche es später nochmal." });
                this.setLoading(false);
            }
        },

        saveNewPrivateDocumentAsPupil(fileData, currentName, wasEmptyPage) {
            this.saveNewFile(fileData, currentName.split('.')[0], null, NewFileType.PRIVATE_FOLDER, wasEmptyPage);
            this.savedToVFS = true;
        },
        saveNewPrivateDocument(fileData, currentName) {
            this.handleSaveNewFile(fileData, currentName, false, null, NewFileType.PRIVATE_FOLDER);
            this.savedToVFS = true;
        },
        saveNewSharedDocument(fileData, currentName) {
            this.handleSaveNewFile(fileData, currentName, false, null, NewFileType.SHARED_FOLDER);
            this.savedToVFS = true;
        },

        /**
         * Method given to EditorBaseComponent to handle Saving Communication with outside
         * @param fileData base64 data of pages
         * @param appointmentId id of open appointment
         * @param fileId id of open file, if no file is open this is null
         * @param fileName name of open file
         * @param wasEmptyPage indicates whether new File to be saved was newly created, and needs id from backend
         */
        async savePupilDocument({fileData, appointmentId, fileId, fileName, wasEmptyPage}) {
            if (!fileId) {
                await this.saveNewFile(fileData, fileName, appointmentId, NewFileType.APPOINTMENT, wasEmptyPage);
            } else {
                const response = await this.safeCurrentFile({
                    userGroup: 'pupil',
                    data: fileData,
                    fileType: 'pdf',
                    appointmentId,
                    fileId,
                    fileName
                });

                if (response.status === 200
                    || response.status === 201
                    || response.status === 202
                    || response.status === 204) {
                    const responseJson = await response.json();
                    await this.$refs.editorBaseComponent.safeAllEditorMessages(responseJson._id);
                    this.showSnackbar({ message: "Dein Aufschrieb ist abgegeben. Gut gemacht!", color: "success" });
                    await this.close();
                } else {
                    this.showSnackbar({ message: 'Ergebnisse konnten nicht gespeichert werden. Bitte versuche es später nochmal.', color: 'error' });
                    this.setLoading(false);
                }
            }
        },
        async saveNewFile(fileData, fileName, appointmentId, newFileType, wasEmptyPage = false) {
            try {
                let response = null;
                let snackbarMessage = 'Dein Aufschrieb ist abgegeben. Gut gemacht!';

                if (newFileType === NewFileType.APPOINTMENT) {
                    response = await this.safeNewPdfFile({
                        data: fileData,
                        appointmentId,
                        fileName,
                        userGroup: 'pupil'
                    });
                } else {
                    snackbarMessage = "Dein Aufschrieb ist in deinem Ordner gespeichert!"
                    response = await this.safeNewPrivateFolderFile({
                        data: fileData,
                        fileName
                    });
                }

                response.addEventListener('load', async (e) => {
                    if (response.status === 200
                        || response.status === 201
                        || response.status === 202
                        || response.status === 204) {
                        this.showSnackbar({ message: snackbarMessage, color: 'success' });
                        const responseJson = JSON.parse(response.response);
                        await this.$refs.editorBaseComponent.safeAllEditorMessages(responseJson._id);
                        if (wasEmptyPage) {
                            this.$refs.editorBaseComponent.setNewValuesForEmptyPage(fileName, responseJson._id);
                        }
                        if (newFileType !== NewFileType.PRIVATE_FOLDER) {
                            await this.close();
                        }
                    } else {
                        this.showSnackbar({ message: 'Ergebnisse konnten nicht gespeichert werden. Bitte versuche es später nochmal.', color: 'error' });
                        this.setLoading(false);
                    }
                });
            } catch (e) {
                this.showSnackbar({ message: 'Ergebnisse konnten nicht gespeichert werden. Bitte versuche es später nochmal.', color: 'error' });
                this.setLoading(false);
            }
        },
        /**
         * Method given to EditorBaseComponent to handle Saving Communication with outside
         * This includes the NameFile Stuff, calling appropriate Actions and triggering UI Feedback in Base Component
         * @param fileData base64 data from canvas pages
         * @param appointmentId read from URL
         * @param fileId if there is one, also read from URL
         * @param fileName to keep correct name or set a new one if file isn't set
         * @param redrawFile flag to indicate whether file needs to saved from scratch or not
         */
        async saveCreatorDocument({fileData, appointmentId, fileId, fileName, redrawFile}) {
            if (!fileId || this.savedToVFS) {
                this.handleSaveNewFile(fileData, fileName, !!appointmentId, appointmentId, (appointmentId ? NewFileType.APPOINTMENT : NewFileType.PRIVATE_FOLDER));
                this.savedToVFS = false;
            } else if (redrawFile) {
                this.setLoading(true, 'Speichern...');
                fileName = fileName.split('.')[0] + ".pdf";

                let req;
                if (appointmentId) {
                    req = await this.patchCurrentAppointmentUploadFile({
                        data: fileData,
                        appointmentId: appointmentId,
                        fileId: fileId,
                        fileName: fileName
                    });
                } else {
                    req = await this.updateVFSFolderUpload_Legacy({
                        data: fileData,
                        fileId: fileId,
                        fileName: fileName,
                    });
                }

                req.addEventListener('load', async (e) => {
                    if (req.status !== 201) {
                        console.error('File patch failed:', req.response);
                    } else {
                        await this.$refs.editorBaseComponent.safeAllEditorMessages(fileId);
                        this.loadingActive = false;
                        this.loadingMessage = '';
                        this.getAppointments(true);
                        await this.close();
                    }
                });
            } else {
                this.loadingActive = true;
                this.loadingMessage = "Speichern";
                await this.$refs.editorBaseComponent.safeAllEditorMessages(fileId);
                this.loadingActive = false;
                this.loadingMessage = '';
                this.getAppointments(true);
                await this.close();
            }
        },
        handleSaveNewFile(fileData, currentFileName, showIsAssignment, appointmentId, newFileType) {
            this.setLoading(true, 'Warten auf Speichern...');
            this.showNameFile = true;
            this.uploadFileName = currentFileName;
            this.showIsAssignmentCheckbox = showIsAssignment;
            this.fileNameCallback = (newFileName, selectedUser, startDate, isAssignment) => {
                this.showNameFile = false;
                this.saveNewPage(appointmentId, newFileName, selectedUser, startDate, isAssignment, fileData, newFileType);
                // this.assignmentName = newFileName;
            };
            this.setLoading(false);
        },

        /**
         * Method called from Namefile callback when Creation was an empty Page
         * @param appointmentId
         * @param newFileName came from NameFile
         * @param selectedUser array from NameFile
         * @param startDate from NameFile
         * @param isAssignment Checkbox from NameFile
         * @param base64 FileData for Single Page
         * @returns {Promise<void>}
         */
        async saveNewPage(appointmentId, newFileName, selectedUser, startDate, isAssignment, base64, newFileType) {
            try {
                let response;
                if(newFileType === NewFileType.APPOINTMENT) {
                    if(!appointmentId){
                        console.error("No appointment id given! Can not save this appointment file");
                        return
                    }
                    // Create new appointment upload
                    response = await this.safeNewPdfFile({
                        data: base64,
                        appointmentId,
                        fileName: newFileName,
                        params: { selectedUser, startDate, isAssignment },
                        userGroup: 'teacher'
                    });
                } else if(newFileType === NewFileType.PRIVATE_FOLDER) {
                    // Create new private folder upload
                    response = await this.safeNewPrivateFolderFile({
                        data: base64,
                        fileName: newFileName
                    });
                } else if(newFileType === NewFileType.SHARED_FOLDER) {
                    // Create new shared folder upload
                    response = await this.safeNewSharedFolderFile({
                        data: base64,
                        fileName: newFileName
                    });
                }

                response.addEventListener('load', async (e) => {
                    if (response.status === 200
                        || response.status === 201
                        || response.status === 202
                        || response.status === 204) {
                        const responseJson = JSON.parse(response.response);
                        await this.$refs.editorBaseComponent.safeAllEditorMessages(responseJson._id);
                        this.$refs.editorBaseComponent.setNewValuesForEmptyPage(newFileName, responseJson._id);
                        // Reload the store, so the new appointment will be fetched
                        this.getAppointments(true);
                        await this.close();
                    } else {
                        this.showSnackbar({
                            message: 'Dokument konnte nicht gespeichert werden',
                            color: 'error'
                        });
                        this.setLoading(false);
                    }
                });
            } catch (e) {
                console.error(e);
                this.showSnackbar({
                    message: 'Dokument konnte nicht gespeichert werden',
                    color: 'error'
                });
                this.setLoading(false);
            }
        },
    }
}
</script>

<style scoped lang="scss">
</style>
