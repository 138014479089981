<template>
    <div :class="currentFontSize">
        <!-- Verfügbare Drag n Drop Elemente die in die Felder gezogen werden können -->
        <div style="margin: 12px">
            <div
                class="greyDiv"
                style="display: flex; flex-direction: row"
            >
                <!--  Lehrer kann Drag n Drop Elemente shufflen -->
                <img
                    v-if="showTeacherUI"
                    :src="aktualisierenIcon"
                    style="cursor: pointer; margin-left: 8px; margin-right: 2px"
                    :style="sizeState === 0 ? 'height: 20px' : sizeState === 1 ? 'height: 25px' : 'height: 35px'"
                    class="my-auto"
                    @click="randomizeFields"
                >
                <div style="display: flex; flex-direction: row; flex-wrap: wrap; align-items: center">
                    <div
                        v-for="(field, index) in fields"
                        :key="index"
                        style="margin: 8px 6px; padding: 8px; border-radius: 6px"
                        :style="field.hasBorder ? 'border: dashed 4px red; padding: 4px !important' : ''"
                        class="editorColorNeutralBackground colorWhite"
                        :class="showPupilUI ? 'moveCursor' : ''"
                        :draggable="showPupilUI"
                        @dragstart="showPupilUI ? setDragData($event, field) : {}"
                        @click="setClickData(field)"
                    >
                        <span style="padding: 4px">{{ field.text === '' ? 'Text eingeben' : field.text }}</span>
                    </div>
                </div>
                <!-- Der Lehrer kann falsche Drag n Drop Elemente hinzufügen -->
                <!--                    <input v-if="addNewWrongField && showTeacherUI" @change="addWrongField(); fillAllFields(true)" v-model="wrongField" style="background-color: white !important; margin: 8px 6px; padding: 4px; border: solid 2px; border-radius: 12px;" class="editorColorPrimaryBorderColor" :style="sizeState === 0 ? 'font-size: medium' : sizeState === 1 ? 'font-size: x-large' : 'font-size: x-large'">-->
                <!--                    <div v-if="showTeacherUI" @click="addNewWrongField = true"-->
                <!--                         style="display: flex; align-items: center; margin: 8px 28px 8px 6px; border: solid 2px; border-radius: 12px; background-color: white !important; cursor: pointer"-->
                <!--                         class="editorColorPrimaryBorderColor"-->
                <!--                         :style="sizeState === 0 ? 'font-size: x-large' : sizeState === 1 ? 'font-size: xx-large' : 'font-size: xxx-large; height: 49px'">-->
                <!--                        <span style="padding: 4px; cursor: pointer">+ Falsche Lösung</span>-->
                <!--                    </div>-->
            </div>
        </div>
        <!-- Lehrer Controls rechts rausgeschoben -->
        <div
            v-if="showTeacherUI"
            class="editorOptionsFade"
            style="display: flex; flex-direction: column; position: absolute; right: -56px; top: 60px; width: 48px; height: 48px; min-width: 48px"
        >
            <div>
                <v-btn
                    class="editorColorPrimaryBackgroundOnHover editorComponentOptionsBtn"
                    @click="changeSize()"
                >
                    <img
                        :src="currentSizeIcon"
                        style="height: 32px"
                        class="iconToWhiteHover"
                        alt="Größe des Elements verändern"
                    >
                </v-btn>
                <v-btn
                    class="editorColorPrimaryBackgroundOnHover editorComponentOptionsBtn"
                    @click="toggleDragnDrop"
                >
                    <img
                        :src="bearbeitenIcon"
                        class="editorColorPrimary iconToWhiteHover"
                    >
                </v-btn>
            </div>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";

import sizeSmallIcon from "@/assets/Icons/EditorRedesign/schrift_klein.svg";
import sizeMediumIcon from "@/assets/Icons/EditorRedesign/schrift_mittel.svg";
import sizeLargeIcon from "@/assets/Icons/EditorRedesign/schrift_groß.svg";
import aktualisierenIcon from "../../assets/Icons/aktualisieren-green-107.svg";
import bearbeitenIcon from "../../assets/Icons/bearbeiten-komplimentär-green-88.svg"
import {v4 as uuidv4} from "uuid";

export default {
    name: "EditorDragnDropFree",
    props: {
        disableMovePupil: {type: Boolean, required: false, default: false },
        canvasWidth: {type: Number, required: true},
        canvasHeight: {type: Number, required: true},
        tabIndex: { type: Number, required: false, default: 0 },
        config: { type: Object, required: true },
        state: { type: String, required: true },
        exportMode: {type: Boolean, required: false, default: false },
        previewPupilUI: { type: Boolean, required: false, default: false },
    },
    data: () => ({
        fields: [],
        fieldOrder: [],
        sizeState: 0,
        sizeSmallIcon,
        sizeMediumIcon,
        sizeLargeIcon,
        aktualisierenIcon,
        bearbeitenIcon,
    }),
    computed: {
        ...mapGetters("auth",["accountRole"]),
        ...mapState("editorDragnDrop", ["dragndrop", "dragndropfields", "dragndropSizeState", "lastClickedFree"]),

        currentFontSize() {
            return this.sizeState ? (this.sizeState === 2 ? 'editorFontSize3' : 'editorFontSize2') : 'editorFontSize1';
        },
        currentSizeIcon() {
            return this.sizeState ? (this.sizeState === 2 ? this.sizeLargeIcon : this.sizeMediumIcon) : this.sizeSmallIcon;
        },
        showPupilUI() {
            return this.exportMode || this.state === 'pupil' || this.previewPupilUI;
        },
        showTeacherUI() {
            return !this.exportMode && !this.previewPupilUI && this.state === 'creator';
        },
    },

    watch: {
        dragndropfields: {
            handler(val, oldVal) {
                if(!(this.accountRole === 'pupil' && this.state === 'pupil')) {
                    this.fields = this.dragndropfields;
                    this.saveState();
                }
            }, deep: true
        },
        dragndropSizeState() {
            this.sizeState = this.dragndropSizeState;
        },
        sizeState() {
            this.saveState();
        },
        lastClickedFree() {

            for(let i=0; i < this.fields.length; i++) {
                let field = this.fields[i]
                field.hasBorder = false;
                this.$set(this.fields, i, field);
            }

            if(this.lastClickedFree) {
                let indexOfLastClicked = this.fields.indexOf(this.lastClickedFree);
                let field = this.fields[indexOfLastClicked]
                field.hasBorder = true;
                this.$set(this.fields, indexOfLastClicked, field);
            }
        }
    },

    mounted() {

        if (!this.config.dragndropFreeConfig) {
            this.config.dragndropFreeConfig = {};
            this.config.identifier = uuidv4();
            this.config.dragndropFreeConfig.fields = this.fields;
            this.config.dragndropFreeConfig.fieldOrder = this.fieldOrder;
            this.config.dragndropFreeConfig.sizeState = this.sizeState;
        } else {
            if(this.config._id) {
                this.config.identifier = this.config._id;
            }
            this.fields = this.config.dragndropFreeConfig.fields;
            this.fieldOrder = this.config.dragndropFreeConfig.fieldOrder;
            this.sizeState = this.config.dragndropFreeConfig.sizeState;
        }
    },
    methods: {
        ...mapActions('editorDragnDrop', ['setdragndrop']),
        ...mapMutations('editorDragnDrop', ["mutateLastDragged", "mutateSizeState", "mutateLastClickedFree"]),

        toggleDragnDrop() {
            this.setdragndrop(!this.dragndrop);
        },

        changeSize() {
            switch (this.dragndropSizeState) {
                case 0:
                    this.mutateSizeState(1);
                    break;
                case 1:
                    this.mutateSizeState(2);
                    break;
                case 2:
                    this.mutateSizeState(0);
                    break;
                default:
                    break;
            }
        },
        setClickData(field) {
            if(this.showPupilUI) {
                this.mutateLastClickedFree(field);
            }
        },

        setDragData(event, field) {
            this.mutateLastDragged(field);
            event.dataTransfer.setData("fieldText", field.text);
        },

        randomizeFields() {
            this.fields = this.shuffleArray([...this.fields]);
            this.saveState();
        },

        shuffleArray(a) {
            for (let i = a.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                [a[i], a[j]] = [a[j], a[i]];
            }
            return a;
        },

        saveState() {

            // save values by overwriting the calcConfig properties
            // which get saved in the db schoolSubjectAppointmentUploadMessage
            this.config.dragndropFreeConfig.fields = this.fields;
            this.config.dragndropFreeConfig.fieldOrder = this.fieldOrder;
            this.config.dragndropFreeConfig.sizeState = this.sizeState;
        },
    }
}
</script>

<style scoped lang="scss">
    .colorWhite {
      color: white;
    }
    .moveCursor {
        cursor: move
    }
</style>
