
const setCookie = (cname, cvalue, exdays) => {
  var d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  var expires = "expires="+d.toUTCString();
  document.cookie = `${cname}=${cvalue};${expires};path=/;SameSite=strict`;
}

const getCookie = (cname) => {
  var name = cname + "=";
  var ca = document.cookie.split(';');
  for(var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      let temp = c.substring(name.length, c.length);
      return temp;
    }
  }
  return "";
}

const checkCookieAccepted = () => {
  var hasAcceptedCookies;
  if(getCookie("eklara_cookielaw_accepted") === "true"){
    hasAcceptedCookies = true;
  }else{
    hasAcceptedCookies = false;
  }
  return hasAcceptedCookies;
}

export { setCookie, getCookie, checkCookieAccepted };
