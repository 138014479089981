<template>
    <v-dialog
        v-model="showDialog"
        max-width="700px"
    >
        <v-card>
            <v-card-title class="d-flex justify-space-between">
                <p>Dokument als PDF exportieren</p>
                <v-btn @click="$emit('close')">
                    X
                </v-btn>
            </v-card-title>
            <v-card-text class="d-inline-flex flex-column">
                <div class="d-flex flex-row">
                    <div class="halfTheExportPopup">
                        <v-text-field
                            v-model="options.theme"
                            class="mb-5"
                            dense
                            outlined
                            hide-details
                            placeholder="Themengebiet"
                            clearable
                        />
                        <v-checkbox
                            v-model="options.nameClassDate"
                            hide-details
                            label="Leiste für Name, Klasse und Datum"
                        />
                        <v-checkbox
                            v-model="options.subjectMarkers"
                            hide-details
                            label="Fachmarkierungen platzieren"
                        />
                        <v-checkbox
                            v-model="options.pageNumbers"
                            hide-details
                            label="Seitenzahlen anzeigen"
                        />
                        <v-checkbox
                            v-model="options.canvasNumbers"
                            label="Foliennummern anzeigen"
                        />
                        <v-btn
                            color="success"
                            class="text-capitalize mt-5"
                            @click="exportDocument"
                        >
                            Export
                        </v-btn>
                    </div>
                    <div class="otherHalfOfTheExportPopup">
                        <img
                            :src="bigPictureIcon"
                            width="100px"
                            height="100px"
                            class="mx-auto"
                        >
                        <p class="mx-auto">
                            Vorschau in Entwicklung
                        </p>
                    </div>
                </div>
                <div id="disclaimer">
                    <p>
                        <span style="font-weight: bold">Hinweis: </span>Die Elemente 'Video', 'Audio' und die neue Textaufgabe werden
                        aktuell nicht exportiert.
                        Exportierte Elemente können in kleineren Punkten vom Aussehen in der Anwendungen abweichen.
                        Der Export weiterer Elemente ist in Entwicklung.
                    </p>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import {PDFDocument, PageSizes, StandardFonts, rgb} from 'pdf-lib';
import {mapGetters} from "vuex";
import bioIcon from "@/assets/Icons/f-biologie-35.svg";
import chemieIcon from "@/assets/Icons/f-chemie-37.svg";
import deutschIcon from "@/assets/Icons/f-deutsch-29.svg";
import englischIcon from "@/assets/Icons/f-englisch-30.svg";
import erdkundeIcon from "@/assets/Icons/f-erdkunde-31.svg";
import geschichteIcon from "@/assets/Icons/f-geschichte-40.svg";
import matheIcon from "@/assets/Icons/f-mathe-32.svg";
import musikIcon from "@/assets/Icons/f-musik-84.svg";
import physikIcon from "@/assets/Icons/f-physik-36.svg";
import politikIcon from "@/assets/Icons/f-politik-34.svg";
import religionIcon from "@/assets/Icons/f-religion-39.svg";
import sportIcon from "@/assets/Icons/f-sport-38.svg";
import wirtschaftIcon from "@/assets/Icons/f-wirtschaft-33.svg";
import currentLogo from '@/assets/login-logo.png'
import bigPictureIcon from '@/assets/Icons/EditorRedesign/bild.svg'

export default {
    name: 'EditorExport',
    props: {
        showDialog: { type: Boolean, required: true },
        docData: { required: true },
        elements: { type: Array, required: true },
        elementScreenshots: { type: Array, required: true },
        pagesMetadata: { type: Array, required: true },
        appointmentId: { type: String, required: true },
        filename: { type: String, required: false, default: '' },
    },
    data: () => ({
        options: {
            pageNumbers: false,
            canvasNumbers: true,
            theme: '',
            nameClassDate: true,
            subjectMarkers: false,
        },
        //Subject Icons
        bioIcon,
        chemieIcon,
        deutschIcon,
        englischIcon,
        erdkundeIcon,
        geschichteIcon,
        matheIcon,
        musikIcon,
        physikIcon,
        politikIcon,
        religionIcon,
        sportIcon,
        wirtschaftIcon,
        bigPictureIcon,

        // normale icons
        currentLogo,
    }),
    computed: {
        ...mapGetters('appointments', ['appointment']),
        currentAppointment() {
            return this.appointment(this.appointmentId);
        },
        subjectName() {
            return this.currentAppointment.schoolSubject.name;
        },
        subjectColorRGB() {
            const rgbValues = this.hexToRgb(this.currentAppointment.schoolSubject.color);
            return rgb(rgbValues.r, rgbValues.g, rgbValues.b);
        },
        subjectIcon() {
            
            return this[`${this.currentAppointment.schoolSubject.icon}`]
        }
    },
    methods: {
        async exportDocument() {
            let sideMargin = 0;
            let topMargin = 0;
            if (this.options.theme && this.options.theme.length
                || this.options.subjectMarkers
                || this.options.nameClassDate
                || this.options.pageNumbers
                || this.options.canvasNumbers) {
                sideMargin = 40;
                topMargin = 20;

            }
            if (this.options.theme && this.options.theme.length || this.options.subjectMarkers) {
                topMargin += 24;
            }
            if (this.options.nameClassDate) {
                topMargin += 29;
            }
            //TODO hanlde docdata == null
            /** Adding Elements to base doc to make positioning easier */
            const basicData = await PDFDocument.load(this.docData);
            const elementsAdded = await this.addElementsToPDF(basicData);
            const elScreenshotsAdded = await this.addElementScreenshotsToPDF(await PDFDocument.load(elementsAdded));
            const currentDoc = await PDFDocument.load(elScreenshotsAdded);

            const computedMetaData = this.computePageMetadata(this.pagesMetadata);

            const resultingDoc = await PDFDocument.create({ updateMetadata: true });
            const eKlaraFont = await resultingDoc.embedFont(StandardFonts.Helvetica)
            let remainingPageHeight = 0;

            for (let i = 0; i < currentDoc.getPageCount(); i++) {

                const oldWidth = currentDoc.getPage(i).getWidth();
                const scale = (PageSizes.A4[0] - 2 * sideMargin) / oldWidth;

                const [ pageToEmbedd ] = await resultingDoc.embedPdf(currentDoc, [i]);
                const normalYCoord = PageSizes.A4[1] - topMargin - pageToEmbedd.height * scale - 15;

                let currentPage = null;
                let yCoord = null;

                //Remaining Height is enough for next page
                if (remainingPageHeight
                    && remainingPageHeight - sideMargin * 0.5 - pageToEmbedd.height * scale >= 0) {
                    currentPage = resultingDoc.getPage(resultingDoc.getPageCount()-1);
                    yCoord = remainingPageHeight - sideMargin * 0.5 - pageToEmbedd.height * scale;
                } else {
                    currentPage = resultingDoc.addPage(PageSizes.A4);
                    yCoord = normalYCoord;
                }
                currentPage.setFont(eKlaraFont);

                currentPage.drawPage(pageToEmbedd, {
                    x: sideMargin,
                    y: yCoord,
                    yScale: scale,
                    xScale: scale,
                });
                remainingPageHeight = yCoord;

                /** OPTIONS */
                // Add page Number
                if (this.options.pageNumbers) {
                    currentPage.drawText(`${i+1}/${currentDoc.getPageCount()}`, {
                        x: currentPage.getWidth() - 50,
                        y: 10,
                        color: rgb(0,0,0),
                        size: 24
                    })
                }

                // Add name, class and date
                if (this.options.nameClassDate) {
                    this.addNameClassDateToPage(currentPage, topMargin, sideMargin)
                }

                // Themengebiet
                if (this.options.theme && this.options.theme.length) {
                    currentPage.drawText(`${this.options.theme.toString()}`, {
                        x: sideMargin,
                        y: currentPage.getHeight() - 1.5 * 24,
                        size: 22,
                        color: rgb(0, 0, 0),
                    })
                }

                if (this.options.subjectMarkers) {
                    this.addSubjectMarkersToPage(currentPage, {
                        x: sideMargin,
                        y: yCoord + pageToEmbedd.height * scale
                    });
                }

                //Watermark
                currentPage.drawText('Freie digitale/analoge Nutzung innerhalb von eklara', {
                    x: currentPage.getWidth() * 0.25,
                    y: 15,
                    color: rgb(0.1,0.1,0.1),
                    size: 12
                });
                const logoBytes = await fetch(this.currentLogo).then(res => res.arrayBuffer());
                const logoToEmbed = await resultingDoc.embedPng(logoBytes);
                currentPage.drawImage(logoToEmbed, {
                    x: currentPage.getWidth() * 0.10,
                    y: 0,
                    width: 75,
                    height: 40,
                    opacity: 0.95
                });

            }

            const resultingUintArray = await resultingDoc.save();
            await this.downloadFile(resultingUintArray);
        },
        /** Util Functions */
        hexToRgb(hex) {
            var result = /^#?([A-F\d]{2})([A-F\d]{2})([A-F\d]{2})$/i.exec(hex);
            return result ? {
                r: parseFloat(parseInt(result[1], 16).toString())/255,
                g: parseFloat(parseInt(result[2], 16).toString())/255,
                b: parseFloat(parseInt(result[3], 16).toString())/255
            } : null;
        },
        unHTML(text) {
            let tmp = document.createElement('div');
            tmp.innerHTML = text;
            return tmp.textContent || tmp.innerText;
        },
        getTextWidth(text, fontSize) {
            let result = 50;

            const textDiv = document.createElement('p');
            textDiv.style.fontSize = `${fontSize === 2 ? 'xx-large' : fontSize === 1 ? 'x-large' : 'large'}`;
            textDiv.style.opacity = '0';
            textDiv.style.position = 'absolute';
            textDiv.textContent = text;
            document.body.appendChild(textDiv);

            result = textDiv.clientWidth;
            document.body.removeChild(textDiv);

            return result;
        },
        computePageMetadata(metaData) {
            

            return metaData;
        },

        /** Elements (biggus dickus move)
         * Es werden nicht alle Elemente hier drauf platziert werden können (vorerst!!),
         * aber welche die auf jeden Fall gehen werden sind:
         * - Bilder,
         * - Formen (Rechteck, Linie)
         * - Interaktive (Daraus aber nur der Texte, keine Logik!)
         * */
        async addElementsToPDF(pdfDoc) {
            for (let i = 0; i < pdfDoc.getPageCount(); i++) {
                const currentPage = pdfDoc.getPage(i);
                const elementsOnPage = this.elements.filter(item => item.page === i);
                for (let j = 0; j < elementsOnPage.length; j++) {
                    const currentElement = elementsOnPage[j];

                    /** Differentiate between elements (because they are special snowflakes) */
                    if (currentElement.messageType === 'imageObject') {
                        const imgBytes = await fetch(currentElement.fileUrl).then(res => res.arrayBuffer());
                        let embeddedImg = null;
                        if (currentElement.fileBlob.type === 'image/jpg'
                            || currentElement.fileBlob.type === 'image/jpeg') {
                            embeddedImg = await pdfDoc.embedJpg(imgBytes);
                        } else {
                            embeddedImg = await pdfDoc.embedPng(imgBytes);
                        }

                        currentPage.drawImage(embeddedImg, {
                            x: (currentElement.x /100) * currentPage.getWidth(),
                            y: currentPage.getHeight()
                                - (currentElement.y /100) * currentPage.getHeight()
                                - currentElement.height * currentPage.getHeight(),
                            width: currentElement.width * currentPage.getWidth(),
                            height: currentElement.height * currentPage.getHeight(),
                        });
                    }
                    if (currentElement.messageType === 'rectangle') {
                        const rgbValues = this.hexToRgb(currentElement.text);

                        currentPage.drawRectangle({
                            x: (currentElement.x /100) * currentPage.getWidth(),
                            y: currentPage.getHeight()
                                - (currentElement.y /100) * currentPage.getHeight()
                                - currentElement.height * currentPage.getHeight(),
                            width: currentElement.width * currentPage.getWidth(),
                            height: currentElement.height * currentPage.getHeight(),
                            color: rgb(rgbValues.r, rgbValues.g, rgbValues.b)
                        })
                    }
                    if (currentElement.messageType === 'moveableText') {

                        currentPage.drawRectangle({
                            x: (currentElement.x /100) * currentPage.getWidth(),
                            y: currentPage.getHeight()
                                - (currentElement.y /100) * currentPage.getHeight()
                                - (12 + 6 * currentElement.fontSize),
                            width: this.getTextWidth(currentElement.text, currentElement.fontSize),
                            height: (12 + 6 * currentElement.fontSize) * 1.33,
                            borderColor: rgb(0,0,1),
                            opacity: 0
                        })
                        currentPage.drawText(currentElement.text, {
                            x: 5+(currentElement.x /100) * currentPage.getWidth(),
                            y: currentPage.getHeight()
                                - (currentElement.y /100) * currentPage.getHeight()
                                - (12 + 6 * currentElement.fontSize) * 0.75,
                            color: rgb(0,0,0),
                            size: 12 + 6 * currentElement.fontSize
                        })
                    }
                    /*if (currentElement.messageType === 'multipleChoice') {
                        
                        currentPage.drawText(currentElement.startTextTask, {
                            x: (currentElement.x /100) * currentPage.getWidth(),
                            y: currentPage.getHeight()
                                - (currentElement.y /100) * currentPage.getHeight()
                                - 18,
                            color: rgb(0,0,0),
                            size: 16 + 6 * currentElement.fontSize
                        });

                        for (let k = 0; k < currentElement.multipleChoices.length; k++) {
                            currentPage.drawSquare({
                                x: (currentElement.x /100) * currentPage.getWidth(),
                                y: currentPage.getHeight()
                                    - (currentElement.y /100) * currentPage.getHeight()
                                    - (k+2) * (18 + 6 * currentElement.fontSize),
                                size: 10,
                                borderColor: rgb(0,0,0),
                                opacity: 0
                            })

                            currentPage.drawText(currentElement.multipleChoices[k].text, {
                                x: 20 + (currentElement.x /100) * currentPage.getWidth(),
                                y: currentPage.getHeight()
                                    - (currentElement.y /100) * currentPage.getHeight()
                                    - (k+2) * (18 + 6 * currentElement.fontSize),
                                color: rgb(0,0,0),
                                size: 16 + 6 * currentElement.fontSize
                            });
                        }
                    }*/
                    if (currentElement.messageType === 'texttask' && currentElement.startTextTask) {
                        currentPage.drawText(this.unHTML(currentElement.startTextTask), {
                            x: 7 + (currentElement.x /100) * currentPage.getWidth(),
                            y: currentPage.getHeight()
                                - (currentElement.y /100) * currentPage.getHeight()
                                - 24,
                            color: rgb(0,0,0),
                            size: 16,
                            maxWidth: currentElement.width * currentPage.getWidth()
                        });
                    }
                }

                if (this.options.canvasNumbers) {
                    currentPage.drawText(`${i+1}/${pdfDoc.getPageCount()}`, {
                        x: currentPage.getWidth() - 50,
                        y: 10,
                        color: rgb(0,0,0),
                        size: 24
                    })
                }

            }
            return await pdfDoc.save();
        },

        /** Screenshots of more complex Elements added to page of pdf */
        async addElementScreenshotsToPDF(pdfDoc) {
            for (let i = 0; i < pdfDoc.getPageCount(); i++) {
                const pdfPage = pdfDoc.getPage(i);
                const elementsOnPage = this.elementScreenshots.filter(item => item.page === i);
                for (let j = 0; j < elementsOnPage.length; j++) {
                    const currentElement = elementsOnPage[j];

                    const embeddedImg = await pdfDoc.embedPng(currentElement.pngString);

                    pdfPage.drawImage(embeddedImg, {
                        x: (currentElement.x /100) * pdfPage.getWidth(),
                        y: pdfPage.getHeight()
                            - (currentElement.y /100) * pdfPage.getHeight()
                            - currentElement.height * pdfPage.getHeight(),
                        width: currentElement.width * pdfPage.getWidth(),
                        height: currentElement.height * pdfPage.getHeight(),
                    });

                }
            }

            return await pdfDoc.save();
        },

        /** Options Tab (moved from method for readability)*/
        addNameClassDateToPage(currentPage, topMargin, sideMargin) {
            const fontSize = 18
            currentPage.drawLine({
                start: {x: sideMargin, y: currentPage.getHeight() - (topMargin + 0.5 * fontSize)},
                end: { x: currentPage.getWidth() - sideMargin, y: currentPage.getHeight() - (topMargin + 0.5 * fontSize) },
                thickness: 2,
                color: rgb(0, 0, 0)
            });

            currentPage.drawText('Name', {
                x: sideMargin,
                y: currentPage.getHeight() - topMargin,
                size: fontSize,
                color: rgb(0, 0, 0),
            });

            currentPage.drawText('Klasse', {
                x: currentPage.getWidth() * 0.45,
                y: currentPage.getHeight() - topMargin,
                size: fontSize,
                color: rgb(0, 0, 0),
            });

            currentPage.drawText('Datum', {
                x: currentPage.getWidth() * 0.7,
                y: currentPage.getHeight() - topMargin,
                size: fontSize,
                color: rgb(0, 0, 0),
            });
        },

        addSubjectMarkersToPage(currentPage, lastDrawingPos) {
            currentPage.drawRectangle({
                x: lastDrawingPos.x - 10,
                width: 10,
                y: lastDrawingPos.y - 50,
                height: 50,
                color: this.subjectColorRGB,
            })

            currentPage.drawLine({
                start: {x: currentPage.getWidth() * 0.8, y: currentPage.getHeight() - 1.75 * 24},
                end: { x: currentPage.getWidth() - 40, y: currentPage.getHeight() - 1.75 * 24 },
                thickness: 1,
                color: this.subjectColorRGB,
            })

            currentPage.drawText(`${this.subjectName}`, {
                x: currentPage.getWidth() * 0.8,
                y: currentPage.getHeight() - 1.5 * 24,
                size: 18,
                color: rgb(0.25, 0.25, 0.25),
            })
        },

        /** final download */
        async downloadFile(uintArray) {
            let blob = await new Blob([uintArray]);
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            a.download = `Export_${this.filename}.pdf`;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();
            setTimeout(function(){
                a.remove();
                window.URL.revokeObjectURL(url);
            }, 1000);
        }
    }
}
</script>

<style scoped lang="scss">
.halfTheExportPopup {
    width: 50%;
    display: flex;
    align-content: flex-start;
    flex-flow: column;
    padding-inline: 5px;
}

.otherHalfOfTheExportPopup {
    width: 50%;
    display: flex;
    align-content: center;
    justify-content: center;
    flex-flow: column;
    background-color: lightgray;
}

#disclaimer {
    padding: 5px 5px 0 5px;
    width: 100%;
}
</style>
