<template>
    <div
        id="textTaskOuter"
        ref="textTaskOuter"
        :class="{ placingTextTaskOuter: state === 'start' }"
    >
        <v-container
            id="textTaskInner"
            ref="textTaskInner"
            :key="isCorrected"
            fluid
            class="pa-0"
        >
            <!-- Teacher text area -->
            <v-row
                dense
                :style="`height: ${task.text && task.text.toString() === 'true' ? '50%' : '100%'}; min-height: 3em`"
            >
                <v-col style="min-width: 250px; height: 100%">
                    <div
                        v-if="state === 'start' && !task.startTextTask"
                        style="display: inline-flex; width: 100%; height: 100%"
                    >
                        <advanced-quill-editor
                            v-model="teacherTask"
                            placeholder="Hier Aufgabe eingeben"
                            style="background-color: #ffffff00; width: 100%; height: 100%"
                            :hide-image-btn="true"
                            :class="{ 'soft-box-shadow': accountRole === 'teacher' }"
                        />

                        <div
                            class="textTaskSaveButtons"
                            style="align-content: flex-end; margin-top: -4px; margin-right: -4px"
                        >
                            <v-btn
                                id="teacherConfirmExerciseButton"
                                color="success"
                                class="ma-1 pa-0"
                                x-small
                                @click="teacherConfirmExercise"
                            >
                                <img
                                    :src="fertigIcon"
                                    alt="Fertigsymbol"
                                    class="icon iconToWhite"
                                >
                            </v-btn>
                        </div>
                    </div>
                    <div
                        v-else
                        class="pa-2"
                        style="display: inline-flex; width: 100%; height: 100%; align-content: flex-end"
                    >
                        <!--               :style="` margin-top: ${task.text && task.text === 'true' ? '10%' : '85px'}`"-->
                        <div
                            class="answerField"
                            style="background-color: #FFFFFF00"
                        >
                            <h1
                                :tabindex="tabIndex"
                                class="mb-0 pa-2 ql-editor"
                                style="text-align: end !important;"
                                v-html="urlify(teacherTask)"
                            />
                        </div>
                        <v-btn
                            v-if="accountRole !== 'teacher'"
                            :aria-hidden="person && person.accessibility && person.accessibility.screenreader"
                            :tabindex="person && person.accessibility && person.accessibility.screenreader ? '-1' : tabIndex"
                            small
                            width="40px"
                            height="40px"
                            class="ml-2"
                            @click="readText(teacherTask, 'lehrerEingabeVorlesenBtnIcon')"
                        >
                            <img
                                :ref="`lehrerEingabeVorlesenBtnIcon`"
                                :src="lautsprecherIcon"
                                alt="Aufgabe vorlesen"
                                width="35px"
                                height="35px"
                                style="filter: brightness(0%)"
                            >
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
            <v-row v-if="task.text && task.text.toString() === 'true'">
                <!-- previous answers -->
                <v-col
                    v-if="pupilAnswers.length"
                    cols="5"
                >
                    <div
                        v-for="(answer, index) in pupilAnswers"
                        :key="index"
                    >
                        <div
                            class="answerField pa-2 ma-2"
                            :class="{
                                correctAnswer: isAnswerCorrect(answer),
                                teacherAnswer: teachers.includes(answer.author),
                            }"
                        >
                            <p class="my-auto">
                                {{ answer.element }}
                            </p>
                            <v-btn
                                v-if="isAnswerMine(answer)"
                                :tabindex="tabIndex"
                                class="ml-2"
                                small
                                width="40px"
                                height="40px"
                                @click="deleteAnswer(answer)"
                            >
                                <img
                                    :src="papierkorbIcon"
                                    alt="Antwort löschen"
                                    width="35px"
                                    height="35px"
                                >
                            </v-btn>
                        </div>
                        <v-btn
                            :tabindex="tabIndex"
                            class="ml-2"
                            small
                            width="40px"
                            height="40px"
                            @click="readText(answer.element, `vorlesenBtnIcon${index}`)"
                        >
                            <img
                                :ref="`vorlesenBtnIcon${index}`"
                                :src="lautsprecherIcon"
                                alt="Deine Antwort vorlesen"
                                width="35px"
                                height="35px"
                            >
                        </v-btn>
                    </div>
                </v-col>

                <!-- Pupil text entry field -->
                <v-col class="mt-0">
                    <v-row
                        dense
                        class="mt-0"
                    >
                        <v-col
                            cols="9"
                            class="pt-0 pl-2"
                        >
                            <v-text-field
                                v-if="state === 'start' || (state === 'answer' && !task.done)"
                                v-model="currentPupilAnswer"
                                :tabindex="tabIndex"
                                no-resize
                                outlined
                                dense
                                background-color="#FFFFFF80"
                                label="Antworteingabe"
                                :aria-label="unHTML(teacherTask)"
                            />
                        </v-col>

                        <!-- Control area -->
                        <v-col cols="3">
                            <div v-if="state === 'answer' && accountRole === 'pupil'">
                                <v-btn
                                    :tabindex="tabIndex"
                                    x-small
                                    color="success"
                                    class="ma-1 acceptEntryButton"
                                    @click="userExerciseAnswer"
                                >
                                    <img
                                        :ref="`fertigBtn`"
                                        :src="fertigIcon"
                                        alt="Antwort fertig"
                                        class="icon iconToWhite"
                                    >
                                </v-btn>
                            </div>
                            <div
                                v-else-if="
                                    state === 'answer' &&
                                        accountRole === 'teacher' &&
                                        !isCorrected
                                "
                            >
                                <v-btn
                                    class="ma-1"
                                    @click="userExerciseAnswer"
                                >
                                    Abgeben
                                </v-btn>
                                <v-btn
                                    color="success"
                                    class="ma-1"
                                    @click="teacherCorrectedUserAnswer"
                                >
                                    Richtig
                                </v-btn>
                            </div>
                            <div
                                v-else-if="
                                    state === 'answer' &&
                                        accountRole === 'teacher' &&
                                        isCorrected
                                "
                            >
                                <v-btn
                                    class="ma-1"
                                    @click="revertCorrection"
                                >
                                    Rückgängig
                                </v-btn>
                            </div>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import {mapActions, mapState, mapGetters} from "vuex";
import lautsprecherIcon from "../../assets/Icons/lautsprecher-13.svg";
import lautsprecherAusIcon from "../../assets/Icons/lautsprecher-aus-89.svg";
import papierkorbIcon from "../../assets/Icons/papierkorb-109.svg";
import fertigIcon from "../../assets/Icons/fertig-74.svg";
import editIcon from '../../assets/Icons/bearbeiten-komplimentär-grau-88.svg'
import moveIcon from '../../assets/Icons/Temp/move.svg';
import { parseHtml } from "../../util/tempFilePreviewUtil";
import AdvancedQuillEditor from "@/components/Utils/AdvancedQuillEditor";

export default {
  name: "EditorTextTask",
    components: { AdvancedQuillEditor },
    props: {
      task: { type: Object, required: true },
      state: { type: String, required: true },
      teachers: { type: Array, required: true },
      tabIndex: { type: Number, required: false, default: 0 },
      canvasWidth: { type: Number, required: true },
      canvasHeight: { type: Number, required: true },

      targetLang: {type: String, required: false, default: 'de'},
      translate: {type: Boolean, required: false, default: false},
  },
  data() {
    return {
        //Textarea models
        teacherTask: null,
        pupilAnswers: [],
        currentPupilAnswer: null,

        // UI
        papierkorbIcon,
        lautsprecherIcon,
        lautsprecherAusIcon,
        fertigIcon,
        vorlesenBtnIcons: [],

        // Translate
        alreadyTranslated: false, //whether the texts were already translated once
        //store original texts
        untranslatedTask: null,
        //store translated texts (so translating is only done once)
        translatedTask: null,

        // Everything else
        isCorrected: null,
        movable: true,
        person: {
            accessibility: {
                screenreader: false,
            }
        }
    };
  },
  computed: {
    ...mapGetters("auth",["accountRole", "accountId"]),
    isAnswerMine() {
      return (answer) => answer.author === this.accountId;
    },
  },
  watch: {
    task(newVal, oldVal) {
      if (newVal && !oldVal) {
        this.fillTaskData(newVal);
      }
    },
    isCorrected(newVal) {
      
      // this.dragElement(this.$refs.moveSymbol, this.$refs.textTaskOuter);
    },
      translate() {
          this.toggleTextLanguage();
      },
  },
    mounted() {
      
        this.fillTaskData(this.task);
        // if (this.state === 'start') {
        //     this.dragElement(this.$refs.moveSymbol, this.$refs.textTaskOuter);
        // }
        this.storeOriginalTexts();
        this.requestPerson();
    },
  methods: {
      ...mapActions('translation', [ 'translateToTargetLang' ]),
      ...mapActions('pupils', [ 'getMePupil' ]),

      async requestPerson() {
        if(this.accountRole === 'pupil') {
          this.person = await this.getMePupil();
          return this.person;
        }
      },

    // task data handling
    fillTaskData(currentTask) {
      if (currentTask.startTextTask) {
        this.teacherTask = currentTask.startTextTask;
      }

      let vorleseBtnsArrayLength = 1;
      if (currentTask.answersTextTask) {
        this.pupilAnswers = currentTask.answersTextTask;
        vorleseBtnsArrayLength += currentTask.answersTextTask.length;
      }

      for (let i = 0; i < vorleseBtnsArrayLength; i++) {
        this.vorlesenBtnIcons.push(this.lautsprecherIcon);
      }
    },

    // Start Methods
    teacherConfirmExercise() {
      this.teacherTask = this.teacherTask.replaceAll("</p>", " </p>");
      this.teacherTask = this.teacherTask.replaceAll("</span>", " </span>");
      this.teacherTask = this.teacherTask.replaceAll("</li>", " </li>");
      this.teacherTask = this.teacherTask.replaceAll("</em>", " </em>");
      this.teacherTask = this.teacherTask.replaceAll("</strong>", " </strong>");

      this.task.startTextTask = this.teacherTask;
      this.isCorrected = true;
      this.movable = false;
      this.$emit('history', { startTextTask: null });
    },
    // vue/no-unused-properties cannot detect methods by $ref, so make a exception
    // eslint-disable-next-line vue/no-unused-properties
    teacherEditExercise() {
      this.teacherTask = this.task.startTextTask.toString();
      this.isCorrected = false;
      this.movable = true;
      this.task.startTextTask = null;
    },

    // Answer Methods
    userExerciseAnswer() {
      this.task.answersTextTask.push({
        element: this.currentPupilAnswer,
        author: this.accountId,
      });
      this.currentPupilAnswer = "";
    },

    teacherCorrectedUserAnswer() {
      this.task.done = true;
      this.isCorrected = true;
    },
    revertCorrection() {
      this.task.done = false;
      this.isCorrected = null;
    },

    // General Actions
    // Why can this not be called?
    // eslint-disable-next-line vue/no-unused-properties,
    deleteTask() {
      this.$emit("removeElement");
    },
    deleteAnswer(answer) {

      const answerIndex = this.task.answersTextTask.findIndex(
        (item) => item === answer
      );
      if (answerIndex > -1) {
        this.task.answersTextTask.splice(answerIndex, 1);
      }
    },

    // Read out Methods
    readText(text, answerRef) {
      if ("speechSynthesis" in window) {
        // Speech Synthesis supported 🎉
      } else {
        // Speech Synthesis Not Supported 😣
        alert("Sorry, your browser doesn't support text to speech!");
      }

      let imageRef = this.$refs[answerRef];
      if (Array.isArray(imageRef)) {
        imageRef = imageRef[0];
      }

      let tmp = document.createElement("div");
      tmp.innerHTML = text;
      let msgText = tmp.textContent || tmp.innerText;

      if (window.speechSynthesis.speaking) {
        window.speechSynthesis.cancel();
        imageRef.src = this.lautsprecherIcon;
      } else {
        let msg = new SpeechSynthesisUtterance();
        msg.text = msgText;
        if(this.translate) {
            msg.lang = this.targetLang;
        } else {
            msg.lang = 'de';
        }

        window.speechSynthesis.speak(msg);

        imageRef.src = this.lautsprecherAusIcon;

        msg.onend = () => {
          imageRef.src = this.lautsprecherIcon;
        };
      }
    },

    // Styling Methods
    isAnswerCorrect(answer) {
      if (
        this.task.done &&
        this.task.answersTextTask &&
        this.task.answersTextTask.length
      ) {
        const pupilAnswers = this.task.answersTextTask.filter(
          (item) => !this.teachers.includes(item.author)
        );
        
        if (answer === pupilAnswers[pupilAnswers.length - 1]) {
          return true;
        }
      }
      return false;
    },
    unHTML(text) {
      let tmp = document.createElement('div');
      tmp.innerHTML = text;
      return tmp.textContent || tmp.innerText;
    },
    urlify(text) {
      // eslint-disable-next-line no-useless-escape
      var urlRegex = /(([a-z]+:\/\/)?(([a-z0-9\-]+\.)+([a-z]{2}|aero|arpa|biz|com|coop|edu|gov|info|int|jobs|software|mil|museum|name|nato|net|org|pro|travel|local|internal|de))(:[0-9]{1,5})?(\/[a-z0-9_\-\.~]+)*(\/([a-z0-9_\-\.]*)(\?[a-z0-9+_\-\.%=&amp;]*)?)?(#[a-zA-Z0-9!$&'()*+.=-_~:@/?]*)?)(\s+|$)/gi;
      return text.toString().replace(urlRegex, function (url) {
        url = url.includes("http") ? url : "http://" + url;
        return (
          '<a href="' +
          url +
          '" target="_blank" rel="noopener noreferrer">' +
          url +
          "</a>"
        );
      });
      // or alternatively
      // return text.replace(urlRegex, '<a href="$1">$1</a>')
    },
      storeOriginalTexts() {
          this.untranslatedTask = this.task.startTextTask;
      },
      async toggleTextLanguage() {
          //TODO find a way to keep URL clickable after translating
          //translate and replace displayed texts
          if (this.translate && !this.alreadyTranslated) {
              this.translatedTask = await this.translateToTargetLang({
                  targetLang: this.targetLang,
                  textToTranslate: this.task.startTextTask,
                  textFormat: 'html'
              });
              this.teacherTask = this.translatedTask;
              this.alreadyTranslated = true;
          }
          //just replace displayed texts with stored translated texts
          else if (this.translate && this.alreadyTranslated) {
              this.teacherTask = this.translatedTask;
          }
          //set texts to original language
          else {
              this.teacherTask = this.untranslatedTask;
          }
      },
  },
};
</script>

<style scoped lang="scss">
.icon {
  width: 25px;
  height: 25px;
  filter: brightness(0);
}

#teacherConfirmExerciseButton {
    width: 48px !important;
    height: 48px !important;

    img {
        width: 20px !important;
        height: 20px !important;
    }
}

.acceptEntryButton {
    width: 36px !important;
    height: 36px !important;

    img {
        width: 20px !important;
        height: 20px !important;
    }
}

#moveSymbol:hover {
  cursor: move;
}

#moveSymbol {
  filter: opacity(0.75);
}

.iconToWhite {
  filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%)
    hue-rotate(36deg) brightness(104%) contrast(105%);
}

#textTaskOuter {
  width: 100%;
  height: 100%;
}

.placingTextTaskOuter {
  border-radius: 4px;
  background-color: rgba(100, 100, 100, 0);
}

.textTaskSaveButtons {
  float: right;
}

#textTaskInner {
  min-height: 160px;
  height: 100%;
  width: 100%;
}

.correctAnswer {
  border: 1px solid #8cbd46 !important;
}

.teacherAnswer {
  border: 1px solid #505faa !important;
}

.answerField {
  display: inline-flex;
  border-radius: 8px;
}

#exerciseField {
  display: inline-flex;
  border-radius: 8px;
  padding: 0.5em;

  p {
    margin-bottom: 0;
    p {
      margin-bottom: 0;
    }
  }
}

</style>

<style lang="scss">
// Overriding stuff
.ql-snow {
  border: 1px solid #555 !important;
}
</style>
