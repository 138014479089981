<template>
    <div>
        <div class="greyBox d-flex flex-column align-center">
            <div
                style="max-width: 500px"
                class="ma-10"
            >
                <!-- <h1
                     aria-label="Profil bearbeiten"
                     class="header"
                     tabindex="0"
                     style="width: fit-content"
                 >
                     Profil bearbeiten
                 </h1> -->
                <div v-if="account.role !== 'pupil'">
                    <p class="font-weight-bold mb-1">
                        Benachrichtigungen
                    </p>
                    <p class="mb-4">
                        Sie können ihre E-Mail Adresse angeben um Benachrichtigungen von eKlara per E-Mail zu erhalten.
                    </p>
                    <p>E-Mail</p>
                    <v-text-field
                        v-model="email"
                        outlined
                        dense
                        hide-details
                        class="mb-2"
                        style="max-width: 500px;"
                    />
                    <div
                        style="display: flex; flex-direction: column"
                        class="mb-6"
                    >
                        <div style="display: flex; flex-direction: row; justify-content: space-between">
                            <p>Schwarzes Brett</p>
                            <v-checkbox
                                v-model="notificationBlackboard"
                                hide-details
                                color="black"
                                style="margin-top: 0; padding-top: 0"
                            />
                        </div>
                        <div
                            v-if="account.role === 'parent'"
                            style="display: flex; flex-direction: row; justify-content: space-between"
                        >
                            <p>Elternbriefe</p>
                            <v-checkbox
                                v-model="notificationParentalLetter"
                                hide-details
                                color="black"
                                style="margin-top: 0; padding-top: 0"
                            />
                        </div>
                        <div
                            v-if="account.role === 'teacher'"
                            style="display: flex; flex-direction: row; justify-content: space-between"
                        >
                            <p>Entschuldigungen</p>
                            <v-checkbox
                                v-model="notificationSicknote"
                                hide-details
                                color="black"
                                style="margin-top: 0; padding-top: 0"
                            />
                        </div>
                    </div>
                </div>
                <p class="font-weight-bold mb-1">
                    Passwort ändern
                </p>

                <p class="mb-4">
                    Das Passwort muss mindestens 8 Zeichen lang sein und mindestens einen Buchstaben und eine Zahl enthalten.
                </p>
                <p>Altes Passwort</p>
                <v-text-field
                    v-model="oldPassword"
                    :type="showPassword ? 'text' : 'password'"
                    outlined
                    dense
                    hide-details
                    class="mb-2"
                    style="max-width: 500px;"
                />

                <!-- <p v-if="!userrole.securityQuestion">
                    Sicherheitsfrage auswählen
                </p>
                <p v-else>
                    Sicherheitsfrage
                </p> -->
                <!-- TODO: remember to use return-object if needed -->
                <!-- <v-select
                    v-model="securityQuestion"
                    aria-label="Sicherheitsfrage"
                    outlined
                    dense
                    hide-details
                    :items="securityQuestions"
                    :menu-props="{ bottom: true, offsetY: true }"
                    no-data-text="Sicherheitsfragen konnten nicht geladen werden"
                    class="mb-2"
                    style="max-width: 500px;"
                />

                <div v-if="securityQuestion">
                    <p v-if="userrole.securityQuestion">
                        Sicherheitsfrage beantworten
                    </p>
                    <p v-else>
                        Neue Sicherheitsfrage setzen
                    </p>
                    <v-text-field
                        v-model="securityQuestionAnswer"
                        outlined
                        dense
                        hide-details
                        class="mb-2"
                        style="max-width: 600px;"
                    />
                </div> -->

                <p>Neues Passwort</p>
                <v-text-field
                    v-model="newPassword"
                    :type="showPassword ? 'text' : 'password'"
                    outlined
                    dense
                    hide-details
                    class="mb-2"
                    style="max-width: 500px;"
                />

                <p>Neues Passwort wiederholen</p>
                <v-text-field
                    v-model="newPasswordRepeat"
                    :type="showPassword ? 'text' : 'password'"
                    outlined
                    dense
                    hide-details
                    class="mb-2"
                    style="max-width: 500px;"
                />

                <v-row class="d-flex justify-center pa-0 ma-0">
                    <v-checkbox
                        v-model="showPassword"
                        aria-label="Passwort anzeigen"
                        hide-details
                        class="ma-0 pa-0 pl-1"
                    />
                    <p aria-hidden="true">
                        Passwort anzeigen
                    </p>
                </v-row>

                <!-- Simple Editor Stuff -->
                <div
                    v-if="accountRole === 'teacher'"
                >
                    <p class="font-weight-bold mb-1">
                        Editor-Modus
                    </p>
                    <v-checkbox
                        v-model="simpleEditorValue"
                        inset
                        :label="`Einfacher Editor-Modus für Lehrer`"
                    />
                </div>

                <v-row
                    id="btnContainer"
                    class="d-flex justify-space-between pa-0 ma-0 mt-5"
                >
                    <v-btn
                        class="backButton text-none"
                        elevation="0"
                        @click="toggleProfileManagement"
                    >
                        &lt; Zurück zu eKlara
                    </v-btn>

                    <v-btn
                        id="custom-disabled"
                        :disabled="!checkPw && securityQuestionAnswer.length > 0"
                        color="gruen"
                        class="changePwBtn text-none"
                        elevation="0"
                        dark
                        @click="saveProfile"
                    >
                        Speichern
                    </v-btn>
                </v-row>
            </div>
        </div>

        <qrcode-vue
            v-show="false"
            ref="qrcodecontainer"
            :value="qrCodeData"
            size="200"
        />

        <v-dialog
            v-model="downloadPdfDialog"
            width="600px"
        >
            <v-card>
                <v-card-title>Passwort herunterladen</v-card-title>
                <v-card-text class="d-flex flex-column align-center">
                    <p
                        class="mb-4"
                    >
                        Das Passwort wurde erfolgreich geändert. Die neuen QR-Codes können über den folgenden Knopf heruntergeladen werden.
                    </p>
                    <v-btn
                        class="text-none mt-2"
                        elevation="0"
                        style="width: 150px"
                        @click="account.role !== 'parent' ? pdf() : parentPdf()"
                    >
                        QR Download
                    </v-btn>
                </v-card-text>
                <v-card-actions class="d-flex justify-end px-6 pb-4">
                    <v-btn
                        color="gruen"
                        class="text-none mt-2"
                        elevation="0"
                        dark
                        @click="handleFinish()"
                    >
                        Fertig
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import { compress } from "shrink-string";
import { PDFDocument } from "pdf-lib";
import fontkit from "@pdf-lib/fontkit";

import QrcodeVue from "qrcode.vue";
import roboto from "@/assets/Fonts/Roboto-Regular.ttf";
import robotoBold from "@/assets/Fonts/Roboto-Bold.ttf";

import pdfFile from "@/assets/PDF/Schueler-QR-Vorlage.pdf";
import pdfParentFile from "@/assets/PDF/Eltern-QR-Vorlage.pdf";
// TODO we need a pdfTeacherFile

import * as backend from '@/api/backend';

export default {
    name: "ProfileManagement",
    components: {
        QrcodeVue,
    },
    data() {
        return {
            pdfFile,
            pdfParentFile,
            roboto,
            robotoBold,

            securityQuestions: [],          // holds the available security questions
            securityQuestion: null,         // is a security question selected in the dialog
            securityQuestionAnswer: '',     // the answer to the selected security question

            email: '',
            notificationBlackboard: false,
            notificationParentalLetter: false,
            notificationSicknote: false,

            showPassword: false,
            oldPassword: '',
            newPassword: '',
            newPasswordRepeat: '',

            qrPassword: '',
            qrCodeData: "{ name: '', pw: '' }",
            name: '',
            lastName: '',

            downloadPdfDialog: false,

            account: {},
            userrole: {},

            // Simple Editor Stuff
            simpleEditorValue: false,
        }
    },
    computed: {
        ...mapState("profileManagement", ["profileManagement"]),
        ...mapGetters('auth', [ 'accountRole' ]),

        // checks if if new PWs are equal & password is at least of length 8 and contains one letter and one number
        checkPw() {
            const pwCheck = new RegExp("^(?=.*[a-zA-Z])(?=.*[0-9])(?=.{8,})");

            if (this.newPassword.trim() === this.newPasswordRepeat.trim()) {
                return pwCheck.test(this.newPassword);
            }
            else {
                return false;
            }
        },
    },
    async mounted() {
        this.securityQuestions = await this.getSecurityQuestions();

        this.account = await this.getCurrentAccount();
        switch (this.account.role) {
            case 'pupil':
                this.userrole = await this.getMePupil();
                break;
            case 'teacher':
                this.userrole = await this.getMeTeacher();
                break;
            case 'parent':
                this.userrole = await this.getMeParent();
                break;
            default:
                console.log('no userrole identified');
                break;
        }
        if(this.userrole) {
            if(this.userrole.email) {
                this.email = this.userrole.email;
            }
            if(this.userrole.notifications) {
                this.notificationBlackboard = this.userrole.notifications.notificationBlackboard;
                this.notificationParentalLetter = this.userrole.notifications.notificationParentalLetter;
                this.notificationSicknote = this.userrole.notifications.notificationSicknote;
            }
        }


        // if securityQuestion has been set by user, remove all other choices
        if (this.userrole.securityQuestion) {
            this.securityQuestion = this.userrole.securityQuestion;
            this.securityQuestions = this.userrole.securityQuestion;
        }

        if (this.userrole) {
            this.simpleEditorValue = this.userrole.simpleEditor || false;
        }
    },
    methods: {
        ...mapActions('profileManagement', ['toggleProfileManagement']),
        ...mapActions('pupils', ['getMePupil', 'editPupil']),
        ...mapActions('teachers', ['getMeTeacher', 'editTeacher', 'editTeacherProfile']),
        ...mapActions('parent', ['getMeParent', 'editParent']),
        ...mapActions("auth", ["getCurrentAccount", "logoutUser", "reloadDontLogout", 'getSecurityQuestions']),
        ...mapMutations('snackbar', ['showSnackbar']),

        async generateQRCodeData() {
            // create a QR PW, it's different from regular one for security reasons
            // it's saved in the QR code as base64 and if user uses same PW on multiple websites this helps a bit
            let qrPassword = ''
            let allCharacters =
                "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

            for (let i = 0; i < 16; i++) {
                qrPassword += allCharacters.charAt(
                    Math.floor(Math.random() * allCharacters.length)
                );
            }
            const compressedName = await compress(this.account.accountName);
            const compressedPW = await compress(qrPassword);
            const qrCodeData = JSON.stringify({
                name: compressedName,
                pw: compressedPW,
            });
            return qrCodeData;
        },
        async saveProfile() {
            const accountPatchBody = {
                ...(this.oldPassword !== '' ? { 'oldPassword' : this.oldPassword } : {}),
                ...(this.newPassword !== '' ? { 'password' : this.newPassword } : {}),
                ...(this.newPassword !== '' ? { 'qrPassword' : await this.generateQRCodeData() } : {}),
            }
            const accountPatchRes = await backend.patchAccount(this.account._id, accountPatchBody);

            const userRolePatchBody = {
                _id: this.userrole._id,
                email: this.email,                
                simpleEditor: this.simpleEditorValue, 
                notifications: {
                    notificationBlackboard: this.notificationBlackboard,
                    notificationParentalLetter: this.notificationParentalLetter,
                    notificationSicknote: this.notificationSicknote,
                }
            }
            let updatedUser;
            switch (this.account.role) {
                case 'pupil':
                    updatedUser = await this.editPupil(userRolePatchBody);
                    break;
                case 'teacher':
                    updatedUser = await this.editTeacher(userRolePatchBody);
                    break;
                case 'parent':
                    updatedUser = await this.editParent(userRolePatchBody);
                    break;
            }
            console.log(updatedUser); console.log(accountPatchRes);
            if (accountPatchRes.status === 200 && !Number.isInteger(updatedUser)) {
                this.showSnackbar({ message: "Änderungen erfolgreich geändert.", color: "success" });
            }
            else {
                this.showSnackbar({ message: "Etwas hat nicht funktioniert.", color: "error" });
            }
        },
        clearPasswordForm() {
            this.password = this.newPassword;
            this.oldPassword = '';
            this.newPassword = '';
            this.newPasswordRepeat = '';
            this.securityQuestion = null;
            this.securityQuestionAnswer = '';
            this.email = '';
        },

        handleFinish() {
            this.downloadPdfDialog = false;
            this.password = '';

            // logout user, because PW was changed
            this.logoutUser();
            this.$router.push({ name: 'login' });
        },

        //#region pdf methods


        saveByteArray(reportName, byte) {
            let blob = new Blob([byte], { type: "application/pdf" });
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = reportName;
            link.click();
        },
        //#endregion
    },
}
</script>

<style lang="scss" scoped>
::v-deep .v-label {
    color: rgba(0, 0, 0, 0.87) !important;
}

#custom-disabled.v-btn--disabled {
    background-color: var(--v-fgrau-base) !important;
    color: white;
}

.header {
    margin-bottom: 0.5em;
}

.greyBox {
    height: 100vh;
    background-color: #ffffff;
    border-radius: 8px;
    overflow: auto;
    padding-bottom: 80px;
}

.changePwBtn {
    border-radius: 10px;
}

.backButton {
    color: var(--v-dunkelgrau-base);
    border-radius: 10px !important;
}

@media only screen and (max-width: 500px) {
    #btnContainer {
        flex-direction: column;
    }

    .changePwBtn {
        order: 1;
    }

    .backButton {
        margin-top: 20px;
        order: 2;
    }
}
</style>
