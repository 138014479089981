import {
    createFileXMLHttpRequest,
    createFileXmlPatchRequest,
    deleteRequest,
    filePostRequest,
    getRequest,
    patchRequest,
    postRequest
} from "./request";

// a getRequest can NOT have a body, use a postRequest instead

const loginAccount = async (body) => postRequest('/account/login', body);
const patchAccount = async (accountId, body) => patchRequest(`/account/${accountId}`, body);
const confirmAccountPassword = async (body) => postRequest('/account/confirm', body);
const subscribe = async (body) => postRequest('/account/subscribe', body);
const unsubscribe = async () => deleteRequest('/account/unsubscribe');
const getCurrentAccount = async () => getRequest('/account/get');
const getSecurityQuestions = async () => getRequest('/account/securityQuestions');
const acceptTermsOfService = async () => postRequest('/account/accepttos');
const getAVVSigned = async() => getRequest('/account/getAVVSigned');
const isAVVSigned = async() => getRequest('/account/isAVVSigned');
const getAVVTemplate = async() => getRequest('/account/getAVVTemplate');

const deleteMessage = async (params) => deleteRequest(`/matrix/message/${params.roomId}/${params.eventId}/${params.txnId}`, params);
const getCurrentMatrixUser = async () => getRequest('/matrix/user/me');
const getMatrixName = async (accountId) => getRequest(`/matrix/user/name/${accountId}`);
const postVoiceMessage = (file) => createFileXMLHttpRequest(`/matrix/voice`, file); // Returning XMLHttpRequest object. You've to call ".send(data)".
const deleteVoiceMessage = async (fileId) => deleteRequest(`/matrix/voice/${fileId}`);
const getVoiceMessage = async (fileId) => getRequest(`/matrix/voice/${fileId}`);
const postPrivateRoom = async (body) => postRequest('/matrix/room/private', body);
const getMatrixRoomLockStatus = async () => getRequest(`/matrix/room/lock`);
const getMatrixRoomLockStatusById = async (roomId) => getRequest(`/matrix/room/lock/${roomId}`);
const setMatrixRoomLockStatus = async (roomId, body) => postRequest(`/matrix/room/lock/${roomId}`, body);

const postNameAvailable = async (body) => postRequest('/account/available', body);

//#region timeslots
const getTimeslots = async () => getRequest('/timeslot');
const postTimeslot = async (body) => postRequest('/timeslot', body);
const patchTimeslot = async (timeslotId, body) => patchRequest(`/timeslot/${timeslotId}`, body);
const deleteTimeslot = async (timeslotId) => deleteRequest(`/timeslot/${timeslotId}`);
//#endregion

//#region groups
const getGroups = async (appointmentId) => getRequest(`/group${appointmentId ? `?appointmentId=${appointmentId}` : ''}`);
const getGroupsInfo = async (appointmentId) => getRequest(`/group/lean${appointmentId ? `?appointmentId=${appointmentId}` : ''}`);
const getGroup = async (groupId) => getRequest(`/group/${groupId}`);
const getGroupByAppointment = async (appointmentId) => getRequest(`/groupByAppointment/${appointmentId}`);
const postGroup = async (body) => postRequest('/group', body);
const patchGroup = async (groupId, body) => patchRequest(`/group/${groupId}`, body);
const deleteGroup = async (groupId) => deleteRequest(`/group/${groupId}`);
//#endregion

//#region maintainers
const getMaintainers = async () => getRequest('/maintainer');
const getMaintainer = async (maintainerId) => getRequest(`/maintainer/${maintainerId}`);
const postMaintainer = async (body) => postRequest('/maintainer', body);
const patchMaintainer = async (maintainerId, body) => patchRequest(`/maintainer/${maintainerId}`, body);
const deleteMaintainer = async (maintainerId) => deleteRequest(`/maintainer/${maintainerId}`);
const confirmMaintainerPassword = async (body) => postRequest(`/maintainer/confirm`, body);
const postMaintainerAVV = (file, accountId) => createFileXMLHttpRequest(`/maintainer/avv/${accountId}`, file); // Returning XMLHttpRequest object. You've to call ".send(data)".
const postMaintainerAppendAVV = (contractId, body) => postRequest(`/maintainer/avv/${contractId}/append`, body);
//#endregion

//#region pupils
const getPupils = async () => getRequest('/pupil');
const getPupil = async (pupilId) => getRequest(`/pupil/${pupilId}`);
const getMePupil = async () => getRequest(`/pupil/me`);
const postPupil = async (body) => postRequest('/pupil', body);
const patchPupil = async (pupilId, body) => patchRequest(`/pupil/${pupilId}`, body);
const deletePupil = async (pupilId) => deleteRequest(`/pupil/${pupilId}`);
//#endregion

//#region parent
const getParents = async () => getRequest('/parent');
const getParent = async (parentId, body) => getRequest(`/parent/${parentId}`, body);
const getMeParent = async () => getRequest('/parent/me');
const postParent = async (body) => postRequest('/parent', body);
const patchParent = async (parentId, body) => patchRequest(`/parent/${parentId}`, body);
const deleteParent = async (parentId) => deleteRequest(`/parent/${parentId}`);
const getChildTeacher = async (childId) => getRequest(`/parent/getTeacher/${childId}`);
//#endregion

//#region parentalLetters
const getAllParentalLetters = async (body) => postRequest('/parentalLetter/list', body);
const getParentalLetterList = async () => getRequest('/parentalLetter/list');
const getParentalLetterById = async (parentalLetterId) => getRequest(`/parentalLetter/${parentalLetterId}`);
const createParentalLetter = async (body) => postRequest('/parentalLetter', body);
const setSeenBy = async (parentalLetterId, body) => postRequest(`/parentalLetter/${parentalLetterId}/seen`, body);
const patchParentalLetter = async (parentalLetterId, body) => patchRequest(`/parentalLetter/${parentalLetterId}`, body);
const deleteParentalLetter = async (parentalLetterId) => deleteRequest(`/parentalLetter/${parentalLetterId}`);
const postParentalLetterFile = (parentalLetterId, file) => createFileXMLHttpRequest(`/parentalLetter/${parentalLetterId}/file`, file); // Returning XMLHttpRequest object. You've to call ".send(data)".
const getParentalLetterFile = async (parentalLetterId, fileId) => getRequest(`/parentalLetter/${parentalLetterId}/file/${fileId}`);
const deleteParentalLetterFile = async (parentalLetterId, fileId) => deleteRequest(`/parentalLetter/${parentalLetterId}/file/${fileId}`);

const createResponse = (parentalLetterId, file, body) => createFileXMLHttpRequest(`/parentalLetterResponse/${parentalLetterId}`, file, body); // Returning XMLHttpRequest object. You've to call ".send(data)".
const getSignature = async (parentalLetterResponseId) => getRequest(`/parentalLetterResponse/${parentalLetterResponseId}/signature`);
const deleteSignature = async (parentalLetterResponseId) => deleteRequest(`/parentalLetterResponse/${parentalLetterResponseId}/signature`);
//#endregion

//#region sickNote
const createSickNote = async (body) => postRequest('/sickNote', body);
const getSickNotes = async () => getRequest('/sickNote');
const getSickNote = async (sickNoteId) => getRequest(`/sickNote/${sickNoteId}`);
const postSickNoteFile = (sickNoteId, file) => createFileXMLHttpRequest(`/sickNote/${sickNoteId}/file`, file); // Returning XMLHttpRequest object. You've to call ".send(data)".
const getSickNoteFile = async (sickNoteId, fileId) => getRequest(`/sickNote/${sickNoteId}/file/${fileId}`);
const acceptSickNote = (sickNoteId) => postRequest(`/sickNote/${sickNoteId}/accept`);
const declineSickNote = (sickNoteId) => postRequest(`/sickNote/${sickNoteId}/decline`);

const uploadSickNoteSignature = (sickNoteId, file, body) => createFileXMLHttpRequest(`/sickNote/${sickNoteId}/signature`, file, body); // Returning XMLHttpRequest object. You've to call ".send(data)".
const getSickNoteSignature = async (sickNoteId) => getRequest(`/sickNote/${sickNoteId}/signature`);
//#endregion

//#region schoolSubjects
const getSchoolSubjects = async () => getRequest('/schoolsubject');
const getSchoolSubject = async (schoolSubjectId) => getRequest(`/schoolsubject/${schoolSubjectId}`);
const postSchoolSubject = async (body) => postRequest('/schoolsubject', body);
const patchSchoolSubject = async (schoolSubjectId, body) => patchRequest(`/schoolsubject/${schoolSubjectId}`, body);
const deleteSchoolSubject = async (schoolSubjectId) => deleteRequest(`/schoolsubject/${schoolSubjectId}`);
//#endregion

//#region schoolSubjectAppointments
const getSchoolSubjectAppointments = async () => getRequest('/schoolsubjectappointment');
const getSchoolSubjectAppointmentsParent = async (pupilId) => getRequest(`/schoolsubjectappointment?pupilId=${pupilId}`);
const postSchoolSubjectAppointment = async (body) => postRequest('/schoolsubjectappointment', body);
const patchSchoolSubjectAppointment = async (schoolSubjectAppointmentId, body) => patchRequest(`/schoolsubjectappointment/${schoolSubjectAppointmentId}`, body);
const deleteSchoolSubjectAppointment = async (schoolSubjectAppointmentId) => deleteRequest(`/schoolsubjectappointment/${schoolSubjectAppointmentId}`);
const deleteSchoolSubjectAppointmentRule = async (ruleId) => deleteRequest(`/schoolsubjectappointment/rule/${ruleId}`);
const deleteSchoolSubjectAppointmentFromRule = async (appointmentId, ruleId) => deleteRequest(`/schoolsubjectappointment/${appointmentId}/rule/${ruleId}`);
const getSchoolSubjectAppointmentRuleFromId = async (ruleId) => getRequest(`/schoolSubjectAppointment/rule/${ruleId}`);
const joinSchoolSubjectAppointmentBbbMeetingUrl = async (schoolSubjectAppointmentId) => getRequest(`/schoolsubjectappointment/${schoolSubjectAppointmentId}/joinBbbMeetingUrl`);
const joinTeachersRoomBbbMeetingUrl = async () => getRequest(`/teacher/joinTeachersRoomUrl`);
const postTeacherUpload = (appointmentId, file) => createFileXMLHttpRequest(`/schoolSubjectAppointment/${appointmentId}/teacherUploads`, file); // Returning XMLHttpRequest object. You've to call ".send(data)".
const postTeacherUploadEdit = async (appointmentId, fileId, file) => filePostRequest(`/schoolSubjectAppointment/${appointmentId}/teacherUploads/${fileId}/edit`, file);
const postTeacherUploadMove = async (appointmentId, fileId) => postRequest(`/schoolSubjectAppointment/${appointmentId}/teacherUploads/${fileId}/move`);
const postTeacherUploadFromFileWidget = async (appointmentId, body) => postRequest(`/schoolSubjectAppointment/${appointmentId}/teacherUploads/postFromFileWidget`, body);
const patchTeacherUploadPip = async (appointmentId, fileId, args) => patchRequest(`/schoolSubjectAppointment/${appointmentId}/teacherUploads/${fileId}/pip`, args);
const patchTeacherUploadFile = async (appointmentId, fileId, newFile) => createFileXmlPatchRequest(`/schoolSubjectAppointment/${appointmentId}/teacherUploads/${fileId}/file`, newFile);
const getTeacherUpload = async (appointmentId, fileId) => getRequest(`/schoolSubjectAppointment/${appointmentId}/teacherUploads/${fileId}`);
const getTeacherUploadThumbnail = async (appointmentId, fileId) => getRequest(`/schoolSubjectAppointment/${appointmentId}/teacherUploads/thumb/${fileId}`);
const deleteUpload = async (fileId) => deleteRequest(`/schoolSubjectAppointment/upload/${fileId}`);
const postPupilUpload = (appointmentId, file) => createFileXMLHttpRequest(`/schoolSubjectAppointment/${appointmentId}/pupilUploads`, file);
const postPupilUploadFromFileWidget = async (appointmentId, body) => postRequest(`/schoolSubjectAppointment/${appointmentId}/pupilUploads/postFromFileWidget`, body);
const postPupilUploadEdit = async (appointmentId, fileId, file) => filePostRequest(`/schoolSubjectAppointment/${appointmentId}/pupilUploads/${fileId}/edit`, file);
const getPupilUpload = async (appointmentId, fileId) => getRequest(`/schoolSubjectAppointment/${appointmentId}/pupilUploads/${fileId}`);
const getPupilUploadThumbnail = async (appointmentId, fileId) => getRequest(`/schoolSubjectAppointment/${appointmentId}/pupilUploads/thumb/${fileId}`);
const postThumbnail = async (fileId, file) => filePostRequest(`/schoolSubjectAppointment/${fileId}/thumbnail`, file);
const getThumbnail = async (fileId) => getRequest(`/schoolSubjectAppointment/${fileId}/thumbnail`);
//#endregion

//#region teachers
const getTeachers = async () => getRequest('/teacher');
const getTeacher = async (teacherId) => getRequest(`/teacher/${teacherId}`);
const getProfilePicture = async (teacherId) => getRequest(`/teacher/${teacherId}/profilePicture`);
const getMeTeacher = async () => getRequest(`/teacher/me`);
const postTeacher = async (body) => postRequest('/teacher', body);
const postTeacherPicture = (teacherId, file) => createFileXMLHttpRequest(`/teacher/${teacherId}/profilePicture`, file);
const patchTeacher = async (teacherId, body) => patchRequest(`/teacher/${teacherId}`, body);
const patchTeacherProfile = async (teacherId, body) => patchRequest(`/teacher/${teacherId}/profile`, body);
const deleteTeacher = async (teacherId) => deleteRequest(`/teacher/${teacherId}`);
//#endregion

const getExternalBbbRooms = async () => getRequest('/externalBbbRoom');
const postExternalBbbRoom = async (externalBbbRoom) => postRequest('/externalBbbRoom', externalBbbRoom);
const patchExternalBbbRoom = async (externalBbbRoomId, externalBbbRoom) => patchRequest(`/externalBbbRoom/${externalBbbRoomId}`, externalBbbRoom);
const deleteExternalBbbRoom = async (externalBbbRoomId) => deleteRequest(`/externalBbbRoom/${externalBbbRoomId}`);
const getExternalBbbRoomGuestData = async (externalBbbRoomId, token) => getRequest(`/externalBbbRoom/${externalBbbRoomId}/guestData?token=${token}`);
const getExternalBbbRoomJoinAsModeratorUrl = async (externalBbbRoomId) => getRequest(`/externalBbbRoom/${externalBbbRoomId}/joinAsModeratorUrl`);
const getExternalBbbRoomJoinAsGuestUrl = async (externalBbbRoomId, token, username) => getRequest(`/externalBbbRoom/${externalBbbRoomId}/joinAsGuestUrl?token=${token}&username=${username}`);

//#region blackboard
const getBlackboard = async () => getRequest('/blackboard');
const getParentBlackboard = async (pupilId) => getRequest(`/blackboard/${pupilId}`);
const postBlackboard = async (body) => postRequest('/blackboard', body);
const postBlackboardFile = (blackboardId, file) => createFileXMLHttpRequest(`/blackboard/${blackboardId}/file`, file);
const getBlackboardUpload = async (fileId) => getRequest(`/blackboard/file/${fileId}`);
const getBlackboardUploadThumbnail = async (fileId) => getRequest(`/blackboard/file/thumb/${fileId}`);
const patchBlackboard = async (blackboardId, body) => patchRequest(`/blackboard/${blackboardId}`, body);
const deleteBlackboard = async (blackboardId, body) => deleteRequest(`/blackboard/${blackboardId}`, body);
const deleteBlackboardUpload = async (blackboardId, fileId) => deleteRequest(`/blackboard/${blackboardId}/file/${fileId}`);
//#endregion

const postUploadMessage = (file) => filePostRequest(`/uploadMessage`, file);
const postUploadMessages = async (body) => postRequest('/uploadMessages', body);
const postUploadMessagesPairing = async (body) => postRequest('/uploadMessages/pairing', body);
const getUploadMessages = async (fileId) => getRequest(`/uploadMessages/${fileId}`);
const getUploadMessage = async (fileId) => getRequest(`/uploadMessage/${fileId}`);

const postSubjectFolderUpload = (subjectId, file) => createFileXMLHttpRequest(`/subjectFolder/${subjectId}`, file); // Returning XMLHttpRequest object. You've to call ".send(data)".
const getSubjectFolderUploads = async (subjectId, teacherId) => getRequest(`/subjectFolder/${subjectId}/${teacherId}`);
const getSubjectFolderUpload = async (fileId) => getRequest(`/subjectFolder/${fileId}`);
const deleteSubjectFolderUpload = async (subjectId, teacherId, fileId) => deleteRequest(`/subjectFolder/${subjectId}/${teacherId}/${fileId}`);

const postVFSFolderUpload = (file, folderType, folderId) => createFileXMLHttpRequest(`/virtualFileSystem/file/${folderType}/${folderId ? `${folderId}` : ''}`, file); // Returning XMLHttpRequest object. You've to call ".send(data)".
const getVFSFolderUploads = async (folderType, folderId) => getRequest(`/virtualFileSystem/${folderType}/${folderId ? `${folderId}` : ''}`);
const getVFSFolderUpload = async (fileId) => getRequest(`/virtualFileSystem/file/download/${fileId}`);
const deleteVFSFolderUpload = async (fileId) => deleteRequest(`/virtualFileSystem/file/${fileId}`);
const postVFSFolderUploadFromFileWidget = async (body) => postRequest('/virtualFileSystem/copy/copyFromFileWidget', body);
const createVFSFolder = async (folderId, body) => postRequest(`/virtualFileSystem${folderId ? `/${folderId}` : ''}`, body);
const updateVFSFolder = async (folderId, body) => patchRequest(`/virtualFileSystem/${folderId}`, body);
const updateVFSFolderUpload = async (fileId, body) => patchRequest(`/virtualFileSystem/file/${fileId}`, body);
const deleteVFSFolder = async (folderId) => deleteRequest(`/virtualFileSystem/${folderId}`);
const searchVFS = async (body) => postRequest(`/virtualFileSystem/search/all`, body);
const updateVFSFolderUpload_Legacy = async (fileId, newFileData) => createFileXmlPatchRequest(`/virtualFileSystem/file/legacy/${fileId}`, newFileData);

const getServerTime = async (body) => getRequest('/time', body);
const getVersionNumber = async () => getRequest('/version');
const getBonusFunctions = async (body) => getRequest('/bonusfunctions');

const teacherClassroom = async () => getRequest('/teacherClassroom');
const joinClassroom = async (classroomId) => getRequest(`/teacherClassroom/${classroomId}`);
const changeRoomStatus = async (classroomId, body) => postRequest(`/teacherClassroom/${classroomId}`, body);

const getMyConferenceTeacher = async () => getRequest('/teacherConferenceRoom');
const getMyConferenceParent = async (pupilId) => getRequest(`/teacherConferenceRoom/${pupilId}`);
const joinConferenceRoom = async (roomId) => getRequest(`/teacherConferenceRoom/bbb/${roomId}`);
const changeConferenceRoomStatus = async (roomId, body) => postRequest(`/teacherConferenceRoom/${roomId}`, body);

const translateToTargetLang = async (body) => postRequest('/translate', body)

const getSubtitle = async (fileId) => getRequest(`/subtitle/${fileId}`);

const getImageLibraryIndex = async () => getRequest(`/image-library`);
const getImageLibraryImage = async (imageId) => getRequest(`/image-library/${imageId}`);

const getDocumentRegistryDocuments = async () => getRequest('/document-registry/document');
const getDocumentRegistryDocument = async (documentId) => getRequest(`/document-registry/document/${documentId}`);
const postDocumentRegistryDocument = async (body) => postRequest('/document-registry/document', body);
const patchDocumentRegistryDocument = async (documentId, body) => patchRequest(`/document-registry/document/${documentId}`, body);
const postDocumentRegistryDocumentFile = async (documentId, file) => createFileXMLHttpRequest(`/document-registry/document/${documentId}/file`, file);
const getDocumentRegistryDocumentFile = async (documentId, fileId) => getRequest(`/document-registry/document/${documentId}/file/${fileId}`);
const deleteDocumentRegistryDocument = async (documentId) => deleteRequest(`/document-registry/document/${documentId}`);

const getDocumentRegistryFolders = async () => getRequest('/document-registry/folder');
const getDocumentRegistryRootFolder = async () => getRequest('/document-registry/folder/root');
const getDocumentRegistryFolder = async (folderId) => getRequest(`/document-registry/folder/${folderId}`);
const postDocumentRegistryFolder = async (body) => postRequest('/document-registry/folder', body);
const patchDocumentRegistryFolder = async (folderId, body) => patchRequest(`/document-registry/folder/${folderId}`, body);
const deleteDocumentRegistryFolder = async (folderId) => deleteRequest(`/document-registry/folder/${folderId}`);

const getDocumentRegistryTags = async () => getRequest('/document-registry/tag');
const getDocumentRegistryTag = async (tagId) => getRequest(`/document-registry/tag/${tagId}`);
const postDocumentRegistryTag = async (body) => postRequest('/document-registry/tag', body);
const deleteDocumentRegistryTag = async (tagId) => deleteRequest(`/document-registry/tag/${tagId}`);

//#region poll
const createPoll = async (body) => postRequest('/poll', body);
const getPoll = async (pollId) => getRequest(`/poll/${pollId}`);
const vote = async (pollId, body) => postRequest(`/poll/${pollId}/vote`, body);
const createOption = async (pollId, body) => postRequest(`/poll/${pollId}/option`, body);
//#endregion

const getDay = async (body) => postRequest('/diary/get', body);
const postDay = async (body) => postRequest('/diary', body);

const createNewSchoolYear = async (body) => postRequest('/change-school-year', body);
export {
    loginAccount,
    patchAccount,
    confirmAccountPassword,
    subscribe,
    unsubscribe,
    getCurrentAccount,
    getSecurityQuestions,
    acceptTermsOfService,
    getAVVSigned,
    isAVVSigned,
    getAVVTemplate,

    deleteMessage,
    getCurrentMatrixUser,
    getMatrixName,
    postVoiceMessage,
    getVoiceMessage,
    deleteVoiceMessage,
    postPrivateRoom,
    getMatrixRoomLockStatus,
    getMatrixRoomLockStatusById,
    setMatrixRoomLockStatus,

    postNameAvailable,

    getGroups,
    getGroupsInfo,
    getGroup,
    getGroupByAppointment,
    postGroup,
    patchGroup,
    deleteGroup,

    getMaintainers,
    getMaintainer,
    postMaintainer,
    patchMaintainer,
    deleteMaintainer,
    confirmMaintainerPassword,
    postMaintainerAVV,
    postMaintainerAppendAVV,

    getPupils,
    getPupil,
    getMePupil,
    postPupil,
    patchPupil,
    deletePupil,

    getParents,
    getParent,
    getMeParent,
    postParent,
    patchParent,
    deleteParent,
    getChildTeacher,

    getAllParentalLetters,
    getParentalLetterList,
    getParentalLetterById,
    createParentalLetter,
    setSeenBy,
    patchParentalLetter,
    deleteParentalLetter,
    postParentalLetterFile,
    getParentalLetterFile,
    deleteParentalLetterFile,

    createSickNote,
    getSickNotes,
    getSickNote,
    postSickNoteFile,
    getSickNoteFile,
    acceptSickNote,
    declineSickNote,
    uploadSickNoteSignature,
    getSickNoteSignature,

    createResponse,
    getSignature,
    deleteSignature,

    getSchoolSubjects,
    getSchoolSubject,
    postSchoolSubject,
    patchSchoolSubject,
    deleteSchoolSubject,

    getSchoolSubjectAppointments,
    getSchoolSubjectAppointmentsParent,
    postSchoolSubjectAppointment,
    patchSchoolSubjectAppointment,
    deleteSchoolSubjectAppointment,
    deleteSchoolSubjectAppointmentRule,
    deleteSchoolSubjectAppointmentFromRule,
    getSchoolSubjectAppointmentRuleFromId,
    joinSchoolSubjectAppointmentBbbMeetingUrl,
    postTeacherUpload,
    postTeacherUploadEdit,
    postTeacherUploadMove,
    postTeacherUploadFromFileWidget,
    patchTeacherUploadPip,
    patchTeacherUploadFile,
    getTeacherUpload,
    getTeacherUploadThumbnail,
    deleteUpload,
    postPupilUpload,
    postPupilUploadFromFileWidget,
    postPupilUploadEdit,
    getPupilUpload,
    getPupilUploadThumbnail,
    postThumbnail,
    getThumbnail,

    joinTeachersRoomBbbMeetingUrl,

    getTeachers,
    getTeacher,
    getProfilePicture,
    getMeTeacher,
    postTeacher,
    postTeacherPicture,
    patchTeacher,
    patchTeacherProfile,
    deleteTeacher,

    getTimeslots,
    postTimeslot,
    patchTimeslot,
    deleteTimeslot,

    getBlackboard,
    getParentBlackboard,
    postBlackboard,
    postBlackboardFile,
    getBlackboardUpload,
    getBlackboardUploadThumbnail,
    deleteBlackboardUpload,
    patchBlackboard,
    deleteBlackboard,

    getExternalBbbRooms,
    postExternalBbbRoom,
    patchExternalBbbRoom,
    deleteExternalBbbRoom,
    getExternalBbbRoomGuestData,
    getExternalBbbRoomJoinAsModeratorUrl,
    getExternalBbbRoomJoinAsGuestUrl,

    postUploadMessage,
    postUploadMessages,
    postUploadMessagesPairing,
    getUploadMessages,
    getUploadMessage,

    postSubjectFolderUpload,
    getSubjectFolderUploads,
    getSubjectFolderUpload,
    deleteSubjectFolderUpload,

    postVFSFolderUpload,
    getVFSFolderUploads,
    getVFSFolderUpload,
    deleteVFSFolder,
    deleteVFSFolderUpload,
    postVFSFolderUploadFromFileWidget,
    createVFSFolder,
    updateVFSFolder,
    updateVFSFolderUpload,
    searchVFS,
    updateVFSFolderUpload_Legacy,

    getServerTime,
    getVersionNumber,
    getBonusFunctions,

    teacherClassroom,
    joinClassroom,
    changeRoomStatus,

    getMyConferenceTeacher,
    getMyConferenceParent,
    joinConferenceRoom,
    changeConferenceRoomStatus,

    translateToTargetLang,

    getSubtitle,

    getImageLibraryIndex,
    getImageLibraryImage,

    getDocumentRegistryDocuments,
    getDocumentRegistryDocument,
    postDocumentRegistryDocument,
    patchDocumentRegistryDocument,
    postDocumentRegistryDocumentFile,
    getDocumentRegistryDocumentFile,
    deleteDocumentRegistryDocument,

    getDocumentRegistryFolders,
    getDocumentRegistryRootFolder,
    getDocumentRegistryFolder,
    postDocumentRegistryFolder,
    patchDocumentRegistryFolder,
    deleteDocumentRegistryFolder,

    getDocumentRegistryTags,
    getDocumentRegistryTag,
    postDocumentRegistryTag,
    deleteDocumentRegistryTag,

    createPoll,
    getPoll,
    vote,
    createOption,


    createNewSchoolYear,
    getDay,
    postDay,
};

