import { render, staticRenderFns } from "./EditorDocumentRegistry.vue?vue&type=template&id=368857cf&"
import script from "./EditorDocumentRegistry.vue?vue&type=script&lang=js&"
export * from "./EditorDocumentRegistry.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VTextField})
