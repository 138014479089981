<template>
    <div>
        <progress-bar
            title="Hochladen"
            :show-progress="showProfilepicUploadProgress"
            :progress="profilepicUploadProgress"
            :abort-progress="teacherXmlHttpRequest ? () => {teacherXmlHttpRequest.abort();}: () => {}"
        />

        <v-row class="mx-0 mt-3 mb-4">
            <div style="height: auto; width: 90%; margin: auto; border-radius: 15px; box-shadow: 1px 5px 5px silver;">
                <div style=" background-color: var(--v-orange-base); border-radius: 15px 15px 0 0; font-size: larger; padding-top: 5px; padding-bottom: 20px; text-align: center; color: white;">
                    <div style="display: flex; width: 50%">
                        <img
                            :src="suchIcon"
                            style="height: 24px; margin-left: 24px; margin-top: 20px;
                        filter: brightness(0) saturate(100%) invert(100%) sepia(5%) saturate(19%) hue-rotate(65deg) brightness(104%) contrast(105%);"
                            alt="Such-Icon"
                        >
                        <v-text-field
                            v-model="search"
                            label="Nach Lehrer suchen"
                            single-line
                            dark
                            hide-details
                            style="width: 50%"
                            class="pl-6"
                        />
                    </div>
                </div>
                <div
                    style="display: flex; font-size: larger; font-family: Roboto; margin-top: 24px; margin-left: 26px"
                    class=""
                >
                    <img
                        :src="lehrerIcon"
                        style="height: 24px; margin-right: 10px"
                        alt=""
                    >
                    Lehrer
                </div>
                <v-data-table
                    :headers="headers"
                    :items="list"
                    :search="search"
                    disable-pagination
                    sort-by="lastName"
                    hide-default-footer
                    fixed-header
                    :height="height"
                    class="ma-3"
                    locale="de-DE"
                    no-data-text="Keine Daten verfügbar"
                    no-results-text="Keine übereinstimmenden Aufzeichnungen gefunden"
                >
                    <template v-slot:item="row">
                        <tr>
                            <td>{{ row.item.lastName }}</td>
                            <td>{{ row.item.name }}</td>
                            <td>{{ row.item.gender }}</td>
                            <td>
                                <v-btn
                                    class="mx-2"
                                    color="#EAEAEA"
                                    style="border-radius: 5px; text-transform: inherit"
                                    elevation="1"
                                    small
                                    light
                                    @click="editUser(row.item)"
                                >
                                    Lehrerprofil bearbeiten
                                </v-btn>
                            </td>
                        </tr>
                    </template>
                </v-data-table>

                <v-overlay
                    absolute
                    :value="showLoadingOverlay"
                    style="width: 100% !important;"
                >
                    <v-progress-circular
                        v-if="showLoadingOverlay"
                        indeterminate
                        size="100"
                    />
                </v-overlay>
            </div>
        </v-row>

        <!-- Lehrer bearbeiten / anlegen -->
        <v-row
            id="edit"
            class="mx-0 my-3"
        >
            <div class="editContainer">
                <v-row
                    class="mx-0 "
                    style="background-color: var(--v-orange-base); border-radius: 15px 15px 0 0"
                >
                    <v-col
                        style="color: white; font-size: larger;"
                        class="ma-1 ml-5"
                    >
                        <v-avatar
                            v-if="profilePic !== ''"
                            size="41"
                            :class="{ errorInput: fields.profilePic }"
                        >
                            <img
                                id="profilePic"
                                alt="Profilsymbol"
                                :src="profilePic"
                                style=""
                            >
                        </v-avatar>
                        <v-avatar
                            v-else
                            size="41"
                            :class="{ errorInput: fields.profilePic }"
                        >
                            <img
                                id="profilePicPlaceholder"
                                alt="Profilsymbol"
                                :src="diversPic"
                                style=""
                            >
                        </v-avatar>
                        <input
                            id="profilepicUploadInput"
                            ref="profilepicUploadInput"
                            type="file"
                            hidden
                            @change="() => teacherUploadInputChange()"
                        >
                        <v-btn
                            style="margin-left: -15px; margin-top: 19px; max-width: 22px; max-height: 22px"
                            fab
                            x-small
                            elevation="0"
                            color="#BDBDBD"
                            @click="clickProfilepicUploadButton"
                        >
                            <img
                                :src="bearbeitenIcon"
                                alt="Bearbeitensymbol"
                                style="max-width: 11px; "
                                class=""
                            >
                        </v-btn>

                        Lehrer {{ oldProfile ? 'bearbeiten' : 'anlegen' }}
                    </v-col>
                    <v-col
                        class="mr-6"
                        style="display: flex; justify-content: flex-end;"
                    >
                        <v-tooltip
                            top
                            :open-on-click="false"
                            :open-on-hover="true"
                            content-class="infoTextToolTip"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <span
                                    v-bind="attrs"
                                    class="my-auto"
                                    v-on="on"
                                >
                                    <v-btn
                                        v-if="!oldProfile && debugMode"
                                        color="#FFFFFF40"
                                        x-small
                                        class="mainFunctionButtons ml-3 my-auto"
                                        v-bind="attrs"
                                        @click="clickExelUploadButton"
                                        v-on="on"
                                    >
                                        <img
                                            :src="hochladenIcon"
                                            alt="Hochladensymbol"
                                        >
                                    </v-btn>
                                </span>
                            </template>
                            <span>Hier können Sie einen Excel von Lehrern hochladen.</span>
                        </v-tooltip>
                        <v-btn
                            color="#FFFFFF40"
                            x-small
                            class="mainFunctionButtons ml-3 my-auto"
                            :disabled="!oldProfile"
                            @click="openDeleteDialog"
                        >
                            <img
                                :src="loeschIcon"
                                alt="Löschensymbol"
                            >
                        </v-btn>

                        <v-tooltip
                            top
                            :open-on-click="false"
                            :open-on-hover="true"
                            content-class="infoTextToolTip"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <span
                                    v-bind="attrs"
                                    class="my-auto"
                                    v-on="on"
                                >
                                    <v-btn
                                        color="#FFFFFF40"
                                        x-small
                                        class="mainFunctionButtons ml-3 "
                                        disabled
                                        @click="pdf"
                                    >
                                        <!-- :disabled="!(togglePDFDownload)" -->
                                        <img
                                            :src="pdfDocIcon"
                                            alt="PDF-Symbol"
                                        >
                                    </v-btn>
                                </span>
                            </template>
                            <span>Aktuell noch nicht verfügbar.</span>
                        </v-tooltip>
                        <v-tooltip
                            top
                            nudge-right="100"
                            :open-on-click="true"
                            :open-on-hover="false"
                            content-class="infoTextToolTip"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    color="#FFFFFF40"
                                    x-small
                                    class="mainFunctionButtons ml-3 my-auto"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    <img
                                        :src="infoIcon"
                                        alt="Informationssymbol"
                                    >
                                </v-btn>
                            </template>
                            <span>Hier können Sie Lehrer anlegen oder bearbeiten.</span>
                        </v-tooltip>
                        <v-btn
                            class="mainControlButtons ml-3 my-auto pa-5"
                            color="#BDBDBD50"
                            dark
                            elevation="0"
                            @click="stopEdit"
                        >
                            <span>Abbrechen</span>
                        </v-btn>
                        <v-btn
                            class="mainControlButtons ml-3 my-auto pa-5"
                            color="#69CD70"
                            dark
                            elevation="4"
                            @click="openDialog"
                        >
                            <span>Speichern</span>
                        </v-btn>
                    </v-col>
                </v-row>
                <div class="mx-2 mb-2">
                    <v-row>
                        <v-col class="mx-5">
                            <div
                                class="font-weight-bold"
                                style="color: rgba(0, 0, 0, 0.6)"
                            >
                                Persönliche Daten
                            </div>
                            <v-row>
                                <v-col cols="5">
                                    Vorname:
                                </v-col>
                                <v-col cols="7">
                                    <input
                                        v-model="name"
                                        type="text"
                                        class="input"
                                        :class="{ errorInput: fields.name }"
                                        @change="() => { suggestLoginId(oldProfile); }"
                                    >
                                </v-col>
                            </v-row>
                            <v-row
                                v-if="fields.name"
                                dense
                            >
                                <v-col
                                    class="pl-2; pt-0"
                                    style="margin-top: -10px"
                                >
                                    <span style="color: red; font-size: 12px">Name darf keine Zahlen erhalten.</span>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="5">
                                    Nachname:
                                </v-col>
                                <v-col cols="7">
                                    <input
                                        v-model="lastName"
                                        type="text"
                                        class="input"
                                        :class="{ errorInput: fields.lastName}"
                                        @change="() => { suggestLoginId(oldProfile); }"
                                    >
                                </v-col>
                            </v-row>
                            <v-row
                                v-if="fields.lastName && lastName.length === 0"
                                dense
                            >
                                <v-col
                                    class="pl-2; pt-0"
                                    style="margin-top: -10px"
                                >
                                    <span style="color: red; font-size: 12px">Pflichtfeld</span>
                                </v-col>
                            </v-row>
                            <v-row
                                v-else-if="fields.lastName"
                                dense
                            >
                                <v-col
                                    class="pl-2; pt-0"
                                    style="margin-top: -10px"
                                >
                                    <span style="color: red; font-size: 12px">Name darf keine Zahlen erhalten.</span>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="5">
                                    Geschlecht:
                                </v-col>
                                <v-col cols="7">
                                    <select
                                        v-model="gender"
                                        class="input"
                                        :class="{ errorInput: fields.gender }"
                                        @change="validateInput"
                                    >
                                        <option
                                            disabled
                                            selected
                                            value
                                        >
                                            Auswählen
                                        </option>
                                        <option value="k">
                                            Keine Angabe
                                        </option>
                                        <option value="m">
                                            Männlich
                                        </option>
                                        <option value="w">
                                            Weiblich
                                        </option>
                                        <option value="d">
                                            Divers
                                        </option>
                                    </select>
                                </v-col>
                            </v-row>
                            <v-row
                                v-if="fields.gender"
                                dense
                            >
                                <v-col
                                    class="pt-0"
                                    style="margin-top: -10px"
                                >
                                    <span style="color: red; font-size: 12px">Pflichtfeld</span>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="5">
                                    Notiz:
                                </v-col>
                                <v-col cols="7">
                                    <textarea
                                        v-model="note"
                                        class="input"
                                    />
                                </v-col>
                            </v-row>
                        </v-col>

                        <v-col class="mx-5">
                            <div
                                class="font-weight-bold"
                                style="color: rgba(0, 0, 0, 0.6)"
                            >
                                Schulisches
                            </div>
                            <v-row>
                                <v-col cols="5">
                                    Gruppen:
                                </v-col>
                                <v-col cols="7">
                                    <v-row
                                        v-for="(item, index) in teacherGroups"
                                        :key="index"
                                    >
                                        <div
                                            class="px-3 py-1 mb-1"
                                            style="min-width: 200px; background-color: var(--v-hellgrau-base); border-radius: 8px"
                                        >
                                            {{ item.name }}
                                        </div>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col />
                            </v-row>
                            <v-row>
                                <v-col />
                            </v-row>
                            <v-row>
                                <v-col cols="5">
                                    Fächer:
                                </v-col>
                                <v-col cols="7">
                                    <v-row
                                        v-for="(item, index) in teacherSubjects"
                                        :key="index"
                                    >
                                        <div
                                            class="px-3 py-1 mb-1"
                                            style="min-width: 200px; background-color: var(--v-hellgrau-base); border-radius: 8px"
                                        >
                                            {{ item.name }}
                                        </div>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col
                            class="mx-5"
                            cols="12"
                            md="3"
                        >
                            <v-row dense>
                                <v-col>
                                    <div
                                        class="font-weight-bold"
                                        style="color: rgba(0, 0, 0, 0.6)"
                                    >
                                        Technisches
                                    </div>
                                </v-col>
                                <v-col>
                                    <v-btn
                                        class="generatePasswordButton"
                                        elevation="0"
                                        icon
                                        @click="makePassword"
                                    >
                                        <img
                                            :src="aktualisierenIcon"
                                            alt="Aktualisierensymbol"
                                        >Passwort generieren
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="5">
                                    Login-ID:
                                </v-col>
                                <v-col
                                    cols="7"
                                    @click="editLoginId = true;"
                                >
                                    <input
                                        v-model="userName"
                                        type="text"
                                        class="input"
                                        :class="{ errorInput: fields.userName }"
                                        :disabled="!editLoginId || oldProfile"
                                        @change="validateInput"
                                    >
                                </v-col>
                            </v-row>
                            <v-row
                                v-if="fields.userName"
                                dense
                            >
                                <v-col style="padding-top: 0; margin-top: -10px">
                                    <span style="color: red; font-size: 12px">Login-ID darf nur Zahlen und Buchstaben erhalten.</span>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="5">
                                    Passwort:
                                </v-col>
                                <v-col
                                    cols="7"
                                    @click="editPassword = true"
                                >
                                    <input
                                        v-model="password"
                                        :type="editPassword ? 'text' : (oldProfile && !newPassword) ? 'password' : 'text'"
                                        :disabled="!editPassword"
                                        class="input"
                                        :class="{ errorInput: fields.password }"
                                        @change="validateInput"
                                    >
                                </v-col>
                            </v-row>
                            <v-row
                                v-if="fields.password"
                                dense
                            >
                                <v-col style="padding-top: 0; margin-top: -10px">
                                    <span style="color: red; font-size: 12px">Passwort erfüllt Richtlinie nicht.</span>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col
                                    cols="12"
                                    class="pt-0"
                                >
                                    <p
                                        class="mb-2"
                                        style="color: #808080;"
                                    >
                                        Muss mind. 8 Zeichen lang sein und einen Buchstaben und eine Zahl enthalten.
                                    </p>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </div>
            </div>
        </v-row>

        <!-- Delete or switch Teacher Dialogue -->
        <v-dialog
            v-model="deleteTeacherDialog"
            width="60%"
            persistent
            style="border-radius: 8px"
        >
            <v-row
                class="ma-0"
                style="background-color: var(--v-blau-base) ; font-size: larger; height: 60px"
                align-content="center"
            >
                <v-col cols="1">
                    <v-btn
                        v-if="deletePagesIndex >= 1"
                        x-small
                        class="ma-auto replaceTeacherControls"
                        @click="deletePagesIndex--"
                    >
                        <img
                            alt="Zurücksymbol"
                            style="transform: rotate(90deg)"
                            :src="arrow"
                        >
                    </v-btn>
                </v-col>
                <v-col cols="10">
                    <p
                        style="color: white; font-size: larger; text-align: center"
                        class="ma-auto"
                    >
                        Lehrer löschen und neu verwalten
                    </p>
                </v-col>
                <v-col cols="1">
                    <v-btn
                        x-small
                        style="float: right"
                        class="ma-auto replaceTeacherControls"
                        @click="() => {deleteTeacherDialog = false; deletePagesIndex = 0;}"
                    >
                        <img
                            :src="abbrechen"
                            alt="Abbrechensymbol"
                        >
                    </v-btn>
                </v-col>
            </v-row>
            <v-card>
                <div
                    v-if="deletePagesIndex === 0"
                    id="delTeacher1"
                >
                    <v-col>
                        <v-row v-show="false">
                            <v-col cols="10">
                                <p>
                                    Wollen Sie diesen Lehrer wirklich löschen? Wählen Sie zwischen 2 Möglichkeiten:
                                </p>
                                <p>
                                    <b>
                                        1. Lehrer löschen und neu verwalten. (Empfohlen)
                                    </b>
                                    <br>
                                    Sie können neue Gruppenleiter und Fachlehrer auswählen und Ihren zu löschenden Lehrer ersetzen.
                                </p>
                            </v-col>
                            <v-col cols="2">
                                <v-btn
                                    color="#69CD70"
                                    disabled
                                    @click="deletePagesIndex++"
                                >
                                    Weiter
                                    <img
                                        alt="Weitersymbol"
                                        style="transform: rotate(270deg); width: 24px; filter: brightness(1000%)"
                                        :src="arrow"
                                    >
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols=" 10">
                                <p>
                                    <b>
                                        1. Lehrer und alle verknüpften Daten endgültig löschen.
                                    </b>
                                    <br>
                                    Daten des zu löschenden Lehrers und alle verknüpften Gruppen werden <b>restlos gelöscht</b>.
                                    <br>
                                    Dies führt dazu, dass Sie Gruppen nochmals einpflegen müssen und auch Fächer im Stundenplan erneut Gruppen zuteilen müssen.
                                </p>
                            </v-col>
                            <v-col cols="2">
                                <v-btn
                                    color="#e6231e"
                                    dark
                                    @click="() => {deleteDialog = true}"
                                >
                                    Löschen
                                    <img
                                        style="width: 24px; filter: brightness(1000%)"
                                        :src="abbrechen"
                                        alt="Abrechensymbol"
                                    >
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                </div>
                <div
                    v-if="deletePagesIndex === 1"
                    id="delTeacher2"
                >
                    <v-col>
                        <v-row>
                            <v-col>
                                <p>
                                    <b>1. Gruppen verwalten</b>
                                    <br>
                                    Falls Ihr zu löschender Lehrer ein Gruppenleiter war, werden hier nun die geleiteten Gruppen aufgelistet.
                                    Teilen Sie der Gruppe einen neuen Gruppenleiter zu.
                                </p>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-row class="mx-1">
                                    <v-col
                                        cols="4"
                                        style="color: var(--v-dunkelgrau-base)"
                                    >
                                        Name
                                    </v-col>
                                    <v-col
                                        cols="2"
                                        style="color: var(--v-dunkelgrau-base)"
                                    >
                                        Aktueller Lehrer
                                    </v-col>
                                    <v-col cols="1" />
                                    <v-col
                                        cols="2"
                                        style="color: var(--v-dunkelgrau-base)"
                                    >
                                        Zukünftiger Lehrer
                                    </v-col>
                                    <v-col cols="3" />
                                </v-row>
                                <v-row
                                    v-for="(item,index) in deleteGroupData"
                                    :key="index"
                                    class="mx-1 pt-1"
                                    style="border-bottom: 2px solid #EAEAEA"
                                >
                                    <v-col
                                        cols="4"
                                        class="pl-2 mt-2 basicDeleteTeacherColumn"
                                    >
                                        {{ item.groupName }}
                                    </v-col>
                                    <v-col
                                        cols="2"
                                        class="mt-2 basicDeleteTeacherColumn deleteCurrentTeacherDataCol"
                                    >
                                        {{ item.currentFullName }}
                                        <img
                                            v-if="profilePic"
                                            :src="profilePic"
                                            alt="Profilsymbol"
                                        >
                                        <img
                                            v-else
                                            :src="getPersonPic(gender)"
                                            alt="Profilsymbol"
                                        >
                                    </v-col>
                                    <v-col cols="1">
                                        <img
                                            :src="rechtsPfeil"
                                            class="ma-auto"
                                            style="width: 20px"
                                        >
                                    </v-col>
                                    <v-col
                                        cols="2"
                                        class="mt-2 basicDeleteTeacherColumn deleteCurrentTeacherDataCol"
                                    >
                                        <img
                                            v-if="item.newTeacherPicture != null"
                                            :src="item.newTeacherPicture"
                                            alt="Profilsymbol"
                                        >
                                        <img
                                            v-else-if="item.newTeacher != null && item.newTeacherPicture == null"
                                            alt="Profilsymbol"
                                            :src="getPersonPic(item.newTeacher.gender)"
                                        >
                                        <v-icon
                                            v-else
                                            style="font-size: 25px; color: grey"
                                        >
                                            fas fa-circle
                                        </v-icon>
                                    </v-col>
                                    <v-col
                                        cols="3"
                                        class="mt-1 basicDeleteTeacherColumn deleteTeacherNewSelection"
                                    >
                                        <v-container id="dropdown-example-1">
                                            <v-select
                                                v-model="deleteGroupData[index].newTeacher"
                                                solo
                                                :background-color="item.newTeacher != null ? 'success':'error'"
                                                dense
                                                dark
                                                :items="list"
                                                return-object
                                                :item-text="xyz => xyz.name +'  '+ xyz.lastName"
                                                class="deleteTeacherSelect"
                                                @change="changeNewTeacher(deleteGroupData[index].newTeacher)"
                                            />
                                        </v-container>
                                    </v-col>
                                </v-row>
                                <v-row v-if="!deleteGroupData.length">
                                    Dieser Lehrer hatte keine Gruppenleitung!
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="2" />
                            <v-col
                                cols="8"
                                style="justify-content: center"
                            >
                                <!--                                <v-icon class="mx-auto" style="font-size: 25px; color: gray">fas fa-circle</v-icon>-->
                                <!--                                <v-icon class="mx-auto" style="font-size: 25px; color: darkgray">fas fa-circle</v-icon>-->
                            </v-col>
                            <v-col cols="2">
                                <v-btn
                                    style="float: right"
                                    :dark="isDeleteGroupDataDone"
                                    color="success"
                                    :disabled="!isDeleteGroupDataDone"
                                    @click="deletePagesIndex++"
                                >
                                    Weiter
                                    <img
                                        style="transform: rotate(270deg);width: 24px; filter: brightness(1000%)"
                                        :src="arrow"
                                        alt="Weitersymbol"
                                    >
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                </div>
                <div
                    v-if="deletePagesIndex === 2"
                    id="delTeacher3"
                >
                    <v-col>
                        <v-row>
                            <v-col>
                                <p>
                                    <b>2. Fächer verwalten</b>
                                    <br>
                                    Falls Ihr zu löschender Lehrer in Fächern unterrichtet hat, werden hier nun diese Fächer aufgelistet. Teilen Sie dem Fach einen neuen Fachlehrer zu.
                                </p>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-row class="mx-1">
                                    <v-col
                                        cols="4"
                                        style="color: var(--v-dunkelgrau-base)"
                                    >
                                        Fächer
                                    </v-col>
                                    <v-col
                                        cols="2"
                                        style="color: var(--v-dunkelgrau-base)"
                                    >
                                        Aktueller Lehrer
                                    </v-col>
                                    <v-col cols="1" />
                                    <v-col
                                        cols="2"
                                        style="color: var(--v-dunkelgrau-base)"
                                    >
                                        Zukünftiger Lehrer
                                    </v-col>
                                    <v-col cols="3" />
                                </v-row>
                                <v-row
                                    v-for="(item,index) in deleteSubjectData"
                                    :key="index"
                                    class="mx-1 pt-1"
                                    style="border-bottom: 2px solid #EAEAEA"
                                >
                                    <v-col
                                        cols="5"
                                        class="pl-2 mt-2 basicDeleteTeacherColumn"
                                    >
                                        {{ item.subject }}
                                    </v-col>
                                    <v-col
                                        cols="1"
                                        class="mt-2 basicDeleteTeacherColumn deleteCurrentTeacherDataCol"
                                    >
                                        <img
                                            v-if="profilePic"
                                            :src="profilePic"
                                            alt="Profilsymbol"
                                        >
                                        <img
                                            v-else
                                            :src="getPersonPic(gender)"
                                            alt="Profilsymbol"
                                        >
                                    </v-col>
                                    <v-col cols="1">
                                        <img
                                            :src="rechtsPfeil"
                                            alt="Pfeil nach rechts symbol"
                                            class="ma-auto"
                                            style="width: 20px"
                                        >
                                    </v-col>
                                    <v-col
                                        cols="2"
                                        class="mt-2 basicDeleteTeacherColumn deleteCurrentTeacherDataCol"
                                    >
                                        <img
                                            v-if="item.newTeacherPicture != null"
                                            :src="item.newTeacherPicture"
                                            alt="Profilsymbol"
                                        >
                                        <img
                                            v-else-if="item.newTeacher != null && item.newTeacherPicture == null"
                                            :src="getPersonPic(item.newTeacher.gender)"
                                            alt="Profilsymbol"
                                        >
                                        <v-icon
                                            v-else
                                            style="font-size: 25px; color: grey"
                                        >
                                            fas fa-circle
                                        </v-icon>
                                    </v-col>
                                    <v-col
                                        cols="3"
                                        class="mt-1 basicDeleteTeacherColumn deleteTeacherNewSelection"
                                    >
                                        <v-container id="dropdown-example-1">
                                            <v-select
                                                v-model="deleteSubjectData[index].newTeacher"
                                                solo
                                                :background-color="item.newTeacher != null ? 'success':'error'"
                                                dense
                                                dark
                                                :items="teacherSubjects[index].teachers"
                                                return-object
                                                :item-text="xyz => xyz.name +'  '+ xyz.lastName"
                                                class="deleteTeacherSelect"
                                                @change="changeNewTeacher(deleteSubjectData[index].newTeacher)"
                                            />
                                        </v-container>
                                    </v-col>
                                </v-row>
                                <v-row v-if="!deleteSubjectData.length">
                                    Dieser Lehrer hatte keine Fächer!
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-btn
                                    style="float: right"
                                    color="success"
                                    :disabled="!isDeleteSubjectDataDone"
                                    :dark="isDeleteSubjectDataDone"
                                    @click="printAllChanges"
                                >
                                    Speichern
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                </div>
            </v-card>
        </v-dialog>

        <!--        Delete dialog-->
        <v-dialog
            v-model="deleteDialog"
            persistent
            max-width="400"
        >
            <v-card>
                <v-card-title class="headline">
                    Benutzer löschen?
                </v-card-title>
                <v-card-text>
                    Sind Sie sicher, dass Sie den Benutzer löschen möchten?
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn
                        color=""
                        text
                        @click="deleteDialog = false"
                    >
                        Abbrechen
                    </v-btn>
                    <v-btn
                        color="red red-1"
                        text
                        @click="() => {deleteUser(); deleteDialog = false}"
                    >
                        Löschen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!--create teacher account-->
        <v-dialog
            v-model="dialog"
            persistent
            max-width="400"
        >
            <v-card>
                <v-card-title class="headline">
                    Account speichern?
                </v-card-title>
                <v-card-text>
                    Ein Account wurde mit folgenden Daten angelegt. An erster Stelle sehen Sie das Passwort, welches für den neuen Benutzer generiert wurde. Bitte notieren Sie dieses und händigen Sie es an dem Benutzer aus.

                    <br> <br>
                    Dieses Passwort wird später nicht mehr angezeigt und kann auch nicht wiederhergestellt werden. Im Zweifelsfall müssen Sie dem Benutzer ein neues Passwort zuweisen, wenn er sich nicht mehr einloggen kann.

                    <br> <br>
                    <div class="ml-3">
                        <v-row>
                            Passwort: {{ password }}
                        </v-row>
                        <!--                        <v-row>-->
                        <!--                            QR-Passwort: {{qrPassword}}-->
                        <!--                        </v-row>-->
                        <v-row>
                            Login-ID: {{ userName }}
                        </v-row>
                        <v-row>
                            Vorname: {{ name }}
                        </v-row>
                        <v-row>
                            Nachname: {{ lastName }}
                        </v-row>
                        <v-row>
                            Geschlecht: {{ gender }}
                        </v-row>
                        <v-row>
                            Notiz: {{ note }}
                        </v-row>
                        <v-row
                            class="mt-5"
                            align="center"
                            justify="center"
                        >
                            <div style="width: 200px">
                                <qrcode-vue
                                    v-show="false"
                                    ref="qrcodecontainer"
                                    :value="qrCodeData"
                                    size="200"
                                />
                                <v-row
                                    style="width: 200px; text-align-last: center;"
                                    class="ma-0"
                                >
                                    <v-col
                                        cols="12"
                                        style=""
                                        class="pa-0"
                                    >
                                        <v-btn
                                            class=""
                                            style=""
                                            @click="pdf()"
                                        >
                                            QR Download
                                        </v-btn>
                                    </v-col>
                                </v-row>
                                <!--                                <v-row style="width: 200px; text-align-last: center;" class="ma-0">-->

                                <!--                                    <v-col cols="" class="pa-0 mt-2">-->
                                <!--                                        <v-btn @click="kuhR()" class="" style="">Kuh-R Bastelvorlage</v-btn>-->
                                <!--                                    </v-col>-->
                                <!--                                </v-row>-->
                            </div>
                        </v-row>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn
                        color="green darken-1"
                        dark
                        @click="closeDialogs"
                    >
                        Fertig
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!--edit teacher account with changing password-->
        <v-dialog
            v-model="dialog2"
            persistent
            max-width="400"
        >
            <v-card>
                <v-card-title class="headline">
                    Account speichern?
                </v-card-title>
                <v-card-text>
                    Ein Account wird mit folgenden Daten geändert. An erster Stelle sehen Sie das Passwort, welches für den neuen Benutzer generiert wurde. Bitte notieren Sie dieses und händigen Sie es an dem Benutzer aus.

                    <br> <br>
                    Dieses Passwort wird später nicht mehr angezeigt und kann auch nicht wiederhergestellt werden. Im Zweifelsfall müssen Sie dem Benutzer ein neues Passwort zuweisen, wenn er sich nicht mehr einloggen kann.

                    <br> <br>
                    <div class="ml-3">
                        <v-row>
                            Passwort: {{ password }}
                        </v-row>
                        <!--                        <v-row>-->
                        <!--                            QR-Passwort: {{qrPassword}}-->
                        <!--                        </v-row>-->
                        <v-row>
                            Login-ID: {{ userName }}
                        </v-row>
                        <v-row>
                            Vorname: {{ name }}
                        </v-row>
                        <v-row>
                            Nachname: {{ lastName }}
                        </v-row>
                        <v-row>
                            Geschlecht: {{ gender }}
                        </v-row>
                        <v-row>
                            Notiz: {{ note }}
                        </v-row>
                        <v-row
                            class="mt-5"
                            align="center"
                            justify="center"
                        >
                            <div style="width: 200px">
                                <qrcode-vue
                                    v-show="false"
                                    ref="qrcodecontainer"
                                    :value="qrCodeData"
                                    size="200"
                                />
                                <v-row
                                    style="width: 200px; text-align-last: center;"
                                    class="ma-0"
                                >
                                    <v-col
                                        cols="12"
                                        style=""
                                        class="pa-0"
                                    >
                                        <v-btn
                                            class=""
                                            style=""
                                            @click="pdf()"
                                        >
                                            QR Download
                                        </v-btn>
                                    </v-col>
                                </v-row>
                                <!--                                <v-row style="width: 200px; text-align-last: center;" class="ma-0">-->

                                <!--                                    <v-col cols="" class="pa-0 mt-2">-->
                                <!--                                        <v-btn @click="kuhR()" class="" style="">Kuh-R Bastelvorlage</v-btn>-->
                                <!--                                    </v-col>-->
                                <!--                                </v-row>-->
                            </div>
                        </v-row>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn
                        color="green darken-1"
                        dark
                        @click="closeDialogs"
                    >
                        Fertig
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <qrcode-vue
            v-show="false"
            ref="hiddenqrcodecontainer"
            :value="qrCodeData"
            size="200"
        />

        <input
            id="exelUploadInput"
            ref="exelUploadInput"
            type="file"
            hidden
            @change="() => exelUploadInputChange()"
        >
    </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import suchIcon from '../../assets/Icons/suche-26.svg'
import hochladenIcon from "../../assets/Icons/hochladen-15.svg";
import lehrerIcon from "../../assets/Icons/teacher.svg";
import bearbeitenIcon from "../../assets/Icons/bearbeiten-normal-weiß-41.svg";
import blauesPlusIcon from "../../assets/Icons/blaues-plus.svg";
import infoIcon from "../../assets/Icons/ausrufezeichen-62.svg";
import arrow from "../../assets/Icons/unten-dropdown-12.svg";
import abbrechen from "../../assets/Icons/abbrechen-08.svg";
import manPic from "@/assets/Icons/Mann-85.svg";
import womanPic from "@/assets/Icons/Frau-84.svg";
import diversPic from "@/assets/Icons/Divers-87.svg";
import loeschIcon from '../../assets/Icons/papierkorb-verwaltung-109.svg';
import pdfDocIcon from '../../assets/Icons/PDF-65.svg';
import aktualisierenIcon from '../../assets/Icons/aktualisieren-107.svg'
import rechtsPfeil from '@/assets/Icons/rechts-11.svg';
import pdfFile from '../../assets/PDF/Lehrer-QR-Vorlage.pdf'
import kuhRFile from '../../assets/PDF/Faltkuh.pdf'
import roboto from '../../assets/Fonts/Roboto-Regular.ttf'
import robotoBold from '../../assets/Fonts/Roboto-Bold.ttf'
import QrcodeVue from 'qrcode.vue';

import {PDFDocument, StandardFonts} from 'pdf-lib';
import {compress} from 'shrink-string'
import ProgressBar from "@/components/ProgressBar";
import * as backend from "@/api/backend";
import fontkit from "@pdf-lib/fontkit";
import * as xlsx from 'xlsx';

import JSZip from 'jszip';
import {saveAs} from 'file-saver';

export default {
        name: "Lehrer",
        components: {
            ProgressBar,
            QrcodeVue,
        },
        props: {

        },
        data() {
            return {
                showLoadingOverlay: false,
                newPassword: null,
                oldProfile: null,
                dialog: false,
                dialog2: false,
                dialog3: false,

                deleteTeacherDialog: false,
                deletePagesIndex: 0,
                deleteDialog: false,
                debugMode: false,

                editPassword: false,
                editLoginId: false,

                accountId: null,
                name: '',
                lastName: '',
                gender: '',
                note: '',
                userName: '',
                groups: [],
                teacherGroups: [],
                subjects: [],
                teacherSubjects: [],
                password: '',
                qrPassword: '',
                profilePic: '',
                picFileName: '',
                picFileExtension: '',
                file: null,
                fields: {
                    name: false,
                    lastName: false,
                    gender: false,
                    userName: false,
                    password: false,
                    profilePic: false
                },

                height: 0,
                search: '',
                headers: [
                    { text: 'Name', value: 'lastName' },
                    { text: 'Vorname', value: 'name' },
                    { text: 'Geschlecht', value: 'gender' },
                    { text: '', value: 'name', sortable: false}
                ],
                list: [
                ],
               
                qrCodeData: "{ name: '', pw: '' }",
                deleteGroupData: [],
                deleteSubjectData: [],

                /*Profilepic upload*/
                showProfilepicUploadProgress: false,
                profilepicUploadProgress: 0.0,
                teacherXmlHttpRequest: null,

                roboto,
                robotoBold,
                suchIcon,
                hochladenIcon,
                pdfFile,
                lehrerIcon,
                bearbeitenIcon,
                arrow,
                abbrechen,
                infoIcon,
                diversPic,
                rechtsPfeil,
                loeschIcon,
                pdfDocIcon,
                aktualisierenIcon,
            };
        },
        computed: {
            ...mapGetters("groups", ['getGroupStatus']),
            ...mapGetters("teachers", ['getTeacherStatus']),
            ...mapGetters("subjects", ['getSubjectInvalidStatus']),
            isUsernameFree() {
                for (let i = 0; i < this.list.length; i++) {
                    let teacher = this.list[i];
                    if (this.userName === teacher.accountName) {
                        return false;
                    }
                }

                return true;
            },
            isDeleteGroupDataDone() {
                for (let i = 0; i < this.deleteGroupData.length; i++) {
                    if (!this.deleteGroupData[i].newTeacher) {
                        return false;
                    }
                }
                return true;
            },
            isDeleteSubjectDataDone() {
                for (let i = 0; i < this.deleteSubjectData.length; i++) {
                    if (!this.deleteSubjectData[i].newTeacher) {
                        return false;
                    }
                }
                return true;
            },
        },
        watch: {
            userName: function () {
                this.qrcodeString();
            },
            password: function () {
                this.qrcodeString();
            },
            getGroupStatus(newVal, oldVal) {
                if (newVal && !oldVal) {
                    this.requestGroups();
                }
            },
            getTeacherStatus(newVal, oldVal) {
                if (newVal && !oldVal) {
                    this.requestTeachers();
                }
            },
            getSubjectInvalidStatus(newVal, oldVal) {
                if (newVal && !oldVal) {
                    this.requestSubjects();
                }
            },
        },
        async created() {
            this.height = window.innerHeight * 0.4
        },
        async mounted() {
            this.showLoadingOverlay = true;
            this.makePassword();
            this.requestTeachers();
            this.requestGroups();
            this.requestSubjects();

            if (this.$route.query.debug) {
                this.debugMode = true;
            }
        },
        methods: {
            ...mapActions("teachers", ["createTeacher","getTeachers","editTeacher", "deleteTeacher", "getProfilePicture", 'setTeachersInvalidStatus']),
            ...mapActions("groups", ["getGroup","getGroups", "editGroup", "deleteGroup", 'setGroupInvalidStatus']),
            ...mapActions("subjects", ["getSubjects", 'setSubjectListStatus']),
            ...mapActions("auth", ["isLoginIdAvailable"]),
            ...mapMutations("snackbar", ["showSnackbar"]),

            closeDialogs(){
                this.stopEdit();
                this.makePassword();
                this.dialog2 = false;
                this.dialog3 = false;
                this.dialog = false;
            },
            async exelUploadInputChange() {
                this.showLoadingOverlay = true;
                const input = this.$refs.exelUploadInput;
                const file = input.files[0];
                if (file) {
                    if(file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                        this.showSnackbar({ message: 'Dateiformat nicht zugelassen. Bitte Excel-Datei auswählen! (".xlsx")', color: 'error'});
                        this.showLoadingOverlay = false;
                        return
                    }
                    let reader = new FileReader();
                    reader.readAsArrayBuffer(file);
                    reader.onload = async (e) => {
                        let data = new Uint8Array(reader.result);
                        let workbook = xlsx.read(data, {type: 'array'});
                        let dateArray = [];
                        let failArray = [];
                        workbook.SheetNames.forEach(sheet => {
                            let rowObject = xlsx.utils.sheet_to_json(workbook.Sheets[sheet]);
                            dateArray = rowObject;
                        });

                        dateArray.forEach((el) => {
                            if(!el.vorname || !el.nachname || !(el.geschlecht === 'w' || el.geschlecht === 'm' || el.geschlecht === 'd')) {
                                el['fehler'] = "Daten unvollständig oder fehlerhaft!";
                                failArray.push(el)
                            }
                        });
                        dateArray = dateArray.filter(el => !el.fehler);
                        let zip = new  JSZip();
                        const sleep = ms => new Promise(resolve => setTimeout(resolve,ms));
                        for(let x = 0; x < dateArray.length; x++) {
                            // await sleep(500);
                            let data = {
                                name: dateArray[x].vorname,
                                lastName: dateArray[x].nachname,
                                gender: dateArray[x].geschlecht,
                                note: '',
                                accountName: dateArray[x].loginid ? dateArray[x].loginid : ((dateArray[x].vorname.trim() + dateArray[x].nachname.trim().slice(0, 1)).toLowerCase()),
                                babyView: false,
                                password: this.password,
                                qrPassword: this.qrPassword,
                            };

                            let response = await this.createTeacher(data);
                            if(Number.isInteger(response)) {
                                if(response === 409) {
                                    dateArray[x]['fehler'] = "Login-ID schon belegt"
                                    failArray.push(dateArray[x])
                                } else {
                                    dateArray[x]['fehler'] = "Ein unerwarteter Fehler ist aufgetretten!";
                                    failArray.push(dateArray[x])
                                }
                            } else {
                                const compressedName = await compress(data.accountName);
                                const compressedPW = await compress(this.password);
                                const compressedString = JSON.stringify({name: compressedName, pw: compressedPW});
                                this.qrCodeData = compressedString;

                                const pdfName = dateArray[x]["__rowNum__"] + "_" + (dateArray[x].loginid ? dateArray[x].loginid : ((dateArray[x].vorname.trim() + dateArray[x].nachname.trim().slice(0, 1)).toLowerCase())) + '.pdf';
                                zip.file(pdfName , await this.getPDFBytes(data));

                                this.list.push(response);
                                this.requestTeachers();
                                this.setTeachersInvalidStatus(true);

                                this.stopEdit();
                                this.makePassword();
                            }
                        }
                        if(failArray.length > 0) {
                            let blob = new Blob([JSON.stringify(failArray)], {type: "text/plain;charset=utf-8"});

                            zip.file('Fehler.txt', blob);
                        }
                        zip.generateAsync({type:"blob"})
                            .then(function(content) {
                                // see FileSaver.js
                                saveAs(content, "Daten.zip");
                            });
                        this.showLoadingOverlay = false;
                    };

                }
            },
            clickExelUploadButton() {
                document.getElementById("exelUploadInput").click();
            },

            printAllChanges(){
                // TODO: why is this empty
            },
            clickProfilepicUploadButton() {
                this.$refs.profilepicUploadInput.click();
            },
            containsUsernameStrangeLetters(username) {
                // eslint-disable-next-line no-useless-escape
                const format = /[!@#$äüö%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
                return format.test(username);
            },

            async teacherUploadInputChange() {
                const input = this.$refs.profilepicUploadInput;
                this.file = input.files[0];
                if (this.file) {
                    this.validateInput();
                    const name = this.file.name;
                    const lastDot = name.lastIndexOf(".");
                    this.picFileName = name.substring(0, lastDot);
                    this.picFileExtension = name.substring(lastDot + 1);

                    let reader = new FileReader();
                    reader.onload = function (e) {
                        if(document.getElementById("profilePicPlaceholder") !== null){
                            document.getElementById("profilePicPlaceholder").src = e.target.result;
                        }
                        if(document.getElementById("profilePic") !== null){
                            document.getElementById("profilePic").src = e.target.result;
                            // this.profilePic = e.target.result;
                        }

                    };
                    reader.readAsDataURL(this.file);

                }
            },

            async saveUser() {
                try {
                    this.showLoadingOverlay = true;
                    let teacherId;

                    let data = {
                        name: this.name,
                        lastName: this.lastName,
                        gender: this.gender,
                        note: this.note,
                        accountName: this.userName,
                        password: this.password,
                        qrPassword: this.qrPassword,
                    };

                    let response = await this.createTeacher(data);
                    if (Number.isInteger(response)) {
                        if(response === 409) {
                            this.showSnackbar({ message: 'Login-ID bereits vergeben. Bitte geben Sie eine andere Login-ID ein!', color: 'error'});
                        } else {
                            this.showSnackbar({ message: 'Etwas ist schiefgelaufen - Bitte aktualisieren Sie die Seite!', color: 'error'});
                        }
                    } else {
                        response['accountName'] = this.userName;
                        this.list.push(response);

                        // Requesting Teachers again and setting invalid flag for other components
                        this.requestTeachers(true);
                        this.setTeachersInvalidStatus(true);

                        //this.stopEdit();
                        this.showSnackbar({ message: 'Gespeichert!'});

                        teacherId = response._id;
                    }

                    if (this.picFileName) {
                        await this.uploadFile(teacherId, this.picFileName, this.picFileExtension);
                    }
                    this.showLoadingOverlay = false;
                } catch (e) {
                    console.error(e);
                    this.showLoadingOverlay = false;
                }
            },

            validateInput() {
                let result = true;

                this.fields.name = false;
                this.fields.lastName = false;
                this.fields.gender = false;
                this.fields.userName = false;
                this.fields.profilePic = false;

                if (this.containsNameNumbers(this.name)) {
                    this.fields.name = true;
                    result = false;
                }

                if (this.lastName.replace(/\s/g, '') === '' || this.containsNameNumbers(this.lastName)) {
                    this.fields.lastName = true;
                    result = false;
                }

                if (this.gender.replace(/\s/g, '') === '') {
                    this.fields.gender = true;
                    result = false;
                }

                if (this.userName.replace(/\s/g, '') === '' || (!this.isUsernameFree && !this.oldProfile)
                    || this.containsUsernameStrangeLetters(this.userName) || this.userName.trim().indexOf(" ") > -1) {
                    this.fields.userName = true;
                    result = false;
                }

                if (this.file && this.file.size > 5000000) {
                    this.fields.profilePic = true;
                    result  = false;

                    this.showSnackbar({ message: "Datei zu groß, max. 5 MB" });
                }

                if (this.editPassword) {
                    this.fields.password = false;
                    if (!this.checkPw()) {
                        this.fields.password = true;
                        result = false;
                    }
                }

                return result;
            },
            containsNameNumbers(name) {
                // eslint-disable-next-line no-useless-escape
                const format = /[0-9]+/;
                return format.test(name);
            },

            async uploadFile(teacherId, newFileName, fileExtension) {
                this.file.originalname = this.file.name;
                if (this.file) {
                    const formData = new FormData();
                    formData.append("file", this.file, `${newFileName}.${fileExtension}`);

                    this.teacherXmlHttpRequest = backend.postTeacherPicture(
                        teacherId,
                        this.file
                    );

                    this.teacherXmlHttpRequest.onerror = (e) => {
                        console.error("Teacher upload error:", e);
                        this.showTeacherUploadProgress = false;
                        this.teacherXmlHttpRequest = null;
                        this.$refs.profilepicUploadInput.value = "";
                    };

                    this.teacherXmlHttpRequest.onabort = (e) => {
                        console.warn("Teacher upload aborted");
                        this.showTeacherUploadProgress = false;
                        this.teacherXmlHttpRequest = null;
                        this.$refs.profilepicUploadInput.value = "";
                    };

                    this.teacherXmlHttpRequest.upload.addEventListener("progress", (e) => {
                        this.teacherUploadProgress = (e.loaded / e.total) * 100;
                    });

                    this.teacherXmlHttpRequest.addEventListener("load", (e) => {
                        if (this.teacherXmlHttpRequest.status !== 201)
                            console.error(
                                "Teacher upload failed:",
                                this.teacherXmlHttpRequest.response
                            );
                        this.showTeacherUploadProgress = false;
                        this.showSnackbar({ message: 'Gespeichert!'});
                        this.teacherXmlHttpRequest = null;
                        this.$refs.profilepicUploadInput.value = "";
                    });

                    this.teacherUploadProgress = 0.0;
                    this.showTeacherUploadProgress = true;
                    this.teacherXmlHttpRequest.send(formData);
                }
            },

            async openDialog() {
                if (this.editPassword) {
                    this.newPassword = this.password;
                }

                if (!this.validateInput()) {
                    this.showSnackbar({ message: 'Bitte füllen Sie alle benötigten Felder aus!', color: 'error'});
                    return;
                }

                if (!this.oldProfile) {
                    this.saveUser();
                    this.dialog = true;
                } else if (!this.newPassword) {
                    await this.patchUser();
                    this.stopEdit();
                } else {
                    this.patchUser();
                    this.dialog2 = true;
                }
            },

            stopEdit() {
                this.oldProfile = null;
                this.name = '';
                this.lastName = '';
                this.gender = '';
                this.note = '';
                this.userName = '';
                this.qrPassword = '';
                this.$refs.profilepicUploadInput.value = "";
                this.makePassword();
                this.profilePic = '';
                this.teacherGroups = [];
                this.teacherSubjects = [];
                if(document.getElementById("profilePicPlaceholder") !== null){
                    document.getElementById("profilePicPlaceholder").src = this.diversPic;
                }

                this.fields.name = false;
                this.fields.lastName = false;
                this.fields.gender = false;
                this.fields.userName = false;
                this.fields.password = false;
                this.fields.profilePic = false;

                this.picFileName = '';
                this.picFileExtension = '';

                this.editPassword = false;
                this.editLoginId = false;
            },

            async editUser(profile) {
                this.showLoadingOverlay = true;
                this.$vuetify.goTo('#edit');
                this.profilePic = '';
                this.oldProfile = profile;
                this.accountId = profile.account;
                this.name = profile.name;
                this.lastName = profile.lastName;
                this.gender = profile.gender;
                this.note = profile.note;
                this.userName = profile.accountName;
                this.qrPassword = profile.qrPassword || this.qrPassword;
                this.newPassword = null;
                this.teacherGroups = this.getTeachersGroups(profile);
                this.teacherSubjects = this.getTeachersSubjects(profile);
                if (this.oldProfile.profilePicture) {
                    this.profilePic = await this.getTeacherPic(this.oldProfile._id);
                }
                this.showLoadingOverlay = false;

                this.editPassword = false;
                this.editLoginId = false;
            },

            async deleteUser() {
                try {
                    this.showLoadingOverlay = true;
                    let response = await this.deleteTeacher(this.oldProfile._id);
                    this.deleteTeacherDialog = false;
                    if (response) {
                        const index = this.list.indexOf(this.oldProfile);
                        if (index > -1) {
                            this.list.splice(index, 1);
                        }

                        this.requestTeachers(true);
                        this.setTeachersInvalidStatus(true);

                        this.stopEdit();
                        this.makePassword();
                        this.showSnackbar({ message: 'Gespeichert!'});
                    } else {
                        this.showSnackbar({ message: 'Etwas ist schiefgelaufen - Bitte aktualisieren Sie die Seite!', color: 'error'});
                    }
                    this.showLoadingOverlay = false;
                } catch (e) {
                    console.error(e);
                    this.showLoadingOverlay = false;
                }
            },
            async pdf() {
                const existingPdfBytes = await fetch(this.pdfFile).then(res => res.arrayBuffer());

                const qrCode = this.$refs.qrcodecontainer.$refs['qrcode-vue'].toDataURL("image/png");

                // Load a PDFDocument from the existing PDF bytes
                const pdfDoc = await PDFDocument.load(existingPdfBytes);

                pdfDoc.registerFontkit(fontkit);

                const pngImage = await pdfDoc.embedPng(qrCode);

                const robotoBytes = await fetch(this.roboto).then((res) => res.arrayBuffer());
                const robotoBoldBytes = await fetch(this.robotoBold).then((res) => res.arrayBuffer());

                const robotoFont = await pdfDoc.embedFont(robotoBytes);
                const robotoBoldFont = await pdfDoc.embedFont(robotoBoldBytes);

                // Get the first page of the document
                const pages = pdfDoc.getPages();
                const firstPage = pages[0];
                const form = pdfDoc.getForm();
                const button = form.createButton('some.button.field');

                // Get the width and height of the first page
                const { width, height } = firstPage.getSize();

                firstPage.drawText(this.name + ' ' + this.lastName, {
                    x: 100.7,
                    y: 670,
                    size: 11,
                    font: robotoFont,
                });

                // Draw a string of text diagonally across the first page
                firstPage.drawText(this.lastName + ',', {
                    x: 235,
                    y: 536,
                    size: 11,
                    font: robotoFont,
                });

                firstPage.drawText(this.userName, {
                    x: 100.7,
                    y: 412,
                    size: 11,
                    font: robotoBoldFont,
                });

                firstPage.drawText(this.password, {
                    x: 100.7,
                    y: 368,
                    size: 11,
                    font: robotoBoldFont,
                });

                firstPage.drawText(window.location.hostname, {
                    x: 100.7,
                    y: 325,
                    size: 11,
                    font: robotoBoldFont,
                });

                //QR1
                firstPage.drawImage(pngImage, {
                    x: 47,
                    y: 56,
                    width: 70,
                    height: 70,
                });

                firstPage.drawText('Nutzername', {
                    x: 200,
                    y: 125,
                    size: 10,
                    font: robotoFont,
                });

                firstPage.drawText(this.userName, {
                    x: 200,
                    y: 110,
                    size: 10,
                    font: robotoBoldFont,
                });

                firstPage.drawText("Passwort", {
                    x: 200,
                    y: 90,
                    size: 10,
                    font: robotoFont,
                });

                firstPage.drawText(this.password, {
                    x: 200,
                    y: 75,
                    size: 10,
                    font: robotoBoldFont,
                });

                firstPage.drawText("Internetseite", {
                    x: 42,
                    y: 33,
                    size: 10,
                    font: robotoFont,
                });

                firstPage.drawText(window.location.hostname, {
                    x: 102,
                    y: 33,
                    size: 10,
                    font: robotoBoldFont,
                });

                //QR2
                firstPage.drawImage(pngImage, {
                    x: 330,
                    y: 56,
                    width: 70,
                    height: 70,
                });

                firstPage.drawText('Nutzername', {
                    x: 482,
                    y: 125,
                    size: 10,
                    font: robotoFont,
                });

                firstPage.drawText(this.userName, {
                    x: 482,
                    y: 110,
                    size: 10,
                    font: robotoBoldFont,
                });

                firstPage.drawText("Passwort", {
                    x: 482,
                    y: 90,
                    size: 10,
                    font: robotoFont,
                });

                firstPage.drawText(this.password, {
                    x: 482,
                    y: 75,
                    size: 10,
                    font: robotoBoldFont,
                });

                firstPage.drawText("Internetseite", {
                    x: 325,
                    y: 33,
                    size: 10,
                    font: robotoFont,
                });

                firstPage.drawText(window.location.hostname, {
                    x: 385,
                    y: 33,
                    size: 10,
                    font: robotoBoldFont,
                });

                // Serialize the PDFDocument to bytes (a Uint8Array)
                const pdfBytes = await pdfDoc.save();

                this.saveByteArray( this.userName + '.pdf',pdfBytes)
            },

            async getPDFBytes(data) {
                const existingPdfBytes = await fetch(this.pdfFile).then(res => res.arrayBuffer());

                const qrCode = this.$refs.hiddenqrcodecontainer.$refs['qrcode-vue'].toDataURL("image/png");

                // Load a PDFDocument from the existing PDF bytes
                const pdfDoc = await PDFDocument.load(existingPdfBytes);

                pdfDoc.registerFontkit(fontkit);

                const pngImage = await pdfDoc.embedPng(qrCode);

                const robotoBytes = await fetch(this.roboto).then((res) => res.arrayBuffer());
                const robotoBoldBytes = await fetch(this.robotoBold).then((res) => res.arrayBuffer());

                const robotoFont = await pdfDoc.embedFont(robotoBytes);
                const robotoBoldFont = await pdfDoc.embedFont(robotoBoldBytes);

                // Get the first page of the document
                const pages = pdfDoc.getPages();
                const firstPage = pages[0];
                const form = pdfDoc.getForm()
                const button = form.createButton('some.button.field')

                // Get the width and height of the first page
                const { width, height } = firstPage.getSize();

                // Draw a string of text diagonally across the first page
                firstPage.drawText(data.lastName + ',', {
                    x: 235,
                    y: 536,
                    size: 11,
                    font: robotoFont,
                });

                firstPage.drawText(data.accountName, {
                    x: 100.7,
                    y: 412,
                    size: 11,
                    font: robotoBoldFont,
                });

                firstPage.drawText(data.password, {
                    x: 100.7,
                    y: 368,
                    size: 11,
                    font: robotoBoldFont,
                });

                firstPage.drawText(window.location.hostname, {
                    x: 100.7,
                    y: 325,
                    size: 11,
                    font: robotoBoldFont,
                });

                //QR1
                firstPage.drawImage(pngImage, {
                    x: 47,
                    y: 56,
                    width: 70,
                    height: 70,
                });

                firstPage.drawText('Nutzername', {
                    x: 200,
                    y: 125,
                    size: 10,
                    font: robotoFont,
                });

                firstPage.drawText(data.accountName, {
                    x: 200,
                    y: 110,
                    size: 10,
                    font: robotoBoldFont,
                });

                firstPage.drawText("Passwort", {
                    x: 200,
                    y: 90,
                    size: 10,
                    font: robotoFont,
                });

                firstPage.drawText(data.password, {
                    x: 200,
                    y: 75,
                    size: 10,
                    font: robotoBoldFont,
                });

                firstPage.drawText("Internetseite", {
                    x: 42,
                    y: 33,
                    size: 10,
                    font: robotoFont,
                });

                firstPage.drawText(window.location.hostname, {
                    x: 102,
                    y: 33,
                    size: 10,
                    font: robotoBoldFont,
                });

                //QR2
                firstPage.drawImage(pngImage, {
                    x: 330,
                    y: 56,
                    width: 70,
                    height: 70,
                });

                firstPage.drawText('Nutzername', {
                    x: 482,
                    y: 125,
                    size: 10,
                    font: robotoFont,
                });

                firstPage.drawText(data.accountName, {
                    x: 482,
                    y: 110,
                    size: 10,
                    font: robotoBoldFont,
                });

                firstPage.drawText("Passwort", {
                    x: 482,
                    y: 90,
                    size: 10,
                    font: robotoFont,
                });

                firstPage.drawText(data.password, {
                    x: 482,
                    y: 75,
                    size: 10,
                    font: robotoBoldFont,
                });

                firstPage.drawText("Internetseite", {
                    x: 325,
                    y: 33,
                    size: 10,
                    font: robotoFont,
                });

                firstPage.drawText(window.location.hostname, {
                    x: 385,
                    y: 33,
                    size: 10,
                    font: robotoBoldFont,
                });

                // Serialize the PDFDocument to bytes (a Uint8Array)
                const pdfBytes = await pdfDoc.save();

                return pdfBytes;
            },

            saveByteArray(reportName, byte) {
                let blob = new Blob([byte], {type: "application/pdf"});
                let link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                let fileName = reportName;
                link.download = fileName;
                link.click();
            },

            async patchUser() {
                try {
                    this.showLoadingOverlay = true;
                    const teacherPatchBody = {
                        _id: this.oldProfile._id,
                        name: this.name,
                        lastName: this.lastName,
                        gender: this.gender,
                        note: this.note,
                    };
                    const accountPatchBody = {
                        ...(this.newPassword ? { 'password' : this.newPassword } : {}),
                        ...(this.newPassword ? { 'qrPassword' : this.qrPassword } : {}),
                        note: this.note,
                    }

                    const accountPatchRes = await backend.patchAccount(this.accountId, accountPatchBody);
                    let response = await this.editTeacher(teacherPatchBody);
                    if (Number.isInteger(response) || accountPatchRes.status !== 200) {
                        if (response === 409) {
                            this.showSnackbar({ message: 'Login-ID bereits vergeben. Bitte geben Sie einen andere Login-ID ein!', color: 'error'});
                        } else {
                            this.showSnackbar({ message: 'Etwas ist schiefgelaufen - Bitte aktualisieren Sie die Seite!', color: 'error'});
                        }
                    } else {
                        if (this.picFileName) {
                            await this.uploadFile(this.oldProfile._id, this.picFileName, this.picFileExtension);
                        }

                        response['accountName'] = this.userName;

                        const index = this.list.indexOf(this.oldProfile);
                        if (index > -1) {
                            this.list.splice(index, 1);
                        }
                        this.list.push(response);

                        // reuqesting Teachers and setting invalid flag for other components
                        this.requestTeachers(true);
                        this.setTeachersInvalidStatus(true);

                        this.showSnackbar({ message: 'Gespeichert!'});
                    }
                    this.showLoadingOverlay = false;
                } catch (e) {
                    console.error(e);
                    this.showLoadingOverlay = false;
                }
            },

            async requestTeachers(update = false) {
                this.showLoadingOverlay = true;
                this.list = await this.getTeachers(update);
                this.showLoadingOverlay = false;
                this.setTeachersInvalidStatus(false);
            },
            async requestGroups() {
                this.groups = [];
                this.teacherGroups = [];
                this.groups = await this.getGroups();
                this.setGroupInvalidStatus(false);
            },

            async requestSubjects() {
                this.subjects = [];
                this.subjects = await this.getSubjects();
                this.setSubjectListStatus(false);
            },
            getTeachersGroups(profile){

                let temp = this.groups.filter(elem => elem.lead === profile._id);
                return temp;
            },
            getTeachersSubjects(profile){
                let temp =[];
                this.subjects.forEach(element => {
                    if(element.teachers.find(elem => elem._id === profile._id)){
                        temp.push(element);
                    }
                });

                // let temp = this.subjects.forEach(element => element.teachers.filter(elem => elem._id === profile._id));
                return temp;
            },
            async openDeleteDialog(){
                this.deleteTeacherDialog = true;
                // let oldTeacherPicture = await this.getTeacherPic(this.oldProfile._id);

                if (this.oldProfile.profilePicture) {
                    this.profilePic = await this.getTeacherPic(this.oldProfile._id);
                }

                this.deleteGroupData = [];
                this.deleteSubjectData = [];

                this.teacherGroups.forEach(elem => {
                    let temp = {
                        groupName: elem.name,
                        groupId: elem._id,
                        groupParticipants: elem.participants,
                        currentName: this.name,
                        currentLastName: this.lastName,
                        currentFullName: this.name + ' '+ this.lastName,
                        currentId: this.oldProfile._id,
                        newTeacher: null,
                        currentTeacherPicture: null,
                        newTeacherPicture: null,
                    };
                    this.deleteGroupData.push(temp);
                });

                this.teacherSubjects.forEach(elem => {
                    let temp = {
                        subject: elem.name,
                        subjectId: elem._id,
                        currentName: this.name,
                        currentLastName: this.lastName,
                        currentFullName: this.name + ' '+ this.lastName,
                        currentId: this.oldProfile._id,
                        possibleTeachers: elem.teachers,
                        newTeacher: null,
                        currentTeacherPicture: this.profilePic,
                        newTeacherPicture: null,
                    };
                    this.deleteSubjectData.push(temp)
                })

            },
            async changeNewTeacher(item) {
                let newTeacherPic = await this.getTeacherPic(item._id);
                item.newTeacherPicture = newTeacherPic;
            },
            makePassword() {
                let result = '';
                let qrResult = '';
                let allCharacters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
                let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
                let numbers = '0123456789';
                let numberCount = Math.floor(Math.random()*(6)+1);

                for ( let i = 0; i < 8 - numberCount; i++ ) {
                    result += characters.charAt(Math.floor(Math.random() * characters.length));
                }
                for ( let i = 0; i < numberCount; i++ ) {
                    result += numbers.charAt(Math.floor(Math.random() * numbers.length));
                }
                for ( let i = 0; i < 16; i++ ) {
                    qrResult += allCharacters.charAt(Math.floor(Math.random() * allCharacters.length));
                }
                this.password = result;
                this.newPassword = result;
                this.qrPassword = qrResult;
            },
            async qrcodeString() {
                const compressedName = await compress(this.userName);
                const compressedPW = await compress(this.qrPassword);

                const compressedString = JSON.stringify({name: compressedName, pw: compressedPW});

                this.qrCodeData = compressedString;
            },
            //if there is an uploaded profile picture for teacher
            async getTeacherPic(id) {
                try {
                    let profilePictureBlob = await this.getProfilePicture(id);
                    return URL.createObjectURL(profilePictureBlob);
                } catch (e) {
                    console.error(e);
                }
            },

            //else get default profile picture by gender
            getPersonPic(gender) {
                try {
                    switch (gender) {
                        case "m": return manPic;
                        case "w": return womanPic;
                        case "d": return diversPic;
                    }
                } catch (e) {
                    console.error(e);
                }
            },
            async suggestLoginId(oldProfile) {
                if (!this.editLoginId && !oldProfile) {
                    let id = this.name.slice(0,1).toLowerCase() + this.lastName.toLowerCase();
                    let data = {
                        userName: id,
                    };
                    let available = await this.isLoginIdAvailable(data);

                    if (available) {
                        this.userName = id;
                    } else {
                        let i = 1;
                        this.userName = id + i;
                        for (let i = 1; !available; i++) {
                            id = this.name.toLowerCase() + this.lastName.slice(0,1).toLowerCase() + i;
                            data = {
                                userName: id,
                            };
                            available = await this.isLoginIdAvailable(data);
                            this.userName = id;
                        }
                    }
                }
                this.validateInput();
            },
            //checks if password is at least of length 8 and contains one letter and one number
            checkPw() {
                const pwCheck = new RegExp("^(?=.*[a-zA-Z])(?=.*[0-9])(?=.{8,})");
                return pwCheck.test(this.password);
            }
        },
    };
</script>

<style lang="scss" scoped>
    table, th, td {
        border: 1px solid black;
        border-collapse: collapse;
    }

    tr {
        height: 40px;
    }

    table tr th,
    table tr td {
        width: 9em;
        border: none;
    }
    table, tr, td {
        border: none;
    }

    input[type="date"] {
        -webkit-appearance: textfield;
        -moz-appearance: textfield;
        min-height: 1.6rem;
    }

    .input{
        display: block;
        width: 100%;
        max-width: 100%;
        border-radius: 5px;
        border-width:0;
        color: black;
        padding: 1px 5px 1px 5px;
        border: none !important;
        background-color: var(--v-hellgrau-base);
        overflow: hidden;
        appearance:none
    }

    .errorInput {
        border: 2px solid red !important;
    }

    .input[disabled]{
        color: grey;
        background-color: #CACACA;
    }

    .editContainer {
        min-height: 40vh;
        height: fit-content;
        width: 90%;
        border-radius: 15px;
        box-shadow: 1px 5px 5px silver;
        margin: auto;
        margin-bottom: 5em
    }

    .mainControlButtons {
        font-weight: 300;
        font-size: 10px;
        border-radius: 8px;

        & span {
            line-height: 0 !important;
        }
    }

    .mainFunctionButtons {
        width: 38px !important;
        height: 38px !important;
        border-radius: 8px;

    img {
        width: 27px !important;
        height: 27px !important;
        filter: brightness(1000%);
    }
    }

    .profilePicture {
        /*display: block;*/
        overflow: hidden;
        height: 41px;
        width: 41px;
    }

    .profilePicture img {
        display: block; /* Otherwise it keeps some space around baseline */
        min-width: 100%;    /* Scale up to fill container width */
        min-height: 100%;   /* Scale up to fill container height */
        -ms-interpolation-mode: bicubic; /* Scaled images look a bit better in IE now */
    }

    .btnStyle {
        min-width: 36px;
        min-height: 36px;
        margin-top: -3px;
    }
    .generatePasswordButton {
        max-height: 25px;
        max-width: 100%;
        width: 100%;
        text-transform: inherit;
        border-radius: 8px;

    img {
        /*filter: brightness(10%);*/
        width: 15px;
        margin-right: 5px;
        margin-top: -2px;
    }
    }

    .basicDeleteTeacherColumn {
        display: inline-flex;
        height: 35px;
        padding: 2px;
        /*border: 1px solid black;*/
    }

    .deleteCurrentTeacherDataCol {

        & img {
            height: 25px;
            width: 25px;
            margin-right: 5px;
            margin-left: 5px;
        }
    }

    .deleteTeacherNewSelection {
        padding: 0;

        & .container {
            padding: 0;
            position: relative;

            & img {
                position: absolute;
                right: 0;
                top: 2px;
                filter: brightness(1000%);
                transform: rotate(90deg);
                width: 35px !important;
            }
        }
    }

    .deleteTeacherSelect {
        height: 35px !important;
        /*max-width: 80px !important;*/
    }

    .replaceTeacherControls {
        background-color: rgba(248,248,248,0.4) !important;
        width: 35px !important;
        height: 35px !important;
        border-radius: 8px;

        & img {
            width: 24px !important;
            filter: brightness(1000%);
        }
    }

    .infoTextToolTip {
        max-width: 250px;
        background-color: var(--v-blau-base);
    }

    .editTeacherButton {
        border-radius: 5px;
        text-transform: inherit;

        img {
            filter: brightness(10%);
            width: 15px;
            margin-right: 5px;
            margin-top: -2px;
        }
    }
</style>
