<template>
    <v-overlay v-if="!hasAcceptedAGB">
        <div
            id="datenschutzAbfrageOuter"
            class="mx-auto"
        >
            <v-card
                color="white"
                style="color: black;"
            >
                <v-card-title style="font-size: x-large">
                    <b style="margin-right: 5px">Einverständniserklärung</b> Nutzungsbedingungen
                </v-card-title>
                <v-card-text
                    style="color: black;"
                >
                    Die Schulanwendung „eKlassenraum“ kurz EKLARA wird auf DE-Schulservern betrieben, ist über eine eindeutige online Schul-Website-URL erreichbar und soll den sicheren, interaktiven, digitalen und papierlosen Fernunterricht ermöglichen. EKLARA wurde in Kooperation mit Schulen in Heilbronn als Initiative entwickelt und wird aktuell an verschiedenen Bildungseinrichtungen eingesetzt, erprobt und stetig optimiert. Der Fernunterricht über den eKlassenraum soll nicht den herkömmlichen Unterricht vor Ort mit Büchern, Arbeitsblättern oder mit Lernpaketen in Papierform ersetzen, sondern sinnvoll ergänzen.
                </v-card-text>
                <v-card-text style="color: black;">
                    Der eKlassenraum wird von der Schule eigenständig betrieben. Die Nutzung der zahlreichen Funktionen (Videokonferenz, Chat, digitale Hausaufgaben, Ablagen, Datenaustausch) erfordert lediglich einen Nutzernamen. Zur einfachen Identifizierung und Betreuung der Schüler wird die Verwendung des Vornamens und ggf. ein Nachnamenskürzel empfohlen. Jeder Schüler erhält nach Einrichtung einen verschlüsselten Zugang in Papierform/ QR-Schulausweis – ein
                    <b>Nutzername</b> und ein
                    <b>Passwort</b>. Die Zugangsdaten sind vertraulich aufzubewahren und vor unbefugten Zugriffen Dritter zu schützen. Der eKlassenraum ist keine herkömmliche App und wird zwecks einfacher Zugänglichkeit über unterschiedliche Endgeräte direkt über einen Internetbrowser - Safari, Chrome und Firefox – eines Smartphones, Tablets oder PCs genutzt. Während der Video- oder Sprachübertragung ist bspw. das Tragen von geschlossenen Kopfhörern + Mikrofon in einer neutralen physischen Umgebung im Hintergrund ohne weiterer Personen und Geräusche, persönlicher zu schützender Gegenstände o.ä. zu empfehlen. Die Privatsphäre (über geteilte Video-, Audio-, Dateninhalte) der Schüler, Lehrer, Eltern, Nutzer o.ä. ist in Eigenverantwortung soweit wie möglich zu schützen und zu tragen.
                </v-card-text>
                <v-card-text style="color: black;">
                    Für Fragen zum eKlassenraum wenden Sie sich bitte direkt per Mail an die Verwaltung ihrer Bildungseinrichtung. Für technische spezielle Fragen außerhalb der Bildungseinrichtung kontaktieren Sie das eKlassenraum-Team unter
                    <a href="mailto:betreuung@eklassenraum.de">betreuung@eklassenraum.de</a>.
                </v-card-text>
                <v-card-text style="color: black;">
                    Datenschutzbedingungen
                </v-card-text>
                <v-card-text style="color: black;">
                    Es liegt eine Vereinbarung über eine Auftragsdatenverarbeitung nach Art. 28 der Europäischen Datenschutz-Grundverordnung (EU-DSGVO) mit dem Auftragsdatenverarbeiter, hier eklassenraum EKLARA von concept hero UG mit Sitz in Heilbronn vor. Der Auftragsdatenverarbeiter unterstützt die Betreuung der Bildungseinrichtung im Auftrag des Stadtträgers oder Landes und trifft alle technischen und organisatorischen Maßnahmen zur angemessenen Datensicherung und Funktionssicherstellung von EKLARA. Er schützt jegliche Daten vor Missbrauch und Verlust, die den datenschutzrechtlichen Anforderungen (Art. 32 EU-DSGVO, Art. 4 Nr. 7 EU-DSGVO) entsprechen. Verarbeitete personenbezogene Daten finden ausschließlich im Rahmen der getroffenen Betreuungsvereinbarungen Einsatz. Als Videokommunikationstechnologie wird das von der Bundesregierung als sicher eingestufte BigBlueButton genutzt. Der Übersetzungsservice wird über die Google Translate Schnittstelle ohne Übertragung personenbezogener Daten realisiert. Übertragene zu übersetzende Textinhalte werden nach einer Frist von maximal 14 Tagen gelöscht. Alle eingesetzten Systeme inkl. Nutzerauthentifizierung nutzten das sichere HTTPS Protokoll. Die Sicherstellung von Daten auf den Servern vor Dritten und die Haftung der Betriebsbereitschaft der Server unter Einhaltung der örtlichen Datenschutzrichtlinien obliegt der alleinigen Verantwortung der Hosting-Provider Hetzner Online GmbH (
                    <a
                        href="https://www.hetzner.com/de/rechtliches/agb/"
                        target="_blank"
                    >https://www.hetzner.com/de/rechtliches/agb/</a>) und Contabo GmbH (
                    <a
                        href="https://contabo.com/de/legal/terms-and-conditions/"
                        target="_blank"
                    >https://contabo.com/de/legal/terms-and-conditions/</a>). Beide Provider erbringen ihre Leistungen im Einklang mit der EU-Verordnung (Datenschutz-Grundverordnung), dem Bundesdatenschutzgesetz (BDSG), den Datenschutzgesetzen der Länder sowie dem Telemediengesetz (TMG). Standorte der Server: München, Nürnberg in Deutschland.
                </v-card-text>
                <v-card-text
                    style="color: black;"
                >
                    Hiermit und durch meine Einwilligung, Fortnutzung von EKLARA bin ich mit den Nutzungsbedingungen einverstanden.
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn
                        color="#e6231e"
                        @click="declineDatasecurity"
                    >
                        Ablehnen
                    </v-btn>
                    <v-btn
                        color="#3caa69"
                        @click="acceptDatasecurity"
                    >
                        Akzeptieren
                    </v-btn>
                </v-card-actions>
            </v-card>
        </div>
    </v-overlay>
</template>

<script>
import { mapActions } from "vuex";

export default {
    name: "Datenschutz",
    components: {},
    props: {
        hasAcceptedAGB: { required: true, default: false }
    },
    data: () => ({
    }),
    methods: {
        ...mapActions('auth', ['logoutUser']),
        ...mapActions('auth', ['acceptTermsOfService']),

        async declineDatasecurity() {
            this.$emit('declinedAGB');
        },
        async acceptDatasecurity() {
            this.acceptTermsOfService();
        }
    },

}
</script>

<style scoped lang="scss">
#datenschutzAbfrageOuter {
    overflow-y: auto;
    width: 75% !important;
    max-height: 80vh !important;
    justify-content: center;
}
</style>
