<template>
    <v-dialog
        v-model="showDialog"
        overlay-opacity="0.7"
        :persistent="true"
        width="500"
    >
        <v-card
            id="card"
            class="pa-3"
        >
            <v-card-title>
                <v-container class="pa-0">
                    <v-row>
                        <v-col>
                            <h1 tabindex="0">
                                Dateiname
                            </h1>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-title>
            <v-card-text class="pb-0">
                <v-container class="pa-0">
                    <v-row
                        align="center"
                        justify="center"
                    >
                        <v-col>
                            <v-text-field
                                v-model="newFileName"
                                label="Dateiname"
                            />
                        </v-col>
                        <v-col cols="auto">
                            <v-btn
                                elevation="2"
                                x-small
                                class="pa-0 mb-2"
                                style="width: 30px; height: 30px"
                                color="secondary"
                                :aria-label="'Datei ' + newFileName + ' hochladen bestätigen'"
                                @click="() => {callback(newFileName, selectedUser, date, (isAssignment && showIsAssignment), createSubtitles); createSubtitles = false}"
                            >
                                <v-icon
                                    x-small
                                    dark
                                >
                                    fas fa-check
                                </v-icon>
                            </v-btn>
                        </v-col>

                        <v-btn
                            elevation="2"
                            x-small
                            class="ml-2 pa-0"
                            style="position: absolute; top: 20px; right: 36px; width: 30px; height: 30px"
                            @click="close"
                        >
                            <img
                                alt="Hochladen abbrechen"
                                :src="schliesenIcon"
                                style="height: 20px;"
                            >
                        </v-btn>
                    </v-row>
                    <v-row
                        v-if="accountRole === 'teacher' && showIsAssignment"
                        class="mt-0 pt-0"
                        align="center"
                        justify="center"
                    >
                        <v-col>
                            <v-checkbox
                                v-model="isAssignment"
                                style="margin-top: 0"
                                label="Es handelt sich um eine Abgabe"
                            />
                        </v-col>
                    </v-row>

                    <v-row
                        v-if="group && bonusFunctions.subtitle && isSubtitleReady"
                        class="mt-0 pt-0"
                        align="center"
                        justify="center"
                    >
                        <v-col style="">
                            <v-checkbox
                                v-model="createSubtitles"
                                style="margin-top: 0"
                            >
                                <template v-slot:label>
                                    Untertitel aktivieren
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <img
                                                v-bind="attrs"
                                                :src="fragezeichenIcon"
                                                class="ml-2"
                                                alt="Fragezeichensymbol"
                                                height="22"
                                                v-on="on"
                                            >
                                        </template>
                                        <p
                                            align="center"
                                            justify="center"
                                            style="margin: auto; max-width: 200px"
                                        >
                                            Hierbei handelt es sich um eine Betafunktion.
                                            Diese ist aktuell nur mit Videos von einer länge bis zu einer Minute kompatibel.
                                            Bis die Untertitel erstellt und angezeigt werden, kann es einige Minuten und eine Aktualisierung der Seite benötigen.
                                        </p>
                                    </v-tooltip>
                                </template>
                            </v-checkbox>
                        </v-col>
                    </v-row>

                    <v-row
                        v-if="group && showIsAssignment"
                        class="ma-0"
                        align="center"
                        justify="center"
                        style="width: 100%"
                    >
                        <v-dialog
                            ref="dialog"
                            v-model="modal"
                            :return-value.sync="date"
                            persistent
                            width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="date"
                                    label="Startdatum"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                />
                            </template>
                            <v-date-picker
                                v-model="date"
                                scrollable
                                locale
                            >
                                <v-spacer />
                                <v-btn
                                    text
                                    color="primary"
                                    @click="modal = false"
                                >
                                    Abbruch
                                </v-btn>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="$refs.dialog.save(date)"
                                >
                                    Ok
                                </v-btn>
                            </v-date-picker>
                        </v-dialog>
                    </v-row>
                    <v-row
                        v-if="group && showIsAssignment"
                        class="ma-0"
                        align="center"
                        justify="center"
                        style="width: 100%"
                    >
                        <v-data-table
                            :items="group.participants"
                            disable-pagination
                            hide-default-footer
                            class="ma-0"
                            style="width: 100%; max-height: 300px; overflow-y: auto"
                        >
                            <template v-slot:item="row">
                                <tr>
                                    <td class="pl-0">
                                        {{ row.item.lastName }}
                                    </td>
                                    <td> {{ row.item.name }}</td>
                                    <td>
                                        <v-checkbox
                                            v-model="selectedUser"
                                            class="mx-2"
                                            rounded
                                            :value="row.item.account"
                                            small
                                            light
                                        />
                                    </td>
                                </tr>
                            </template>
                        </v-data-table>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import schliesenIcon from '../assets/Icons/abbrechen-08.svg'
import vueCustomScrollbar from "vue-custom-scrollbar";
import fragezeichenIcon from "@/assets/Icons/Fragezeichen-83.svg";

import { mapState, mapGetters } from "vuex";
export default {
    name: "NameFile",
    components: {
    },
    model: {
        prop: 'showDialog',
        event: 'showChange'
    },
    props: {
        showDialog: {type: Boolean, required: true},
        showIsAssignment: {type: Boolean, required: false, default: true},
        originalName: {type: String, required: false, default: ''},
        callback: {type: Function, required: true},
        isSubtitleReady: {type: Boolean, required: false, default: null},
        group: {type: Object, required: false, default: null},
    },
    data() {
        return {
            isAssignment: true,
            createSubtitles: false,
            schliesenIcon,
            fragezeichenIcon,
            newFileName: '',
            selectedUser: [],
            date: new Date().toISOString().substr(0, 10),
            modal: false,
        };
    },
    computed: {
      ...mapGetters("auth",["accountRole"]),
      ...mapState("util", ["bonusFunctions"]),
    },
    watch: {
        showDialog(val, prev) {
            if (val && !prev) {
                this.newFileName = this.originalName;
                if (this.group) {
                    this.selectedUser = this.group.participants.map(a => a.account);
                }
            }
        },
        group(val) {
            if(val) {
                this.selectedUser = val.participants.map(a => a.account);
            }
        }
    },
    methods: {
        close() {
            document.getElementById('uploadInput').value = null;
            this.$emit('showChange', false);
            this.$emit('close');
        }
    },
}
</script>

<style scoped>
    .scroll-area {
        position: relative;
        margin: auto;
        max-height: 300px;
        width: 100%;
    }
</style>
