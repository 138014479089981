var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"margin-left":"12px","margin-right":"12px"}},[_c('v-row',[_c('div',{staticClass:"greyDivBorderColor"},[_c('div',{ref:"gridHTML",staticClass:"greyDiv",staticStyle:{"margin":"8px","padding-top":"1px","padding-bottom":"1px"}},[_c('div',{staticClass:"greyDiv",staticStyle:{"margin":"20px"}},_vm._l((_vm.gridSize),function(n,yVal){return _c('div',{key:yVal,staticStyle:{"display":"flex","flex-direction":"row"}},_vm._l((_vm.gridSize),function(m,xVal){return _c('div',{key:xVal,staticStyle:{"flex-direction":"column"}},[_c('v-btn',{class:_vm.getGridSelected(xVal, yVal),attrs:{"block":"","tile":"","width":_vm.gridWidth,"height":_vm.gridHeight,"min-width":_vm.gridWidth+'px !important',"min-height":_vm.gridHeight+'px',"max-width":_vm.gridWidth+'px',"max-height":_vm.gridHeight+'px',"color":_vm.getGridColor(xVal, yVal)},on:{"click":function($event){return _vm.gridClicked(xVal,yVal)}}},[_c('span',{style:(("font-size: " + (_vm.sizeState + (_vm.smallUI ? 1 : 2)) + "em"))},[_vm._v(" "+_vm._s(_vm.getGridLetter(xVal, yVal))+" ")])])],1)}),0)}),0)])]),(_vm.showTeacherUI)?_c('div',{staticClass:"editorOptionsFade",style:('position: absolute; left: '+(_vm.gridSize*_vm.gridWidth+52)+'px; top: 48px')},[_c('v-col',[_c('div',[_c('v-tooltip',{attrs:{"top":"","disabled":_vm.answers.length < 1},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2 editorColorPrimaryBackgroundOnHover",staticStyle:{"opacity":"0.9","width":"48px","height":"48px","min-width":"48px"},attrs:{"disabled":_vm.answers.length < 1},on:{"click":_vm.generateGrid}},on),[_c('v-icon',{staticClass:"editorColorPrimaryOnHoverChildColorToWhite",staticStyle:{"opacity":"0.75"},attrs:{"large":""}},[_vm._v(" mdi-dice-5-outline ")])],1)]}}],null,false,1929004991)},[_c('span',[_vm._v("Buchstabensalat generieren")])])],1),_c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2 editorColorPrimaryBackgroundOnHover",staticStyle:{"margin-top":"10px","opacity":"0.9","width":"48px","height":"48px","min-width":"48px"},on:{"click":function($event){return _vm.changeSize()}}},on),[_c('img',{staticClass:"iconToWhiteHover",staticStyle:{"height":"32px"},attrs:{"src":_vm.currentSizeIcon,"alt":"Größe des Elements verändern"}})])]}}],null,false,1076070146)},[_c('span',[_vm._v("Größe verändern")])])],1),_c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2 editorColorPrimaryBackgroundOnHover",staticStyle:{"margin-top":"10px","opacity":"0.9","width":"48px","height":"48px","min-width":"48px"},on:{"click":function($event){return _vm.mutateDirections()}}},on),[_c('v-icon',{staticClass:"editorColorPrimaryOnHoverChildColorToWhite",staticStyle:{"opacity":"0.75"},attrs:{"large":""}},[_vm._v(" "+_vm._s(_vm.horizontal ? _vm.vertical ? 'mdi-format-text-rotation-down-vertical' : 'mdi-format-text-rotation-none' : _vm.vertical ? 'mdi-format-text-rotation-vertical' : '')+" ")])],1)]}}],null,false,743974455)},[_c('span',[_vm._v(_vm._s(_vm.horizontal ? _vm.vertical ? 'Wörter horizontal und vertikal einfügen' : 'Wörter horizontal einfügen' : _vm.vertical ? 'Wörter vertikal einfügen' : ''))])])],1),_c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2 editorColorPrimaryBackgroundOnHover",staticStyle:{"margin-top":"10px","opacity":"0.9","width":"48px","height":"48px","min-width":"48px"},on:{"click":function () {_vm.cfgHighlightFirst = !_vm.cfgHighlightFirst}}},on),[_c('v-icon',{staticClass:"editorColorPrimaryOnHoverChildColorToWhite",staticStyle:{"opacity":"0.75"},attrs:{"large":""}},[_vm._v(" "+_vm._s(_vm.cfgHighlightFirst ? 'mdi-marker' : 'mdi-marker-cancel')+" ")])],1)]}}],null,false,1630924321)},[_c('span',[_vm._v(_vm._s(_vm.cfgHighlightFirst ? 'Erster Buchstabe wird markiert':'Erster Buchstabe wird nicht markiert'))])])],1),_c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2 editorColorPrimaryBackgroundOnHover",staticStyle:{"margin-top":"10px","opacity":"0.9","width":"48px","height":"48px","min-width":"48px"},on:{"click":function () {_vm.cfgAllowEmpty = !_vm.cfgAllowEmpty}}},on),[_c('v-icon',{staticClass:"editorColorPrimaryOnHoverChildColorToWhite",staticStyle:{"opacity":"0.75"},attrs:{"large":""}},[_vm._v(" "+_vm._s(_vm.cfgAllowEmpty ? 'mdi-border-none-variant' : 'mdi-border-all-variant')+" ")])],1)]}}],null,false,220835175)},[_c('span',[_vm._v(_vm._s(_vm.cfgAllowEmpty ? 'Erlaubt leere Felder':'Erlaubt keine leeren Felder'))])])],1)])],1):_vm._e()]),(_vm.accountRole === 'teacher' && !_vm.previewPupilUI
            || (_vm.accountRole === 'pupil' && (_vm.done || _vm.cfgShowAnswers))
            || (_vm.cfgShowAnswers && _vm.exportMode && !_vm.previewPupilUI))?_c('div',{staticClass:"editorOptionsFade"},[_c('v-row',{style:('max-width: '+(_vm.gridSize*_vm.gridWidth+64)+'px; min-width: '+(_vm.gridSize*_vm.gridWidth+64)+'px')},[_c('v-combobox',{staticStyle:{"margin-top":"10px","margin-bottom":"-28px"},attrs:{"multiple":"","readonly":!_vm.showTeacherUI,"label":(!_vm.showTeacherUI) ? 'Lösungen' : 'Worte durch Komma trennen',"outlined":"","filled":"","chips":"","deletable-chips":_vm.showTeacherUI,"search-input":_vm.answersArea,"clearable":_vm.showTeacherUI},on:{"update:searchInput":function($event){_vm.answersArea=$event},"update:search-input":function($event){_vm.answersArea=$event}},model:{value:(_vm.answers),callback:function ($$v) {_vm.answers=$$v},expression:"answers"}}),(_vm.showTeacherUI)?_c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2 greyDiv editorColorPrimaryBackgroundOnHover",staticStyle:{"margin-top":"15px","opacity":"0.9"},style:('position: absolute; left: '+(_vm.gridSize*_vm.gridWidth+64)+'px; top: '+(_vm.gridSize*_vm.gridWidth+66)+'px'),attrs:{"width":"50","height":"50","min-width":"20"},on:{"click":function (){_vm.cfgShowAnswers=!_vm.cfgShowAnswers}}},on),[_c('v-icon',{staticClass:"editorColorPrimaryOnHoverChildColorToWhite",staticStyle:{"opacity":"0.75"},attrs:{"large":"","height":"50","width":"50"}},[_vm._v(" "+_vm._s(_vm.cfgShowAnswers ? 'mdi-eye' : 'mdi-eye-off')+" ")])],1)]}}],null,false,3180635740)},[_c('span',[_vm._v(_vm._s(_vm.cfgShowAnswers ? 'Die Antwort-Liste ist für SuS sichtbar' : 'Die Antwort-Liste ist für SuS nicht sichtbar'))])])],1):_vm._e()],1)],1):_vm._e(),(((_vm.accountRole === 'teacher' && _vm.cfgShowAnswers) || (_vm.accountRole === 'pupil' && (_vm.cfgShowAnswers || _vm.state === 'viewer'))))?_c('div',{staticClass:"editorOptionsFade",attrs:{"hidden":true}},_vm._l((_vm.answers),function(answer,index){return _c('div',{key:index,staticStyle:{"display":"flex","flex-direction":"row"}},[_c('v-text-field',{staticClass:"shrink",staticStyle:{"max-width":"400px","margin-top":"5px","margin-bottom":"-25px","border-top-style":"inset","border-width":"5px"},attrs:{"solo":"","readonly":_vm.accountRole === 'pupil' || _vm.state === 'viewer' || _vm.done,"outlined":"","maxlength":_vm.maxGridSize,"dense":"","label":"Wort"},model:{value:(_vm.answers[index]),callback:function ($$v) {_vm.$set(_vm.answers, index, $$v)},expression:"answers[index]"}}),(_vm.accountRole === 'teacher' && !_vm.done)?_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",staticStyle:{"margin-left":"20px","margin-top":"5px","margin-bottom":"-25px"},attrs:{"small":"","fab":"","tabindex":"-1","color":_vm.colorArr[index]}},on),[_c('v-icon',{staticStyle:{"opacity":"0.5"},attrs:{"dark":""}},[_vm._v(" mdi-eyedropper ")])],1)]}}],null,true)},[_c('span',[_vm._v("Die Antwort wird in dieser Farbe markiert")])])],1):_vm._e(),(_vm.state === 'viewer' || (_vm.accountRole === 'teacher' && _vm.done))?_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",staticStyle:{"margin-left":"20px","margin-top":"5px","margin-bottom":"-25px"},attrs:{"small":"","fab":"","tabindex":"-1","color":_vm.colorArr[index]}},on),[_c('v-icon',{staticStyle:{"opacity":"0.5"},attrs:{"dark":""}},[_vm._v(" "+_vm._s(_vm.answersFound[index] ? 'mdi-check' : 'mdi-close')+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.answersFound[index] ? 'Das Wort wurde gefunden' : 'Das Wort wurde nicht gefunden')+" ")])])],1):_vm._e(),_c('v-spacer')],1)}),0):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }