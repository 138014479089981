import * as backend from '../../api/backend';
import { checkResponseStatus } from '../../util/check';

const defaultState = {
};

const mutations = {
};

const actions = {
  async createPrivateRoom ({ commit, dispatch }, params) {
    try {
      const res = await backend.postPrivateRoom(params);
      await checkResponseStatus(201, res);
    }
    catch(err) {
      return err.response.status;
    }
  },

  async removeMessage ({ commit, dispatch }, params) {
    try {
      const res = await backend.deleteMessage(params);
      await checkResponseStatus(204, res);
    }
    catch(err) {
      return err.response.status;
    }
  },

  async getMatrixName ({ commit, dispatch }, params) {
    try {
      const res = await backend.getMatrixName(params);
      await checkResponseStatus(201, res);
      const name = await res.json();
      return name;
    }
    catch(err) {
      return err.response.status;
    }
  },

  async getMatrixRoomLockStatus({ commit, dispatch }) {
    try {
      const res = await backend.getMatrixRoomLockStatus();
      await checkResponseStatus(200, res);
      const matrixRoomLockStatus = await res.json();
      return matrixRoomLockStatus;
    }
    catch(err) {
      return err.response.status;
    }
  },

  async getMatrixRoomLockStatusById({commit, dispatch }, params) {
    try {
      const roomId = params;
      const res = await backend.getMatrixRoomLockStatusById(roomId);
      return res;
    }
    catch(err) {
      return err.response.status;
    }
  },

  async setMatrixRoomLockStatus({ commit, dispatch }, params) {
    try {
      const roomId = params.roomId;
      delete params.roomId;
      const res = await backend.setMatrixRoomLockStatus(roomId, params);
      
      await checkResponseStatus(201, res);
      return res;
    }
    catch(err) {
      return err.response.status;
    }
  },
};

export default {
  namespaced: true,
  state: defaultState,
  mutations,
  actions,
};
