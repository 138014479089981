<template>
    <div
        style="height: auto"
        :style="`width: calc(100% - ${accountRole !== 'teacher' ? '60px' : '0px'});`"
        @click="$emit('focusMe')"
    >
        <div
            class="d-flex"
            style="height: 100%; width: 100%;"
        >
            <div
                v-if="this.person && (this.person.accessibility && this.person.accessibility.screenreader) && videoUrl"
                class="d-flex"
                style="flex-direction: column"
            >
                <video
                    id="playerAccessibility"
                    :class="{ 'moveableItemShadow': accountRole === 'teacher' }"
                    aria-label="Videoplayer"
                    role="application"
                    :tabindex="tabIndex"
                    :poster="videoPoster"
                    class="pa-0"
                    :style="`width: calc(100% - ${accountRole === 'teacher' ? '32px' : '0px'});`"
                    style="max-height: 100%; height: 100%;"
                >
                    <source
                        :src="videoUrl"
                        style="height: 100%; width: 100%;"
                    >
                </video>
                <v-btn
                    class="visually-hidden"
                    @click="toggleVideo"
                >
                    {{ videoPlaying ? 'Video pausieren' : 'Video abspielen' }}
                </v-btn>
                <v-btn
                    class="visually-hidden"
                    @click="stopVideo"
                >
                    Video stoppen
                </v-btn>
            </div>

            <video
                v-else-if="videoUrl"
                id="player"
                :class="{ 'moveableItemShadow': accountRole === 'teacher' }"
                aria-label="Videoplayer"
                role="application"
                :tabindex="tabIndex"
                :poster="videoPoster"
                controls
                class="pa-0"
                :style="`width: calc(100% - ${accountRole === 'teacher' ? '32px' : '0px'});`"
                style="max-height: 100%; height: 100%;"
            >
                <source
                    :src="videoUrl"
                    style="height: 100%; width: 100%;"
                >
            </video>

            <!-- Sidebar Option Buttons -->
            <v-tooltip right>
                <template v-slot:activator="{ on }">
                    <div
                        v-if="accountRole === 'teacher'"
                        class="editorOptionsFade"
                        v-on="on"
                    >
                        <v-btn
                            id="changeSizeButton"
                            x-small
                            @click="changeSize()"
                        >
                            <img
                                style="height: 32px"
                                class="iconToWhiteHover"
                                :src="currentSizeIcon"
                                alt="Größe des Elements verändern"
                            >
                        </v-btn>
                    </div>
                </template>
                <span>Größe verändern</span>
            </v-tooltip>
        </div>
    </div>
</template>

<script>

import videoPoster from "../../assets/Bilder/videoposter.png";
import sizeSmallIcon from "@/assets/Icons/EditorRedesign/schrift_klein.svg";
import sizeMediumIcon from '@/assets/Icons/EditorRedesign/schrift_mittel.svg';
import sizeLargeIcon from '@/assets/Icons/EditorRedesign/schrift_groß.svg';
import * as backend from "@/api/backend";
import { mapGetters, mapActions } from "vuex";

export default {
    name: "EditorVideoObject",
    props: {
        element: { type: Object, required: true },
        canvasWidth: { type: Number, required: true },
        canvasHeight: { type: Number, required: true },
        tabIndex: { type: Number, required: false, default: 0 },
    },
    data: () => ({
        videoPoster,
        sizeSmallIcon,
        sizeMediumIcon,
        sizeLargeIcon,

        videoUrl: null,
        person: null,
        videoPlaying: false,

        // Size Button Stuff
        sizeState: 0,
        currentSizeIcon: sizeSmallIcon,
    }),
    computed: {
        ...mapGetters("auth", ["accountRole"]),
    },
    watch: {
        element(oldElement, newElement) {
            if (newElement.fileUrl)
                this.videoUrl = newElement.fileUrl;
        }
    },
    async mounted() {
        if (!this.element.fileUrl || !this.element.fileBlob) {
            this.loadVideo();
        } else {
            this.videoUrl = this.element.fileUrl;
        }

        if (this.accountRole === 'pupil') {
            this.person = await this.getMePupil();

        }
    },
    async unmounted() {
        URL.revokeObjectURL(this.videoUrl);
    },
    methods: {
        ...mapActions("pupils", ["getMePupil"]),

        toggleVideo() {
            if (this.videoPlaying) {
                document.getElementById("playerAccessibility").pause();
            } else {
                document.getElementById("playerAccessibility").play();
            }
            this.videoPlaying = !this.videoPlaying;
        },

        stopVideo() {
            document.getElementById("playerAccessibility").pause();
            document.getElementById("playerAccessibility").currentTime = 0;
            this.videoPlaying = false;
        },
        //#region size change
        changeSize() {
            // Changes size stuff from current case to new values
            switch (this.sizeState) {
                case 0:
                    this.currentSizeIcon = this.sizeMediumIcon;
                    this.sizeState = 1;
                    break;
                case 1:
                    this.currentSizeIcon = this.sizeLargeIcon;
                    this.sizeState = 2;
                    break;
                case 2:
                    this.currentSizeIcon = this.sizeSmallIcon;
                    this.sizeState = 0;
                    break;
            }
            this.$emit('resizeInStages', this.sizeState);
        },
        //#endregion

        async loadVideo() {
            // fileUrl: url, fileBlob: blob,
            const res = await backend.getUploadMessage(this.element.start);

            const blob = await res.blob();

            this.$set(this.element, 'fileBlob', blob);
            this.$set(this.element, 'fileUrl', window.URL.createObjectURL(blob));
            this.videoUrl = this.element.fileUrl;
        },
    }
}
</script>

<style scoped>
#changeSizeButton {
    position: absolute;
    top: 110px;
    right: -55px;
    margin-top: 10px;
    opacity: 0.9;
    height: 48px !important;
    width: 48px !important;
    min-width: 48px;
}
</style>
