<template>
    <v-app style="height: 100vh;">
        <!-- Universal Loading Bar -->
        <v-progress-linear
            v-if="showLoading"
            indeterminate
            buffer-value="100"
            :color="backendReachable ? 'primary' : 'error'"
            height="10"
            style="position: fixed; top: 0 !important;"
        />

        <!-- Universal Upload -->
        <progress-bar
            title="Hochladen"
            :show-progress="showPupilUploadProgress"
            :progress="pupilUploadProgress"
            :abort-progress="pupilXmlHttpRequest ? () => { pupilXmlHttpRequest.abort() } : () => { }"
        />

        <label
            style="display: none"
            for="uploadInput"
        >Datei hochladen</label>
        <input
            id="uploadInput"
            ref="uploadInput"
            type="file"
            hidden
            label="Datei hochladen"
            multiple
            @change="() => uploadInputChange()"
        >

        <name-file
            v-model="showNameFile"
            :original-name="uploadFileName"
            :callback="fileNameCallback"
            :is-subtitle-ready="isSubtitleReady"
            :group="currentlyOpenAppointment ? currentlyOpenAppointment.group : null"
            :show-is-assignment="!(currentUploadGroup === 'teacherFolder' || currentUploadGroup === 'privateFolder')"
        />

        <!-- Universal Upload End -->

        <!--#endregion -->
        <UserSwitch
            v-if="developmentMode"
            style="z-index: 5"
        />
        <!-- Cookie Law needs to be above the router view or otherwise it wont load any content -->
        <cookie-law />
        <router-view />

        <!-- Snackbar Area -->
        <snackbar />
        <v-card
            v-if="showTranslatedText"
            id="translatedTextShow"
            type="info"
            style="background-color: var(--v-secondary-base);"
        >
            <v-card-text
                class="py-2"
                style="height: 100%; text-align: center"
            >
                <div style="display: flex;">
                    <!-- Disable vue/no-v-html because translatedText from google translate, propably not insecure -->
                    <!-- eslint-disable vue/no-v-html -->
                    <p
                        id="translatedTextText"
                        style="color: white !important; overflow-y: auto"
                        class="mb-0 pb-2 mx-auto"
                        v-html="urlify(translatedText, true)"
                    />
                    <!-- eslint-enable vue/no-v-html -->
                    <v-icon
                        role="button"
                        aria-label="Schließen"
                        large
                        dark
                        class="mr-1"
                        color="white"
                        @click="showTranslation(false)"
                    >
                        fas fa-times
                    </v-icon>
                </div>
            </v-card-text>
        </v-card>

        <keyboard />
        <magnifier v-if="magnifier" />
    </v-app>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from "vuex";
import UserSwitch from "./components/Utils/UserSwitch";
import Snackbar from './components/Utils/Snackbar'
import NameFile from "./components/NameFile";
import ProgressBar from "./components/ProgressBar";
import * as backend from "./api/backend";
import Keyboard from "./components/Keyboard";
import Magnifier from "./components/Magnifier";
import CookieLaw from "./components/CookieLaw";
import setGlobalButtonContrast from "./util/accessibilityButtonContrast";


export default {
    name: "App",

    components: {Magnifier, Keyboard, UserSwitch, NameFile, ProgressBar, CookieLaw, Snackbar },
    data: () => ({
        transText: '',
        developmentMode: process.env.NODE_ENV === "development",
        showNameFile: false,
        isSubtitleReady: false,
        uploadFileName: '',
        fileNameCallback: () => { },
        showPupilUploadProgress: false,
        showBackendUnreachable: false,
        pupilUploadProgress: 0.0,
        pupilXmlHttpRequest: null,
        hasAcceptedAGB: false,
        displayAGB: false,
        setGlobalButtonContrast,
    }),
    computed: {
        ...mapState("magnifier", ["magnifier"]),
        ...mapGetters("auth", ["accountRole"]),
        ...mapState("connection", ["backendReachable"]),
        ...mapGetters('translation', ['getTranslatedText', 'isTranslationShown']),
        ...mapGetters('util', ['isLoadingShown', 'currentlyOpenAppointment', 'currentUploadGroup', 'currentUploadFolder', 'currentlyOpenTeacherUploadId']),
        translatedText() {
            return this.getTranslatedText;
        },
        showTranslatedText() {
            return this.isTranslationShown;
        },
        showLoading() {
            return this.isLoadingShown;
        }
    },
    watch: {
        account(newAccount) {
            if (newAccount && newAccount.accountName) {

                // Display Terms of Service or directly login
                this.hasAcceptedAGB = newAccount.hasAcceptedToS;
                if (newAccount.hasAcceptedToS) {
                    // this.rerouteAccount(newAccount);
                } else {
                    this.displayAGB = true;
                }
                return;
            }
        },
        isTranslationShown(value) {
            if (value) {
                setTimeout(() => {
                    this.showTranslation(false);
                    this.transText = '';
                }, 15000);
            }
            this.transText = this.translatedText;
        },
        backendReachable(backendReachability) {
            if (backendReachability === false) {
                this.showBackendUnreachable = true;
            }
        },
        getTranslatedText() {
            this.transText = this.getTranslatedText;
        },
        isLoadingShown(value) {
        },
        handleBeforeUnload(event) {
            this.autoLogoutUser();
            this.resetServerTimeOffset();
        },
    },
    async mounted() {
        window.addEventListener("orientationchange", this.handleOrientationChange);
        window.addEventListener('beforeunload', this.handleBeforeUnload);
        window.addEventListener('resize', this.handleViewHeightChange);
        this.handleViewHeightChange();
        this.setTargetLang('de');
        this.initGamepadApi();
        this.setGlobalButtonContrast('none', 'none');
        this.checkBrowserCompatibilityForNotifications();
    },
    beforeDestroy() {
        window.removeEventListener("orientationchange", this.handleOrientationChange);
        window.removeEventListener('beforeunload', this.handleBeforeUnload);
        window.removeEventListener('resize', this.handleViewHeightChange);
    },
    methods: {
        ...mapActions('gamepad', ["initGamepadApi"]),
        ...mapActions(['setFabricLibLoaded']),
        ...mapActions("auth", ["autoLogoutUser", "logoutUser", "getAccount"]),
        ...mapActions('translation', ['setTargetLang', 'showTranslation']),
        ...mapActions("groups", ["getGroupByAppointment"]),
        ...mapActions("appointments", ['getAppointments']),
        ...mapActions('util', [
            'checkBrowserCompatibilityForNotifications',
            'resetServerTimeOffset',
            'toggleOpenAppointment',
            'toggleCurrentUploadGroup',
            'toggleCurrentUploadFolder',
            'toggleOpenTeacherUploadId',
            'setOpenAppointment',
            'reopenAppointment',
            'setWindowWidth',
            'setWindowHeight',
        ]),
        ...mapMutations('snackbar', ["showSnackbar"]),

        urlify(text, alternate) {
            // eslint-disable-next-line no-useless-escape
            var urlRegex = /(([a-z]+:\/\/)?(([a-z0-9\-]+\.)+([a-z]{2}|aero|arpa|biz|com|software|coop|edu|gov|info|int|jobs|mil|museum|name|nato|net|org|pro|travel|local|internal|de))(:[0-9]{1,5})?(\/[a-z0-9_\-\.~]+)*(\/([a-z0-9_\-\.]*)(\?[a-z0-9+_\-\.%=&amp;]*)?)?(#[a-zA-Z0-9!$&'()*+.=-_~:@/?]*)?)(\s+|$)/gi;
            return text.toString().replace(urlRegex, function(url) {
                url = url.includes('http') ? url : 'http://' + url;
                if (alternate) {
                    return '<a style="color: white" href="' + url + '" target="_blank" rel="noopener noreferrer">' + url + '</a>';
                }
                return '<a href="' + url + '" target="_blank" rel="noopener noreferrer">' + url + '</a>';
            })
            // or alternatively
            // return text.replace(urlRegex, '<a href="$1">$1</a>')
        },
        handleOrientationChange() {
            // const orientation = window.screen.orientation.type;
            // if (orientation === "portrait-primary") {
            //     // portrait mode
            // } else if (orientation === "landscape-primary") {
            //     // landscape mode
            // }
        },
        // height change to fit browser bar
        // https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
        handleViewHeightChange() {
            // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
            let vh = window.innerHeight * 0.01;
            // Then we set the value in the --vh custom property to the root of the document
            document.documentElement.style.setProperty('--vh', `${vh}px`);

            // Timeout is needed for windowWidth because on Safari Mobile,
            // under certain circumstances the new innerWidth takes too long to calculate,
            // in which case we safe the old one
            setTimeout(() => {
                this.setWindowWidth(window.innerWidth);
                this.setWindowHeight(window.innerHeight);
            }, 400);
        },

        getExtension(filename) {
            var parts = filename.split('.');
            return parts[parts.length - 1];
        },
        isVideo(filename) {
            var ext = this.getExtension(filename);
            switch (ext.toLowerCase()) {
                case 'm4v':
                case 'avi':
                case 'mpg':
                case 'mp4':
                case 'mkv':
                    return true;
            }
            return false;
        },

        async uploadInputChange() {
            if (this.currentUploadGroup === 'teacher') {
                let appointmnet = JSON.parse(JSON.stringify(this.currentlyOpenAppointment));
                let group = await this.getGroupByAppointment(this.currentlyOpenAppointment._id);
                appointmnet.group = group[0] ? group[0] : null;
                this.toggleOpenAppointment(appointmnet);
            }

            const files = Array.from(this.$refs.uploadInput.files);
            // Create tmp variables because in uploadFile these variables get reset to null
            // and we want to call uploadFile with the initial variables
            const tmpCurrentlyOpenAppointment = this.currentlyOpenAppointment;
            const tmpCurrentGroup = this.currentUploadGroup;
            const tmpOpenTeacherUploadId = this.currentUploadFolder;

            // Upload files sequentially
            const fileUploadResults = await files.reduce(async (previousFileUploadResultsPromise, file) => {
                const previousFileUploadResults = await previousFileUploadResultsPromise;

                // Show name file widget and upload files when appropriate
                const prepareFileUpload = (file) => new Promise((resolve, reject) => {
                    if (this.isVideo(file.name)) // ToDo check Video duration
                    {
                        this.isSubtitleReady = true;
                    } else {
                        this.isSubtitleReady = false;
                    }

                    const name = file.name;
                    const lastDot = name.lastIndexOf('.');
                    const fileName = name.substring(0, lastDot);
                    const fileExtension = name.substring(lastDot + 1);
                    this.uploadFileName = fileName;
                    this.fileNameCallback = (newFileName, selectedUser, startDate, isAssignment, createSubtitles) => {
                        this.showNameFile = false;
                        this
                            .uploadFile(file, newFileName, fileExtension, selectedUser, startDate, isAssignment, createSubtitles, tmpCurrentlyOpenAppointment, tmpCurrentGroup, tmpOpenTeacherUploadId)
                            .then(() => {
                                if (this.currentUploadGroup === 'pupil' || this.currentUploadGroup === 'teacher') {
                                    /*this.$route.query.appointment = localStorage.getItem('reopenAppointment');
                                    localStorage.removeItem('reopenAppointment');*/
                                }
                                resolve();
                            })
                    }
                    this.showNameFile = true;
                });

                const fileUploadResult = await prepareFileUpload(file);

                return Promise.resolve([...previousFileUploadResults, fileUploadResult]);
            }, Promise.resolve([]))
        },

        async uploadFile(file,
            fileName,
            fileExtension,
            selectedUser,
            startDate,
            isAssignment,
            createSubtitles,
            currentlyOpenAppointment = this.currentlyOpenAppointment,
            currentUploadGroup = this.currentUploadGroup,
            currentUploadFolder = this.currentUploadFolder,
        ) {
            const localAppointment = currentlyOpenAppointment;
            const localTeacherUploadId = this.currentlyOpenTeacherUploadId;

            if (file) {
                const formData = new FormData();
                formData.append('file', file, `${fileName}.${fileExtension}`);

                if (currentUploadGroup === 'teacher') {
                    formData.append('visibleFor', JSON.stringify(selectedUser));
                    formData.append('startDate', JSON.stringify(startDate));
                    formData.append('isAssignment', JSON.stringify(isAssignment));
                    formData.append('createSubtitles', JSON.stringify(createSubtitles));

                    this.pupilXmlHttpRequest = await backend.postTeacherUpload(localAppointment._id, file);
                } else if (currentUploadGroup === 'pupil') {
                    this.pupilXmlHttpRequest = await backend.postPupilUpload(localAppointment._id, file);
                } else if (currentUploadGroup === 'subject') {
                    this.pupilXmlHttpRequest = await backend.postSubjectFolderUpload(localAppointment._id, file);
                } else if (currentUploadGroup === 'baby' && localTeacherUploadId) {
                    this.pupilXmlHttpRequest = await backend.postTeacherUploadEdit(localAppointment._id, localTeacherUploadId, file).then((response) => {
                        if (response.status === 201) {
                            this.showSnackbar({ message: "Gespeichert!" });
                        } else {
                            this.showSnackbar({ message: "Etwas ist schiefgelaufen", color: "error" })
                        }
                        this.toggleOpenAppointment(null);
                        this.toggleCurrentUploadGroup(null);
                        this.toggleOpenTeacherUploadId(null);
                        this.pupilXmlHttpRequest = null;
                        this.$refs.uploadInput.value = '';
                    });
                } else if (currentUploadGroup === 'teacherFolder' || currentUploadGroup === 'privateFolder') {
                    this.pupilXmlHttpRequest = await backend.postVFSFolderUpload(file, currentUploadGroup, currentUploadFolder._id);
                }

                this.pupilXmlHttpRequest.onerror = (e) => {
                    console.error('Pupil upload error:', e)
                    this.showPupilUploadProgress = false;
                    this.pupilXmlHttpRequest = null;
                    this.$refs.uploadInput.value = '';
                    this.toggleOpenAppointment(null);
                    this.toggleOpenTeacherUploadId(null);
                    this.toggleCurrentUploadGroup(null);
                };

                this.pupilXmlHttpRequest.onabort = (e) => {
                    console.warn('Pupil upload aborted');
                    this.showPupilUploadProgress = false;
                    this.pupilXmlHttpRequest = null;
                    this.$refs.uploadInput.value = '';
                    this.toggleOpenAppointment(null);
                    this.toggleOpenTeacherUploadId(null);
                    this.toggleCurrentUploadGroup(null);
                }

                this.pupilXmlHttpRequest.upload.addEventListener('progress', (e) => {
                    this.pupilUploadProgress = (e.loaded / e.total) * 100;
                });

                this.pupilXmlHttpRequest.addEventListener('load', async (e) => {
                    if (this.pupilXmlHttpRequest.status !== 201) {
                        console.error('Pupil upload failed:', this.pupilXmlHttpRequest.response);
                        if (this.pupilXmlHttpRequest.status === 409) {
                            this.showSnackbar({ message: "Speicher überschritten. Datei konnte nicht gespeichert werden", color: "error" });
                        }
                    }
                    this.showPupilUploadProgress = false;
                    this.showSnackbar({ message: "Gespeichert!" }); this.toggleOpenAppointment(null);
                    this.toggleCurrentUploadGroup(null);
                    this.toggleOpenTeacherUploadId(null);
                    this.getAppointments(true)
                    this.pupilXmlHttpRequest = null;
                    this.$refs.uploadInput.value = '';
                });

                this.pupilUploadProgress = 0.0;
                this.showPupilUploadProgress = true;
                this.pupilXmlHttpRequest.send(formData);
            }
        }
    },
};
</script>

<style lang="scss">
/*@import '~@/styles/globals.scss';*/
/*@import url('https://fonts.googleapis.com/css2?family=Andika+New+Basic&display=swap');*/

img {
    -webkit-user-select: none !important;
    -webkit-touch-callout: none !important; /* iOS Safari */
}

#translatedTextShow {
    position: fixed;
    bottom: 10px;
    right: 0;
    left: 0;
    margin-right: 10%;
    margin-left: 10%;
    min-width: 300px !important;
    height: 100px;
    border-radius: 15px;
    box-shadow: 1px 5px 5px silver;
    z-index: 210;   // increased so it also shows in front of v-dialogs
}

@media only screen and (max-width: 500px),
    (max-width: 900px) and (orientation: portrait) {
    #translatedTextShow {
        bottom: 80px;
    }
}

#translatedTextText {
    text-align: center !important;
    font-size: x-large !important;
    color: #000000 !important;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4; /* number of lines to show */
    -webkit-box-orient: vertical;
}

.v-snack.v-snack--top {
    bottom: initial; /* or auto */
    width: 100vw !important;
}

.v-snack.v-snack--bottom {
    top: initial; /* or auto */
    width: 100vw !important;
}

.preventColorInvert {
    filter: unset !important;
    border-color: #22a9ff !important;
}
.preventColorInvertFilter {
    filter: unset !important;
}
.visually-hidden {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
}
h1 {
    display: inherit;
    font-size: inherit;
    margin-top: inherit;
    margin-bottom: inherit;
    margin-left: inherit;
    margin-right: inherit;
    font-weight: inherit;
}
h2 {
    display: inherit;
    font-size: inherit;
    margin-top: inherit;
    margin-bottom: inherit;
    margin-left: inherit;
    margin-right: inherit;
    font-weight: inherit;
}

.soft-box-shadow {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.moveableItemShadow {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

@font-face {
    font-family: "eKlara Custom";
    src: url("~@/assets/Fonts/ABeeZee-Regular.ttf");
    font-weight: normal;
    font-style: normal;
}

.v-application {
    font-family: "eKlara Custom", sans-serif !important;
}
</style>
