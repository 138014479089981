import * as backend from '../../api/backend';
import { checkResponseStatus } from '../../util/check';

const defaultState = {
};

const mutations = {
};

const actions = {
    async getMaintainers ({ commit, dispatch }) {
        try {
            const res = await backend.getMaintainers();
            await checkResponseStatus(200, res);
            return  await res.json();
        }
        catch(err) {
            return err.response.status;
        }
    },

    async confirmMaintainerPassword({ commit, dispatch }, params) {
        try {
            delete params.maintainerId;
            const res = await backend.confirmMaintainerPassword(params);
            await checkResponseStatus(200, res);
            return res;
        } catch(err) {
            console.warn(err);
            return err.response;
        }
    },

    async changeSchoolYear({ commit, dispatch }, params) {
        try {
            const res = await backend.createNewSchoolYear(params);
            await checkResponseStatus(200, res);
            return res;
        } catch (err) {
            console.warn(err);
            return err.response;
        }
    }
};

export default {
  namespaced: true,
  state: defaultState,
  mutations,
  actions,
};
