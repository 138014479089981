import * as backend from "@/api/backend";
import {checkResponseStatus} from "@/util/check";

const actions = {
    async createSickNote({ commit, dispatch }, body) {
        try {
            const res = await backend.createSickNote(body);
            await checkResponseStatus(201, res);
            const sickNote = await res.json();
            sickNote.status = 201;
            return sickNote;
        }
        catch (err) {
            return err.response;
        }
    },

    async getSickNotes() {
        try {
            const res = await backend.getSickNotes();
            await checkResponseStatus(200, res);
            return await res.json();
        }
        catch (err) {
            return err.response;
        }
    },

    async getSickNote({commit, dispatch}, params) {
      try {
          const res = await backend.getSickNote(params);
          await checkResponseStatus(200, res);
          return await res.json();
      }
      catch (err) {
          return err.response;
      }
    },

    async acceptSickNote({commit, dispatch}, params) {
        try {
            const res = await backend.acceptSickNote(params);
            await checkResponseStatus(200, res);
            return res;
        }
        catch (err) {
            return err.response;
        }
    },

    async declineSickNote({commit, dispatch}, params) {
        try {
            const res = await backend.declineSickNote(params);
            await checkResponseStatus(200, res);
            return res;
        }
        catch (err) {
            return err.response;
        }
    }
}

export default {
    namespaced: true,
    actions,
};
