<template>
    <div style="max-height: 100vh">
        <div id="qrCodeUI">
            <v-btn
                id="qrCodeClose"
                x-small
                class="mr-0"
                @click="closeFunction"
            >
                <img
                    :src="closeIcon"
                    style="height: 40px; filter: brightness(1000%)"
                    alt="QR Code Login schließen"
                >
            </v-btn>
            <div class="qrCodeVisorContainer">
                <div class="qrCodeVisor" />
            </div>
        </div>
        <qrcode-stream
            ref="qrCodeScanner"
            class="qrCodeScanner"
            style="max-height: 100vh !important; overflow: hidden"
            @decode="onDecode"
            @init="onInit"
        >
            <div class="qrCodeVisorContainer">
                <div class="qrCodeVisor">
                    <div>
                        <img src="../assets/Bilder/sample_qrvisor.png">
                    </div>
                </div>
            </div>
        </qrcode-stream>
    </div>
</template>

<script>
import { mapMutations } from "vuex";
import { QrcodeStream } from "vue-qrcode-reader";
import { decompress } from "shrink-string";
import closeIcon from "../assets/Icons/abbrechen-08.svg";
import qrIcon from "../assets/Bilder/sample_qrvisor.png";

export default {
  name: "QRScanLogin",
  components: {
    QrcodeStream,
  },
  props: {
    closeFunction: { type: Function, required: true },
  },
  data() {
    return {
      closeIcon,
    };
  },
  methods: {
      ...mapMutations('snackbar', ["showSnackbar"]),
      async onInit(promise) {
        try {
          await promise;
        } catch (e) {
          console.error(e);
          if (e.name === 'NotReadableError') {
            this.showSnackbar({ message: "QRCode nicht gültig!"});
          } else  if (e.name === 'NotAllowedError'){
            this.showSnackbar({ message: `Fehler! ${e.name}`});
          } else if (e.name === 'NotFoundError' || e.name === 'OverconstrainedError') {
            this.showSnackbar({ message: "Das Gerät scheint keine nutzbare Kamera zu besitzen. Bitte schließe eine an oder wechsel das Gerät."});
          } else {
            this.showSnackbar({ message: `Fehler! ${e.name}`});
          }
        }
    },
    async onDecode(result) {
      if (result.length > 0 && this.scannedCodeValid(result)) {
        const jsonResult = JSON.parse(result);
        const decompressedResultName = await decompress(jsonResult.name);
        const decompressedResultPW = await decompress(jsonResult.pw);

        this.$emit('login', {
          accountName: decompressedResultName,
          password: decompressedResultPW,
        })
      } else if (result.length > 0 && !this.scannedCodeValid(result)) {
        this.showSnackbar({ message: 'QRCode nicht gültig!' });
      }
    },
    scannedCodeValid(scannedCode) {
      try {
        const jsonCode = JSON.parse(scannedCode);
        if (jsonCode != null) {
          return !!(jsonCode.name && jsonCode.pw);
        }
      } catch (e) {
        console.error(e);
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#qrCodeUI {
  z-index: 10;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

#qrCodeClose {
  position: absolute;
  background-color: var(--v-dunkelgrau-base);
  width: 49px !important;
  height: 49px !important;
  top: 10px;
  right: 10px;
  z-index: 11;
}

.qrCodeVisorContainer {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.qrCodeVisor {
  position: absolute;
  width: 30% !important;
  height: 0% !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  filter: opacity(50%);

  div {
    width: 100%;
    padding-top: 100%; /* 1:1 Aspect Ratio */
    position: relative; /* If you want text inside of it */
    transform: translate(0%, -25%);
    img {
      display: block;
      width: 100%;
      height: 100%;
      transform: translate(0%, -100%);
    }
  }
}

#qrCodeVisorContainer {
  position: relative;
  width: 100%;
  height: 100%;
}

.v-snack.v-snack--top {
  bottom: initial; /* or auto */
}

.v-snack.v-snack--bottom {
  top: initial; /* or auto */
}
</style>
