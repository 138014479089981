<template>
    <v-col
        v-if="!answerEntry.text || !answerEntry.text.length"
        class="pa-0"
        style="display: inline-flex"
    >
        <v-textarea
            id="pupilAnswerTextArea"
            ref="pupilAnswerTextArea"
            v-model="currentPupilAnswer"
            :class="{ 'soft-box-shadow': accountRole === 'teacher' }"
            :tabindex="tabIndex"
            outlined
            background-color="#ffffff00"
            hide-details
            dense
            rows="1"
            row-height="10"
            auto-grow
            label="Antworteingabe"
        />

        <v-btn
            v-if="accountRole !== 'teacher'"
            id="fertigBtn"
            :tabindex="tabIndex"
            color="success"
            x-small
            class="ml-2 mb-4 acceptEntryButton pa-0"
            @click="userExerciseAnswer"
        >
            <img
                :ref="`fertigBtn`"
                :src="fertigIcon"
                alt="Antwort fertig"
                class="icon iconToWhite"
            >
        </v-btn>
    </v-col>
    <v-col
        v-else
        style="display: inline-flex"
        class="mb-6"
    >
        <div class="answerField">
            <p
                id="antwortText"
                ref="antwortText"
                class="my-auto"
                :tabindex="tabIndex"
            >
                {{ answerEntry.text }}
            </p>

            <v-btn
                class="ml-2"
                x-small
                width="36px"
                height="36px"
                :tabindex="tabIndex"
                @click="deleteAnswer"
            >
                <img
                    :src="papierkorbIcon"
                    alt="Antwort löschen"
                    height="25"
                    width="25"
                >
            </v-btn>
        </div>
        <v-btn
            ref="readAnswerButton"
            :aria-hidden="person && person.accessibility && person.accessibility.screenreader"
            class="ml-2 my-auto"
            small
            width="40px"
            height="40px"
            :tabindex="tabIndex"
            @click="readText(answerEntry.text, `vorlesenBtnIcon`)"
        >
            <img
                :ref="`vorlesenBtnIcon`"
                :src="lautsprecherIcon"
                alt="Antwort vorlesen"
                width="35px"
                height="35px"
                style="filter: brightness(0%)"
            >
        </v-btn>
    </v-col>
</template>

<script>
import {mapActions, mapState, mapGetters} from "vuex";
import lautsprecherIcon from "../../assets/Icons/lautsprecher-13.svg";
import lautsprecherAusIcon from "../../assets/Icons/lautsprecher-aus-89.svg";
import papierkorbIcon from "../../assets/Icons/papierkorb-109.svg";
import fertigIcon from "../../assets/Icons/fertig-74.svg";

export default {
    name: 'EditorAnswerField',
    props: {
        answerEntry: {type: Object, required: true},
        tabIndex: {type: Number, required: false, default: 0},
        canvasWidth: {type: Number, required: true},
        canvasHeight: {type: Number, required: true},
    },
    data() {
        return {
            //Textarea model
            currentPupilAnswer: null,

            // UI
            papierkorbIcon,
            lautsprecherIcon,
            lautsprecherAusIcon,
            fertigIcon,

            person: {
                accessibility: {
                    screenreader: false,
                }
            }
        };
    },
    computed: {
        ...mapGetters("auth", ["accountRole", "accountId"]),

    },
    mounted() {
        this.requestPerson();
    },
    methods: {
        ...mapActions('pupils', ['getMePupil']),
        async requestPerson() {
            if (this.accountRole === 'pupil') {
                this.person = await this.getMePupil();
                return this.person;
            }
        },
        // General Methods
        deleteAnswer() {
            this.answerEntry.text = "";
            this.currentPupilAnswer = "";

            setTimeout(() => {
                this.$refs.pupilAnswerTextArea.focus();
            }, 500)

            this.$forceUpdate();
        },
        // Answer Methods
        async userExerciseAnswer() {
            this.answerEntry.text = this.currentPupilAnswer;
            this.answerEntry.author = this.accountId;
            this.$emit('history', {text: '', author: ''})
            this.currentPupilAnswer = "";
            await this.$nextTick();
            this.$refs.antwortText.focus();
        },

        // Read out Methods
        readText(text, answerRef) {
            if ("speechSynthesis" in window) {
                // Speech Synthesis supported 🎉
            } else {
                // Speech Synthesis Not Supported 😣
                alert("Sorry, your browser doesn't support text to speech!");
            }

            let imageRef = this.$refs[answerRef];
            if (Array.isArray(imageRef)) {
                imageRef = imageRef[0];
            }

            if (window.speechSynthesis.speaking) {
                window.speechSynthesis.cancel();
                imageRef.src = this.lautsprecherIcon;
            } else {
                let msg = new SpeechSynthesisUtterance();
                msg.text = text;
                msg.lang = "de";
                window.speechSynthesis.speak(msg);

                imageRef.src = this.lautsprecherAusIcon;

                msg.onend = () => {
                    imageRef.src = this.lautsprecherIcon;
                };
            }
        },
    },
};
</script>

<style scoped lang="scss">
.userExerciseAnswerButton {
    float: right;
}

.icon {
    width: 25px;
    height: 25px;
}

.iconToWhite {
    filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%) hue-rotate(36deg) brightness(104%) contrast(105%);
}

#answerFieldOuter {
    -webkit-transform-origin: top left !important; // vllt hilft das ja sogar, sonst hab ich das mal für morgen reingeschrieben :D thx. bb.
    background-color: #00000000;
    display: block;
    overflow: hidden !important;
    resize: both !important;

    min-width: 200px;
    min-height: 50px;
}

.answerField {
    font-size: large;
    display: inline-flex;
    border-radius: 8px;

    p {
        max-width: 400px;
        overflow-wrap: break-word;
    }
}

#pupilAnswerTextArea {
    width: 100% !important;
    height: 100% !important;
    resize: none;
    outline: none;

}

.acceptEntryButton {
    width: 36px !important;
    height: 36px !important;

    img {
        width: 20px !important;
        height: 20px !important;
    }
}
</style>

