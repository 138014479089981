const styleHorizontalScrollbar = function() {
  const scrollBarContainer = document.getElementsByClassName('__bar-is-horizontal')[0];
  const arrowLeft = document.createElement('div');
  arrowLeft.style.borderWidth = 'calc(15px)';
  arrowLeft.style.borderStyle = 'solid';
  arrowLeft.style.borderColor= 'transparent rgb(206, 206, 206) transparent transparent';
  arrowLeft.style.borderImage = 'initial';
  arrowLeft.style.maxWidth = '20px';
  arrowLeft.style.height = '30px';
  arrowLeft.style.marginTop = '7.5px';

  const arrowDown = document.createElement('div');
  arrowDown.style.borderWidth = 'calc(15px)';
  arrowDown.style.borderStyle = 'solid';
  arrowDown.style.borderColor= 'transparent transparent transparent rgb(206, 206, 206) ';
  arrowDown.style.borderImage = 'initial';
  arrowDown.style.maxWidth = '20px';
  arrowDown.style.height = '30px';
  arrowDown.style.right = '0';
  arrowDown.style.position = 'absolute';
  arrowDown.style.bottom = '7.5px';

  const upperLine = document.createElement('div');
  upperLine.style.height = '20px';
  upperLine.style.width = '2px';
  upperLine.style.backgroundColor = 'lightgray';
  upperLine.style.position = 'absolute';
  upperLine.style.left = 'calc(50% - 16px)';
  upperLine.style.top = '12.5px';

  const middleLine = document.createElement('div');
  middleLine.style.height = '20px';
  middleLine.style.width = '2px';
  middleLine.style.backgroundColor = 'lightgray';
  middleLine.style.position = 'absolute';
  middleLine.style.left = 'calc(50% - 1px)';
  middleLine.style.top = '12.5px';

  const lowerLine = document.createElement('div');
  lowerLine.style.height = '20px';
  lowerLine.style.width = '2px';
  lowerLine.style.backgroundColor = 'lightgray';
  lowerLine.style.position = 'absolute';
  lowerLine.style.left = 'calc(50% + 14px)';
  lowerLine.style.top = '12.5px';

  scrollBarContainer.appendChild(arrowLeft);
  scrollBarContainer.appendChild(upperLine);
  scrollBarContainer.appendChild(middleLine);
  scrollBarContainer.appendChild(lowerLine);
  scrollBarContainer.appendChild(arrowDown);

  const scrollRailButtons = document.getElementsByClassName('__bar-button');
  if(scrollRailButtons.length) {

    const scrollRailButtonParent = scrollRailButtons[0].parentNode;
    scrollRailButtonParent.removeChild(scrollRailButtons[1]);
    scrollRailButtonParent.removeChild(scrollRailButtons[0]);
  }

  const scrollBarWrapper = document.getElementsByClassName('__bar-wrap-is-horizontal');
  if (scrollBarWrapper.length) {
    scrollBarWrapper[0].style.left = '0';
    scrollBarWrapper[0].style.right = '0';
    scrollBarWrapper[0].style.opacity = '0';
  }
};

const styleVerticalScrollbar = function() {
  const scrollBarContainer = document.getElementsByClassName('__bar-is-vertical')[0];
  const arrowUp = document.createElement('div');
  arrowUp.style.borderWidth = 'calc(13px)';
  arrowUp.style.borderStyle = 'solid';
  arrowUp.style.borderColor= 'transparent transparent rgb(206, 206, 206)';
  arrowUp.style.borderImage = 'initial';
  arrowUp.style.maxWidth = '20px';
  arrowUp.style.height = '30px';
  arrowUp.style.margin = 'auto';

  const arrowDown = document.createElement('div');
  arrowDown.style.borderWidth = 'calc(13px)';
  arrowDown.style.borderStyle = 'solid';
  arrowDown.style.borderColor= 'rgb(206, 206, 206) transparent transparent';
  arrowDown.style.borderImage = 'initial';
  arrowDown.style.maxWidth = '20px';
  arrowDown.style.height = '30px';
  arrowDown.style.bottom = '0';
  arrowDown.style.position = 'absolute';
  arrowDown.style.left = '9.5px';

  const upperLine = document.createElement('div');
  upperLine.style.height = '2px';
  upperLine.style.width = '20px';
  upperLine.style.backgroundColor = 'lightgray';
  upperLine.style.position = 'absolute';
  upperLine.style.top = 'calc(50% - 16px)';
  upperLine.style.left = '12.5px';

  const middleLine = document.createElement('div');
  middleLine.style.height = '2px';
  middleLine.style.width = '20px';
  middleLine.style.backgroundColor = 'lightgray';
  middleLine.style.position = 'absolute';
  middleLine.style.top = 'calc(50% - 1px)';
  middleLine.style.left = '12.5px';

  const lowerLine = document.createElement('div');
  lowerLine.style.height = '2px';
  lowerLine.style.width = '20px';
  lowerLine.style.backgroundColor = 'lightgray';
  lowerLine.style.position = 'absolute';
  lowerLine.style.top = 'calc(50% + 14px)';
  lowerLine.style.left = '12.5px';

  scrollBarContainer.appendChild(arrowUp);
  scrollBarContainer.appendChild(upperLine);
  scrollBarContainer.appendChild(middleLine);
  scrollBarContainer.appendChild(lowerLine);
  scrollBarContainer.appendChild(arrowDown);

  const scrollRailButtons = document.getElementsByClassName('__bar-button');
  if(scrollRailButtons.length) {

    const scrollRailButtonParent = scrollRailButtons[0].parentNode;
    scrollRailButtonParent.removeChild(scrollRailButtons[1]);
    scrollRailButtonParent.removeChild(scrollRailButtons[0]);
  }

  const scrollBarWrapper = document.getElementsByClassName('__bar-wrap-is-vertical');
  if (scrollBarWrapper.length) {
    scrollBarWrapper[0].style.top = '0';
    scrollBarWrapper[0].style.bottom = '0';
    scrollBarWrapper[0].style.opacity = '0';
  }
};

export default {
 styleHorizontalScrollbar,
 styleVerticalScrollbar
}
