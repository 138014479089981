const defaultState = {
    profileManagement: false,
};

const mutations = {
    setProfileManagement(state, value) {
        
        state.profileManagement = value;
    }
};

const actions = {
    toggleProfileManagement({ commit, state }) {
        
        commit("setProfileManagement", !state.profileManagement);
    },
    closeProfileManagement({ commit, state }) {
        
        commit("setProfileManagement", false);
    },
};

export default {
    namespaced: true,
    state: defaultState,
    mutations,
    actions,
};
