<template>
    <div class="text-center">
        <!--v-click-outside="declineCookies"-->
        <v-bottom-sheet
            v-model="notCookiesAccepted"
            inset
            persistent
        >
            <v-card>
                <v-card-title>
                    Datenschutzeinstellungen
                </v-card-title>
                <v-card-text class="mt-3">
                    Wir nutzen auf unserer Website Essentielle Cookies und Cookies von Drittanbietern. Diese Cookies
                    dienen dazu Ihnen eine möglichst angenehme Nutzung zu ermöglichen.
                    <br> <br> Durch klicken auf Akzeptieren, oder die weitere Nutzung unserer Website erklären Sie sich
                    einverstanden Cookies speichern zu lassen.
                    <br> <span
                        style="cursor:pointer; color:blue; text-decoration:underline"
                        @click="datenschutz = true"
                    >Unsere Datenschutzerklärung</span>
                </v-card-text>
                <v-card-text
                    v-if="showAVV"
                    class="mt-3"
                >
                    Die Auftragsdatenverarbeitung in Übereinstimmung mit der EU-DSGVO können Sie <span
                        style="cursor:pointer; color:blue; text-decoration:underline"
                        @click="downloadAVV()"
                    >hier</span> einsehen.
                </v-card-text>
                <v-card-actions class="mx-3">
                    <v-btn
                        outlined
                        large
                        class="declineBtn text-capitalize"
                        @click="declineCookies"
                    >
                        Ablehnen
                    </v-btn>
                    <v-spacer />
                    <v-btn
                        id="acceptCookies"
                        outlined
                        large
                        class="acceptBtn text-capitalize"
                        @click="acceptCookies"
                    >
                        Akzeptieren
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-bottom-sheet>
        <v-bottom-sheet
            v-model="datenschutz"
            inset
            scrollable
        >
            <v-card>
                <v-card-title><h1>Datenschutzerklärung</h1></v-card-title>
                <v-card-text>
                    <p>
                        Verantwortlicher im Sinne der Datenschutzgesetze, insbesondere der EU-Datenschutzgrundverordnung
                        (DSGVO), ist:
                    </p>
                    <p>Waldemar Koch</p>
                    <h2>Ihre Betroffenenrechte</h2>
                    <p>
                        Unter den angegebenen Kontaktdaten unseres Datenschutzbeauftragten können Sie jederzeit folgende
                        Rechte ausüben:
                    </p>
                    <ul>
                        <li>Auskunft über Ihre bei uns gespeicherten Daten und deren Verarbeitung (Art. 15 DSGVO),</li>
                        <li>Berichtigung unrichtiger personenbezogener Daten (Art. 16 DSGVO),</li>
                        <li>Löschung Ihrer bei uns gespeicherten Daten (Art. 17 DSGVO),</li>
                        <li>
                            Einschränkung der Datenverarbeitung, sofern wir Ihre Daten aufgrund gesetzlicher Pflichten
                            noch nicht löschen dürfen (Art. 18 DSGVO),
                        </li>
                        <li>Widerspruch gegen die Verarbeitung Ihrer Daten bei uns (Art. 21 DSGVO) und</li>
                        <li>
                            Datenübertragbarkeit, sofern Sie in die Datenverarbeitung eingewilligt haben oder einen
                            Vertrag mit uns abgeschlossen haben (Art. 20 DSGVO).
                        </li>
                    </ul>
                    <p>
                        Sofern Sie uns eine Einwilligung erteilt haben, können Sie diese jederzeit mit Wirkung für die
                        Zukunft widerrufen.
                    </p>
                    <p>
                        Sie können sich jederzeit mit einer Beschwerde an eine Aufsichtsbehörde wenden, z. B. an die
                        zuständige Aufsichtsbehörde des Bundeslands Ihres Wohnsitzes oder an die für uns als
                        verantwortliche Stelle zuständige Behörde.
                    </p>
                    <p>
                        Eine Liste der Aufsichtsbehörden (für den nichtöffentlichen Bereich) mit Anschrift finden Sie
                        unter: <a
                            href="https://www.bfdi.bund.de/DE/Service/Anschriften/Laender/Laender-node.html"
                            target="_blank"
                            rel="noopener nofollow"
                        >https://www.bfdi.bund.de/DE/Service/Anschriften/Laender/Laender-node.html</a>.
                    </p>
                    <p />
                    <h2>Erfassung allgemeiner Informationen beim Besuch unserer Website</h2>
                    <h3>Art und Zweck der Verarbeitung:</h3>
                    <p>
                        Wenn Sie auf unsere Website zugreifen, d.h., wenn Sie sich nicht registrieren oder anderweitig
                        Informationen übermitteln, werden automatisch Informationen allgemeiner Natur erfasst. Diese
                        Informationen (Server-Logfiles) beinhalten etwa die Art des Webbrowsers, das verwendete
                        Betriebssystem, den Domainnamen Ihres Internet-Service-Providers, Ihre IP-Adresse und
                        ähnliches.
                    </p>
                    <p>Sie werden insbesondere zu folgenden Zwecken verarbeitet:</p>
                    <ul>
                        <li>Sicherstellung eines problemlosen Verbindungsaufbaus der Website,</li>
                        <li>Sicherstellung einer reibungslosen Nutzung unserer Website,</li>
                        <li>Auswertung der Systemsicherheit und -stabilität sowie</li>
                        <li>zur Optimierung unserer Website.</li>
                    </ul>
                    <p>
                        Wir verwenden Ihre Daten nicht, um Rückschlüsse auf Ihre Person zu ziehen. Informationen dieser
                        Art werden von uns ggfs. anonymisiert statistisch ausgewertet, um unseren Internetauftritt und
                        die dahinterstehende Technik zu optimieren.
                    </p>
                    <h3>Rechtsgrundlage und berechtigtes Interesse:</h3>
                    <p>
                        Die Verarbeitung erfolgt gemäß Art. 6 Abs. 1 lit. f DSGVO auf Basis unseres berechtigten
                        Interesses an der Verbesserung der Stabilität und Funktionalität unserer Website.
                    </p>
                    <h3>Empfänger:</h3>
                    <p>
                        Empfänger der Daten sind ggf. technische Dienstleister, die für den Betrieb und die Wartung
                        unserer Webseite als Auftragsverarbeiter tätig werden.
                    </p>
                    <p />
                    <h3>Drittlandtransfer:</h3>
                    <p>Die erhobenen Daten werden ggfs. in folgende Drittländer übertragen: </p>
                    <p>Nein</p>
                    <p>Folgende Datenschutzgarantien liegen vor: </p>
                    <p />
                    <h3>Speicherdauer:</h3>
                    <p>
                        Die Daten werden gelöscht, sobald diese für den Zweck der Erhebung nicht mehr erforderlich sind.
                        Dies ist für die Daten, die der Bereitstellung der Website dienen, grundsätzlich der Fall, wenn
                        die jeweilige Sitzung beendet ist.
                    </p>
                    <p>
                        Im Falle der Speicherung der Daten in Logfiles ist dies nach spätestens 14 Tagen der Fall. Eine
                        darüberhinausgehende Speicherung ist möglich. In diesem Fall werden die IP-Adressen der Nutzer
                        anonymisiert, sodass eine Zuordnung des aufrufenden Clients nicht mehr möglich ist.
                    </p>
                    <p />
                    <h3>Bereitstellung vorgeschrieben oder erforderlich:</h3>
                    <p>
                        Die Bereitstellung der vorgenannten personenbezogenen Daten ist weder gesetzlich noch vertraglich
                        vorgeschrieben. Ohne die IP-Adresse ist jedoch der Dienst und die Funktionsfähigkeit unserer
                        Website nicht gewährleistet. Zudem können einzelne Dienste und Services nicht verfügbar oder
                        eingeschränkt sein. Aus diesem Grund ist ein Widerspruch ausgeschlossen.
                    </p>
                    <p />
                    <h2>Cookies</h2>
                    <p>
                        Wie viele andere Webseiten verwenden wir auch so genannte „Cookies“. Bei Cookies handelt es sich
                        um kleine Textdateien, die auf Ihrem Endgerät (Laptop, Tablet, Smartphone o.ä.) gespeichert
                        werden, wenn Sie unsere Webseite besuchen.
                    </p>
                    <p>
                        Sie können Sie einzelne Cookies oder den gesamten Cookie-Bestand löschen. Darüber hinaus erhalten
                        Sie Informationen und Anleitungen, wie diese Cookies gelöscht oder deren Speicherung vorab
                        blockiert werden können. Je nach Anbieter Ihres Browsers finden Sie die notwendigen
                        Informationen unter den nachfolgenden Links:
                    </p>
                    <ul>
                        <li>
                            Mozilla Firefox: <a
                                href="https://support.mozilla.org/de/kb/cookies-loeschen-daten-von-websites-entfernen"
                                target="_blank"
                                rel="nofollow noopener"
                            >https://support.mozilla.org/de/kb/cookies-loeschen-daten-von-websites-entfernen</a>
                        </li>
                        <li>
                            Internet Explorer: <a
                                href="https://support.microsoft.com/de-de/help/17442/windows-internet-explorer-delete-manage-cookies"
                                target="_blank"
                                rel="nofollow noopener"
                            >https://support.microsoft.com/de-de/help/17442/windows-internet-explorer-delete-manage-cookies</a>
                        </li>
                        <li>
                            Google Chrome: <a
                                href="https://support.google.com/accounts/answer/61416?hl=de"
                                target="_blank"
                                rel="nofollow noopener"
                            >https://support.google.com/accounts/answer/61416?hl=de</a>
                        </li>
                        <li>
                            Opera: <a
                                href="http://www.opera.com/de/help"
                                target="_blank"
                                rel="nofollow noopener"
                            >http://www.opera.com/de/help</a>
                        </li>
                        <li>
                            Safari: <a
                                href="https://support.apple.com/kb/PH17191?locale=de_DE&viewlocale=de_DE"
                                target="_blank"
                                rel="nofollow noopener"
                            >https://support.apple.com/kb/PH17191?locale=de_DE&viewlocale=de_DE</a>
                        </li>
                    </ul>
                    <h3>Speicherdauer und eingesetzte Cookies:</h3>
                    <p>
                        Soweit Sie uns durch Ihre Browsereinstellungen oder Zustimmung die Verwendung von Cookies
                        erlauben, können folgende Cookies auf unseren Webseiten zum Einsatz kommen:
                    </p>
                    <p />
                    <h2>Technisch notwendige Cookies </h2>
                    <h3>Art und Zweck der Verarbeitung: </h3>
                    <p>
                        Wir setzen Cookies ein, um unsere Website nutzerfreundlicher zu gestalten. Einige Elemente
                        unserer Internetseite erfordern es, dass der aufrufende Browser auch nach einem Seitenwechsel
                        identifiziert werden kann.
                    </p>
                    <p>
                        Der Zweck der Verwendung technisch notwendiger Cookies ist, die Nutzung von Websites für die
                        Nutzer zu vereinfachen. Einige Funktionen unserer Internetseite können ohne den Einsatz von
                        Cookies nicht angeboten werden. Für diese ist es erforderlich, dass der Browser auch nach einem
                        Seitenwechsel wiedererkannt wird.
                    </p>
                    <p>Für folgende Anwendungen benötigen wir Cookies:</p>
                    <p />
                    <h3>Rechtsgrundlage und berechtigtes Interesse: </h3>
                    <p>
                        Die Verarbeitung erfolgt gemäß Art. 6 Abs. 1 lit. f DSGVO auf Basis unseres berechtigten
                        Interesses an einer nutzerfreundlichen Gestaltung unserer Website.
                    </p>
                    <h3>Empfänger: </h3>
                    <p>
                        Empfänger der Daten sind ggf. technische Dienstleister, die für den Betrieb und die Wartung
                        unserer Website als Auftragsverarbeiter tätig werden.
                    </p>
                    <p />
                    <h3>Bereitstellung vorgeschrieben oder erforderlich:</h3>
                    <p>
                        Die Bereitstellung der vorgenannten personenbezogenen Daten ist weder gesetzlich noch vertraglich
                        vorgeschrieben. Ohne diese Daten ist jedoch der Dienst und die Funktionsfähigkeit unserer
                        Website nicht gewährleistet. Zudem können einzelne Dienste und Services nicht verfügbar oder
                        eingeschränkt sein.
                    </p>
                    <h3>Widerspruch</h3>
                    <p>Lesen Sie dazu die Informationen über Ihr Widerspruchsrecht nach Art. 21 DSGVO weiter unten.</p>
                    <p />
                    <h2>Technisch nicht notwendige Cookies</h2>
                    <p>
                        Des Weiteren setzen wir Cookies ein, um das Angebot auf unserer Website besser auf die Interessen
                        unserer Besucher abzustimmen oder auf Basis statistischer Auswertungen allgemein zu
                        verbessern.
                    </p>
                    <p>
                        Welche Anbieter Cookies setzen, entnehmen Sie bitte den unten aufgeführten Informationen zu den
                        eingesetzten Darstellungs-, Tracking-, Remarketing- und Webanalyse-Technologien.
                    </p>
                    <h3>Rechtsgrundlage:</h3>
                    <p>
                        Rechtsgrundlage für diese Verarbeitungen ist jeweils Ihre Einwilligung, Art. 6 Abs. 1 lit. a
                        DSGVO.
                    </p>
                    <h3>Empfänger:</h3>
                    <p>
                        Empfänger der Daten sind ggf. technische Dienstleister, die für den Betrieb und die Wartung
                        unserer Website als Auftragsverarbeiter tätig werden.
                    </p>
                    <p>
                        Weitere Empfänger entnehmen Sie bitte den unten aufgeführten Informationen zu den eingesetzten
                        Darstellungs-, Tracking-, Remarketing- und Webanalyse-Technologien.
                    </p>
                    <h3>Drittlandtransfer:</h3>
                    <p>
                        Informationen hierzu entnehmen Sie bitte aus den Auflistungen der einzelnen Darstellungs-,
                        Tracking-, Remarketing- und Webanalyse-Anbietern.
                    </p>
                    <h3>Bereitstellung vorgeschrieben oder erforderlich:</h3>
                    <p>
                        Natürlich können Sie unsere Website grundsätzlich auch ohne Cookies betrachten. Webbrowser sind
                        regelmäßig so eingestellt, dass sie Cookies akzeptieren. Im Allgemeinen können Sie die
                        Verwendung von Cookies jederzeit über die Einstellungen Ihres Browsers deaktivieren (siehe
                        Widerruf der Einwilligung).
                    </p>
                    <p>
                        Bitte beachten Sie, dass einzelne Funktionen unserer Website möglicherweise nicht funktionieren,
                        wenn Sie die Verwendung von Cookies deaktiviert haben.
                    </p>
                    <h3>Widerruf der Einwilligung:</h3>
                    <p>Sie können Ihre Einwilligung jederzeit über unser Cookie-Consent-Tool widerrufen. </p>
                    <h3>Profiling:</h3>
                    <p>
                        Inwiefern wir das Verhalten von Websitebesuchern mit pseudonymisierten Nutzerprofilen
                        analysieren, entnehmen Sie bitte den unten aufgeführten Informationen zu den eingesetzten
                        Darstellungs-, Tracking-, Remarketing- und Webanalyse-Technologien.
                    </p>
                    <p />
                    <h2>Registrierung auf unserer Website</h2>
                    <h3>Art und Zweck der Verarbeitung:</h3>
                    <p>
                        Für die Registrierung auf unserer Website benötigen wir einige personenbezogene Daten, die über
                        eine Eingabemaske an uns übermittelt werden.
                    </p>
                    <p>Zum Zeitpunkt der Registrierung werden zusätzlich folgende Daten erhoben:</p>
                    <p>IP-Adresse, Zeitstempel</p>
                    <p>
                        Ihre Registrierung ist für das Bereithalten bestimmter Inhalte und Leistungen auf unserer Website
                        erforderlich.
                    </p>
                    <h3>Rechtsgrundlage:</h3>
                    <p>
                        Die Verarbeitung der bei der Registrierung eingegebenen Daten erfolgt auf Grundlage einer
                        Einwilligung des Nutzers (Art. 6 Abs. 1 lit. a DSGVO).
                    </p>
                    <h3>Empfänger:</h3>
                    <p>
                        Empfänger der Daten sind ggf. technische Dienstleister, die für den Betrieb und die Wartung
                        unserer Website als Auftragsverarbeiter tätig werden.
                    </p>
                    <p />
                    <h3>Drittlandtransfer:</h3>
                    <p>Die erhobenen Daten werden ggfs. in folgende Drittländer übertragen: </p>
                    <p>Nein</p>
                    <p>Folgende Datenschutzgarantien liegen vor: </p>
                    <p />
                    <h3>Speicherdauer:</h3>
                    <p>
                        Daten werden in diesem Zusammenhang nur verarbeitet, solange die entsprechende Einwilligung
                        vorliegt.
                    </p>
                    <h3>Bereitstellung vorgeschrieben oder erforderlich:</h3>
                    <p>
                        Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig, allein auf Basis Ihrer
                        Einwilligung. Ohne die Bereitstellung Ihrer personenbezogenen Daten können wir Ihnen keinen
                        Zugang auf unsere angebotenen Inhalte gewähren.
                    </p>
                    <p />
                    <h2>SSL-Verschlüsselung</h2>
                    <p>
                        Um die Sicherheit Ihrer Daten bei der Übertragung zu schützen, verwenden wir dem aktuellen Stand
                        der Technik entsprechende Verschlüsselungsverfahren (z. B. SSL) über HTTPS.
                    </p>
                    <p />
                    <hr>
                    <h2>Information über Ihr Widerspruchsrecht nach Art. 21 DSGVO</h2>
                    <h3>Einzelfallbezogenes Widerspruchsrecht</h3>
                    <p>
                        Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit
                        gegen die Verarbeitung Sie betreffender personenbezogener Daten, die aufgrund Art. 6 Abs. 1 lit.
                        f DSGVO (Datenverarbeitung auf der Grundlage einer Interessenabwägung) erfolgt, Widerspruch
                        einzulegen; dies gilt auch für ein auf diese Bestimmung gestütztes Profiling im Sinne von Art. 4
                        Nr. 4 DSGVO.
                    </p>
                    <p>
                        Legen Sie Widerspruch ein, werden wir Ihre personenbezogenen Daten nicht mehr verarbeiten, es sei
                        denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre
                        Interessen, Rechte und Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung,
                        Ausübung oder Verteidigung von Rechtsansprüchen.
                    </p>
                    <h3>Empfänger eines Widerspruchs</h3>
                    <p>concept hero UG (haftungsbeschränkt)</p>
                    <hr>
                    <h2>Änderung unserer Datenschutzbestimmungen</h2>
                    <p>
                        Wir behalten uns vor, diese Datenschutzerklärung anzupassen, damit sie stets den aktuellen
                        rechtlichen Anforderungen entspricht oder um Änderungen unserer Leistungen in der
                        Datenschutzerklärung umzusetzen, z.B. bei der Einführung neuer Services. Für Ihren erneuten
                        Besuch gilt dann die neue Datenschutzerklärung.
                    </p>
                    <h2>Fragen an den Datenschutzbeauftragten</h2>
                    <p>
                        Wenn Sie Fragen zum Datenschutz haben, schreiben Sie uns bitte eine E-Mail oder wenden Sie sich
                        direkt an die für den Datenschutz verantwortliche Person in unserer Organisation:
                    </p>
                    <p>Waldemar Koch</p>
                    <p>
                        <em>Die Datenschutzerklärung wurde mithilfe der activeMind AG erstellt, den Experten für <a
                            href="https://www.activemind.de/datenschutz/datenschutzbeauftragter/"
                            target="_blank"
                            rel="noopener"
                        >externe Datenschutzbeauftragte</a> (Version #2020-09-30).</em>
                    </p>
                </v-card-text>
                <v-card-actions>
                    <v-btn @click="datenschutz = false">
                        Zurück
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-bottom-sheet>
    </div>
</template>

<script>
import {mapState} from "vuex";
import {checkCookieAccepted, getCookie, setCookie} from '@/util/cookies';
import * as backend from "./../api/backend";

export default {
    name: "CookieLaw",
    data() {
        return {
            notCookiesAccepted: true,
            datenschutz: false,
            showAVV: false,
        }
    },
    computed: {
        ...mapState("util", ["showCookieLawWatcher"]),
    },
    watch: {
        showCookieLawWatcher() {
            this.notCookiesAccepted = true;
        }
    },
    async mounted() {
        this.checkCookies("eklara_cookielaw_accepted");
        const res = await backend.isAVVSigned();
        if (res.status===200) {
            this.showAVV = true;
        }
    },
    methods: {
        async checkCookies() {
            this.notCookiesAccepted = !checkCookieAccepted();
        },
        async acceptCookies() {
            setCookie("eklara_cookielaw_accepted", true, 365)
            // setCookie("testCookie",true,365)
            this.notCookiesAccepted = false;
        },

        declineCookies() {
            this.notCookiesAccepted = false;
        },

        async downloadAVV() {
            const res = await backend.getAVVSigned();
            let blob = await res.blob();
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement("a");
            a.href = url;
            a.download = 'AVV_eklara';
            document.body.appendChild(a);
            a.click();
            setTimeout(function () {
                a.remove();
                window.URL.revokeObjectURL(url);
            }, 1000);
        },
    }
}
</script>

<style scoped lang="scss">

.acceptBtn {
    color: white;
    background-color: var(--v-success-base);
    width: 25%;
}

.declineBtn {
    color: white;
    background-color: var(--v-error-base);
    width: 25%;
}

@media only screen and (max-width: 500px) {

    .acceptBtn {
        width: 210px;
    }

}
</style>
