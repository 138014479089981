<template>
    <div>
        <v-row class="mx-0">
            <div class="whiteBg widgetContainer">
                <!--Header of Stundenplan-->
                <div style="background-color: #6995CD; border-radius: 15px 15px 0 0; padding-top: 10px; padding-bottom: 10px; color: white;">
                    <v-row
                        v-if="windowWidth > 600"
                        style="max-width: 100%; height: 35px"
                        class="mx-2 d-flex align-center justify-space-between"
                    >
                        <v-col
                            cols="9"
                            md="8"
                            lg="8"
                            class="pa-0 pl-2 d-flex align-center"
                            style="font-size: larger"
                        >
                            <img
                                :src="stundenplanIcon"
                                style="height: 20px;"
                                class="iconToWhite mr-1"
                                alt=""
                            >
                            Stundenplan
                        </v-col>

                        <div class="d-flex justify-end align-center">
                            <div
                                class="pa-0 calWeek"
                                style="font-size: medium"
                            >
                                KW {{ getMonday(date).getWeek() }}
                            </div>

                            <!-- <div class="pa-0">
                                <v-btn
                                    v-if="!small"
                                    x-small
                                    class="ml-2"
                                    style="background-color: #f8f8f880; width: 35px; height: 35px"
                                    @click="() => { showWeeklyViewDialog = !showWeeklyViewDialog; requestAppointments() }"
                                >
                                    <img
                                        :src="vollbildIcon"
                                        alt="Vollbild"
                                        style="height: 20px;"
                                        class="iconToWhite"
                                    >
                                </v-btn>
                            </div>-->
                        </div>
                    </v-row>

                    <v-row
                        v-else
                        style="max-width: 100%; height: 35px; max-height: 35px;"
                        class="d-flex align-center justify-space-between"
                        :class="windowWidth > 340 ? 'mx-2' : 'mx-1'"
                    >
                        <v-col
                            class="pa-0 d-flex align-center justify-space-between"
                            :class="dateMargin(windowWidth)"
                            :cols="calcCols(windowWidth)"
                        >
                            <v-badge
                                color="red"
                                :content="past"
                                :value="past"
                                overlap
                                left
                            >
                                <v-btn
                                    icon
                                    @click="subDay"
                                >
                                    <img
                                        :src="linksIcon"
                                        class="iconToWhite"
                                        style="height: 20px;"
                                        alt="Vorheriger Tag"
                                    >
                                </v-btn>
                            </v-badge>

                            <v-menu
                                v-model="datePickerMenu"
                                :close-on-content-click="false"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <p
                                        v-bind="attrs"
                                        class="planWidgetDisplayDate mb-0 mx-1"
                                        style="color: #ffffff; font-weight: 500"
                                        v-on="on"
                                    >
                                        {{ getDisplayDateShort() }}
                                    </p>
                                </template>

                                <v-date-picker
                                    v-model="pickerDate"
                                    :allowed-dates="weekdaysOnly"
                                    locale="de-DE"
                                    first-day-of-week="1"
                                    no-title
                                    show-week
                                >
                                    <v-spacer />
                                    <v-btn
                                        text
                                        class="text-capitalize"
                                        color="gruen"
                                        @click="setPickerDate"
                                    >
                                        Bestätigen
                                    </v-btn>
                                </v-date-picker>
                            </v-menu>

                            <v-badge
                                color="red"
                                :content="future"
                                :value="future"
                                overlap
                            >
                                <v-btn
                                    icon
                                    @click="addDay"
                                >
                                    <img
                                        :src="rechtsIcon"
                                        class="iconToWhite"
                                        style="height: 24px;"
                                        alt="Nächster Tag"
                                    >
                                </v-btn>
                            </v-badge>
                        </v-col>

                        <v-col
                            cols="4"
                            class="pa-0 d-flex align-center justify-end"
                            :class="todayMargin(windowWidth)"
                        >
                            <div
                                class="calWeek mr-1"
                                :style="windowWidth > 370 ? 'font-size: medium' : 'font-size: 12px'"
                            >
                                KW {{ getMonday(date).getWeek() }}
                            </div>

                            <v-btn
                                elevation="0"
                                small
                                style="background-color: #f8f8f880; color: #ffffff"
                                class="ml-1 pa-0 text-capitalize"
                                @click="jumpToCurrentDay()"
                            >
                                Heute
                            </v-btn>
                        </v-col>
                    </v-row>
                </div>

                <div
                    style="border-radius: 15px"
                    class="whiteBg"
                >
                    <!--menu bar of Stundenplan-->
                    <div
                        v-if="windowWidth > 600"
                        style="width: 99%; margin: 0 auto 0.5em;"
                        class="whiteBg d-flex justify-space-between pt-3"
                    >
                        <div
                            class="d-flex align-center justify-center pl-2 mr-2"
                            :style="windowWidth < 1100 ? 'width: 44px' : 'width: 64px'"
                        >
                            <v-btn
                                elevation="0"
                                :class="isDisplayDateToday(getDisplayDate()) ? 'planWidgetTodayButton' : 'planWidgetTodayButtonActive'"
                                class="text-capitalize pa-0"
                                :small="windowWidth < 1100"
                                @click="jumpToCurrentDay()"
                            >
                                Heute
                            </v-btn>
                        </div>

                        <div
                            class="d-flex align-center justify-space-between"
                            :style="windowWidth < 1100 ? 'width: calc(100% - 44px)' : 'width: calc(100% - 64px)'"
                        >
                            <div
                                class="d-flex justify-center"
                                :class="windowWidth < 950 ? 'px-1' : 'px-2'"
                            >
                                <v-badge
                                    color="red"
                                    :content="past"
                                    :value="past"
                                    overlap
                                    left
                                >
                                    <v-btn
                                        icon
                                        small
                                        @click="subDay"
                                    >
                                        <img
                                            :src="linksIcon"
                                            class="iconToBlue"
                                            style="height: 24px;"
                                            alt="Vorheriger Tag"
                                        >
                                    </v-btn>
                                </v-badge>
                            </div>

                            <div class="text-center d-flex justify-center align-center">
                                <v-menu
                                    v-model="datePickerMenu"
                                    :close-on-content-click="false"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <p
                                            v-bind="attrs"
                                            class="planWidgetDisplayDate"
                                            :style="isDisplayDateToday(getDisplayDate()) ? 'color: #6995CD; font-weight: bold !important': 'color: var(--v-dunkelgrau-base); font-weight: 500'"
                                            v-on="on"
                                        >
                                            {{ getDisplayDate() }}
                                        </p>
                                    </template>

                                    <v-date-picker
                                        v-model="pickerDate"
                                        :allowed-dates="weekdaysOnly"
                                        locale="de-DE"
                                        first-day-of-week="1"
                                        no-title
                                        show-week
                                    >
                                        <v-spacer />
                                        <v-btn
                                            text
                                            class="text-capitalize"
                                            color="gruen"
                                            @click="setPickerDate"
                                        >
                                            Bestätigen
                                        </v-btn>
                                    </v-date-picker>
                                </v-menu>
                            </div>

                            <div
                                class="d-flex justify-center"
                                :class="windowWidth < 950 ? 'px-1' : 'px-2'"
                            >
                                <v-badge
                                    color="red"
                                    :content="future"
                                    :value="future"
                                    overlap
                                >
                                    <v-btn
                                        icon
                                        small
                                        @click="addDay"
                                    >
                                        <img
                                            :src="rechtsIcon"
                                            class="iconToBlue"
                                            style="height: 24px;"
                                            alt="Nächster Tag"
                                        >
                                    </v-btn>
                                </v-badge>
                            </div>
                        </div>
                    </div>

                    <vue-custom-scrollbar
                        class="scroll-area"
                        :settings="settings"
                    >
                        <table style="width: 99%; margin: 0.5em auto 5em auto; color: var(--v-dunkelgrau-base)">
                            <v-row
                                v-for="(item,index) in slots"
                                :key="index"
                                cols="12"
                                style="width: 99%; margin: 0.1em auto 0.1em auto;"
                            >
                                <v-col
                                    cols="2"
                                    style="font-weight: normal; color: var(--v-dunkelgrau-base)"
                                    class="text-center pa-2"
                                >
                                    {{ ("0" + new Date(item.start).getHours()).slice(-2) + ":" + ("0" + new Date(item.start).getMinutes()).slice(-2) }}
                                    <br>
                                    {{ ("0" + new Date(item.end).getHours()).slice(-2) + ":" + ("0" + new Date(item.end).getMinutes()).slice(-2) }}
                                </v-col>
                                <v-col
                                    cols="10"
                                    class="pa-0 pl-4"
                                >
                                    <div v-if="getAppointmentsToday(item._id).length">
                                        <div
                                            v-for="el in getAppointmentsToday(item._id)"
                                            :key="el._id"
                                        >
                                            <Fach
                                                :is-running="isTimeslotCurrentlyRunningToday(item)"
                                                :is-open="el.open"
                                                :appointment="el"
                                                :request-appointments="requestAppointments"
                                                @pass-on-bbb-tut="receivedEmit"
                                            />
                                            <!-- Disable vue/no-v-html because appointment note is sanitized on backend-->
                                            <!-- eslint-disable vue/no-v-html -->
                                            <div
                                                v-if="el.note"
                                                style="display: flex; flex-flow: column; height: 100%; justify-content: center; padding-right: 20px;"
                                                v-html="parseHtmlPreview(el.note)"
                                            />
                                            <!-- eslint-enable vue/no-v-html -->
                                        </div>
                                    </div>

                                    <div v-else>
                                        <Fach
                                            :is-running="isTimeslotCurrentlyRunningToday(item)"
                                            :request-appointments="requestAppointments"
                                            @pass-on-bbb-tut="receivedEmit"
                                        />
                                    </div>
                                </v-col>
                            </v-row>
                        </table>
                    </vue-custom-scrollbar>
                </div>
            </div>
        </v-row>

        <!--fullscreen/weekly view of stundenplan-->
        <WeeklyViewDialog
            v-if="showWeeklyViewDialog"
            :slots-prop="slots"
            :appointments-prop="appointments"
            @closeDialog="() => { showWeeklyViewDialog = false; }"
        />
    </div>
</template>

<script>
import vueCustomScrollbar from "vue-custom-scrollbar";
import Fach from "./FachLehrer"
import WeeklyViewDialog from "../Stundenplan/WeeklyViewDialog";
import linksIcon from '../../assets/Icons/links-filled-10.svg'
import rechtsIcon from '../../assets/Icons/rechts-filled-11.svg'
import stundenplanIcon from '../../assets/Icons/timetable.svg'

import {mapState, mapMutations, mapActions, mapGetters} from "vuex";
import { parseHtml } from "@/util/filePreviewUtils";

export default {
    name: "PlanWidget",
    components: {
        Fach,
        vueCustomScrollbar,
        WeeklyViewDialog,
    },
    props: {
        small: { required: false, default: false },
    },
    data() {
        return {
            settings: {
                suppressScrollY: false,
                suppressScrollX: true,
                wheelPropagation: false,
            },

            future: 0,
            past: 0,

            futureWeek: 0,
            pastWeek: 0,

            date: this.getDay(),    //is being changed when date in calendar is changed
            today: this.getDay(),   //don't change! reference to highlight current day
            // appointments: [],    // Not needed anymore
            person: null,
            slots: [],
            noJump: false, //to prevent to jump to the current date
            showWeeklyViewDialog: false,
            pickerDate : new Date().toISOString().substr(0, 10),
            datePickerMenu: false,
            linksIcon,
            rechtsIcon,
            stundenplanIcon,
        };
    },
    computed: {
        ...mapGetters('util', ['currentlyOpenAppointment']),
        ...mapState('util', ['windowWidth']),
        ...mapGetters('appointments', ['appointments', 'appointment']),
    },
    watch: {
        currentlyOpenAppointment(newVal, oldVal) {
            if (!newVal && oldVal) {
                this.requestAppointments();
            }
        }
    },
    beforeCreate() {
        // Returns the ISO week of the date.
        Date.prototype.getWeek = function() {
            var date = new Date(this.getTime());
            date.setHours(0, 0, 0, 0);
            // Thursday in current week decides the year.
            date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
            // January 4 is always in week 1.
            var week1 = new Date(date.getFullYear(), 0, 4);
            // Adjust to Thursday in week 1 and count number of weeks from date to week1.
            return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000
                - 3 + (week1.getDay() + 6) % 7) / 7);
        }
    },
    async mounted() {
        this.requestSlots();
        this.requestAppointments();
        this.requestPerson();
        this.jumpToCurrentDay();    //because somehow it jumps to a different day sometimes

        setTimeout(function(){ speechSynthesis.cancel(); }, 500);   //if you spam through tutorial this should cancel speaking
    },
    methods: {
        ...mapActions("appointments", ["getAppointments"]),
        ...mapActions("timeslots", ["getSlots"]),
        ...mapActions("teachers", ["getMeTeacher"]),
        ...mapActions("auth", ["logoutUser"]),

        receivedEmit(callback) {
            /*emit to PlanLehrer*/
            this.$emit('start-bbb-tut', callback);
        },

        checkParams() {
            let appointment = this.appointments.find((el) => {
                return this.$route.query.appointment === el._id || this.$route.query.aId === el._id
            });
            if(appointment) {
                this.noJump = true;
                this.date = new Date(appointment.day);

                // remove needed query because they fulfilled their purpose
                setTimeout(async () => {
                    await this.$router.replace({ query: { appointment: undefined } });
                }, 1000);
            }
        },
        async requestAppointments(fetch) {
            await this.getAppointments(fetch);
            this.checkParams();
            this.countBadges();
            this.countBadgesWeek();
        },

        async requestSlots() {
            this.slots = await this.getSlots();
        },

        async requestPerson() {
            this.person = await this.getMeTeacher();
            return this.person;
        },
        jumpToCurrentDay() {
            if(!this.noJump) {
                this.date = this.today;
            }else {
                this.noJump = false;
            }

        },
        addDay() {
            do {
                this.date = new Date(this.date .getFullYear(), this.date .getMonth(), this.date .getDate()+1);
            } while (!(this.date .getDay() % 6))
            this.countBadges();
            this.countBadgesWeek();
        },
        subDay() {
            do {
                this.date = new Date(this.date .getFullYear(), this.date .getMonth(), this.date .getDate()-1);
            } while (!(this.date .getDay() % 6))
            this.countBadges();
            this.countBadgesWeek();
        },
        //compare displayDate with today, return true if equal, to color current day
        isDisplayDateToday(compareDate) {
            let heute, monat;
            let jahr = (this.today).toString().slice(13, 15);
            let tag = (this.today).toString().slice(8, 10);

            //from month string to number
            switch ((this.today).toString().slice(4, 7)) {
                case "Jan":
                    monat = "01"; break;
                case "Feb":
                    monat = "02"; break;
                case "Mar":
                    monat = "03"; break;
                case "Apr":
                    monat = "04"; break;
                case "May":
                    monat = "05"; break;
                case "Jun":
                    monat = "06"; break;
                case "Jul":
                    monat = "07"; break;
                case "Aug":
                    monat = "08"; break;
                case "Sep":
                    monat = "09"; break;
                case "Oct":
                    monat = "10"; break;
                case "Nov":
                    monat = "11"; break;
                case "Dec":
                    monat = "12"; break;
            }

            heute = tag + "." + monat + "." + jahr;

            if ((compareDate.toString()).slice(5, compareDate.toString().length) === heute) {
                return true;
            }
            else {
                return false;
            }
        },
        getDisplayDate() {
            let heute = new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate());
            let datum, tag;

            switch (heute.getDay()) {
                case 0:
                    tag = ("So.");
                    break;
                case 1:
                    tag = ("Mo.");
                    break;
                case 2:
                    tag = ("Di.");
                    break;
                case 3:
                    tag = ("Mi.");
                    break;
                case 4:
                    tag = ("Do.");
                    break;
                case 5:
                    tag = ("Fr.");
                    break;
                case 6:
                    tag = ("Sa.");
                    break;
                default:
                    tag = ("TEST");
            }
            //add leading zeros to date
            //for day and month
            if (heute.getDate() < 10 && heute.getMonth()+1 < 10) {
                datum = (tag + ", 0" + (heute.getDate()) + '.0' + (heute.getMonth()+1) + '.' + heute.getFullYear().toString().slice(-2));
            } //for day
            else if (heute.getDate() < 10 ) {
                datum = (tag + ", 0" + (heute.getDate()) + '.' + (heute.getMonth()+1) + '.' + heute.getFullYear().toString().slice(-2));
            } //for month
            else if (heute.getMonth()+1 < 10 ) {
                datum = (tag + ", " + (heute.getDate()) + '.0' + (heute.getMonth()+1) + '.' + heute.getFullYear().toString().slice(-2));
            } //regular
            else {
                datum = (tag + ", " + (heute.getDate()) + '.' + (heute.getMonth()+1) + '.' + heute.getFullYear().toString().slice(-2));
            }
            return datum;
        },
        getDisplayDateShort() {
          return this.getDisplayDate().slice(0,11);
        },
        getDay() {
            let date = new Date();
            while (!(date .getDay() % 6)) {
                date = new Date(date .getFullYear(), date .getMonth(), date .getDate()-1);
            }
            return date;
        },
        getMonday(d) {
            d = new Date(d);
            let day = d.getDay(),
                diff = d.getDate() - day + (day === 0 ? -6:1); // adjust when day is sunday
            return new Date(d.setDate(diff));
        },
        getAppointmentToday(slot) {
            return this.appointments.find((el) => { return (el.timeslot === slot && this.sameDay(new Date(el.day), this.date))})
        },
        getAppointmentsToday(slot) {
            return this.appointments.filter((el) => { return (el.timeslot === slot && this.sameDay(new Date(el.day), this.date))})
        },
        countBadges() {
            this.future = 0;
            this.past = 0;
            this.appointments.forEach((el) => {
                if(!this.sameDay(new Date(el.day), this.date)){
                    if(new Date(el.day) > this.date) {
                        this.future += el.pupilUpload.filter((element) => !element.seenByMe).length;
                    } else {
                        this.past += el.pupilUpload.filter((element) => !element.seenByMe).length;
                    }
                }
            })
        },

        countBadgesWeek() {
            this.futureWeek = 0;
            this.pastWeek = 0;
            this.appointments.forEach((el) => {
                if(!(new Date(el.day).getWeek() === this.date.getWeek())){
                    if(new Date(el.day) > this.date) {
                        this.futureWeek += el.pupilUpload.filter((element) => !element.seenByMe).length;
                    } else {
                        this.pastWeek += el.pupilUpload.filter((element) => !element.seenByMe).length;
                    }
                }
            })
        },

        sameDay(first, second) {
            return   first.getFullYear() === second.getFullYear() &&
                first.getMonth() === second.getMonth() &&
                first.getDate() === second.getDate();
        },
        isTimeslotCurrentlyRunningToday(timeslot) {
            const currentTimeAndDay = new Date();
            const appointment = this.getAppointmentToday(timeslot._id);
            if (appointment) {
                const appointmentDate = new Date(appointment.day).toDateString();
                if (appointmentDate === currentTimeAndDay.toDateString()) {
                    // If we are in here that means the appointment is today
                    if (new Date(timeslot.start).toLocaleTimeString('de-DE') <= currentTimeAndDay.toLocaleTimeString('de-DE')
                        && new Date(timeslot.end).toLocaleTimeString('de-DE') > currentTimeAndDay.toLocaleTimeString('de-DE')) {
                        // Currenttime is between start and end of timeslot
                        return true;
                    }
                }
            }
            return false;
        },

        calcCols(width) {
            if (width > 450) {
                return '5'
            }
            else if (width > 380) {
                return '6'
            }
            else {
                return '7'
            }
        },

        dateMargin(width) {
            if (width > 400) {
                return 'ml-4'
            }
            else if (width > 350) {
                return 'ml-2'
            }
            else {
                return 'ml-1'
            }
        },

        todayMargin(width) {
            if (width > 460) {
                return 'mr-8'
            }
            else if (width > 400) {
                return 'mr-6'
            }
            else if (width > 355) {
                return 'mr-4'
            }
            else {
                return 'mr-2'
            }
        },
        parseHtmlPreview(text) {
            return parseHtml(text);
        },

        //take date from date picker and reformat it to set it to this.date
        setPickerDate() {
            this.datePickerMenu = false;
            let pickerDate = new Date(this.pickerDate);
            this.date = new Date(pickerDate.getFullYear(), pickerDate.getMonth(), pickerDate.getDate());
        },

        //only allow weekdays to be selected in date picker
        weekdaysOnly(val) {
            let date = new Date(val);
            //saturday is weekday 0, sunday is weekday 6, so monday-friday is 1-5
            return date.getDay() > 0 && date.getDay() < 6;
        },
    },
};
</script>

<style lang="scss" scoped>
table, th, td {
    border: none;
    border-collapse: collapse;
}

tr {
    height: 40px;
}

table tr th:first-child,
table tr td:first-child {
    width: 10px !important;
}

table tr th:last-child,
table tr td:last-child {
    width: 5px !important;
}

table tr th,
table tr td {
    width: 9em;
    border: none;
}

table, tr, td {
    border: none;
}

.fach {
    cursor: pointer;
}

.whiteBg {
    background-color: #ffffff;
}

//filter generated with https://codepen.io/sosuke/pen/Pjoqqp
.iconToWhite {
    filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%) hue-rotate(36deg) brightness(104%) contrast(105%);
}

.iconToBlue {
    filter: invert(61%) sepia(43%) saturate(515%) hue-rotate(175deg) brightness(87%) contrast(82%);
}

.scroll-area {
    position: relative;
    margin: auto;
    max-height: calc(70vh - 100px);
}

.widgetContainer {
    height: auto;
    width: 100%;
    min-height: 80vh;
    max-height: 80vh;
    border-radius: 15px;
    box-shadow: 1px 5px 5px silver;
    margin: auto auto 5em;
}

.planWidgetDisplayDate {
    display: inline-block;
    font-weight: normal;
    font-size: large;
}

.planWidgetTodayButtonActive {
    background-color: #6995CD !important;
    color: white;
    border-radius: 8px;
}

.planWidgetTodayButton {
    background-color: var(--v-hellgrau-base) !important;
    color: var(--v-dunkelgrau-base);
    border-radius: 8px;
    box-shadow: 1px 5px 5px silver;
}

.colHeader{
    color: var(--v-dunkelgrau-base);
    width: 18%;
}

.colHeaderCurrent{
    color: #6995CD;
    width: 18%;
}

.greyDayHeader{
    background-color: var(--v-hellgrau-base);
    border-radius: 10px 10px 0 0;
}

.greyDayBody{
    background-color: var(--v-hellgrau-base);
}

.centerFach {
    padding-left: 1%;
}

.centerHeader {
    display: flex;
    align-content: center;
}

@media only screen and (min-width: 901px) and (max-width: 1010px) {
    .planWidgetDisplayDate {
        font-size: medium;
    }
}

@media only screen and (min-width: 901px) and (max-width: 1050px) {
    .calWeek {
        display: none;
    }
}

@media only screen and (max-width: 900px) {
    .widgetContainer {
        width: 90%;
    }
}
</style>
