<template>
    <v-card style="border-radius: 1em; pointer-events: all">
        <div
            v-if="showList"
            class="py-1"
        >
            <div
                v-for="(item, i) in items"
                :key="i"
                class="px-2"
                style="padding-top: 2px; padding-bottom: 2px"
            >
                <div
                    class="menuItemContainer"
                    tabindex="0"
                    :class="{'menuItemContainerDisabled': item.disabled}"
                    @click="itemClicked(item)"
                >
                    <div
                        class="px-2"
                        style="display: flex"
                    >
                        <div class="d-flex mr-2">
                            <v-tooltip
                                left
                                :disabled="!item.tooltip"
                                nudge-left="10"
                                nudge-bottom="40"
                                max-width="200px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <img
                                        style="width: 30px"
                                        :src="item.icon"
                                        aria-hidden="true"
                                        alt=""
                                        v-bind="attrs"
                                        :style="`filter: ${item.color || ''}`"
                                        v-on="on"
                                    >
                                </template>
                                <span>{{ item.tooltip }}</span>
                            </v-tooltip>
                        </div>
                        <div class="py-1 d-flex">
                            <p
                                class="mb-0"
                                role="button"
                                :aria-hidden="item.ariaLabel"
                                :style="`color: ${item.color || ''}`"
                                v-text="item.text"
                            />
                            <span
                                role="button"
                                class="visually-hidden"
                            >{{ item.ariaLabel }}</span>
                        </div>
                    </div>
                    <div style="display: flex;">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon
                                    v-if="item.accessibility"
                                    class="mr-1"
                                    v-on="on"
                                >
                                    mdi-human
                                </v-icon>
                            </template>
                            <span>{{ item.accessibilityTooltip }}</span>
                        </v-tooltip>
                        <v-btn
                            v-if="item.help"
                            aria-hidden="true"
                            depressed
                            class="my-auto mr-1"
                            x-small
                            min-width="40px"
                            width="40px"
                            height="40px"
                            color="white"
                            aria-haspopup="dialog"
                            :aria-expanded="showTutorial"
                            @click="() => openTutorial(item.help, i)"
                        >
                            <img
                                alt="Tutorial öffnen"
                                style="width: 30px; opacity: 0.8"
                                :src="infoIcon"
                            >
                        </v-btn>
                    </div>
                </div>
            </div>
        </div>
        <!-- A slot to display custom data -->
        <slot v-else />

        <!-- lets hope this works -->
        <v-dialog
            v-model="showTutorial"
            role="dialog"
            scrollable
            width="85vw"
        >
            <v-card>
                <v-toolbar
                    color="orange"
                    dense
                    style="max-height: 65px"
                >
                    <v-toolbar-title class="white--text">
                        Editor Elemente erklärt: {{ currentHelp.title }}
                    </v-toolbar-title>
                    <v-spacer />
                    <v-btn
                        elevation="2"
                        x-small
                        class="ml-2 pa-0"
                        style="width: 40px; height: 40px; opacity: 0.8"
                        @click="showTutorial = !showTutorial"
                    >
                        <img
                            :src="schliesenIcon"
                            alt="Schließensymbol"
                            style="height: 35px;"
                        >
                    </v-btn>
                </v-toolbar>
                <div id="currentHelpContainer">
                    <!-- Disable v-html xss checking currentHelp.content is provided only by developers -->
                    <!-- eslint-disable vue/no-v-html -->
                    <div
                        class="mx-10 pa-5"
                        style="color:red;"
                        v-html="currentHelp.content"
                    />
                    <!-- eslint-enable vue/no-v-html -->
                </div>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
import schliesenIcon from "@/assets/Icons/abbrechen-08.svg";
import infoIcon from "@/assets/Icons/info-2-121.svg";

export default {
    name: "BaseMenu",
    props: {
        showList: {
            required: false,
            default: true,
        },
        items: {
            required: false
        },
        headerColor: {
            required: false,
            default: "orange"
        },
        headerTitle: {
            required: false,
            default: "Hinzufügen"
        },
        hintForUser: {
            required: false,
            type: String,
            default: '',
        }
    },
    data() {
        return {
            schliesenIcon,
            infoIcon,
            currentHelpItem: 0,
            showTutorial: false,
            currentHelp: { title: '', content: '' },
        }
    },
    methods: {
        openTutorial(help, i) {
            this.currentHelpItem = i;
            this.currentHelp = help.content;
            this.showTutorial = true;
        },
        itemClicked(item) {
            if (!item.disabled) {
                this.$emit('itemClicked', item);
            }
        }
    }
}
</script>

<style scoped lang="scss">
#currentHelpContainer {
    margin-top: 1em;
    overflow: auto;
}

#hintBox {
    display: inline-flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 80%;
    max-width: 300px;
    color: grey;
}

.menuItemContainer {
    height: 45px;
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    cursor: pointer;
    background-color: var(--v-hellgrau-base);
    border-radius: 0.5em;
}

.menuItemContainerDisabled {
    pointer-events: none;
    background-color: var(--v-grau-base) !important;
    //opacity: 0.8;
    color: rgba(0,0,0,.26) !important;

    img {
        pointer-events: all;
    }
}

.menuItemTooltip {
    position: absolute;
    top: 0;
    left: -100px;
    width: 150px;
    background-color: var(--v-grau-base) !important;
    opacity: 0.8;
    padding: 2px;
}
</style>
