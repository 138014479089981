<template>
    <div
        style="background-color: #f8f8f8;"
        class="backgroundImg"
    >
        <!--bottom navigation bar, used only for mobile devices-->
        <div class="btmNav">
            <div
                class="d-flex flex-row justify-space-around align-center"
                style="height: 100%; width: 100%"
            >
                <v-btn
                    v-for="item in btmNavItems"
                    :key="item.name"
                    elevation="0"
                    small
                    class="btmNavBtn pa-0"
                    :style="currSlide === item.goToIndex && !btmNavMenu ? 'background-color:' + item.bgColor + '!important;' : ''"
                    @click="switchSlide(item.goToIndex)"
                >
                    <img
                        :src="item.icon"
                        class="btmNavIcon"
                        :style="currSlide === item.goToIndex && !btmNavMenu ? 'filter: brightness(1000%);' : item.color"
                    >
                </v-btn>

                <!--menu activator-->
                <v-btn
                    v-if="!textSelected"
                    ref="btmMenuBtn"
                    elevation="0"
                    small
                    class="btmNavBtn pa-0"
                    :style="btmNavMenu ? 'background-color: #6995CD !important;' : ''"
                    @click="handleBtmMenuClick"
                >
                    <img
                        :src="menuIcon"
                        class="btmNavIcon"
                        :style="btmNavMenu ? 'filter: brightness(1000%);' : 'filter: brightness(0) saturate(100%) invert(56%) sepia(46%) saturate(448%) hue-rotate(174deg) brightness(93%) contrast(86%);'"
                    >
                </v-btn>

                <!--textToSpeech when text is selected-->
                <v-btn
                    v-else
                    elevation="0"
                    small
                    class="btmNavBtn pa-0"
                    @click.self="clickTTS"
                >
                    <TTSSmall
                        ref="tts"
                        :icon-size="'35px'"
                    />
                </v-btn>

                <!--menu content-->
                <div
                    ref="btmMenu"
                    v-closable="{ exclude: ['btmMenuBtn', 'btmMenu'], handler: 'onClose' }"
                    class="d-flex flex-column pa-2 btmNavMenu"
                    :class="btmNavMenu ? '' : 'hideMe'"
                    :style="btmNavLangMenu ? 'box-shadow: -5px -2px 4px silver;' : 'border-top-left-radius: 12px;'"
                >
                    <div class="d-flex pa-2 mb-2">
                        <div
                            :style="getGenderColor()"
                            class="roundProfilePicBtmNav d-flex justify-center align-center"
                        >
                            <img
                                :src="profileIcon"
                                class="iconToWhite"
                                height="20"
                                width="20"
                                alt
                            >
                        </div>

                        <v-select
                            v-model="currentChild"
                            :items="children"
                            item-text="name"
                            return-object
                            hide-details
                            height="25"
                            class="ma-0 ml-2 pa-0"
                        />
                    </div>

                    <join-sprechzimmer-button :current-child="currentChild" />

                    <!-- opens profile page -->
                    <v-btn
                        v-ripple
                        class="d-flex mb-2 pa-2 btmNavMenuBtn pointer preventColorInvert"
                        depressed
                        @click="toggleProfileManagement(); closeBtmNavMenus()"
                    >
                        <img
                            :src="profileOutlineIcon"
                            height="25"
                            alt=""
                        >
                        <p class="ml-2 ma-0 text-capitalize">
                            Profil
                        </p>
                    </v-btn>

                    <!--activator language menu-->
                    <v-btn
                        v-ripple
                        class="d-flex mb-2 pa-2 btmNavMenuBtn pointer"
                        depressed
                        @click="btmNavLangMenu = !btmNavLangMenu"
                    >
                        <img
                            :src="btmNavLangMenu ? abbrechenIcon : currentTTSLangImg"
                            height="25"
                        >
                        <p class="ml-2 ma-0 text-capitalize">
                            Sprache
                        </p>
                    </v-btn>

                    <!--language menu content-->
                    <div
                        :class="btmNavLangMenu ? '' : 'hideMe'"
                        class="pa-2 pr-0 btmNavLangMenu"
                    >
                        <!--1st lang row-->
                        <div class="d-flex">
                            <v-btn
                                v-for="lang in btmNavLangItems.slice(0, 3)"
                                :key="lang.countryCode"
                                class="btmNavLangBtn d-flex mb-2 mr-2 pa-2"
                                depressed
                                :class="currentTTSLangImg === lang.icon ? 'justify-center align-center langBtnBg border' : (lang.icon !== '' ? 'langBtnBg pointer' : '')"
                                @click="changeLangBtmNav(lang.countryCode, lang.icon)"
                            >
                                <img
                                    v-if="lang.icon !== ''"
                                    :src="lang.icon"
                                    height="25"
                                    width="25"
                                >
                                <div
                                    v-else
                                    class="btmNavLangNoIcon"
                                />
                            </v-btn>
                        </div>

                        <!--2nd lang row-->
                        <div class="d-flex">
                            <v-btn
                                v-for="lang in btmNavLangItems.slice(3, 6)"
                                :key="lang.countryCode"
                                class="btmNavLangBtn d-flex mb-2 mr-2 pa-2"
                                depressed
                                :class="currentTTSLangImg === lang.icon ? 'justify-center align-center langBtnBg border' : (lang.icon !== '' ? 'langBtnBg pointer' : '')"
                                @click="changeLangBtmNav(lang.countryCode, lang.icon)"
                            >
                                <img
                                    v-if="lang.icon !== ''"
                                    :src="lang.icon"
                                    height="25"
                                    width="25"
                                >
                                <div
                                    v-else
                                    class="btmNavLangNoIcon"
                                />
                            </v-btn>
                        </div>

                        <!--3rd lang row-->
                        <div class="d-flex">
                            <v-btn
                                v-for="lang in btmNavLangItems.slice(6, 9)"
                                :key="lang.countryCode"
                                class="btmNavLangBtn d-flex mb-2 mr-2 pa-2"
                                depressed
                                :class="currentTTSLangImg === lang.icon ? 'justify-center align-center langBtnBg border' : (lang.icon !== '' ? 'langBtnBg pointer' : '')"
                                @click="changeLangBtmNav(lang.countryCode, lang.icon)"
                            >
                                <img
                                    v-if="lang.icon !== ''"
                                    :src="lang.icon"
                                    height="25"
                                    width="25"
                                >
                                <div
                                    v-else
                                    class="btmNavLangNoIcon"
                                />
                            </v-btn>
                        </div>

                        <!--4th lang row, does not have mb-2 class-->
                        <div class="d-flex">
                            <v-btn
                                v-for="lang in btmNavLangItems.slice(9, 12)"
                                :key="lang.countryCode"
                                class="btmNavLangBtn d-flex mr-2 pa-2"
                                depressed
                                :class="currentTTSLangImg === lang.icon ? 'justify-center align-center langBtnBg border' : (lang.icon !== '' ? 'langBtnBg' : '')"
                                @click="changeLangBtmNav(lang.countryCode, lang.icon)"
                            >
                                <img
                                    v-if="lang.icon !== ''"
                                    :src="lang.icon"
                                    height="25"
                                    width="25"
                                >
                                <div
                                    v-else
                                    class="btmNavLangNoIcon"
                                />
                            </v-btn>
                        </div>
                    </div>

                    <v-btn
                        v-ripple
                        class="d-flex mb-2 pa-2 btmNavMenuBtn pointer"
                        depressed
                        @click="reloadPage(); closeBtmNavMenus"
                    >
                        <img
                            :src="aktualisierenIcon"
                            height="25"
                        >
                        <p class="ml-2 ma-0 text-capitalize">
                            Aktualisieren
                        </p>
                    </v-btn>

                    <!--                    <v-btn v-ripple @click="closeBtmNavMenus" class="d-flex mb-2 pa-2 btmNavMenuBtn pointer" depressed>
                        <img :src="fragezeichenIcon" height="25"/>
                        <p class="ml-2 ma-0 text-capitalize">Tutorial</p>
                    </v-btn>-->

                    <v-btn
                        v-ripple
                        class="d-flex pa-2 btmNavMenuBtn pointer"
                        depressed
                        @click="clickLogoutUser(); closeBtmNavMenus"
                    >
                        <img
                            :src="logoutIcon"
                            height="25"
                            alt=""
                        >
                        <p
                            class="ml-2 ma-0 text-capitalize"
                            style="color: #e6231e"
                        >
                            Abmelden
                        </p>
                    </v-btn>
                </div>
            </div>
        </div>

        <!--top menu bar-->
        <v-overlay
            v-if="topMenu || btmNavMenu"
            style="z-index: 10"
            overlay-opacity="0.8"
            @click.native="onCloseTop()"
        />
        <v-row
            style="background-color: var(--v-dunkelgrau-base); z-index: 12 !important;"
            class="displayHide"
        >
            <v-col
                class="d-flex align-center"
                style="margin-left: 2.5%"
                cols="4"
            >
                <!-- Sprechzimmer Button -->
                <join-sprechzimmer-button :current-child="currentChild" />
            </v-col>

            <!--name and options-->
            <v-col
                class="d-flex justify-end align-center pl-0"
                style="margin-right: 2.5%"
            >
                <div
                    :style="getGenderColor()"
                    class="roundProfilePic d-flex justify-center align-center"
                >
                    <img
                        :src="profileIcon"
                        class="iconToWhite"
                        height="25"
                        width="25"
                        alt
                    >
                </div>

                <p class="ma-0 ml-2 ellipsis white--text">
                    {{ person ? 'Eltern von' : '' }}
                </p>
                <v-select
                    v-model="currentChild"
                    :items="children"
                    item-text="name"
                    return-object
                    hide-details="true"
                    class="ma-0 ml-2 pa-0"
                    dark
                    style="max-width: 150px;"
                />

                <v-btn
                    style="background-color: #FFFFFF; width: 50px; height: 50px; border-radius: 8px;"
                    x-small
                    elevation="0"
                    class="ml-4 animateIcon"
                    @click="reloadPage()"
                >
                    <img
                        :src="aktualisierenIcon"
                        style="max-width: 25px; max-height: 25px;"
                        alt="aktualisieren"
                    >
                </v-btn>

                <!--menu activator-->
                <v-btn
                    v-if="!textSelected"
                    ref="topMenuBtn"
                    elevation="0"
                    small
                    class="btmNavBtn pa-0 ml-4"
                    style="z-index: 11 !important"
                    :style="topMenu ? 'background-color: #6995CD !important;' : ''"
                    @click="handleTopMenuClick"
                >
                    <img
                        :src="menuIcon"
                        alt="menu"
                        style="max-width: 25px; max-height: 25px;"
                        :style="topMenu ? 'filter: brightness(1000%);' : 'filter: brightness(0) saturate(100%) invert(56%) sepia(46%) saturate(448%) hue-rotate(174deg) brightness(93%) contrast(86%);'"
                    >
                </v-btn>

                <!--textToSpeech when text is selected-->
                <v-btn
                    v-else
                    elevation="0"
                    small
                    class="btmNavBtn pa-0 ml-4"
                    style="background-color: #6995CD !important"
                    @click.self="clickTTS"
                >
                    <TTSSmall
                        ref="tts"
                        :icon-color="'white'"
                    />
                </v-btn>

                <!--menu content-->
                <div
                    v-closable="{exclude: ['topMenuBtn'], handler: 'onCloseTop'}"
                    style="z-index: 11 !important"
                    class="d-flex flex-column pa-2 topMenu"
                    :class="topMenu ? '' : 'hideMe'"
                    :style="topLangMenu ? 'box-shadow: -3px 2px 4px silver;' : 'border-bottom-left-radius: 12px'"
                >
                    <!-- opens profile page -->
                    <v-btn
                        v-ripple
                        class="d-flex mb-2 pa-2 btmNavMenuBtn pointer preventColorInvert"
                        depressed
                        @click="toggleProfileManagement(); closeTopMenus()"
                    >
                        <img
                            :src="profileOutlineIcon"
                            height="25"
                            alt=""
                        >
                        <p class="ml-2 ma-0 text-capitalize">
                            Profil
                        </p>
                    </v-btn>

                    <!--activator language menu-->
                    <v-btn
                        v-ripple
                        class="d-flex mb-2 pa-2 btmNavMenuBtn pointer"
                        depressed
                        @click="topLangMenu = !topLangMenu"
                    >
                        <img
                            :src="topLangMenu ? abbrechenIcon : currentTTSLangImg"
                            height="25"
                        >
                        <p class="ml-2 ma-0 text-capitalize">
                            Sprache
                        </p>
                    </v-btn>

                    <!--language menu content-->
                    <div
                        :class="topLangMenu ? '' : 'hideMe'"
                        class="pa-2 pr-0 topLangMenu"
                    >
                        <!--1st lang row-->
                        <div class="d-flex">
                            <v-btn
                                v-for="lang in btmNavLangItems.slice(0, 3)"
                                :key="lang.countryCode"
                                class="btmNavLangBtn d-flex mb-2 mr-2 pa-2"
                                depressed
                                :class="currentTTSLangImg === lang.icon ? 'justify-center align-center langBtnBg border' : (lang.icon !== '' ? 'langBtnBg pointer' : '')"
                                @click="changeLangNav(lang.countryCode, lang.icon)"
                            >
                                <img
                                    v-if="lang.icon !== ''"
                                    :src="lang.icon"
                                    height="25"
                                    width="25"
                                >
                                <div
                                    v-else
                                    class="btmNavLangNoIcon"
                                />
                            </v-btn>
                        </div>

                        <!--2nd lang row-->
                        <div class="d-flex">
                            <v-btn
                                v-for="lang in btmNavLangItems.slice(3, 6)"
                                :key="lang.countryCode"
                                class="btmNavLangBtn d-flex mr-2 pa-2"
                                depressed
                                :class="currentTTSLangImg === lang.icon ? 'justify-center align-center langBtnBg border' : (lang.icon !== '' ? 'langBtnBg pointer' : '')"
                                @click="changeLangNav(lang.countryCode, lang.icon)"
                            >
                                <img
                                    v-if="lang.icon !== ''"
                                    :src="lang.icon"
                                    height="25"
                                    width="25"
                                >
                                <div
                                    v-else
                                    class="btmNavLangNoIcon"
                                />
                            </v-btn>
                        </div>
                    </div>

                    <!--                    <v-btn v-ripple @click="closeTopMenus" class="d-flex mb-2 pa-2 btmNavMenuBtn pointer" depressed>
                        <img :src="fragezeichenIcon" height="25"/>
                        <p class="ml-2 ma-0 text-capitalize">Tutorial</p>
                    </v-btn>-->

                    <v-btn
                        v-ripple
                        class="d-flex pa-2 btmNavMenuBtn pointer"
                        depressed
                        @click="clickLogoutUser(); closeTopMenus"
                    >
                        <img
                            :src="logoutIcon"
                            height="25"
                        >
                        <p
                            class="ml-2 ma-0 text-capitalize"
                            style="color: #e6231e"
                        >
                            Abmelden
                        </p>
                    </v-btn>
                </div>
            </v-col>
        </v-row>

        <div
            v-if="windowWidth > 900 && !profileManagement"
            class="big mt-4"
        >
            <v-btn
                fab
                small
                color="#eaeaea"
                style="margin-left: 5px; margin-top: 20%"
                class="arrowBtn buttonFocusHover"
                icon
                @click="page= Math.abs((page-1) %2)"
            >
                <img
                    style="position: relative; margin: auto; display: block; width: 20px"
                    :src="left"
                    class="imgFocusHover"
                    :alt="page === 0 ? 'Vorherige Seite mit Elternbriefen' : page === 1 ? 'Vorherige Seite mit Stundenplan, Schwarzem Brett und Chat' : 'Vorherige Seite'"
                >
            </v-btn>
            <v-row>
                <v-col>
                    <PlanWidget
                        v-if="page === 0"
                        :current-child="currentChild"
                    />
                    <MessagesWidget
                        v-if="page === 1"
                        :role="'parent'"
                        :child-id="currentChild._id"
                    />
                    <!--                    <FileWidget v-if="page === 1" style="height: 100vh"></FileWidget>-->
                </v-col>
                <v-col>
                    <BrettWidget
                        v-if="page === 0"
                        :current-child="currentChild"
                    />
                </v-col>
                <v-col>
                    <chat-widget
                        v-if="page === 0"
                        :current-child="currentChild"
                    />
                </v-col>
            </v-row>
            <v-btn
                fab
                small
                color="#eaeaea"
                style="margin-right: 5px; margin-top: 20%"
                class="arrowBtn buttonFocusHover"
                icon
                @click="page = Math.abs((page + 1) % 2)"
            >
                <img
                    style="position: relative; margin: auto; display: block; width: 20px"
                    :src="right"
                    class="imgFocusHover"
                    :alt="page === 0 ? 'Nächste Seite mit Elternbriefen' : page === 1 ? 'Nächste Seite mit Stundenplan, Schwarzem Brett und Chat' : 'Nächste Seite'"
                >
            </v-btn>
        </div>
        <ProfileManagement
            v-else-if="profileManagement"
            style="height: 100%; min-height: 100vh"
        />
        <VueSlickCarousel
            v-else
            ref="carousel"
            class="small topPadding"
            :arrows="false"
            :initial-slide="0"
            :infinite="false"
            :dots="false"
            @afterChange="vueSlickCarouselAfterChange"
        >
            <PlanWidget
                :small="true"
                style="height: 100vh"
                :current-child="currentChild"
            />
            <BrettWidget
                style="height: 100vh"
                :current-child="currentChild"
            />
            <chat-widget :current-child="currentChild" />
            <MessagesWidget
                style="height: 100vh"
                :role="'parent'"
                :child-id="currentChild._id"
            />
        </VueSlickCarousel>
        <VideoTutorial
            v-if="videoTutorial"
            :video-tutorial="videoTutorial"
            @close="videoTutorialClosed = true"
        />
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";

import TTSSmall from "../TextToSpeechSmall";
import PlanWidget from "@/components/Eltern/PlanWidgetEltern";
import BrettWidget from "@/components/BrettWidget";
import ChatWidget from "../Chat/ChatWidget";
import MessagesWidget from "@/components/MessagesWidget";
import JoinSprechzimmerButton from "@/components/JoinSprechzimmerButton";
import VideoTutorial from "@/components/Tutorial/VideoTutorial.vue";

import ProfileManagement from "@/components/ProfileManagement";
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

import deFlagIcon from '../../assets/Icons/deutsch-59.svg'
import frFlagIcon from '../../assets/Icons/französisch-60.svg'
import enFlagIcon from '../../assets/Icons/englisch-61.svg'
import trFlagIcon from '../../assets/Icons/türkisch-97.svg'
import arFlagIcon from '../../assets/Icons/syrisch-98.svg'
import ruFlagIcon from '../../assets/Icons/russisch.svg'
import profileIcon from "@/assets/Icons/profile.svg";
import left from '../../assets/Icons/links-filled-10.svg'
import right from '../../assets/Icons/rechts-filled-11.svg'
import logoutIcon from '../../assets/Icons/beitretensvg-red-06.svg'
import stundenplanIcon from '../../assets/Icons/timetable.svg'
import brettIcon from '../../assets/Icons/brett.svg'
import chatIcon from '../../assets/Icons/chat-alternative-filled-102.svg'
import menuIcon from '../../assets/Icons/einstellungen-neu-103.svg'
import abbrechenIcon from '../../assets/Icons/abbrechen-08.svg'
import aktualisierenIcon from '../../assets/Icons/aktualisieren-107.svg'
import briefIcon from '@/assets/Icons/letter.svg'
import profileOutlineIcon from "@/assets/Icons/profil-schueler.svg";

// This variable will hold the reference to document's click handler
let handleOutsideClick

export default {
    name: "Plan",
    components: {
        ProfileManagement,
        ChatWidget,
        MessagesWidget,
        TTSSmall,
        PlanWidget,
        BrettWidget,
        VueSlickCarousel,
        JoinSprechzimmerButton,
        VideoTutorial,
    },

    directives: {
        //directive from https://tahazsh.com/detect-outside-click-in-vue with changes from Peter Means (in comments)
        closable: {
            bind(el, binding, vnode) {
                // Here's the click/touchstart handler (it is registered below)
                handleOutsideClick = (e) => {
                    e.stopPropagation()
                    // Get the handler method name and the exclude array from the object used in v-closable
                    const { handler, exclude } = binding.value

                    // This variable indicates if the clicked element is excluded
                    let clickedOnExcludedEl = false
                    exclude.forEach(refName => {
                        // We only run this code if we haven't detected any excluded element yet
                        if (!clickedOnExcludedEl) {
                            // Get the element using the reference name
                            const excludedEl = vnode.context.$refs[refName];
                            let excludedDomEl = null;
                            if (excludedEl) {
                                // If it's a vue component grab the element, otherwise it is the element
                                excludedDomEl = excludedEl.$el ? excludedEl.$el : excludedEl;
                                clickedOnExcludedEl = excludedDomEl.contains(e.target);
                            }
                        }
                    })
                    // We check to see if the clicked element is not the dialog element and not excluded
                    if (!el.contains(e.target) && !clickedOnExcludedEl) {
                        // If the clicked element is outside the dialog and not the button,
                        // then call the outside-click handler from the same component this directive is used in
                        vnode.context[handler]()
                    }
                }
                // Register click/touchstart event listeners on the whole page
                document.addEventListener('click', handleOutsideClick)
                document.addEventListener('touchstart', handleOutsideClick)
            },

            unbind() {
                // If the element that has v-closable is removed, then
                // unbind click/touchstart listeners from the whole page
                document.removeEventListener('click', handleOutsideClick)
                document.removeEventListener('touchstart', handleOutsideClick)
            }
        }
    },
    data() {
        return {
            topMenu: false,
            topLangMenu: false,
            btmNavMenu: false,
            btmNavLangMenu: false,
            date: new Date(),
            appointments: [],
            person: null,
            children: [],
            currentChild: null,
            currentTTSLangImg: deFlagIcon,
            slots: [],
            page: 0,

            currSlide: 0,               //to color icons of mobile bottom navBar
            slideChangeAllowed: true,   //to prevent spamming of btmNavBar, which would bring highlighting of btmNavIcons and currently displayed widget out of sync

            textSelected: false,
            selectedText: '',

            videoTutorialClosed: false,

            profileIcon,
            left,
            right,
            logoutIcon,
            menuIcon,
            abbrechenIcon,
            aktualisierenIcon,
            profileOutlineIcon,

            btmNavItems: [
                { name: 'stundenplan', icon: stundenplanIcon, goToIndex: 0, bgColor: '#6995CD', color: 'filter: brightness(0) saturate(100%) invert(56%) sepia(46%) saturate(448%) hue-rotate(174deg) brightness(93%) contrast(86%);' },
                { name: 'brett', icon: brettIcon, goToIndex: 1, bgColor: '#9C9CA0', color: 'filter: brightness(0) saturate(100%) invert(47%) sepia(0%) saturate(98%) hue-rotate(150deg) brightness(90%) contrast(86%);' },
                { name: 'chat', icon: chatIcon, goToIndex: 2, bgColor: '#8CBD46', color: 'filter: brightness(0) saturate(100%) invert(70%) sepia(22%) saturate(1027%) hue-rotate(43deg) brightness(94%) contrast(90%);' },
                { name: 'brief', icon: briefIcon, goToIndex: 3, bgColor: '#94519B', color: 'filter: brightness(0) saturate(100%) invert(34%) sepia(43%) saturate(774%) hue-rotate(247deg) brightness(98%) contrast(81%)' },
            ],
            btmNavLangItems: [
                /*1st lang row*/
                { countryCode: 'de', icon: deFlagIcon },
                { countryCode: 'en', icon: enFlagIcon },
                { countryCode: 'fr', icon: frFlagIcon },
                /*2nd lang row*/
                { countryCode: 'tr', icon: trFlagIcon },
                { countryCode: 'ar', icon: arFlagIcon },
                { countryCode: 'ru', icon: ruFlagIcon },
                /*3rd lang row*/
                { icon: '' },
                { icon: '' },
                { icon: '' },
                /*4th lang row*/
                { icon: '' },
                { icon: '' },
                { icon: '' },
                /*5th lang row*/
                { icon: '' },
                { icon: '' },
                { icon: '' },
            ]
        };
    },
    computed: {
        ...mapState("profileManagement", ["profileManagement"]),
        ...mapState("auth", ["stayLoggedIn", "deviceToken"]),
        ...mapState("util", ["browserSupported", 'windowWidth']),
        ...mapGetters('videoTutorial', [ 'videoTutorialsByFilename' ]),
        videoTutorial() {
            const tutorial = this.videoTutorialsByFilename['Eltern_in_5Min_erklaert.mp4'];
            if(tutorial?.seeAgain && !this.videoTutorialClosed) {
                return tutorial;
            }
            return false;
        }
    },
    watch: {
        selectedText() {
            if (this.selectedText.length) {
                if (!this.textSelected) {
                    this.textSelected = true;
                }
            } else {
                if (this.textSelected) {
                    this.textSelected = false;
                }
            }
        },
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
    },
    async mounted() {
        window.addEventListener('resize', this.onResize);
        window.addEventListener('orientationchange', this.onOrientationChange);

        setInterval(() => {
            let selection = JSON.parse(JSON.stringify(this.selectedText));
            if (window.getSelection) {
                selection = window.getSelection();
            }
            // document.getSelection
            else if (document.getSelection) {
                selection = document.getSelection();
            }
            // document.selection
            else if (document.selection) {
                selection =
                    document.selection.createRange().text;
            }
            if (selection.toString() !== this.selectedText) {
                this.update = !this.update;
            }
            this.selectedText = selection.toString();

        }, 100);

        await this.requestPerson();
        this.date = new Date(await this.loadServerTime());
        this.requestSlots();
        this.requestAppointments();
    },
    methods: {
        ...mapActions("appointments", ["getAppointmentsParent"]),
        ...mapActions("timeslots", ["getSlots"]),
        ...mapActions("parent", ["getMeParent"]),
        ...mapActions("pupils", ["getPupil"]),
        ...mapActions("translation", ["setTargetLang"]),
        ...mapActions("auth", ["logoutUser", "reloadDontLogout"]),
        ...mapActions("util", ["loadServerTime"]),
        ...mapMutations("snackbar", ["showSnackbar"]),
        ...mapActions("profileManagement", ["toggleProfileManagement", "closeProfileManagement"]),

        vueSlickCarouselAfterChange() {
            this.slideChangeAllowed = true;
            this.currSlide = this.$refs.carousel.$refs.innerSlider.currentSlide;
        },
        onResize() {
            this.closeBtmNavMenus();
        },
        async requestAppointments(update = false) {
            this.appointments = await this.getAppointmentsParent(this.currentChild._id);
        },

        async requestPerson() {
            this.person = await this.getMeParent();
            let pupils = this.person.pupils;
            for (let i = 0; i < pupils.length; i++) {
                let item = await this.getPupil(pupils[i]);
                item['value'] = item.name;
                this.children.push(item);
            }
            this.currentChild = this.children[0];
            return this.person;
        },
        async requestSlots() {
            this.slots = await this.getSlots();
        },

        getGenderColor() {
            let gender;
            if (this.person) {
                gender = this.person.gender;
            }
            if (gender) {
                switch (gender) {
                    case "m": return 'background-color: #538DCA';
                    case "w": return 'background-color: #E5536E';
                    default: return 'background-color: #9B91C8';
                }
            } else {
                return 'background-color: #9B91C8';
            }
        },

        clickLogoutUser() {
            this.logoutUser();
            this.$router.push({ name: 'login' });
        },
        reloadPage() {
            this.reloadDontLogout(true);
            this.$router.go();
        },

        //#region btmNav methods
        changeLangBtmNav(newLang, newLangImg) {
            //statement needed for empty menuIcons in btmNavLangMenu
            if (newLangImg !== '') {
                this.currentTTSLangImg = newLangImg;
                this.setTargetLang(newLang);
                this.btmNavLangMenu = false;
            }
        },
        switchSlide(index) {
            if (this.slideChangeAllowed && index !== this.currSlide) {
                this.$refs.carousel.goTo(index);

                this.currSlide = index;
                this.slideChangeAllowed = false;
                this.closeBtmNavMenus();
            }
            /*if btmNavMenu is open and you click on the button for the widget which is already being displayed*/
            else if (this.slideChangeAllowed && this.btmNavMenu) {
                this.btmNavMenu = false; //then just close the btmNavMenu
            }
        },
        closeBtmNavMenus() {
            this.btmNavLangMenu = false;
            this.btmNavMenu = false;
        },
        handleBtmMenuClick() {
            this.btmNavMenu = !this.btmNavMenu;
            this.btmNavLangMenu = false;
        },
        // vue/no-unused-properties cannot detect methods by directives
        // and this is referenced by a directive, so make a exception
        // eslint-disable-next-line vue/no-unused-properties
        onClose() {
            this.closeBtmNavMenus();
        },
        onOrientationChange() {
            this.switchSlide(0);
            this.slideChangeAllowed = true;
        },
        clickTTS() {
            this.$refs.tts.$el.click();
        },
        //#endregion

        //#region topMenu methods
        changeLangNav(newLang, newLangImg) {
            //statement needed for empty menuIcons in btmNavLangMenu
            if (newLangImg !== '') {
                this.currentTTSLangImg = newLangImg;
                this.setTargetLang(newLang);
                this.topLangMenu = false;
            }
        },
        handleTopMenuClick() {
            this.topMenu = !this.topMenu;
            this.topLangMenu = false;
        },
        onCloseTop() {
            this.closeTopMenus();
        },
        closeTopMenus() {
            this.topLangMenu = false;
            this.topMenu = false;
        },
        //#endregion

        /*async subscribe() {
            navigator.serviceWorker.getRegistrations().then(async (registrations) => {
                // Register Push
                const subscription = await registrations[0].pushManager.subscribe({
                    userVisibleOnly: true,
                    applicationServerKey: this.urlBase64ToUint8Array('BKFBvlmQYh9nNP9ECrMgMj-3v__wDW8Q3M0NPcHpH3LXSq7YsYvSR_dj757TxzwL2hKr--gN6SuIyuy3H0OUgeo')
                });
                await backend.subscribe({
                    subscription,
                    deviceToken: this.deviceToken,
                });
            });
            this.showSnackbar({
                message: 'Benachrichtigungen aktiv für das Schwarze Brett',
                color: 'info'
            });
        },*/

        /*urlBase64ToUint8Array(base64String) {
            const padding = "=".repeat((4 - base64String.length % 4) % 4);
            const base64 = (base64String + padding)
                // eslint-disable-next-line no-useless-escape
                .replace(/\-/g, "+")
                .replace(/_/g, "/");

            const rawData = window.atob(base64);
            const outputArray = new Uint8Array(rawData.length);

            for (let i = 0; i < rawData.length; ++i) {
                outputArray[i] = rawData.charCodeAt(i);
            }
            return outputArray;
        },*/
    },
};
</script>

<style lang="scss" scoped>
.backgroundImg {
    background-image: url(../../assets/Bilder/scribble.svg);
    background-repeat: repeat;
    overflow: hidden;
    height: 100%;
}

table,
th,
td {
    border: 1px solid black;
    border-collapse: collapse;
}

tr {
    height: 40px;
}

table tr th:first-child,
table tr td:first-child {
    width: 10px !important;
}

table tr th,
table tr td {
    width: 9em;
    border: none;
}
table,
tr,
td {
    border: none;
}

.fach {
    cursor: pointer;
}

.small {
    display: none;
}

.big {
    display: flex;
}

.headerAsOne {
    display: none !important;
}

.slick-slider {
    user-select: auto !important;
}

.ellipsis {
    text-overflow: ellipsis;

    /* Required for text-overflow to do anything */
    white-space: nowrap;
    overflow: hidden;
}

.greyText {
    color: var(--v-dunkelgrau-base);
}

.iconToWhite {
    filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%)
        hue-rotate(36deg) brightness(104%) contrast(105%);
}

.roundProfilePic {
    border-radius: 50%;
    height: 35px;
    width: 35px;
}

.roundProfilePicBtmNav {
    border-radius: 50%;
    height: 25px;
    width: 25px;
    min-height: 25px;
    min-width: 25px;
}

.buttonFocusHover:hover .imgFocusHover {
    filter: invert(51%) sepia(66%) saturate(310%) hue-rotate(175deg)
        brightness(98%) contrast(91%) !important;
}

.btmNav {
    display: none;
    z-index: 11;
    position: fixed;
    bottom: 0;
    width: 100% !important;
    height: 70px !important;
    background-color: var(--v-dunkelgrau-base);
}

.btmNavBtn {
    height: 50px !important;
    width: 50px !important;
    background-color: white !important;
    border-radius: 8px !important;
}

/*removes darker background after btn is clicked
    also works without the specific class in front*/
.btmNavBtn.v-btn:before {
    opacity: 0 !important;
}
.arrowBtn:before {
    opacity: 0 !important;
}

.btmNavIcon {
    height: 35px;
}

.hideMe {
    display: none !important;
}

.topMenu {
    position: fixed;
    top: 74px;
    right: 5%;
    width: 160px;
    max-width: 160px;
    background-color: #ffffff;
    box-shadow: 0 2px 4px silver;
}

.topLangMenu {
    position: fixed;
    top: 74px;
    right: calc(5% + 160px);
    border-bottom-left-radius: 12px;
    background-color: #ffffff;
    box-shadow: -3px 2px 3px silver;
}

.btmNavMenu {
    position: fixed;
    bottom: 70px;
    right: 0;
    width: 160px;
    max-width: 160px;
    background-color: #ffffff;
    box-shadow: 0 -2px 4px silver;
}

.btmNavMenuBtn {
    color: var(--v-dunkelgrau-base);
    background-color: var(--v-editorGrey-base);
    border-radius: 8px;
    justify-content: flex-start;
}

.btmNavLangNoIcon {
    height: 25px;
    width: 25px;
    background-color: transparent;
}

.btmNavLangMenu {
    position: fixed;
    bottom: 70px;
    right: 160px;
    border-top-left-radius: 12px;
    background-color: #ffffff;
    box-shadow: -5px -2px 4px silver;
}

.btmNavLangBtn {
    width: 41px;
    height: 41px; /*needed so that border and box-sizing work*/
    color: var(--v-dunkelgrau-base);
    border-radius: 8px;
    min-width: 41px !important;
}

/*if there is a flag/icon it has a grey background*/
.langBtnBg {
    background-color: #efefef !important;
}

.border {
    border: 3px #6995cd solid;
    box-sizing: border-box !important;
}

.pointer {
    cursor: pointer;
}

.btmNavMenuIconActive {
    background-color: #6995cd !important;
}

@media only screen and (max-width: 900px) {
    .small {
        display: block !important;
    }

    .big {
        display: none !important;
    }

    .topPadding {
        padding-top: 12px;
    }
}

@media only screen and (max-width: 500px),
    (max-width: 900px) and (orientation: portrait) {
    .headerSides {
        display: none !important;
    }

    .headerAsOne {
        display: inline-flex !important;
    }

    .headerMid {
        width: 100% !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .displayHide {
        display: none;
    }

    .btmNav {
        display: flex;
    }

    .moreBottom {
        bottom: 80px !important;
    }
}

.animateIcon {
    overflow: hidden;
}

.animateIcon:after {
    animation: shine 60s ease-in-out infinite;
    animation-fill-mode: forwards;
    content: "";
    position: absolute;
    top: -110%;
    left: -210%;
    width: 200%;
    height: 200%;
    opacity: 0;
    transform: rotate(30deg);

    background: rgba(255, 255, 255, 0);
    background: linear-gradient(
        to right,
        rgba(112, 112, 112, 0.2) 0%,
        rgba(112, 112, 112, 0.2) 77%,
        rgba(112, 112, 112, 0.5) 92%,
        rgba(112, 112, 112, 0) 100%
    );
}

/* Hover state - trigger effect */

/* Active state */
.animateIcon:active:after {
    opacity: 0;
}

@keyframes shine {
    1% {
        opacity: 1;
        top: -30%;
        left: -30%;
        transition-property: left, top, opacity;
        transition-duration: 0.7s, 0.7s, 0.15s;
        transition-timing-function: ease;
    }
    5% {
        opacity: 0;
        top: -30%;
        left: -30%;
        transition-property: left, top, opacity;
    }
}

.v-snack.v-snack--top {
    bottom: initial; /* or auto */
}

.v-snack.v-snack--bottom {
    top: initial; /* or auto */
}
</style>
