<template>
    <div v-if="audioCheck">
        <v-tooltip
            v-model="recordingTooltip"
            top
            :open-on-hover="false"
        >
            <template v-slot:activator="{ attrs }">
                <v-btn
                    id="recordBtn"
                    v-long-press="100"
                    :tabindex="tabOrder"
                    v-bind="attrs"
                    elevation="0"
                    class="text-capitalize ml-1"
                    :color="toggle ? 'grau' : ''"
                    :dark="toggle ? true : false"
                    :fab="fab"
                    aria-label="Sprachaufnahme als Abgabe aufnehmen (gedrückt halten)"
                    role="button"
                    @click="keyboard ? (toggle ? stopRecording() : startRecording()) : null"
                    @long-press-start="startRecording"
                    @long-press-stop="stopRecording"
                    @keydown.enter="startRecording()"
                    @keyup.enter="stopRecording()"
                >
                    <div :class="toggle ? 'micIcon--recording' : 'micIcon'" />
                </v-btn>
            </template>
            <span> {{ recordingTime }}</span>
        </v-tooltip>

        <v-dialog
            v-model="showDialog"
            overlay-opacity="0.7"
            :persistent="true"
            width="500"
        >
            <v-card
                id="card"
                class="pa-3"
            >
                <v-card-title>
                    <v-container class="pa-0">
                        <v-row>
                            <v-col>
                                <h1
                                    tabindex="0"
                                    style="width: fit-content"
                                >
                                    Dateiname
                                </h1>
                            </v-col>
                            <v-col cols="auto">
                                <v-btn
                                    elevation="2"
                                    x-small
                                    class="ml-2 pa-0"
                                    style="width: 30px; height: 30px"
                                    @click="showDialog = false"
                                >
                                    <img
                                        alt="Hochladen abbrechen"
                                        :src="schliesenIcon"
                                        style="height: 20px"
                                    >
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-title>
                <v-card-text class="pb-0">
                    <v-container class="pa-0">
                        <v-row
                            align="center"
                            justify="center"
                        >
                            <v-col>
                                <v-text-field v-model="newFileName" />
                            </v-col>
                            <v-col cols="auto">
                                <v-btn
                                    elevation="2"
                                    x-small
                                    class="pa-0 mb-2"
                                    style="width: 30px; height: 30px"
                                    color="secondary"
                                    :aria-label="'Datei ' + newFileName + ' hochladen'"
                                    @click="finishProcess"
                                >
                                    <v-icon
                                        x-small
                                        dark
                                    >
                                        fas fa-check
                                    </v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row>
                            <audio
                                :src="audioUrl"
                                controls
                                style="width: 100%"
                            >
                                Your browser does not support the video tag.
                            </audio>
                        </v-row>

                        <v-row
                            v-if="group"
                            class="ma-0 mt-3"
                            align="center"
                            justify="center"
                            style="width: 100%"
                        >
                            <vue-custom-scrollbar
                                class="scroll-area"
                                :settings="settings"
                                style="width: 100%"
                            >
                                <v-data-table
                                    :items="this.group.participants"
                                    disable-pagination
                                    hide-default-footer
                                    fixed-header
                                    class="ma-0"
                                    style="width: 100%"
                                >
                                    <template v-slot:item="row">
                                        <tr>
                                            <td class="pl-0">
                                                {{ row.item.lastName }}
                                            </td>
                                            <td>{{ row.item.name }}</td>
                                            <td>
                                                <v-checkbox
                                                    v-model="selectedUser"
                                                    class="mx-2"
                                                    rounded
                                                    :value="row.item.account"
                                                    small
                                                    light
                                                />
                                            </td>
                                        </tr>
                                    </template>
                                </v-data-table>
                            </vue-custom-scrollbar>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import Recorder from "js-audio-recorder";
import schliesenIcon from "../assets/Icons/abbrechen-08.svg";
import vueCustomScrollbar from "vue-custom-scrollbar";

export default {
  name: "AudioUpload",
  components: {
    vueCustomScrollbar,
  },
  props: {
    appointmentId: { type: String, required: true },
    upload: { type: Function, required: true },
    group: { type: Object, required: false, default: null },
    fab: { type: Boolean, required: false, default: false },
    color: { type: String, required: false, default: "" },
      tabOrder: { required: false, default: "0" },
  },
  data() {
    return {
      schliesenIcon,
      toggle: false,
      audioUrl: null,
      showDialog: false,
      recorder: new Recorder(),
      audioCheck: false,
      newFileName: "Sprachabgabe",
      file: null,
      selectedUser: [],
      settings: {
        suppressScrollY: false,
        suppressScrollX: true,
        wheelPropagation: false,
      },
      recordingTime: "",
      elapsedTimeIntervalRef: "",
      recordingTooltip: false,
    };
  },
  computed: {
    ...mapState("util", ["keyboard"]),
    ...mapGetters("files", ["getCurrentFileType"]),
  },
  async mounted() {
    this.checkPermission();
  },
  async unmounted() {
    URL.revokeObjectURL(this.audioUrl);
  },
  methods: {
    checkPermission() {
      Recorder.getPermission().then(
        () => {
          this.audioCheck = true;
        },
        (error) => {
          console.error(`${error.name} : ${error.message}`);
        }
      );
    },
    startRecording() {
        if(!this.toggle) {
            this.startTimer();
            this.recordingTooltip = true;
            this.toggle = true;
            this.recorder.startRecord();
        }
    },
    stopRecording() {
      this.recordingTooltip = false;
      this.toggle = false;
      this.recorder.stopRecord();
      this.file = this.recorder.getWAVBlob();
      this.audioUrl = URL.createObjectURL(this.file);
      this.showDialog = true;
      setTimeout(() => {
        this.stopTimer();
      }, 500);
    },
    finishProcess() {
      /*//setting reopenAppointment to reopen popup after recording is done
      this.$route.query.appointment = this.appointmentId;*/

      this.upload(
        this.appointmentId,
        this.newFileName,
        "wav",
        this.file,
        this.selectedUser
      );

      this.showDialog = false;
    },
    startTimer() {
      var startTime = new Date();
      this.recordingTime = this.getElapsedTime(startTime);
      this.elapsedTimeIntervalRef = setInterval(() => {
        this.recordingTime = this.getElapsedTime(startTime);
      }, 1000);
    },
    stopTimer() {
      if (typeof this.elapsedTimeIntervalRef !== "undefined") {
        clearInterval(this.elapsedTimeIntervalRef);
        this.elapsedTimeIntervalRef = undefined;
      }
      this.recordingTime = "";
    },
    getElapsedTime(startTime) {
      // Record end time
      let endTime = new Date();
      // Compute time difference in milliseconds
      let timeDiff = endTime.getTime() - startTime.getTime();
      // Convert time difference from milliseconds to seconds
      timeDiff = timeDiff / 1000;
      // Extract integer seconds that dont form a minute using %
      let seconds = Math.floor(timeDiff % 60); //ignoring uncomplete seconds (floor)
      // Pad seconds with a zero if neccessary
      let secondsAsString = seconds < 10 ? "0" + seconds : seconds + "";
      // Convert time difference from seconds to minutes using %
      timeDiff = Math.floor(timeDiff / 60);
      // Extract integer minutes that don't form an hour using %
      let minutes = timeDiff % 60; //no need to floor possible incomplete minutes, becase they've been handled as seconds
      // Pad minutes with a zero if neccessary
      let minutesAsString = minutes < 10 ? "0" + minutes : minutes + "";
      // Convert time difference from minutes to hours
      timeDiff = Math.floor(timeDiff / 60);
      // Extract integer hours that don't form a day using %
      let hours = timeDiff % 24; //no need to floor possible incomplete hours, becase they've been handled as seconds
      // Convert time difference from hours to days
      timeDiff = Math.floor(timeDiff / 24);
      // The rest of timeDiff is number of days
      let days = timeDiff;
      let totalHours = hours + days * 24; // add days to hours
      let totalHoursAsString =
        totalHours < 10 ? "0" + totalHours : totalHours + "";
      if (totalHoursAsString === "00") {
        return minutesAsString + ":" + secondsAsString;
      } else {
        return (
          totalHoursAsString + ":" + minutesAsString + ":" + secondsAsString
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>

.iconToWhite {
  filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%)
    hue-rotate(36deg) brightness(104%) contrast(105%);
}

.micIcon {
  background-image: url("../assets/Icons/mikrofon-22.svg");
  width: 20px;
  height: 20px;
}

.micIcon--recording {
  background-image: url("../assets/Icons/recording-dot.svg");
  width: 30px;
  height: 30px;
}
</style>
