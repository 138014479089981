import { render, staticRenderFns } from "./WeeklyViewDialog.vue?vue&type=template&id=1594bb66&scoped=true&"
import script from "./WeeklyViewDialog.vue?vue&type=script&lang=js&"
export * from "./WeeklyViewDialog.vue?vue&type=script&lang=js&"
import style0 from "./WeeklyViewDialog.vue?vue&type=style&index=0&id=1594bb66&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1594bb66",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBadge,VBtn,VCard,VCol,VDialog,VRow})
