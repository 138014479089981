<template>
    <div>
        <v-menu
            :close-on-content-click="false"
            nudge-bottom="45px"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    dark
                    x-small
                    elevation="0"
                    v-bind="attrs"
                    class="colorButton ml-0"
                    :style="`background-color: ${currentColor}`"
                    v-on="on"
                />
            </template>
            <div id="colorButtonContainer">
                <v-btn
                    v-for="(color, index) in colors"
                    :key="index"
                    class="colorButton"
                    :class="{ 'currentColor': color === currentColor }"
                    :style="`background-color: ${color}`"
                    x-small
                    elevation="1"
                    @click="callback(color)"
                >
                    <img
                        v-if="color === currentColor"
                        :src="fertigIcon"
                    >
                </v-btn>
            </div>
        </v-menu>
    </div>
</template>

<script>
import fertigIcon from '../../assets/Icons/fertig-74.svg'

export default {
    name: 'VerwaltungColorPicker',
    props: {
        currentColor: { required: true, type: String },
        callback: { required: true, type: Function }
    },
    data() {
        return {
            colors: ['#fcd20a', '#f0b423',  '#ff6941', '#91c364', '#91c887', '#d7875f', '#ebaabe', '#9b91c8',  '#6ec3dc', '#9bcdc3',  '#b2b2b2', '#707070'],
            fertigIcon,
        };
    },
}
</script>

<style lang="scss" scoped>
#colorButtonContainer {
    max-width: 235px;
    height: 100px;
    display: table;
    border-radius: 0 !important;
    background-color: #FFFFFF;
}
.colorButton {
    width: 42px !important;
    height: 42px !important;
    margin: 8px;
    border-radius: 8px;
    border: none;

    & img {
        background-color: rgba(240, 240, 240, 50%);
        width: 20px !important;
        height: 20px !important;
        border-radius: 50%;
        padding: 5px;
    }
}
.currentColor {
    border: 2px solid grey;
}
</style>
