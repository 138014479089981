
const state = {
    videoTutorials: [{
        filename: 'Verwaltung_15Min_erklaert.mp4',
        title: 'eKlara für die Verwaltung in 15 Minuten erklärt',
        location: 'verwaltung.plan',
        seeAgain: true,
    },
    {
        filename: 'Lehrer_in_8Min_erklaert.mp4',
        title: 'eKlara für Lehrer in 8 Minuten erklärt',
        location: 'lehrer.plan',
        seeAgain: true,
    },
    {
        filename: 'Normal_in_4Min_erklaert.mp4',
        title: 'eKlara in 4 Minuten erklärt',
        location: 'schueler.plan',
        seeAgain: true,
    },
    {
        filename: 'Einfach_in_4Min_erklaert.mp4',
        title: 'eKlara in 4 Minuten erklärt',
        location: 'baby.plan',
        seeAgain: true,
    },
    {
        filename: 'Eltern_in_5Min_erklaert.mp4',
        title: 'eKlara in 5 Minuten erklärt',
        location: 'eltern.plan',
        seeAgain: true,
    },
    {
        filename: 'HA_erstellt_Autokorrektur.mp4',
        title: 'Der eKlara Editor erklärt',
        location: 'lehrer.create',
        seeAgain: true,
    },
    {
        filename: 'Lehrer_bbb_erklaert.mp4',
        title: 'Ein Überblick über BBB',
        location: 'lehrer.bbb',
        seeAgain: true,
    },
    {
        filename: 'Normal_bbb_in_3Min_erklaert.mp4',
        title: 'Ein Überblick über BBB',
        location: 'schueler.bbb',
        seeAgain: true,
    },
    {
        filename: 'Einfach_bbb_in_3Min_erklaert.mp4',
        title: 'Ein Überblick über BBB',
        location: 'baby.bbb',
        seeAgain: true,
    }],
};

const mutations = {
    setTutorialSeeAgain(state, {filename, seeAgain}) {
        state.videoTutorials = state.videoTutorials.map(tutorial => {
            if(tutorial.filename !== filename) {
                return tutorial;
            }
            return {
                ...tutorial,
                seeAgain
            }
        })
    }
};

const getters = {
    videoTutorialsByFilename(state) {
        return state.videoTutorials.reduce((acc, tutorial) => {
            acc[tutorial.filename] = tutorial;
            return acc;
        }, {})
    }
};

const actions = {
};


export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions,
};
