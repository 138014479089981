var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"offset-y":"","nudge-bottom":"20","close-on-content-click":false,"attach":'#previewChangeMenuToggle'},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onMenu = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onTooltip = ref.on;
return [_c('div',_vm._g({staticClass:"d-flex justify-center align-center pointer",staticStyle:{"background-color":"var(--v-hellgrau-base)"},style:(_vm.windowWidth < 544 ? 'height: 40px; padding-right: 2px; padding-left: 2px' : 'padding-right: 8px; padding-left: 8px'),attrs:{"id":"previewChangeMenuToggle"}},Object.assign({}, onMenu, onTooltip)),[_c('img',{staticClass:"squareBtnIcon",style:(_vm.windowWidth < 544 ? 'height: 30px' : ''),attrs:{"src":_vm.currentPreviewIcon,"alt":"Vorschauwechsel"}}),(_vm.windowWidth > 400)?_c('p',[_vm._v(" Vorschau ")]):(_vm.windowWidth > 360)?_c('p',{staticStyle:{"font-size":"small","margin-left":"0px"}},[_vm._v(" Vorschau ")]):_vm._e()])]}}],null,true)},[_c('span',[_vm._v("Vorschauwechsel")])])]}}])},[_c('div',{staticClass:"d-flex justify-center align-center pa-2 menuBg"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-3 toolSelector",attrs:{"x-small":"","disabled":_vm.currentPreview === 'default'},on:{"click":function($event){return _vm.$emit('change','default')}}},on),[_c('img',{staticStyle:{"max-width":"28px","max-height":"35px"},attrs:{"alt":"Vorschau Standardansicht","src":_vm.defaultDesktopIcon}})])]}}])},[_c('span',[_vm._v("Standardansicht")])]),_c('div',{staticClass:"d-inline-flex mr-3",staticStyle:{"min-height":"45px !important","border-left":"3px solid white","border-radius":"1px"}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-3 toolSelector",attrs:{"x-small":"","disabled":_vm.currentPreview === 'ipad landscape'},on:{"click":function($event){return _vm.$emit('change', 'ipad landscape')}}},on),[_c('img',{staticStyle:{"max-width":"28px","max-height":"35px"},attrs:{"alt":"Vorschau iPad Horizontalansicht","src":_vm.tabletLandscapeIcon}})])]}}])},[_c('span',[_vm._v("iPad horizontal")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-3 toolSelector",attrs:{"x-small":"","disabled":_vm.currentPreview === 'ipad portrait'},on:{"click":function($event){return _vm.$emit('change', 'ipad portrait')}}},on),[_c('img',{staticStyle:{"max-width":"28px","max-height":"35px"},attrs:{"alt":"Vorschau iPad Vertikalansicht","src":_vm.tabletPortraitIcon}})])]}}])},[_c('span',[_vm._v("iPad vertikal")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-3 toolSelector",attrs:{"x-small":"","disabled":_vm.currentPreview === 'smartphone landscape'},on:{"click":function($event){return _vm.$emit('change','smartphone landscape')}}},on),[_c('img',{staticStyle:{"max-width":"28px","max-height":"35px"},attrs:{"alt":"Vorschau Smartphone Horizontalansicht","src":_vm.smartphoneLandscapeIcon}})])]}}])},[_c('span',[_vm._v("Smartphone horizontal")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"toolSelector",attrs:{"x-small":"","disabled":_vm.currentPreview === 'smartphone portrait'},on:{"click":function($event){return _vm.$emit('change','smartphone portrait')}}},on),[_c('img',{staticStyle:{"max-width":"28px","max-height":"35px"},attrs:{"alt":"Vorschau Smartphone Vertikalansicht","src":_vm.smartphonePortraitIcon}})])]}}])},[_c('span',[_vm._v("Smartphone vertikal")])])],1),_c('div',{staticClass:"d-flex menuBg pl-2 pb-2"},[_c('v-switch',{staticStyle:{"margin-top":"0px !important"},attrs:{"label":"Schüler Ansicht","hide-details":""},model:{value:(_vm.previewPupilUI),callback:function ($$v) {_vm.previewPupilUI=$$v},expression:"previewPupilUI"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }