<template>
    <div
        id="btnsContainer"
        class="d-flex justify-start"
    >
        <!-- bbb for external guests, visible to teacher only -->
        <!-- :color="externalRoom.isActive ? 'gruen' : 'fgrau'" -->

        <v-btn
            class="joinRoomBtn px-2"
            elevation="0"
            :color="'gruen'"
            @click="clickExternalButton"
        >
            <img :src="videoWeissIcon">
            <p>Extern</p>
        </v-btn>
        <!-- Pack dialog into a external compontent in order to conditionally load data-->
        <JoinExternalDialog
            v-if="dialogActive"
            @closeDialog="closeDialog"
        />
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import videoWeissIcon from '@/assets/Icons/kamera-video-weiss-72.svg';
import JoinExternalDialog from './JoinExternalDialog.vue';

export default {
  name: "JoinExternalButton",
  components: {
    JoinExternalDialog,
  },
  props: {
  },
  data() {
    return {
      videoWeissIcon,
      dialogActive: false,
    };
  },
  watch: {
  },
  async beforeMount() {
  },
  methods: {
    async clickExternalButton() {
      this.dialogActive = true;
    },
    closeDialog() {
      this.dialogActive = false;
    },
  }
}
</script>

<style scoped lang="scss">
.v-btn {
  border-radius: 8px;
}

.joinRoomBtn {
  color: white;
  text-transform: inherit;
  height: 50px !important;
  margin-left: 12px;

  img {
    height: 25px;
    filter: brightness(1000%);
    margin-right: 4px;
  }

  p {
    font-size: large;
    font-weight: 400;
    line-height: 0;
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 900px) {
  .joinRoomBtn {
    height: 40px !important;
    padding: 8px;
    margin-left: 8px;

    img {
      height: 20px;
    }

    p {
      font-size: small;
    }
  }
}

//when change to mobile nav on bottom
@media only screen and (max-width: 500px), (max-width: 900px) and (orientation: portrait) {
    .joinRoomBtn {
        padding: 8px !important;
        margin-left: 0 !important;
        margin-bottom: 8px;
        width: 144px;
    }
}
</style>
