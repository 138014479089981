<template>
    <div
        v-if="tutorialState"
        style="background-color: #f8f8f8"
        class="backgroundImg"
    >
        <!--bottom navigation bar, used only for mobile devices-->
        <div
            v-if="!faq"
            class="btmNav"
        >
            <div
                class="d-flex flex-row justify-space-around align-center"
                style="height: 100%; width: 100%"
            >
                <v-btn
                    v-for="item in btmNavItems"
                    :key="item.name"
                    elevation="0"
                    small
                    class="btmNavBtn pa-0"
                    :style="currSlide === item.goToIndex && !btmNavMenu ? 'background-color:' + item.bgColor + '!important;' : ''"
                    @click="switchSlide(item.goToIndex)"
                >
                    <img
                        :src="item.icon"
                        :alt="item.alt"
                        class="btmNavIcon"
                        :style="currSlide === item.goToIndex && !btmNavMenu ? 'filter: brightness(1000%);' : item.color"
                    >
                </v-btn>

                <!--menu activator-->
                <v-btn
                    ref="btmMenuBtn"
                    elevation="0"
                    small
                    class="btmNavBtn pa-0"
                    :style="btmNavMenu ? 'background-color: #6995CD !important;' : ''"
                    @click="handleBtmMenuClick"
                >
                    <img
                        :src="menuIcon"
                        alt="Menüsymbol"
                        class="btmNavIcon"
                        :style="btmNavMenu ? 'filter: brightness(1000%);' : 'filter: brightness(0) saturate(100%) invert(56%) sepia(46%) saturate(448%) hue-rotate(174deg) brightness(93%) contrast(86%);'"
                    >
                </v-btn>

                <!--menu content-->
                <div
                    ref="btmMenu"
                    v-closable="{ exclude: ['btmMenuBtn', 'btmMenu'], handler: 'onClose' }"
                    class="d-flex flex-column pa-2 btmNavMenu"
                    :class="btmNavMenu ? '' : 'hideMe'"
                    :style="btmNavLangMenu ? 'box-shadow: -5px -2px 4px silver;' : 'border-top-left-radius: 12px;'"
                >
                    <div class="d-flex pa-2 mb-2">
                        <img
                            v-if="this.person ? this.person.profilePicture : false"
                            :src="usedProfilePic"
                            class="roundProfilePicBtmNav"
                            alt="Profilbild"
                        >

                        <div
                            v-else
                            :style="getGenderColor()"
                            class="roundProfilePicBtmNav d-flex justify-center align-center"
                        >
                            <img
                                :src="usedProfilePic"
                                class="iconToWhite"
                                height="20"
                                width="20"
                                alt
                            >
                        </div>
                        <p class="ml-2 ma-0 ellipsis greyText">
                            {{ person ? person.name : 'Name' }}
                        </p>
                    </div>

                    <join-lehrerzimmer-button />
                    <join-klassenraum-button />
                    <join-sprechzimmer-button />
                    <join-external-button />

                    <!-- opens profile page -->
                    <v-btn
                        v-ripple
                        class="d-flex mb-2 pa-2 btmNavMenuBtn pointer preventColorInvert"
                        depressed
                        @click="toggleProfileManagement(); closeBtmNavMenus()"
                    >
                        <img
                            :src="profileOutlineIcon"
                            height="25"
                            alt=""
                        >
                        <p class="ml-2 ma-0 text-capitalize">
                            Profil
                        </p>
                    </v-btn>

                    <v-btn
                        v-ripple
                        class="d-flex mb-2 pa-2 btmNavMenuBtn"
                        depressed
                        @click="() => { setSupportPageOpen(true); }"
                    >
                        <v-icon
                            alt
                            height="25"
                        >
                            mdi-face-agent
                        </v-icon>
                        <p class="ml-2 ma-0 text-capitalize">
                            Hilfe
                        </p>
                    </v-btn>

                    <v-btn
                        v-ripple
                        class="d-flex mb-2 pa-2 btmNavMenuBtn"
                        depressed
                        @click="reloadPage(); closeBtmNavMenus"
                    >
                        <img
                            :src="aktualisierenIcon"
                            alt="Aktualisierensymbol"
                            height="25"
                        >
                        <p class="ml-2 ma-0 text-capitalize">
                            Aktualisieren
                        </p>
                    </v-btn>
                    <v-btn
                        v-ripple
                        class="d-flex pa-2 btmNavMenuBtn"
                        depressed
                        @click="clickLogoutUser(); closeBtmNavMenus()"
                    >
                        <img
                            :src="logoutIcon"
                            alt="Abmeldensymbol"
                            height="25"
                        >
                        <p
                            class="ml-2 ma-0 text-capitalize"
                            style="color: var(--v-error-base)"
                        >
                            Abmelden
                        </p>
                    </v-btn>
                </div>
            </div>
        </div>
        <!--top menu bar-->
        <v-overlay
            v-if="topMenu || btmNavMenu || supportPageOpen"
            style="z-index: 10"
            overlay-opacity="0.8"
            @click.native="onCloseTop"
        />
        <v-row
            style="background-color: var(--v-dunkelgrau-base);"
            class="displayHide"
        >
            <v-col
                class="d-flex align-center"
                style="margin-left: 2.5%"
            >
                <!-- Lehrerzimmer Button -->
                <join-lehrerzimmer-button />

                <!-- Klassenraum Button -->
                <join-klassenraum-button />

                <!-- Sprechzimmer Button -->
                <join-sprechzimmer-button />

                <!-- External Guests Button -->
                <join-external-button />
            </v-col>

            <!--name and options-->
            <v-col
                class="d-flex justify-end align-center pl-0"
                style="margin-right: 2.5%"
            >
                <img
                    v-if="this.person ? this.person.profilePicture : false"
                    :src="usedProfilePic"
                    class="roundProfilePic"
                    alt="Profilbild"
                >

                <div
                    v-else
                    :style="getGenderColor()"
                    class="roundProfilePic d-flex justify-center align-center"
                >
                    <img
                        :src="usedProfilePic"
                        class="iconToWhite"
                        height="25"
                        width="25"
                        alt
                    >
                </div>

                <p class="ma-0 ml-2 ellipsis white--text">
                    {{ person ? person.name.slice(0, 1) + '. ' + person.lastName : 'Name' }}
                </p>

                <v-btn
                    x-small
                    elevation="0"
                    class="ml-4"
                    style="background-color: #FFFFFF; width: 50px; height: 50px; border-radius: 8px;"
                    @click="openDiary()"
                >
                    <v-icon
                        size="25"
                        color="dunkelgruen"
                    >
                        mdi-book
                    </v-icon>
                </v-btn>

                <Diary
                    v-if="diaryDialog"
                    :diary-dialog="diaryDialog"
                    @close="diaryDialog = false"
                />

                <v-tooltip
                    v-if="magnifier"
                    bottom
                >
                    <template v-slot:activator="{ on }">
                        <v-btn
                            style="background-color: #8CBD46 !important; width: 50px; height: 50px; border-radius: 8px;"
                            x-small
                            elevation="0"
                            class="ml-4"
                            v-on="on"
                            @click="toggleMagnifier();"
                        >
                            <img
                                :src="lupenIcon"
                                style="max-width: 25px; max-height: 25px;"
                                alt="Lupe einschalten/ausschalten (Alt + L)"
                            >
                        </v-btn>
                    </template>
                    <div style="display: flex; flex-direction: column; text-align: center">
                        <span>
                            <span style="text-decoration: underline white">L</span>upe einschalten/ausschalten (Alt + L)
                        </span>
                    </div>
                </v-tooltip>

                <v-tooltip
                    v-if="keyboard"
                    bottom
                >
                    <template v-slot:activator="{ on }">
                        <v-btn
                            x-small
                            elevation="0"
                            class="ml-4"
                            style="background-color: #8CBD46 !important; width: 50px; height: 50px; border-radius: 8px;"
                            v-on="on"
                            @click="toggleKeyboard()"
                        >
                            <img
                                :src="keyboardIcon"
                                alt="Tastatursteuerung einschalten/ausschalten (Alt + T)"
                                height="25"
                            >
                        </v-btn>
                    </template>
                    <div style="display: flex; flex-direction: column; text-align: center">
                        <span>
                            <span style="text-decoration: underline white">T</span>astatursteuerung einschalten/ausschalten (Alt + T)
                        </span>
                    </div>
                </v-tooltip>

                <v-btn
                    style="background-color: #FFFFFF; width: 50px; height: 50px; border-radius: 8px;"
                    x-small
                    elevation="0"
                    class="ml-4 animateIcon"
                    @click="reloadPage()"
                >
                    <img
                        :src="aktualisierenIcon"
                        alt="Aktualisieren"
                        style="max-width: 25px; max-height: 25px;"
                    >
                </v-btn>

                <!--menu activator-->
                <v-btn
                    ref="topMenuBtn"
                    elevation="0"
                    small
                    class="btmNavBtn pa-0 ml-4"
                    style="z-index: 11 !important"
                    :style="topMenu ? 'background-color: #6995CD !important;' : ''"
                    @click="handleTopMenuClick"
                >
                    <img
                        :src="menuIcon"
                        alt="Menü"
                        style="max-width: 25px; max-height: 25px;"
                        :style="topMenu ? 'filter: brightness(1000%);' : 'filter: brightness(0) saturate(100%) invert(56%) sepia(46%) saturate(448%) hue-rotate(174deg) brightness(93%) contrast(86%);'"
                    >
                </v-btn>

                <!--menu content-->
                <v-overlay
                    v-if="false"
                    overlay-opacity="0.8"
                />
                <div
                    ref="topMenuDiv"
                    v-closable="{ exclude: ['topMenuBtn', 'topMenuDiv'], handler: 'onCloseTop' }"
                    style="z-index: 11 !important"
                    class="d-flex flex-column pa-2 topMenu"
                    :class="topMenu ? '' : 'hideMe'"
                >
                    <!-- opens profile page -->
                    <v-btn
                        v-ripple
                        class="d-flex mb-2 pa-2 btmNavMenuBtn pointer preventColorInvert"
                        depressed
                        @click="toggleProfileManagement(); closeTopMenus()"
                    >
                        <img
                            :src="profileOutlineIcon"
                            height="25"
                            alt=""
                        >
                        <p class="ml-2 ma-0 text-capitalize">
                            Profil
                        </p>
                    </v-btn>

                    <v-btn
                        v-ripple
                        class="d-flex mb-2 pa-2 btmNavMenuBtn"
                        depressed
                        @click="() => { setSupportPageOpen(true); closeTopMenus(); }"
                    >
                        <v-icon
                            alt
                            height="25"
                        >
                            mdi-face-agent
                        </v-icon>
                        <p class="ml-2 ma-0 text-capitalize">
                            Hilfe
                        </p>
                    </v-btn>
                    <v-btn
                        v-ripple
                        class="d-flex pa-2 btmNavMenuBtn pointer"
                        depressed
                        @click="() => { clickLogoutUser(); closeTopMenus(); }"
                    >
                        <img
                            :src="logoutIcon"
                            alt
                            height="25"
                        >
                        <p
                            class="ml-2 ma-0 text-capitalize"
                            style="color: var(--v-error-base)"
                        >
                            Abmelden
                        </p>
                    </v-btn>
                </div>
            </v-col>
        </v-row>

        <div
            v-if="windowWidth > 900 && !faq && !profileManagement"
            class="big mt-4"
        >
            <v-btn
                fab
                small
                icon
                color="#eaeaea"
                style="margin-left: 5px; margin-top: 20%"
                class="arrowBtn buttonFocusHover"
                @click="page = Math.abs((page - 1) % 2)"
            >
                <img
                    style="position: relative; margin:auto; display: block; width: 20px"
                    alt="Pfeil nach links"
                    :src="left"
                    class="imgFocusHover"
                >
            </v-btn>
            <v-row>
                <v-col class="pb-0">
                    <PlanWidget
                        v-if="page === 0"
                        @start-bbb-tut="startBBBTut"
                    />
                    <FileWidget v-if="page === 1" />
                </v-col>

                <v-col class="pb-0">
                    <BrettWidget v-if="page === 0" />
                    <MessagesWidget
                        v-if="page === 1"
                        :role="'teacher'"
                    />
                </v-col>
                <v-col class="pb-0">
                    <chat-widget v-if="page === 0" />
                    <Verlag v-if="page === 1 && bonusFunctions.etherpad" />
                </v-col>
            </v-row>
            <v-btn
                fab
                small
                icon
                color="#eaeaea"
                style="margin-right: 5px; margin-top: 20%"
                class="arrowBtn buttonFocusHover"
                @click="page = Math.abs((page + 1) % 2)"
            >
                <img
                    style="position: relative; margin:auto; display: block; width: 20px"
                    alt="Pfeil nach rechts"
                    :src="right"
                    class="imgFocusHover"
                >
            </v-btn>
        </div>
        <Faq
            v-else-if="windowWidth > 900 && faq"
            style="height: 100%; min-height: 90vh"
            @closeTopMenu="onCloseTop"
        />
        <ProfileManagement
            v-else-if="windowWidth > 900 && profileManagement"
            style="height: 100%; min-height: 90vh"
        />
        <VueSlickCarousel
            v-else-if="!faq && !profileManagement"
            ref="carousel"
            class="small topPadding"
            :arrows="false"
            :initial-slide="0"
            :infinite="false"
            :dots="false"
            @afterChange="vueSlickCarouselAfterChange"
        >
            <PlanWidget
                :small="true"
                @start-bbb-tut="startBBBTut"
            />
            <BrettWidget style="height: 100vh" />
            <chat-widget />
            <FileWidget style="height: 100vh" />
            <MessagesWidget :role="'teacher'" />
        </VueSlickCarousel>
        <Faq
            v-else-if="faq"
            style="height: 100%; min-height: 100vh"
        />
        <ProfileManagement
            v-else
            style="height: 100%; min-height: 100vh"
        />
        <SupportChat
            v-if="supportPageOpen"
            @resetTutorial="resetTutorial()"
        />

        <v-dialog
            v-model="securityQuestionDialog"
            width="600px"
        >
            <v-card>
                <v-card-title>
                    Sicherheitsfrage festlegen
                </v-card-title>
                <v-card-text>
                    <p class="mb-2">
                        Sie haben noch keine Sicherheitsfrage für Ihr Konto festgelegt.
                        Wählen Sie nun eine Frage und eine Antwort fest um die Sicherheit ihres Kontos zu erhöhen.
                    </p>
                    <p>Sicherheitsfrage auswählen</p>
                    <v-select
                        v-model="securityQuestion"
                        outlined
                        dense
                        hide-details
                        :items="securityQuestions"
                        :menu-props="{ bottom: true, offsetY: true }"
                        no-data-text="Sicherheitsfragen konnten nicht geladen werden"
                        class="mb-2"
                        style="max-width: 600px;"
                    />

                    <div v-if="securityQuestion">
                        <p>Sicherheitsfrage beantworten</p>
                        <v-text-field
                            v-model="securityQuestionAnswer"
                            outlined
                            dense
                            hide-details
                            class="mb-2"
                            style="max-width: 600px;"
                        />
                    </div>
                </v-card-text>
                <v-card-actions class="d-flex justify-end px-6 pb-4">
                    <v-btn
                        id="custom-disabled"
                        :disabled="!(securityQuestion && securityQuestionAnswer)"
                        color="gruen"
                        class="text-none"
                        elevation="0"
                        dark
                        @click="setSecurityQuestion"
                    >
                        Speichern
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <VideoTutorial
            v-if="videoTutorial"
            :video-tutorial="videoTutorial"
            @close="videoTutorialClosed = true"
        />
    </div>

    <Tutorial
        v-else
        :baby-view="false"
        :teacher-view="teacherView"
        :teacher-b-b-b="teacherBBB"
        :check-tutorial-state="checkTutorialState"
        @bbb-tut-done="openBBB"
    />
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";

import PlanWidget from "../../components/Lehrer/PlanWidgetLehrer";
import BrettWidget from "../../components/BrettWidget";
import FileWidget from "../../components/FileWidget";
import ChatWidget from "../Chat/ChatWidget";
import MessagesWidget from "../MessagesWidget";
import Verlag from "../../components/Verlag";
import Tutorial from "../Tutorial/Tutorial";
import SupportChat from "../Support/SupportChat";
import JoinLehrerzimmerButton from "./JoinLehrerzimmerButton";
import JoinKlassenraumButton from "../../components/JoinKlassenraumButton";
import JoinSprechzimmerButton from "../../components/JoinSprechzimmerButton";
import JoinExternalButton from "../../components/JoinExternal/JoinExternalButton";
import Diary from "../Lehrer/Diary";
import Faq from "../Faq";
import ProfileManagement from "../../components/ProfileManagement"
import VideoTutorial from "../../components/Tutorial/VideoTutorial.vue"
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css' // optional style for arrows & dots

import briefIcon from '../../assets/Icons/letter.svg'
import logoutIcon from '../../assets/Icons/beitretensvg-red-06.svg'
import left from '../../assets/Icons/links-filled-10.svg'
import right from '../../assets/Icons/rechts-filled-11.svg'
import profileIcon from "../../assets/Icons/profile.svg";
import stundenplanIcon from "../../assets/Icons/timetable.svg";
import brettIcon from "../../assets/Icons/brett.svg";
import chatIcon from "../../assets/Icons/chat-alternative-filled-102.svg";
import dateienIcon from "../../assets/Icons/file.svg";
import menuIcon from "../../assets/Icons/einstellungen-neu-103.svg";
import aktualisierenIcon from "../../assets/Icons/aktualisieren-107.svg";
import lupenIcon from '../../assets/Icons/lupe-18.svg';
import keyboardIcon from '../../assets/Icons/keyboard-24px.svg';
import profileOutlineIcon from "../../assets/Icons/profil-schueler.svg";

// This variable will hold the reference to document's click handler, used for the v-directive closable
let handleOutsideClick;

export default {
    name: "Plan",
    components: {
        Faq,
        ProfileManagement,
        JoinLehrerzimmerButton,
        JoinKlassenraumButton,
        JoinSprechzimmerButton,
        JoinExternalButton,
        ChatWidget,
        BrettWidget,
        PlanWidget,
        FileWidget,
        MessagesWidget,
        Verlag,
        VueSlickCarousel,
        Tutorial,
        SupportChat,
        Diary,
        VideoTutorial, 
    },
    directives: {
        //directive from https://tahazsh.com/detect-outside-click-in-vue with changes from Peter Means (in comments)
        closable: {
            bind(el, binding, vnode) {
                // Here's the click/touchstart handler (it is registered below)
                handleOutsideClick = (e) => {
                    e.stopPropagation()
                    // Get the handler method name and the exclude array from the object used in v-closable
                    const { handler, exclude } = binding.value

                    // This variable indicates if the clicked element is excluded
                    let clickedOnExcludedEl = false
                    exclude.forEach(refName => {
                        // We only run this code if we haven't detected any excluded element yet
                        if (!clickedOnExcludedEl) {
                            // Get the element using the reference name
                            const excludedEl = vnode.context.$refs[refName];
                            let excludedDomEl = null;
                            if (excludedEl) {
                                // If it's a vue component grab the element, otherwise it is the element
                                excludedDomEl = excludedEl.$el ? excludedEl.$el : excludedEl;
                                clickedOnExcludedEl = excludedDomEl.contains(e.target);
                            }
                        }
                    })
                    // We check to see if the clicked element is not the dialog element and not excluded
                    if (!el.contains(e.target) && !clickedOnExcludedEl) {
                        // If the clicked element is outside the dialog and not the button,
                        // then call the outside-click handler from the same component this directive is used in
                        vnode.context[handler]()
                    }
                }
                // Register click/touchstart event listeners on the whole page
                document.addEventListener('click', handleOutsideClick)
                document.addEventListener('touchstart', handleOutsideClick)
            },

            unbind() {
                // If the element that has v-closable is removed, then
                // unbind click/touchstart listeners from the whole page
                document.removeEventListener('click', handleOutsideClick)
                document.removeEventListener('touchstart', handleOutsideClick)
            }
        }
    },
    props: {

    },
    data() {
        return {
            left,
            right,
            logoutIcon,
            menuIcon,
            aktualisierenIcon,
            lupenIcon,
            keyboardIcon,
            profileOutlineIcon,

            topMenu: false,
            btmNavMenu: false,
            btmNavLangMenu: false,
            person: null,
            page: 0,

            securityQuestionSet: true,     // TODO: set to null on default | is a security question set for this account?
            securityQuestionDialog: false,
            securityQuestions: [],          // holds the available security questions
            securityQuestion: null,         // is a security question selected in the dialog
            securityQuestionAnswer: '',     // the answer to the selected security question

            usedProfilePic: profileIcon,

            profilePictureUrl: "",
            tutorialState: false,
            teacherView: true,
            teacherBBB: false,
            bbbTutState: null,

            diaryDialog: false,

            callbackMethod: null,   //joinBBB method passed up from Fach-component

            currSlide: 0,               //to color icons of mobile bottom navBar
            slideChangeAllowed: true,   //to prevent spamming of btmNavBar, which would bring highlighting of btmNavIcons and currently displayed widget out of sync

            videoTutorialClosed: false,

            btmNavItems: [
                { name: 'stundenplan', alt: 'Stundenplan', icon: stundenplanIcon, goToIndex: 0, bgColor: '#6995CD', color: 'filter: brightness(0) saturate(100%) invert(56%) sepia(46%) saturate(448%) hue-rotate(174deg) brightness(93%) contrast(86%);' },
                { name: 'brett', alt: 'Schwarzes Brett', icon: brettIcon, goToIndex: 1, bgColor: '#9C9CA0', color: 'filter: brightness(0) saturate(100%) invert(47%) sepia(0%) saturate(98%) hue-rotate(150deg) brightness(90%) contrast(86%);' },
                { name: 'chat', alt: 'Chat', icon: chatIcon, goToIndex: 2, bgColor: '#8CBD46', color: 'filter: brightness(0) saturate(100%) invert(70%) sepia(22%) saturate(1027%) hue-rotate(43deg) brightness(94%) contrast(90%);' },
                { name: 'dateien', alt: 'Dateien', icon: dateienIcon, goToIndex: 3, bgColor: '#F49F31', color: 'filter: brightness(0) saturate(100%) invert(67%) sepia(71%) saturate(1286%) hue-rotate(338deg) brightness(104%) contrast(91%);' },
                { name: 'brief', alt: 'Elternbrief', icon: briefIcon, goToIndex: 4, bgColor: '#94519B', color: 'filter: brightness(0) saturate(100%) invert(34%) sepia(43%) saturate(774%) hue-rotate(247deg) brightness(98%) contrast(81%)' },
            ],
        };
    },
    computed: {
        ...mapState("faq", ["faq"]),
        ...mapState("auth", ["stayLoggedIn", "deviceToken"]),
        ...mapState("profileManagement", ["profileManagement"]),
        ...mapState("magnifier", ["magnifier"]),
        ...mapState("util", ["keyboard", 'bonusFunctions', 'browserSupported', 'windowWidth']),
        ...mapGetters("support",["supportPageOpen"]),
        ...mapGetters('videoTutorial', [ 'videoTutorialsByFilename' ]),
        videoTutorial() {
            const tutorial = this.videoTutorialsByFilename['Lehrer_in_8Min_erklaert.mp4'];
            if(tutorial?.seeAgain && !this.videoTutorialClosed) {
                return tutorial;
            }
            return false;
        }
    },
    watch: {
        async person(newVal) {
            const profilePic = await this.getTeacherPic();
            if(profilePic) {
                this.usedProfilePic = profilePic;
            }
        }
    },
    beforeDestroy() {
        window.removeEventListener('orientationchange', this.onOrientationChange);
    },
    async created() {
        this.checkTutorialState();
    },
    async mounted() {
        window.addEventListener('orientationchange', this.onOrientationChange);
        this.requestPerson();
        this.requestBonusFunctions();
        this.checkSecurityQuestion();
    },
    async unmounted() {
        if (this.usedProfilePic && this.usedProfilePic !== profileIcon) {
            URL.revokeObjectURL(this.usedProfilePic);
        }
    },
    methods: {
        ...mapActions("teachers", ["getMeTeacher", "getProfilePicture"]),
        ...mapActions("util", ["requestBonusFunctions", "toggleKeyboard"]),
        ...mapActions("auth", ["logoutUser", "reloadDontLogout"]),
        ...mapActions("faq", ["toggleFaq", "closeFaq"]),
        ...mapActions("profileManagement", ["toggleProfileManagement", "closeProfileManagement"]),
        ...mapActions("magnifier", ["toggleMagnifier", "turnOffMagnifier"]),
        ...mapMutations("snackbar", ["showSnackbar"]),
        ...mapMutations("support", ["setSupportPageOpen"]),

        vueSlickCarouselAfterChange() {
            this.slideChangeAllowed = true;
            this.currSlide = this.$refs.carousel.$refs.innerSlider.currentSlide;
        },

        // async subscribe() {
        //     navigator.serviceWorker.getRegistrations().then(async (registrations) => {
        //         // Register Push
        //         const subscription = await registrations[0].pushManager.subscribe({
        //             userVisibleOnly: true,
        //             applicationServerKey: this.urlBase64ToUint8Array('BKFBvlmQYh9nNP9ECrMgMj-3v__wDW8Q3M0NPcHpH3LXSq7YsYvSR_dj757TxzwL2hKr--gN6SuIyuy3H0OUgeo')
        //         });
        //         await backend.subscribe({
        //             subscription,
        //             deviceToken: this.deviceToken,
        //         });
        //     });
        //     this.showSnackbar({
        //         message: 'Benachrichtigungen aktiv',
        //         color: 'info'
        //     })
        // },
        startBBBTut(callback) {
            this.tutorialState = false;

            this.bbbTutState = JSON.parse(localStorage.getItem('bbbTut'));
            this.teacherView = false;
            this.teacherBBB = true;
            this.callbackMethod = callback;
        },

        openBBB() {
            this.callbackMethod();
        },

        checkSecurityQuestion() {
            if (!this.securityQuestionSet) {
                this.securityQuestionDialog = true;
                // TODO: also get available security questions
                this.securityQuestions = ['Wie heißt deine Oma mit Vornamen?', 'Wie heißt dein Opa mit Vornamen?'];
            }
        },

        // clear fields and close dialog if request to backend was successful
        setSecurityQuestion() {
            this.securityQuestion = null;
            this.securityQuestionAnswer = '';
            this.securityQuestionDialog = false;
        },

        resetTutorial() {
            this.tutorialState = false;
            localStorage.setItem('tutorial', 'false');
        },

        checkTutorialState() {
            this.tutorialState = JSON.parse(localStorage.getItem('tutorial'));
        },

        async requestPerson() {
            this.person = await this.getMeTeacher();
              //TODO: remove me
        },

        //if there is an uploaded profile picture for teacher
        async getTeacherPic() {
            if (this.person.profilePicture) {
                let profilePictureBlob = await this.getProfilePicture(this.person._id);
                const objectURL = URL.createObjectURL(profilePictureBlob);

                this.profilePictureUrl = objectURL;
                this.person.bild = objectURL;

                return objectURL;
            }
        },

        // else it uses general profile pic with background color
        getGenderColor() {
            if (this.person) {
                let gender = this.person.gender;
                switch (gender) {
                    case "m": return 'background-color: #538DCA';
                    case "w": return 'background-color: #E5536E';
                    default: return 'background-color: #9B91C8';
                }
            } else {
                return 'background-color: #9B91C8';
            }
        },

        clickLogoutUser() {
            if (this.profileManagement) {
                this.closeProfileManagement();
            }
            if (this.faq) {
                this.toggleFaq();
            }
            this.logoutUser();
            this.$router.push({ name: 'login' });
        },
        reloadPage() {
            this.reloadDontLogout(true);
            this.$router.go();
        },

        /*btmNav methods*/
        switchSlide(index) {
            if (this.slideChangeAllowed && index !== this.currSlide) {
                if (this.babyView) {
                    this.$refs.carouselBabyView.goTo(index);
                } else {
                    this.$refs.carousel.goTo(index);
                }
                this.currSlide = index;
                this.slideChangeAllowed = false;
                this.closeBtmNavMenus();
            }
            /*if btmNavMenu is open and you click on the button for the widget which is already being displayed*/
            else if (this.slideChangeAllowed && this.btmNavMenu) {
                this.btmNavMenu = false; //then just close the btmNavMenu
            }
        },
        closeBtmNavMenus() {
            this.btmNavLangMenu = false;
            this.btmNavMenu = false;
        },
        handleBtmMenuClick() {
            this.btmNavMenu = !this.btmNavMenu;
            this.btmNavLangMenu = false;
        },
        // vue/no-unused-properties cannot detect methods by directives 
        // and this is referenced by a directive, so make a exception
        // eslint-disable-next-line vue/no-unused-properties
        onClose() {
            this.closeBtmNavMenus();
        },
        onOrientationChange() {
            this.switchSlide(0);
            this.slideChangeAllowed = true;
        },

        /*#region topMenu methods*/
        handleTopMenuClick() {
            this.topMenu = !this.topMenu;
        },
        onCloseTop() {
            this.closeTopMenus();
        },
        closeTopMenus() {
            this.topMenu = false;
        },
        /*#endregion*/

        openDiary() {
            this.diaryDialog = true;
        },
    },
};
</script>

<style lang="scss" scoped>
.backgroundImg {
    background-image: url(../../assets/Bilder/scribble.svg);
    background-repeat: repeat;
    overflow: hidden;
    height: 100%;
}

table,
th,
td {
    border: 1px solid black;
    border-collapse: collapse;
}

tr {
    height: 40px;
}

table tr th:first-child,
table tr td:first-child {
    width: 10px !important;
}

table tr th,
table tr td {
    width: 9em;
    border: none;
}
table,
tr,
td {
    border: none;
}

.small {
    display: none;
}

.big {
    display: flex;
}

.ellipsis {
    text-overflow: ellipsis;

    /* Required for text-overflow to do anything */
    white-space: nowrap;
    overflow: hidden;
}

.greyText {
    color: var(--v-dunkelgrau-base);
}

.iconToWhite {
    filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%)
        hue-rotate(36deg) brightness(104%) contrast(105%);
}

#custom-disabled.v-btn--disabled {
    background-color: var(--v-fgrau-base) !important;
    color: white;
}

.roundProfilePic {
    border-radius: 50%;
    height: 35px;
    width: 35px;
}

.roundProfilePicBtmNav {
    border-radius: 50%;
    height: 25px;
    width: 25px;
}

.btmNav {
    display: none;
    z-index: 11;
    position: fixed;
    bottom: 0;
    width: 100% !important;
    height: 70px !important;
    background-color: var(--v-dunkelgrau-base);
}

.btmNavBtn {
    height: 50px !important;
    width: 50px !important;
    background-color: white !important;
    border-radius: 8px !important;
}

/*removes darker background after btn is clicked
    also works without the specific class in front*/
.btmNavBtn.v-btn:before {
    opacity: 0 !important;
}
.arrowBtn:before {
    opacity: 0 !important;
}

.btmNavIcon {
    height: 35px;
}

.hideMe {
    display: none !important;
}

.topMenu {
    position: absolute;
    top: 74px;
    right: 5%;
    width: 180px;
    max-width: 180px;
    background-color: #ffffff;
    border-radius: 3px;
    box-shadow: 0 2px 4px silver;
}

.btmNavMenu {
    position: fixed;
    bottom: 70px;
    right: 0;
    width: 165px;
    max-width: 165px;
    background-color: #ffffff;
    box-shadow: 0 -2px 4px silver;
}

.btmNavMenuBtn {
    color: var(--v-dunkelgrau-base);
    background-color: var(--v-editorGrey-base);
    border-radius: 8px;
    justify-content: flex-start;
}

.btmNavLangNoIcon {
    height: 25px;
    width: 25px;
    background-color: #ffffff;
}

.btmNavLangMenu {
    position: fixed;
    bottom: 70px;
    right: 140px;
    border-top-left-radius: 12px;
    background-color: #ffffff;
    box-shadow: -5px -2px 4px silver;
}

.btmNavLangBtn {
    width: 41px;
    height: 41px; /*needed so that border and box-sizing work*/
    color: var(--v-dunkelgrau-base);
    border-radius: 8px;
}

.border {
    border: 3px #6995cd solid;
    box-sizing: border-box !important;
}

.pointer {
    cursor: pointer;
}

@media only screen and (max-width: 900px) {
    .small {
        display: block !important;
    }

    .big {
        display: none !important;
    }

    .topPadding {
        padding-top: 12px;
    }
}

.slick-slider {
    user-select: auto !important;
}

@media only screen and (max-width: 500px),
    (max-width: 900px) and (orientation: portrait) {
    .displayHide {
        display: none;
    }

    .btmNav {
        display: block;
    }
}

.animateIcon {
    overflow: hidden;
}

.animateIcon:after {
    animation: shine 60s ease-in-out infinite;
    animation-fill-mode: forwards;
    content: "";
    position: absolute;
    top: -110%;
    left: -210%;
    width: 200%;
    height: 200%;
    opacity: 0;
    transform: rotate(30deg);

    background: rgba(255, 255, 255, 0);
    background: linear-gradient(
        to right,
        rgba(112, 112, 112, 0.2) 0%,
        rgba(112, 112, 112, 0.2) 77%,
        rgba(112, 112, 112, 0.5) 92%,
        rgba(112, 112, 112, 0) 100%
    );
}

/* Hover state - trigger effect */

/* Active state */
.animateIcon:active:after {
    opacity: 0;
}

@keyframes shine {
    1% {
        opacity: 1;
        top: -30%;
        left: -30%;
        transition-property: left, top, opacity;
        transition-duration: 0.7s, 0.7s, 0.15s;
        transition-timing-function: ease;
    }
    5% {
        opacity: 0;
        top: -30%;
        left: -30%;
        transition-property: left, top, opacity;
    }
}

.v-snack.v-snack--top {
    bottom: initial; /* or auto */
}

.v-snack.v-snack--bottom {
    top: initial; /* or auto */
}

.imgFocus {
    filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%)
        hue-rotate(36deg) brightness(104%) contrast(105%) !important;
}

/*    .buttonFocusHover:hover {
      background-color: #6994cd !important;
    }*/

.buttonFocusHover:hover .imgFocusHover {
    filter: invert(51%) sepia(66%) saturate(310%) hue-rotate(175deg)
        brightness(98%) contrast(91%) !important;
}
</style>
