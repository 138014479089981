<template>
    <div>
        <v-btn
            v-if="sickNote"
            elevation="0"
            class="text-none"
            @click.stop="openDialog"
        >
            + Unterschrift hinzufügen
        </v-btn>

        <v-btn
            v-else
            class="actionBtn"
            x-small
            color="gruen"
            @click.stop="openDialog"
        >
            <img
                :src="unterschriftIconWeiss"
                class="iconToWhite"
                alt="Brief unterschreiben"
            >
        </v-btn>

        <v-dialog
            v-model="signatureDialogOpen"
            max-width="750px"
            max-height="500px"
        >
            <v-card>
                <v-card-title
                    style="justify-content: space-between"
                    class="header mb-2"
                >
                    <p>Unterschreiben</p>
                    <div>
                        <v-btn
                            class="optionBtn"
                            x-small
                            color="fhellgruen"
                            @click="finishSignature"
                        >
                            <img
                                :src="fertigIcon"
                                class="icon iconToWhite"
                                alt="fertig"
                            >
                        </v-btn>

                        <v-btn
                            class="optionBtn ml-2"
                            x-small
                            color="rgba(248, 248, 248, 0.4)"
                            @click="abort"
                        >
                            <img
                                :src="schliessenIcon"
                                class="icon iconToWhite"
                                alt="schließen"
                            >
                        </v-btn>
                    </div>
                </v-card-title>
                <v-card-text>
                    <div
                        id="canvasContainer"
                        ref="canvasContainer"
                    >
                        <canvas
                            id="signatureCanvas"
                            ref="signatureCanvas"
                            style="width: 100%; height: 100%"
                        />
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { fabric } from "fabric";
import unterschriftIconWeiss from '../assets/Icons/unterschrift-weiss-113.svg'
import schliessenIcon from '@/assets/Icons/abbrechen-08.svg'
import fertigIcon from '@/assets/Icons/fertig-74.svg'

export default {
    name: 'SignatureDialog',
    props: {
        letter: { type: Object, required: false, default: null },
        createResponseOnDone: { required: false, default: false },
        sickNote: { type: Boolean, required: false },
    },
    data: () => ({
        signatureImg: null,
        signatureDialogOpen: false,
        localCanvas: null,
        letterPassOn: null,

        // Icons
        unterschriftIconWeiss,
        schliessenIcon,
        fertigIcon,
    }),
    methods: {
        /** General Methods */
        async openDialog() {
            this.signatureDialogOpen = true;
            this.letterPassOn = this.letter;

            await this.$nextTick();
            this.$nextTick(() => {
                this.prepareCanvas();
            });
        },
        closeDialog() {
            if (this.localCanvas != null) {
                this.localCanvas.dispose();
            }

            this.signatureDialogOpen = false;
        },

        /** Specific Methods for Actions */
        finishSignature() {
            this.signatureImg = this.localCanvas.toDataURL('image/png', 1);
            if (this.createResponseOnDone) {
                this.$emit('signatureDone', this.signatureImg, true, this.letterPassOn);
            } else {
                this.$emit('signatureDone', this.signatureImg, false, this.letterPassOn);
            }

            this.closeDialog();
        },
        abort() {
            this.signatureImg = null;
            this.localCanvas = null;

            this.$emit('signatureAbort');
            this.closeDialog();
        },
        prepareCanvas() {
            this.localCanvas = new fabric.Canvas(this.$refs.signatureCanvas);

            const width = this.$refs.canvasContainer.clientWidth;
            const height = this.$refs.canvasContainer.clientHeight;

            this.localCanvas.setDimensions({ width, height });
            this.localCanvas.backgroundColor = "#fff";
            this.localCanvas.isDrawingMode = true;
            this.localCanvas.freeDrawingBrush.color = '#000';
            this.localCanvas.freeDrawingBrush.width = 10;

            let horizontalLine = new fabric.Line([0, 0, width * 0.9, 0], {
                left: width * 0.05,
                top: height * 0.9,
                stroke: '#555',
                selectable: false,
                evented: false
            });

            this.localCanvas.add(horizontalLine);
        },
    }
}
</script>

<style scoped lang="scss">
.iconToWhite {
    filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%) hue-rotate(36deg) brightness(104%) contrast(105%);
}

.actionBtn {
    color: white;
    border-radius: 8px;
    min-width: 30px !important;
    width: 30px !important;
    height: 30px !important;

    img {
        height: 20px;
    }
}

.optionBtn {
    border-radius: 8px;
    min-width: 35px !important;
    width: 35px !important;
    height: 35px !important;

    img {
        height: 20px;
        filter: brightness(1000%);
    }
}

.header {
    background-color: var(--v-frot-base);
    border-radius: 15px 15px 0 0;
    padding-top: 10px;
    padding-bottom: 10px;
    color: white;
}

#canvasContainer {
    min-height: 300px;
    width: 100%;
    height: 100%;
    border: 1px solid grey;
    border-radius: 4px;
}
</style>
