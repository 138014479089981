import { checkResponseStatus } from '@/util/check';
import * as backend from '../../api/backend';

const state = {
  schoolSubjects: {},
  error: false,
};

const getters = {
  schoolSubjects: state => state.schoolSubjects,

  // [{_id: "subject1", ... }, _id: { "subject2", ... }] =>  { subject1: {...}, subject2": {...} }
  // Can hugeley improve performance when accessing fields by Id directly instead of finding
  schoolSubjectsById: state => state.schoolSubjects.reduce((pv, cv) => ({...pv, [cv._id]: cv}), {}),
};

const mutations = {
  setSuccessfulSchoolSubjects: (state, schoolSubjects) => {
    state.schoolSubjects = schoolSubjects;
    state.error = false;
  },
  setErrorSchoolSubjects: (state) => {
    state.schoolSubjects = {};
    state.error = true;
  },
};

const actions = {
  async loadSchoolSubjects ({ commit, dispatch }, params) {
    try {
        const res = await backend.getSchoolSubjects(params);
        await checkResponseStatus(200, res);
        const schooolSubjects = await res.json();
        commit('setSuccessfulSchoolSubjects', schooolSubjects);
    }
    catch(err) {
        commit('setErrorSchoolSubjects');
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
