import * as backend from '../../api/backend';
import { checkResponseStatus } from '../../util/check';

const defaultState = {
    privateFolderSelection: 0,  // amount of elements in current selection
    teacherFolderSelection: 0,  // amount of elements in current selection
    docRegistryFolderSelection: 0, // amount of elements in current selection
    privateFolderSortDescending: false,
    teacherFolderSortDescending: false,
    docRegistryFolderSortDescending: false,
    // how many elements can be shown per row. 
    // at 100% it changes into a list view, 
    // at or below 50% in shows preview images
    vfsGridWidthPct: 50,
};

const actions = {
    async searchVFS ({commit, dispatch}, params) {
        try {
            const res = await backend.searchVFS(params);
            await checkResponseStatus(200, res);
            const queryResult = await res.json();
            return queryResult;
        }
        catch(err) {
            return err.response.status;
        }
    },
};

const mutations = {
    setPrivateFolderSelection: (state, num) => {
        state.privateFolderSelection = num;
    },
    setTeacherFolderSelection: (state, num) => {
        state.teacherFolderSelection = num;
    },
    setDocRegistryFolderSelection: (state, num) => {
        state.docRegistryFolderSelection = num;
    },
    setPrivateFolderSortDescending: (state, bool) => {
        state.privateFolderSortDescending = bool;
    },
    setTeacherFolderSortDescending: (state, bool) => {
        state.teacherFolderSortDescending = bool;
    },
    setDocRegistryFolderSortDescending: (state, bool) => {
        state.docRegistryFolderSortDescending = bool;
    },
    mutateVfsGridWidthPct: (state) => {
        switch(state.vfsGridWidthPct) {
            case 33:
                state.vfsGridWidthPct = 50;
                break;
            case 50:
                state.vfsGridWidthPct = 100;
                break;
            case 100:
                state.vfsGridWidthPct = 33;
                break;
            default:
                state.vfsGridWidthPct = 50;
                break;
        }
    },
};

const getters = {
    privateFolderSelection: state => state.privateFolderSelection,
    teacherFolderSelection: state => state.teacherFolderSelection,
    docRegistryFolderSelection: state => state.docRegistryFolderSelection,
    privateFolderSortDescending: state => state.privateFolderSortDescending,
    teacherFolderSortDescending: state => state.teacherFolderSortDescending,
    docRegistryFolderSortDescending: state => state.docRegistryFolderSortDescending,
    vfsGridWidthPct: state => state.vfsGridWidthPct,
};  

export default {
  namespaced: true,
  state: defaultState,
  mutations,
  actions,
  getters,
};
