var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"offset-y":"","nudge-left":"130px","nudge-bottom":"20","close-on-content-click":false,"attach":'#editorStaticElementBarColumn'},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onMenu = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onTooltip = ref.on;
return [(_vm.mode === 'creator' || _vm.mode === 'teacher' || _vm.mode === 'pupil')?_c('v-btn',_vm._g(_vm._b({staticClass:"squareBtn",attrs:{"icon":"","aria-hidden":"true"},on:{"click":function () { _vm.menu = 'basic'; _vm.$emit('opened') }}},'v-btn',attrs,false),Object.assign({}, onMenu, onTooltip)),[_c('img',{staticClass:"iconToWhite",attrs:{"src":_vm.plusIcon,"alt":"Menü öffnen"}})]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Medien und Formen")])])]}}]),model:{value:(_vm.vMenu),callback:function ($$v) {_vm.vMenu=$$v},expression:"vMenu"}},[(_vm.menu === 'basic')?_c('div',{staticClass:"menuBg"},[_c('div',{staticClass:"d-flex pa-4"},[_c('div',[_c('div',[_c('p',{staticClass:"white--text"},[_vm._v(" Medien ")])]),_c('div',{staticClass:"vl mr-2"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({staticClass:"squareBtn mr-2",attrs:{"x-small":""},on:{"click":_vm.addImage}},tooltip),[_c('img',{attrs:{"src":_vm.bildIcon,"alt":"Bild hinzufügen"}})])]}}],null,false,3313894874)},[_c('div',{staticStyle:{"display":"flex","flex-direction":"column","text-align":"center"}},[_c('span',[_vm._v("Bild hinzufügen")])])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({staticClass:"squareBtn mr-2",attrs:{"x-small":""},on:{"click":_vm.addVideo}},tooltip),[_c('img',{attrs:{"src":_vm.videoIcon,"alt":"Video hinzufügen"}})])]}}],null,false,488844972)},[_c('div',{staticStyle:{"display":"flex","flex-direction":"column","text-align":"center"}},[_c('span',[_vm._v("Video hinzufügen")])])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({staticClass:"squareBtn mr-2",attrs:{"x-small":""},on:{"click":_vm.addAudio}},tooltip),[_c('img',{attrs:{"src":_vm.audioIcon,"alt":"Audio hinzufügen"}})])]}}],null,false,1953013323)},[_c('div',{staticStyle:{"display":"flex","flex-direction":"column","text-align":"center"}},[_c('span',[_vm._v("Audio hinzufügen")])])])],1),_c('div',[_c('input',{ref:"imgUpload",staticStyle:{"display":"none"},attrs:{"type":"file","hidden":"","name":"file","accept":"image/*"},on:{"change":_vm.imgSelected}}),_c('input',{ref:"videoUpload",staticStyle:{"display":"none"},attrs:{"type":"file","hidden":"","name":"file","accept":"video/mp4,video/mpeg,video/mov,video/*"},on:{"change":_vm.videoSelected}}),_c('input',{ref:"audioUpload",staticStyle:{"display":"none"},attrs:{"type":"file","hidden":"","name":"file","accept":"audio/m4a,.m4a,audio/mpeg,audio/ogg,audio/mp3,audio/*"},on:{"change":_vm.audioSelected}})])]),_c('div',[_c('div',[_c('p',{staticClass:"white--text"},[_vm._v(" Formen ")])]),_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({staticClass:"squareBtn mr-2",attrs:{"x-small":""},on:{"click":_vm.clickedAddRect}},tooltip),[_c('img',{attrs:{"src":_vm.rechteckIcon,"alt":"Rechteck hinzufügen"}})])]}}],null,false,863434258)},[_c('div',{staticStyle:{"display":"flex","flex-direction":"column","text-align":"center"}},[_c('span',[_vm._v("Rechteck hinzufügen")])])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({staticClass:"squareBtn",attrs:{"x-small":""},on:{"click":_vm.clickedAddLine}},tooltip),[_c('img',{attrs:{"src":_vm.linieIcon,"alt":"Linie hinzufügen"}})])]}}],null,false,4032886172)},[_c('div',{staticStyle:{"display":"flex","flex-direction":"column","text-align":"center"}},[_c('span',[_vm._v("Linie hinzufügen")])])])],1)])])]):_vm._e(),(_vm.menu === 'rect' || _vm.menu === 'line')?_c('div',{staticClass:"menuBg"},[_c('div',{staticClass:"d-flex pa-4"},[_c('div',[_c('div',[_c('p',{staticClass:"white--text"},[_vm._v(" Farbauswahl ")])]),_c('div',[_c('v-color-picker',{staticClass:"ma-2 menuBg",attrs:{"id":"cPicker","swatches":_vm.swatches,"hide-mode-switch":"","hide-canvas":"","hide-inputs":"","show-swatches":""},model:{value:(_vm.selectedColor),callback:function ($$v) {_vm.selectedColor=$$v},expression:"selectedColor"}})],1),(_vm.menu === 'rect')?_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"white--text"},[_vm._v("Mit Rahmen")]),_c('v-checkbox',{staticClass:"ma-0",attrs:{"hide-details":"","dark":""},model:{value:(_vm.rectangleBorderState),callback:function ($$v) {_vm.rectangleBorderState=$$v},expression:"rectangleBorderState"}})],1):_vm._e(),_c('div',{staticClass:"d-flex justify-space-between"},[_c('v-btn',{staticClass:"text-capitalize ml-1",attrs:{"color":"error"},on:{"click":_vm.back}},[_vm._v(" Zurück ")]),_c('v-btn',{staticClass:"text-capitalize",attrs:{"color":"success"},on:{"click":_vm.spawnForm}},[_vm._v(" Hinzufügen ")])],1)])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }