<template>
    <v-menu
        offset-y
        nudge-bottom="20"
        :close-on-content-click="false"
        :attach="'#previewChangeMenuToggle'"
    >
        <template v-slot:activator="{ on: onMenu }">
            <v-tooltip bottom>
                <template v-slot:activator="{ on: onTooltip }">
                    <div
                        id="previewChangeMenuToggle"
                        class="d-flex justify-center align-center pointer"
                        style="background-color: var(--v-hellgrau-base)"
                        :style="windowWidth < 544 ? 'height: 40px; padding-right: 2px; padding-left: 2px' : 'padding-right: 8px; padding-left: 8px'"
                        v-on="{ ...onMenu, ...onTooltip }"
                    >
                        <img
                            :src="currentPreviewIcon"
                            class="squareBtnIcon"
                            :style="windowWidth < 544 ? 'height: 30px' : ''"
                            alt="Vorschauwechsel"
                        >
                        <p
                            v-if="windowWidth > 400"
                        >
                            Vorschau
                        </p>
                        <p
                            v-else-if="windowWidth > 360"
                            style="font-size: small; margin-left: 0px"
                        >
                            Vorschau
                        </p>
                    </div>
                </template>
                <span>Vorschauwechsel</span>
            </v-tooltip>
        </template>

        <div class="d-flex justify-center align-center pa-2 menuBg">
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn
                        x-small
                        class="mr-3 toolSelector"
                        :disabled="currentPreview === 'default'"
                        v-on="on"
                        @click="$emit('change','default')"
                    >
                        <img
                            alt="Vorschau Standardansicht"
                            :src="defaultDesktopIcon"
                            style="max-width: 28px; max-height: 35px"
                        >
                    </v-btn>
                </template>
                <span>Standardansicht</span>
            </v-tooltip>

            <!-- Divider -->
            <div
                class="d-inline-flex mr-3"
                style="min-height: 45px !important; border-left: 3px solid white; border-radius: 1px"
            />

            <!-- iPad-sized preview -->
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn
                        x-small
                        class="mr-3 toolSelector"
                        :disabled="currentPreview === 'ipad landscape'"
                        v-on="on"
                        @click="$emit('change', 'ipad landscape')"
                    >
                        <img
                            alt="Vorschau iPad Horizontalansicht"
                            :src="tabletLandscapeIcon"
                            style="max-width: 28px; max-height: 35px"
                        >
                    </v-btn>
                </template>
                <span>iPad horizontal</span>
            </v-tooltip>
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn
                        x-small
                        class="mr-3 toolSelector"
                        :disabled="currentPreview === 'ipad portrait'"
                        v-on="on"
                        @click="$emit('change', 'ipad portrait')"
                    >
                        <img
                            alt="Vorschau iPad Vertikalansicht"
                            :src="tabletPortraitIcon"
                            style="max-width: 28px; max-height: 35px"
                        >
                    </v-btn>
                </template>
                <span>iPad vertikal</span>
            </v-tooltip>

            <!-- Smartphone-sized preview -->
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn
                        x-small
                        class="mr-3 toolSelector"
                        :disabled="currentPreview === 'smartphone landscape'"
                        v-on="on"
                        @click="$emit('change','smartphone landscape')"
                    >
                        <img
                            alt="Vorschau Smartphone Horizontalansicht"
                            :src="smartphoneLandscapeIcon"
                            style="max-width: 28px; max-height: 35px"
                        >
                    </v-btn>
                </template>
                <span>Smartphone horizontal</span>
            </v-tooltip>
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn
                        x-small
                        class="toolSelector"
                        :disabled="currentPreview === 'smartphone portrait'"
                        v-on="on"
                        @click="$emit('change','smartphone portrait')"
                    >
                        <img
                            alt="Vorschau Smartphone Vertikalansicht"
                            :src="smartphonePortraitIcon"
                            style="max-width: 28px; max-height: 35px"
                        >
                    </v-btn>
                </template>
                <span>Smartphone vertikal</span>
            </v-tooltip>
        </div>
        <div class="d-flex menuBg pl-2 pb-2">
            <v-switch
                v-model="previewPupilUI"
                style="margin-top: 0px !important;"
                label="Schüler Ansicht"
                hide-details
            />
        </div>
    </v-menu>
</template>

<script>
import textIcon from "@/assets/Icons/Textfunktion-67.svg";
import defaultDesktopIcon from '@/assets/Icons/default-desktop.svg';
import smartphoneLandscapeIcon from '@/assets/Icons/smartphone-landscape.svg';
import smartphonePortraitIcon from '@/assets/Icons/smartphone-portrait.svg';
import tabletLandscapeIcon from '@/assets/Icons/tablet-landscape.svg';
import tabletPortraitIcon from '@/assets/Icons/tablet-portrait.svg';

export default {
    name: 'EditorPreviewModeSelector',
    props: {
        currentPreview: { required: true, type: String },
        windowWidth: { required: true, type: Number },
    },
    data: () => ({
        previewPupilUI: false,
        defaultDesktopIcon,
        smartphoneLandscapeIcon,
        smartphonePortraitIcon,
        tabletLandscapeIcon,
        tabletPortraitIcon,
    }),
    watch: {
        previewPupilUI() {
            this.$emit("previewPupilUIChanged", this.previewPupilUI);
        }
    },
    computed: {
        currentPreviewIcon() {
            switch (this.currentPreview) {
                case 'ipad landscape' : return this.tabletLandscapeIcon;
                case 'ipad portrait' : return this.tabletPortraitIcon;
                case 'smartphone landscape' : return this.smartphoneLandscapeIcon;
                case 'smartphone portrait' : return this.smartphonePortraitIcon;
                default: return this.defaultDesktopIcon;
            }
        }
    }
}
</script>

<style scoped lang="scss">
#previewChangeMenuToggle {
    text-transform: inherit;
    height: 49px;
    border-radius: 8px;
}

.squareBtn {
    width: 49px;
    height: 49px;
    border-radius: 8px;
}

.squareBtnIcon {
    width: 40px;
    height: 40px;
}

.toolSelector {
    background-color: #f8f8f880;
    width: 49px !important;
    height: 49px !important;

    @media (max-width: 430px) {
        width: 40px !important;
        height: 40px !important;

        img {
            width: 30px !important;
            height: 30px !important;
        }
    }
}

.toolSelector:disabled {
    background-color: #f8f8f840 !important;
    filter: brightness(200%);
}

.menuBg {
    background-color: rgba(100, 100, 100, 0.8);
}
</style>
