<template>
    <v-dialog
        v-model="localOpen"
        style="border-radius: 15px"
        @click:outside="closeDialog"
    >
        <v-card
            style="z-index: 15; overflow-x: hidden"
            class="ma-0"
        >
            <div
                style="border-radius: 15px; width: 100%; box-shadow: 1px 5px 5px silver; background: white;"
            >
                <div
                    style="background-color: #6995CD; border-radius: 15px 15px 0 0; padding-top: 10px; padding-bottom: 10px; color: white;"
                >
                    <v-row
                        class="mx-4"
                        style="display: flex; align-items: center;"
                    >
                        <v-col
                            cols="4"
                            class="pa-0 text-left"
                            style="font-size: x-large; display: flex; align-content: center"
                        >
                            <img
                                :src="stundenplanIcon"
                                alt="Stundenplan"
                                style="height: 35px;"
                                class="iconToWhite"
                            >
                            <p class="pl-2 mb-0">
                                Stundenplan
                            </p>
                        </v-col>

                        <v-col
                            cols="3"
                            class="pa-0"
                        >
                            <v-btn
                                class="mr-4 text-capitalize"
                                style="background-color: #f8f8f880; color: white"
                                @click="jumpToCurrentDay()"
                            >
                                Heute
                            </v-btn>
                            <v-badge
                                color="red"
                                :content="pastWeek"
                                :value="pastWeek"
                                overlap
                                left
                            >
                                <v-btn
                                    class="mx-2"
                                    style="background-color: #f8f8f880"
                                    elevation="2"
                                    icon
                                    small
                                    @click="subWeek"
                                >
                                    <img
                                        :src="linksIcon"
                                        alt="Pfeil nach links"
                                        style="height: 20px;"
                                        class="iconToWhite"
                                    >
                                </v-btn>
                            </v-badge>

                            <v-badge
                                color="red"
                                :content="futureWeek"
                                :value="futureWeek"
                                overlap
                            >
                                <v-btn
                                    class="mx-2"
                                    style="background-color: #f8f8f880"
                                    elevation="2"
                                    icon
                                    small
                                    @click="addWeek"
                                >
                                    <img
                                        :src="rechtsIcon"
                                        alt="Pfeil nach rechts"
                                        style="height: 20px;"
                                        class="iconToWhite"
                                    >
                                </v-btn>
                            </v-badge>
                        </v-col>

                        <v-col
                            cols="2"
                            class="px-0 text-right"
                        >
                            {{ getDisplayMonth() + ' ' + getMonday(date).getFullYear() }}
                        </v-col>

                        <v-col
                            cols="2"
                            class="px-0 text-right"
                            style="font-size: medium"
                        >
                            KW {{ getMonday(date).getWeek() }}
                        </v-col>

                        <v-col
                            cols="1"
                            class="px-0 text-right"
                        >
                            <v-btn
                                x-small
                                elevation="2"
                                style="background-color: #f8f8f880; width: 48px; height: 48px"
                                @click="closeDialog"
                            >
                                <img
                                    :src="schliessenIcon"
                                    alt="Schließensymbol"
                                    style="height: 40px;"
                                    class="iconToWhite"
                                >
                            </v-btn>
                        </v-col>
                    </v-row>
                </div>

                <v-row style="width: 99%; margin: 1em auto 0 auto;">
                    <th style="width: 10%">
                        <img
                            :src="uhrIcon"
                            alt="Uhrsymbol"
                            width="24"
                            height="24"
                        >
                    </th>

                    <v-row style="width: 1%" />

                    <v-row
                        style="width: 17%"
                        class="centerHeader"
                    >
                        <th
                            v-if="isDisplayDateTodayShort(getDisplayDateMonday())"
                            class="colHeaderCurrent text-center"
                            style="color: #6995CD; width: 100%"
                        >
                            Mo., {{ (getMonday(date).getDate()) + '.' + (getMonday(date).getMonth() + 1) + '.' + getMonday(date).getFullYear().toString().slice(-2) }}
                        </th>
                        <th
                            v-else
                            class="colHeader text-center"
                            style="width: 100%"
                        >
                            Mo., {{ (getMonday(date).getDate()) + '.' + (getMonday(date).getMonth() + 1) + '.' + getMonday(date).getFullYear().toString().slice(-2) }}
                        </th>
                    </v-row>

                    <v-row style="width: 1%" />

                    <v-row
                        style="width: 17%"
                        class="greyDayHeader centerHeader"
                    >
                        <th
                            v-if="isDisplayDateTodayShort(getDisplayDateTuesday())"
                            class="colHeaderCurrent text-center"
                            style="color: #6995CD; width: 100%"
                        >
                            Di., {{ (getTuesday(date).getDate()) + '.' + (getTuesday(date).getMonth() + 1) + '.' + getTuesday(date).getFullYear().toString().slice(-2) }}
                        </th>
                        <th
                            v-else
                            class="colHeader text-center"
                            style="width: 100%"
                        >
                            Di., {{ (getTuesday(date).getDate()) + '.' + (getTuesday(date).getMonth() + 1) + '.' + getTuesday(date).getFullYear().toString().slice(-2) }}
                        </th>
                    </v-row>

                    <v-row style="width: 1%" />

                    <v-row
                        style="width: 17%"
                        class="centerHeader"
                    >
                        <th
                            v-if="isDisplayDateTodayShort(getDisplayDateWednesday())"
                            class="colHeaderCurrent text-center"
                            style="color: #6995CD; width: 100%"
                        >
                            Mi., {{ (getWednesday(date).getDate()) + '.' + (getWednesday(date).getMonth() + 1) + '.' + getWednesday(date).getFullYear().toString().slice(-2) }}
                        </th>
                        <th
                            v-else
                            class="colHeader text-center"
                            style="width: 100%"
                        >
                            Mi., {{ (getWednesday(date).getDate()) + '.' + (getWednesday(date).getMonth() + 1) + '.' + getWednesday(date).getFullYear().toString().slice(-2) }}
                        </th>
                    </v-row>

                    <v-row style="width: 1%" />

                    <v-row
                        style="width: 17%"
                        class="greyDayHeader centerHeader"
                    >
                        <th
                            v-if="isDisplayDateTodayShort(getDisplayDateThursday())"
                            class="colHeaderCurrent text-center"
                            style="color: #6995CD; width: 100%"
                        >
                            Do., {{ (getThursday(date).getDate()) + '.' + (getThursday(date).getMonth() + 1) + '.' + getThursday(date).getFullYear().toString().slice(-2) }}
                        </th>
                        <th
                            v-else
                            class="colHeader text-center"
                            style="width: 100%"
                        >
                            Do., {{ (getThursday(date).getDate()) + '.' + (getThursday(date).getMonth() + 1) + '.' + getThursday(date).getFullYear().toString().slice(-2) }}
                        </th>
                    </v-row>

                    <v-row style="width: 1%" />

                    <v-row
                        style="width: 17%"
                        class="centerHeader"
                    >
                        <th
                            v-if="isDisplayDateTodayShort(getDisplayDateFriday())"
                            class="colHeaderCurrent text-center"
                            style="color: #6995CD; width: 100%"
                        >
                            Fr., {{ (getFriday(date).getDate()) + '.' + (getFriday(date).getMonth() + 1) + '.' + getFriday(date).getFullYear().toString().slice(-2) }}
                        </th>
                        <th
                            v-else
                            class="colHeader text-center"
                            style="width: 100%"
                        >
                            Fr., {{ (getFriday(date).getDate()) + '.' + (getFriday(date).getMonth() + 1) + '.' + getFriday(date).getFullYear().toString().slice(-2) }}
                        </th>
                    </v-row>
                </v-row>

                <vue-custom-scrollbar
                    class="scroll-area"
                    :settings="settings"
                >
                    <v-row
                        v-for="(item,index) in slots"
                        :key="index"
                        style="width: 99%; margin: 0 auto 0 auto;"
                    >
                        <td
                            style="width: 10%; color: var(--v-dunkelgrau-base); font-weight: normal;"
                            class="d-flex align-center justify-center pa-2"
                        >
                            {{ ("0" + new Date(item.start).getHours()).slice(-2) + ":" + ("0" + new Date(item.start).getMinutes()).slice(-2) }}
                            <br>
                            {{ ("0" + new Date(item.end).getHours()).slice(-2) + ":" + ("0" + new Date(item.end).getMinutes()).slice(-2) }}
                        </td>

                        <v-row style="width: 1%" />

                        <v-row
                            style="width: 17%"
                            class="centerFach"
                        >
                            <div
                                v-if="getAppointmentsPerDay(item._id, 'mon').length"
                                style="width: 100%"
                            >
                                <div
                                    v-for="el in getAppointmentsPerDay(item._id, 'mon')"
                                    :key="el._id + 'mon'"
                                >
                                    <Fach
                                        :is-running="isTimeslotCurrentlyRunning(item, 'mon')"
                                        :appointment="el"
                                        :request-appointments="requestAppointments"
                                        @pass-on-bbb-tut="receivedEmit"
                                        @update-me="requestAppointments"
                                    />
                                </div>
                            </div>
                            <div
                                v-else
                                style="width: 100%"
                            >
                                <Fach
                                    :is-running="isTimeslotCurrentlyRunning(item, 'mon')"
                                    :request-appointments="requestAppointments"
                                    @pass-on-bbb-tut="receivedEmit"
                                    @update-me="requestAppointments"
                                />
                            </div>
                        </v-row>

                        <v-row style="width: 1%" />

                        <v-row
                            style="width: 17%"
                            class="centerFach greyDayBody"
                            :style="index != Object.keys(slots).length - 1 ? '' : 'border-radius: 0 0 10px 10px'"
                        >
                            <div
                                v-if="getAppointmentsPerDay(item._id, 'die').length"
                                style="width: 100%"
                            >
                                <div
                                    v-for="el in getAppointmentsPerDay(item._id, 'die')"
                                    :key="el._id + 'tue'"
                                >
                                    <Fach
                                        :is-running="isTimeslotCurrentlyRunning(item, 'die')"
                                        :appointment="el"
                                        :request-appointments="requestAppointments"
                                        @pass-on-bbb-tut="receivedEmit"
                                        @update-me="requestAppointments"
                                    />
                                </div>
                            </div>
                            <div
                                v-else
                                style="width: 100%"
                            >
                                <Fach
                                    :is-running="isTimeslotCurrentlyRunning(item, 'die')"
                                    :request-appointments="requestAppointments"
                                    @pass-on-bbb-tut="receivedEmit"
                                    @update-me="requestAppointments"
                                />
                            </div>
                        </v-row>

                        <v-row style="width: 1%" />

                        <v-row
                            style="width: 17%"
                            class="centerFach"
                        >
                            <div
                                v-if="getAppointmentsPerDay(item._id, 'mit').length"
                                style="width: 100%"
                            >
                                <div
                                    v-for="el in getAppointmentsPerDay(item._id, 'mit')"
                                    :key="el._id + 'wed'"
                                >
                                    <Fach
                                        :is-running="isTimeslotCurrentlyRunning(item, 'mit')"
                                        :appointment="el"
                                        :request-appointments="requestAppointments"
                                        @pass-on-bbb-tut="receivedEmit"
                                        @update-me="requestAppointments"
                                    />
                                </div>
                            </div>
                            <div
                                v-else
                                style="width: 100%"
                            >
                                <Fach
                                    :is-running="isTimeslotCurrentlyRunning(item, 'mit')"
                                    :request-appointments="requestAppointments"
                                    @pass-on-bbb-tut="receivedEmit"
                                    @update-me="requestAppointments"
                                />
                            </div>
                        </v-row>

                        <v-row style="width: 1%" />

                        <v-row
                            style="width: 17%"
                            class="centerFach greyDayBody"
                            :style="index != Object.keys(slots).length - 1 ? '' : 'border-radius: 0 0 10px 10px'"
                        >
                            <div
                                v-if="getAppointmentsPerDay(item._id, 'don').length"
                                style="width: 100%"
                            >
                                <div
                                    v-for="el in getAppointmentsPerDay(item._id, 'don')"
                                    :key="el._id + 'thr'"
                                >
                                    <Fach
                                        :is-running="isTimeslotCurrentlyRunning(item, 'don')"
                                        :appointment="el"
                                        :request-appointments="requestAppointments"
                                        @pass-on-bbb-tut="receivedEmit"
                                        @update-me="requestAppointments"
                                    />
                                </div>
                            </div>
                            <div
                                v-else
                                style="width: 100%"
                            >
                                <Fach
                                    :is-running="isTimeslotCurrentlyRunning(item, 'don')"
                                    :request-appointments="requestAppointments"
                                    @pass-on-bbb-tut="receivedEmit"
                                    @update-me="requestAppointments"
                                />
                            </div>
                        </v-row>

                        <v-row style="width: 1%" />

                        <v-row
                            style="width: 17%"
                            class="centerFach"
                        >
                            <div
                                v-if="getAppointmentsPerDay(item._id, 'fre').length"
                                style="width: 100%"
                            >
                                <div
                                    v-for="el in getAppointmentsPerDay(item._id, 'fre')"
                                    :key="el._id + 'fri'"
                                >
                                    <Fach
                                        :is-running="isTimeslotCurrentlyRunning(item, 'fre')"
                                        :appointment="el"
                                        :request-appointments="requestAppointments"
                                        @pass-on-bbb-tut="receivedEmit"
                                        @update-me="requestAppointments"
                                    />
                                </div>
                            </div>
                            <div
                                v-else
                                style="width: 100%"
                            >
                                <Fach
                                    :is-running="isTimeslotCurrentlyRunning(item, 'fre')"
                                    :request-appointments="requestAppointments"
                                    @pass-on-bbb-tut="receivedEmit"
                                    @update-me="requestAppointments"
                                />
                            </div>
                        </v-row>
                    </v-row>
                </vue-custom-scrollbar>
            </div>
        </v-card>
    </v-dialog>
</template>
<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";

import Fach from "../Lehrer/FachLehrer";
import vueCustomScrollbar from "vue-custom-scrollbar";
import linksIcon from '../../assets/Icons/links-filled-10.svg'
import rechtsIcon from '../../assets/Icons/rechts-filled-11.svg'
import schliessenIcon from '../../assets/Icons/abbrechen-08.svg'
import stundenplanIcon from '../../assets/Icons/timetable.svg'
import uhrIcon from '../../assets/Icons/uhr-04.svg'
import { getWeekDayDate, getDayDateLocaleString, getDisplayMonthLong } from "@/util/timetable";

export default {
    name: "WeeklyViewDialog",
    components: {
        Fach,
        vueCustomScrollbar
    },
    props: {
        slotsProp: { required: true, type: Array },
        appointmentsProp: { required: true, type: Array }
    },
    data: () => ({
        settings: {
            suppressScrollY: false,
            suppressScrollX: true,
            wheelPropagation: false,
            //left out keyboard
            handlers: ['click-rail', 'drag-thumb', 'wheel', 'touch']
        },
        date: new Date(),
        today: new Date(),
        pastWeek: 0,
        futureWeek: 0,
        slots: [],
        appointments: [],
        localOpen: false,

        // Icons
        stundenplanIcon,
        linksIcon,
        rechtsIcon,
        schliessenIcon,
        uhrIcon,
    }),
    mounted() {
        this.localOpen = true;
        this.requestAppointments();
    },
    methods: {
        ...mapActions("appointments", ["getAppointments"]),
        ...mapActions("timeslots", ["getSlots"]),

        closeDialog() {
            this.localOpen = false;
            this.$emit('closeDialog');
        },
        receivedEmit(callback) {
            this.$emit('start-bbb-tut', callback);
        },
        async requestAppointments() {
            try {
                this.slots = this.slotsProp || await this.getSlots();
                this.appointments = this.appointmentsProp || await this.getAppointments();
                await this.checkParams();
                // this.countBadges();
                this.countBadgesWeek();
            } catch (e) {
                console.warn(e);
            }
        },
        async checkParams() {
            let appointment = this.appointments.find((el) => { return this.$route.query.appointment === el._id })
            if (appointment) {
                this.date = new Date(appointment.day)
                /*appointment['open'] = true*/
            }
        },
        countBadges() {
            this.future = 0;
            this.past = 0;
            if (!Array.isArray(this.appointments)) return;
            this.appointments.forEach((el) => {
                if (!this.sameDay(new Date(el.day), this.date)) {
                    if (new Date(el.day) > this.date) {
                        this.future += el.teacherUploads.filter((element) => !element.seenByMe).length;
                    } else {
                        this.past += el.teacherUploads.filter((element) => !element.seenByMe).length;
                    }
                }
            })
        },

        countBadgesWeek() {
            this.futureWeek = 0;
            this.pastWeek = 0;
            if (!Array.isArray(this.appointments)) return;
            this.appointments.forEach((el) => {
                if (!(new Date(el.day).getWeek() === this.date.getWeek())) {
                    if (new Date(el.day) > this.date) {
                        this.futureWeek += el.teacherUploads.filter((element) => !element.seenByMe).length;
                    } else {
                        this.pastWeek += el.teacherUploads.filter((element) => !element.seenByMe).length;
                    }
                }
            })
        },
        addWeek() {
            this.date = new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate() + 7);
            this.countBadges();
            this.countBadgesWeek();
        },
        subWeek() {
            this.date = new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate() - 7);
            this.countBadges();
            this.countBadgesWeek();
        },
        jumpToCurrentDay() {
            this.date = this.today;
        },
        //to display month string in header
        getDisplayMonth() {
            // TODO: Move all the display month methods in the project to this util
            return getDisplayMonthLong(this.date);
        },
        getMonday(d) {
            // TODO:  Move all the date strings computed to this util format, and use only one method
            // So delete all getMonday, getTuesday... and move to getWeekDayDate(string, date)
            return getWeekDayDate('mon', d);
        },
        getTuesday(d) {
            return getWeekDayDate('tue', d);
        },
        getWednesday(d) {
            return getWeekDayDate('wed', d);
        },
        getThursday(d) {
            return getWeekDayDate('thu', d);
        },
        getFriday(d) {
            return getWeekDayDate('fri', d);
        },
        getDisplayDateMonday() {
            // TODO:  Move all the date strings computed to this util format, and use only one method
            return `Mo., ${getDayDateLocaleString(this.getMonday(this.date))}`
        },
        getDisplayDateTuesday() {
            return "Di., " + (this.getTuesday(this.date).getDate()) + '.' + (this.getTuesday(this.date).getMonth() + 1) + '.' + this.getTuesday(this.date).getFullYear().toString().slice(-2);
        },
        getDisplayDateWednesday() {
            return "Mi., " + (this.getWednesday(this.date).getDate()) + '.' + (this.getWednesday(this.date).getMonth() + 1) + '.' + this.getWednesday(this.date).getFullYear().toString().slice(-2);
        },
        getDisplayDateThursday() {
            return "Do., " + (this.getThursday(this.date).getDate()) + '.' + (this.getThursday(this.date).getMonth() + 1) + '.' + this.getThursday(this.date).getFullYear().toString().slice(-2);
        },
        getDisplayDateFriday() {
            return "Fr., " + (this.getFriday(this.date).getDate()) + '.' + (this.getFriday(this.date).getMonth() + 1) + '.' + this.getFriday(this.date).getFullYear().toString().slice(-2);
        },
        //WEEKLY VIEW Version | compare displayDate with today, return true if equal, to color current day
        isDisplayDateTodayShort(compareDate) {
            let heute, monat, tag;
            let jahr = (this.today).toString().slice(13, 15);
            let unshortenedDay = (this.today).toString().slice(8, 10);

            //if day shorter than 10, cut of first character
            if (unshortenedDay.slice(0, 1) === "0") {
                tag = unshortenedDay.slice(1, 2);
            }
            else {
                tag = unshortenedDay.slice(0, 2);
            }

            //from month string to number
            switch ((this.today).toString().slice(4, 7)) {
                case "Jan":
                    monat = "1"; break;
                case "Feb":
                    monat = "2"; break;
                case "Mar":
                    monat = "3"; break;
                case "Apr":
                    monat = "4"; break;
                case "May":
                    monat = "5"; break;
                case "Jun":
                    monat = "6"; break;
                case "Jul":
                    monat = "7"; break;
                case "Aug":
                    monat = "8"; break;
                case "Sep":
                    monat = "9"; break;
                case "Oct":
                    monat = "10"; break;
                case "Nov":
                    monat = "11"; break;
                case "Dec":
                    monat = "12"; break;
            }

            heute = tag + "." + monat + "." + jahr;

            return (compareDate.toString()).slice(5, compareDate.toString().length) === heute;
        },
        getAppointmentsPerDay(slot, day) {
            switch (day) {
                case 'mon':
                    day = this.getMonday(this.date);
                    break;
                case 'die':
                    day = this.getTuesday(this.date);
                    break;
                case 'mit':
                    day = this.getWednesday(this.date);
                    break;
                case 'don':
                    day = this.getThursday(this.date);
                    break;
                case 'fre':
                    day = this.getFriday(this.date);
                    break;
            }
            return this.appointments.filter((el) => {
                return (el.timeslot === slot && this.sameDay(new Date(el.day), day))
            })
        },
        sameDay(date, otherDate) {
            return date.toLocaleDateString() === otherDate.toLocaleDateString();
        },
        isTimeslotCurrentlyRunning(timeslot, day) {
            const currentTimeAndDay = this.today;
            const appointment = this.getAppointmentsPerDay(timeslot._id, day)[0];
            if (appointment) {
                const appointmentDate = new Date(appointment.day).toDateString();
                if (appointmentDate === currentTimeAndDay.toDateString()) {
                    // If we are in here that means the appointment is today
                    if (new Date(timeslot.start).toLocaleTimeString('de-DE') <= currentTimeAndDay.toLocaleTimeString('de-DE')
                        && new Date(timeslot.end).toLocaleTimeString('de-DE') > currentTimeAndDay.toLocaleTimeString('de-DE')) {
                        // Currenttime is between start and end of timeslot
                        return true;
                    }
                }
            }
            return false;
        },
    }
}
</script>

<style lang="scss" scoped>
table,
th,
td {
    border: none;
    border-collapse: collapse;
}

tr {
    height: 40px;
}

th {
    vertical-align: top;
}

table tr th:first-child,
table tr td:first-child {
    padding-top: 10px;
    width: 10px !important;
}

table tr th:last-child,
table tr td:last-child {
    width: 5px !important;
}

table tr th,
table tr td {
    width: 9em;
    border: none;
}

table,
tr,
td {
    border: none;
}

h1 {
    display: inherit;
    font-size: inherit;
    margin-top: inherit;
    margin-bottom: inherit;
    margin-left: inherit;
    margin-right: inherit;
    font-weight: inherit;
}

h2 {
    display: inherit;
    font-size: inherit;
    margin-top: inherit;
    margin-bottom: inherit;
    margin-left: inherit;
    margin-right: inherit;
    font-weight: inherit;
}

.fach {
    cursor: pointer;
}

.whiteBg {
    background-color: #ffffff;
}

.iconToWhite {
    filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%)
        hue-rotate(36deg) brightness(104%) contrast(105%);
}

.iconToBlue {
    filter: invert(61%) sepia(43%) saturate(515%) hue-rotate(175deg)
        brightness(87%) contrast(82%);
}

.scroll-area {
    position: relative;
    margin: auto;
    max-height: calc(65vh - 30px);
}

.widgetContainer {
    height: auto;
    width: 100%;
    min-height: 80vh;
    border-radius: 15px;
    box-shadow: 1px 5px 5px silver;
    margin: auto auto 5em;
}

.planWidgetDisplayDate {
    display: inline-block;
    font-weight: normal;
    font-size: large;
}

.planWidgetTodayButtonActive {
    background-color: #6995cd !important;
    color: white;
    border-radius: 8px;
}

.planWidgetTodayButton {
    background-color: var(--v-hellgrau-base) !important;
    color: var(--v-dunkelgrau-base);
    border-radius: 8px;
    box-shadow: 1px 5px 5px silver;
}

.colHeader {
    color: var(--v-dunkelgrau-base);
    width: 18%;
}

.colHeaderCurrent {
    color: #6995cd;
    width: 18%;
}

.greyDayHeader {
    background-color: var(--v-hellgrau-base);
    border-radius: 10px 10px 0 0;
}

.greyDayBody {
    background-color: var(--v-hellgrau-base);
}

.centerFach {
    padding-left: 1%;
}

.centerHeader {
    display: flex;
    align-content: center;
}

@media only screen and (min-width: 901px) and (max-width: 1050px) {
    .calWeek {
        display: none;
    }
}

@media only screen and (max-width: 959px) {
    .clockIcon {
        display: none;
    }
}

@media only screen and (max-width: 900px) {
    .clockIcon {
        display: initial;
    }

    .widgetContainer {
        width: 90%;
    }
}
</style>
