var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"fileRow mx-2 d-flex align-center"},[_c('v-tooltip',{attrs:{"disabled":!_vm.showTooltips,"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-avatar',_vm._g({staticClass:"ml-2 smallBabyViewActionBtn fachLogo",style:(("background-color: " + (_vm.item.fachColor) + " !important"))},on),[_c('img',{staticClass:"smallBabyViewActionBtnImg",class:_vm.index === 0 ? 'firstItemOderSo' : '',attrs:{"id":'toDoIcon-' + _vm.index,"tabindex":"0","src":_vm.getSrc(_vm.item.fachIcon),"alt":_vm.item.groupName}})])]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.item.groupName))])]),_c('div',{staticClass:"fachLine ma-0 pa-0",style:(("background-color: " + (_vm.item.fachColor) + " !important"))}),_c('p',{staticClass:"fileTitle mx-2 my-0",staticStyle:{"width":"fit-content"},attrs:{"id":'toDoItem-' + _vm.index,"tabindex":"0"},on:{"focus":function($event){return _vm.readExerciseName(_vm.item)}}},[_vm._v(" "+_vm._s(_vm.item.title)+" ")]),(_vm.category === 'toDo')?_c('div',{staticClass:"my-0 mr-2",staticStyle:{"display":"flex","margin-left":"auto"}},[(!_vm.item.pipReference)?_c('v-tooltip',{attrs:{"disabled":!_vm.showTooltips,"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"babyViewActionBtn ml-3",attrs:{"small":""},on:{"click":function($event){return _vm.clickDownloadTeacherUpload(_vm.item)}}},on),[_c('img',{staticClass:"babyViewActionBtnImg",attrs:{"src":_vm.runterladenIcon,"alt":"Herunterladen"}})])]}}],null,false,111418186)},[_c('div',{staticStyle:{"display":"flex","flex-direction":"column","text-align":"center"}},[_c('span',[_vm._v("Herunterladen")]),(_vm.hoeren)?_c('img',{staticStyle:{"margin":"auto"},attrs:{"src":_vm.herunterladenMetacom,"width":"100","alt":""}}):_vm._e()])]):(_vm.item.pipReference)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btnStyle ml-1 babyViewActionBtn",attrs:{"tabindex":"1","elevation":"0","x-small":""}},'v-btn',attrs,false),on),[_c('img',{staticClass:"babyViewActionBtnImg",attrs:{"src":_vm.arrowDown,"alt":_vm.downloadMenuOpen ? 'Menü einklappen' : 'Menü ausklappen'}})])]}}],null,false,580474114)},[_c('div',{staticClass:"small-drop-down-menu"},[_c('v-btn',{staticClass:"d-flex justify-start",staticStyle:{"text-transform":"inherit","color":"var(--v-dunkelgrau-base)"},attrs:{"tabindex":"1","text":""},on:{"click":function () { return _vm.clickDownloadTeacherUpload(_vm.item); }}},[_c('img',{staticClass:"mr-1 babyViewActionBtnImg",attrs:{"src":_vm.runterladenIcon,"alt":"Herunterladen"}}),_vm._v(" Hauptvideo herunterladen ")]),_c('v-btn',{staticClass:"d-flex justify-start",staticStyle:{"text-transform":"inherit","color":"var(--v-dunkelgrau-base)"},attrs:{"tabindex":"1","text":""},on:{"click":function () { return _vm.clickDownloadTeacherUpload(_vm.babyFilesToDo.find(function (value) { return value._id === _vm.item.pipReference; })); }}},[_c('img',{staticClass:"mr-1 babyViewActionBtnImg",attrs:{"src":_vm.runterladenIcon,"alt":"Herunterladen"}}),_vm._v(" DGS-Video herunterladen ")])],1)]):_vm._e(),_c('v-tooltip',{attrs:{"disabled":!_vm.showTooltips,"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"babyViewActionBtn ml-3",attrs:{"small":""},on:{"click":_vm.pupilUploadFile}},on),[_c('img',{staticClass:"babyViewActionBtnImg",attrs:{"src":_vm.kameraIcon,"alt":"Hochladen"}})])]}}],null,false,3054481387)},[_c('div',{staticStyle:{"display":"flex","flex-direction":"column","text-align":"center"}},[_c('span',[_vm._v("Hochladen")]),(_vm.hoeren)?_c('img',{staticStyle:{"margin":"auto"},attrs:{"src":_vm.hochladenMetacom,"width":"100","alt":""}}):_vm._e()])]),_c('v-tooltip',{attrs:{"disabled":!_vm.showTooltips,"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"babyViewActionBtn move ml-3",attrs:{"dark":"","x-small":""},on:{"click":function($event){return _vm.$emit('moveWithoutAnswer')}}},on),[_c('img',{staticClass:"babyViewActionBtnImg editImg",attrs:{"src":_vm.tick1Icon,"alt":"Ohne Abgabe abgeben"}})])]}}],null,false,34776932)},[_c('span',[_vm._v("Ohne Abgabe abgeben")])]),(['mp4', 'wmv', 'flv', 'webm', 'mpg', 'm4v', 'mov', 'mkv'].includes(_vm.item.title.split('.').pop()))?_c('v-tooltip',{attrs:{"disabled":!_vm.showTooltips,"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-badge',{staticStyle:{"z-index":"8","width":"100%","height":"48px !important"},attrs:{"color":"red","content":"1","value":!_vm.item.seenByMe,"overlap":"","left":"","offset-x":"25"}},[_c('v-btn',_vm._g({staticClass:"babyViewActionBtn edit ml-3",attrs:{"small":"","dark":"","loading":_vm.videoLoading},on:{"click":function($event){return _vm.play(_vm.item)}}},on),[_c('img',{staticClass:"babyViewActionBtnImg editImg",attrs:{"src":_vm.rechtsIcon,"alt":"Video abspielen"}})])],1)]}}],null,false,534218739)},[_c('span',[_vm._v("Video abspielen")])]):(['wav', 'mp3', 'wma', 'm4a' , 'acc', 'ogg'].includes(_vm.item.title.split('.').pop()))?_c('v-tooltip',{attrs:{"disabled":!_vm.showTooltips,"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-badge',{staticStyle:{"z-index":"8","width":"100%","height":"48px !important"},attrs:{"color":"red","content":"1","value":!_vm.item.seenByMe,"overlap":"","left":"","offset-x":"25"}},[_c('v-btn',_vm._g({staticClass:"babyViewActionBtn edit ml-3",attrs:{"small":"","dark":"","loading":_vm.videoLoading},on:{"click":function($event){return _vm.playAudio(_vm.item)}}},on),[_c('img',{staticClass:"babyViewActionBtnImg editImg",attrs:{"src":_vm.rechtsIcon,"alt":"Audio abspielen"}})])],1)]}}],null,false,1249902594)},[_c('span',[_vm._v("Audio abspielen")])]):(['pdf', 'png', 'jpg', 'jpeg', 'tiff', 'bmp'].includes(_vm.item.title.split('.').pop().toLowerCase())
                    && (_vm.item.isAssignment || _vm.item.isAssignment == null))?_c('v-tooltip',{attrs:{"disabled":!_vm.showTooltips,"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-badge',{staticStyle:{"z-index":"8","width":"100%","height":"48px !important"},attrs:{"color":"red","content":"1","value":!_vm.item.seenByMe,"overlap":"","left":"","offset-x":"25"}},[_c('v-btn',_vm._g({staticClass:"babyViewActionBtn edit ml-3",attrs:{"id":_vm.item._id + '-editButton',"small":""},on:{"click":function($event){return _vm.openEdit(_vm.item)}}},on),[_c('img',{staticClass:"babyViewActionBtnImg editImg",attrs:{"src":_vm.bearbeitenIcon,"alt":"im Editor bearbeiten"}})])],1)]}}],null,false,1659900936)},[_c('div',{staticStyle:{"display":"flex","flex-direction":"column","text-align":"center"}},[_c('span',[_vm._v("Bearbeiten")]),_c('div',{staticStyle:{"margin":"auto"}},[(_vm.hoeren)?_c('img',{staticClass:"mr-1",attrs:{"src":_vm.hausaufgabenMetacom,"width":"100","alt":""}}):_vm._e(),(_vm.hoeren)?_c('img',{attrs:{"src":_vm.toDoMetacom,"width":"100","alt":""}}):_vm._e()])])]):(['pdf', 'png', 'jpg', 'jpeg', 'tiff', 'bmp'].includes(_vm.item.title.split('.').pop().toLowerCase()))?_c('v-tooltip',{attrs:{"disabled":!_vm.showTooltips,"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-badge',{staticStyle:{"z-index":"8","width":"100%","height":"48px !important"},attrs:{"color":"red","content":"1","value":!_vm.item.seenByMe,"overlap":"","left":"","offset-x":"25"}},[_c('v-btn',_vm._g({staticClass:"babyViewActionBtn edit ml-3",attrs:{"small":""},on:{"click":_vm.handleOpenAnsichtClick}},on),[_c('img',{staticClass:"babyViewActionBtnImg editImg",attrs:{"src":_vm.previewIcon,"alt":"Ansehen"}})])],1)]}}],null,false,765928610)},[_c('span',[_vm._v("Ansehen")])]):_c('div',{staticStyle:{"display":"inherit"}},[_c('v-tooltip',{attrs:{"disabled":!_vm.showTooltips,"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"babyViewActionBtn edit ml-3",staticStyle:{"background-color":"#8CBD46"},attrs:{"dark":"","x-small":"","elevation":"0"},on:{"click":function($event){return _vm.fileNotSupported(_vm.item)}}},on),[_c('img',{staticClass:"babyViewActionBtnImg editImg",attrs:{"src":_vm.infoIcon,"alt":"Dateiformat nicht unterstützt"}})])]}}],null,false,2553023460)},[_c('span',[_vm._v("Dateiformat nicht unterstützt")])])],1)],1):(_vm.category === 'done')?_c('div',{staticClass:"my-0 mr-2",staticStyle:{"display":"flex","margin-left":"auto"}},[(['pdf', 'png', 'jpg', 'jpeg', 'tiff', 'bmp'].includes(_vm.item.title.split('.').pop().toLowerCase()))?_c('v-tooltip',{attrs:{"disabled":!_vm.showTooltips,"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"babyViewActionBtn ml-3",attrs:{"small":""},on:{"click":_vm.handleOpenAnsichtClick}},on),[_c('img',{staticStyle:{"width":"30px","color":"#939393"},attrs:{"src":_vm.previewIcon,"alt":"Vorschau"}})])]}}],null,false,1833214284)},[_c('span',[_vm._v("Vorschau")])]):_vm._e(),(_vm.wasMovedByMe)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"babyViewActionBtn ml-3",attrs:{"small":"","plain":"","text":""}},on),[_c('img',{staticClass:"babyViewActionBtnImg",attrs:{"src":_vm.infoIcon,"alt":"Das Material wurde ohne Abgabe abgegeben"}})])]}}],null,false,3084444933)},[_c('span',[_vm._v("Material wurde ohne Abgabe abgegeben")])]):(!_vm.wasMovedByMe)?_c('v-tooltip',{attrs:{"disabled":!_vm.showTooltips,"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-btn',_vm._b({staticClass:"babyViewActionBtn ml-3",attrs:{"small":""},on:{"click":function($event){return _vm.downloadCorrectFile(_vm.item)}}},'v-btn',on,false),[_c('img',{staticClass:"babyViewActionBtnImg",attrs:{"src":_vm.runterladenIcon,"alt":"Herunterladen"}})])]}}])},[_c('span',[_vm._v("Herunterladen")])]):_vm._e()],1):_c('div',{staticClass:"my-0 mr-2",staticStyle:{"display":"flex","margin-left":"auto"}},[(['pdf', 'png', 'jpg', 'jpeg', 'tiff', 'bmp'].includes(_vm.item.title.split('.').pop().toLowerCase()))?_c('v-tooltip',{attrs:{"disabled":!_vm.showTooltips,"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-badge',{staticStyle:{"z-index":"8","width":"100%","height":"48px !important"},attrs:{"color":"red","content":"1","value":!_vm.item.seenByMe,"overlap":"","left":"","offset-x":"25"}},[_c('v-btn',_vm._g({staticClass:"babyViewActionBtn ml-3",attrs:{"small":""},on:{"click":_vm.handleOpenAnsichtClick}},on),[_c('img',{staticStyle:{"width":"30px","color":"#939393"},attrs:{"src":_vm.previewIcon,"alt":"Vorschau"}})])],1)]}}],null,false,3744115014)},[_c('span',[_vm._v("Vorschau")])]):_c('v-tooltip',{attrs:{"disabled":!_vm.showTooltips,"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-badge',{staticStyle:{"z-index":"8","width":"100%","height":"48px !important"},attrs:{"color":"red","content":"1","value":!_vm.item.seenByMe,"overlap":"","left":"","offset-x":"25"}},[_c('v-btn',_vm._b({staticClass:"babyViewActionBtn ml-3",attrs:{"small":""},on:{"click":function($event){return _vm.downloadCorrectFile(_vm.item)}}},'v-btn',on,false),[_c('img',{staticClass:"babyViewActionBtnImg",attrs:{"src":_vm.runterladenIcon,"alt":"Herunterladen"}})])],1)]}}])},[_c('span',[_vm._v("Herunterladen")])])],1)],1),(_vm.previewData)?_c('div',{staticClass:"mx-2 mb-1 d-flex justify-center",staticStyle:{"max-height":"15vh","background-color":"gray","border-bottom-left-radius":"6px","border-bottom-right-radius":"6px"}},[(_vm.previewData.loading)?_c('v-progress-circular',{staticClass:"my-3",attrs:{"indeterminate":"","color":"#F49F31"}}):(['png', 'jpg', 'jpeg', 'bmp', 'mp4', 'wmv', 'flv', 'webm', 'mpg', 'm4v', 'mov', 'mkv'].includes(_vm.item.title.split('.').pop().toLowerCase()) && _vm.previewData.preview)?_c('img',{staticStyle:{"cursor":"pointer","max-width":"345px","max-height":"15vh"},attrs:{"src":_vm.previewData.preview,"alt":""},on:{"click":function () { return ['png', 'jpg', 'jpeg', 'bmp' ].includes(_vm.item.title.split('.').pop().toLowerCase()) ? _vm.openEdit(_vm.item) : _vm.play(_vm.item); }}}):(['pdf'].includes(_vm.item.title.split('.').pop().toLowerCase()))?_c('object',{staticStyle:{"cursor":"pointer","max-width":"345px","max-height":"15vh"},attrs:{"type":"application/pdf","data":_vm.previewData.preview,"aria-hidden":"true","tabindex":"-1"},on:{"click":function () { return _vm.openEdit(_vm.item); }}}):(_vm.previewData.fetched)?_c('div',{staticClass:"my-2 grey--text text--lighten-5 subtitle-1"},[_vm._v(" Es ist keine Vorschau verfügbar ")]):_vm._e()],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }