import { render, staticRenderFns } from "./PlanWidget.vue?vue&type=template&id=11442ac0&scoped=true&"
import script from "./PlanWidget.vue?vue&type=script&lang=js&"
export * from "./PlanWidget.vue?vue&type=script&lang=js&"
import style0 from "./PlanWidget.vue?vue&type=style&index=0&id=11442ac0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11442ac0",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBadge,VBtn,VCol,VDialog,VRow,VTooltip})
