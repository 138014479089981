<template>
    <div>
        <v-overlay
            v-if="brettOpen"
            opacity="0.80"
            class="overlay"
            @click.native="brettWidgetButtonClick"
        />
        <div id="container">
            <v-badge
                color="red"
                :content="entryCount"
                :value="newEntriesAvailable > 10 ? '10+': entryCount"
                overlap
                style="z-index: 9; width: 100%"
            >
                <v-tooltip
                    :value="shortcutTooltips"
                    :disabled="!showTooltips"
                    bottom
                >
                    <template v-slot:activator="{ on }">
                        <v-btn
                            id="BrettWidgetButton"
                            style="background-color: #FFFFFF; width: 50px; height: 50px; border-radius: 8px;"

                            x-small
                            elevation="0"
                            class="ml-4"
                            :class="{brettClicked: brettOpen }"
                            v-on="on"
                            @click="brettWidgetButtonClick"
                        >
                            <img
                                :src="brettIcon"
                                style="max-width: 25px; max-height: 25px;"
                                :class="{iconToWhite: brettOpen}"
                                :alt="brettOpen ? 'Brett schließen' : 'Brett öffnen'"
                            >
                        </v-btn>
                    </template>
                    <div style="display: flex; flex-direction: column; text-align: center">
                        <span>Brett (Alt + N)</span>
                        <img v-if="hoeren" :src="brettMetacom" width="100" style="margin: auto">
                    </div>
                </v-tooltip>
                <BrettWidget
                    v-show="brettOpen"
                    id="BrettWidget"
                    @updateBlackboardEntryCount="updateBlackboardEntryCount"
                />
            </v-badge>
        </div>
    </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import brettIcon from '@/assets/Icons/brett.svg';
import BrettWidget from "@/components/BrettWidget";

import brettMetacom from '../../assets/METACOM/Header/neuigkeit.png'

export default {
    name: 'BabyBlackboardDropDown',
    components: {
        BrettWidget,
    },
    props: {
        closeBrett: { required: false, type: Boolean, default: false },
    },
    data: () => ({
        brettOpen: false,
        entryCount: 0,
        newEntriesAvailable: false,

        sehen: false,
        hoeren: false,
        babyView: false,

        //Icons
        brettIcon,
        brettMetacom,
    }),
    computed: {
        ...mapState("tooltips", ["shortcutTooltips", "showTooltips"]),
    },
    watch: {
        closeBrett(newVal, oldVal) {
            if (!oldVal && newVal) {
                this.brettOpen = false;
            }
        }
    },
    mounted() {
        this.requestBabyViewAndAccessibilityMode();
        window.addEventListener("keyup", this.handleKeyUp);
    },
    methods: {
        ...mapActions("pupils", ["getMePupil"]),
        ...mapActions("tooltips", ["triggerShortcutTooltips"]),

        handleKeyUp(event) {
            if (event.altKey && event.key === 'n') {
                this.toggleBrettOpen();
                this.triggerShortcutTooltips();
            }
        },
        toggleBrettOpen() {
            this.brettOpen = !this.brettOpen;
        },
        updateBlackboardEntryCount(count) {
            this.entryCount = count;
            this.newEntriesAvailable = count > 0;
        },
        brettWidgetButtonClick() {
            if (!this.brettOpen) {
                this.$emit('closeOtherPopups', true);
            }
            this.brettOpen = !this.brettOpen;
        },
        async requestBabyViewAndAccessibilityMode() {
            let temp = await this.getMePupil();
            this.babyView = temp.babyView;
            if(temp.accessibility) {
                this.sehen = temp.accessibility.sehen;
                this.hoeren = temp.accessibility.hoeren;
            } else {
                this.sehen = false;
                this.hoeren = false;
            }
            //to check if they are babys
            if (this.babyView) {
                this.babyViewTut = true;
            }
        },
    }
}
</script>

<style scoped lang="scss">
.overlay {
    z-index: 8!important;
}
#container {
    position: relative;
}

#BrettWidget {
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 1;
    width: 400px;
    max-width: 95vw;
}

.animateIcon {
    overflow: hidden;
}

.animateIcon:after {
    animation: shine 60s ease-in-out infinite;
    animation-fill-mode: forwards;
    content: "";
    position: absolute;
    top: -110%;
    left: -210%;
    width: 200%;
    height: 200%;
    opacity: 0;
    transform: rotate(30deg);

    background: rgba(255, 255, 255, 0.0);
    background: linear-gradient(
            to right,
            rgba(112, 112, 112, 0.2) 0%,
            rgba(112, 112, 112, 0.2) 77%,
            rgba(112, 112, 112, 0.5) 92%,
            rgba(112, 112, 112, 0.0) 100%
    );
}

/* Hover state - trigger effect */

/* Active state */
.animateIcon:active:after {
    opacity: 0;
}

@keyframes shine{
    1% {
        opacity: 1;
        top: -30%;
        left: -30%;
        transition-property: left, top, opacity;
        transition-duration: 0.7s, 0.7s, 0.15s;
        transition-timing-function: ease;
    }
    5% {
        opacity: 0;
        top: -30%;
        left: -30%;
        transition-property: left, top, opacity;
    }
}

//filter generated with https://codepen.io/sosuke/pen/Pjoqqp
.iconToWhite {
    filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%) hue-rotate(36deg) brightness(104%) contrast(105%);
}

.brettClicked {
    background-color: var(--v-dunkelgrau-base) !important;
}
</style>
