<template>
    <div
        class="background"
        :style="backgroundCssProps"
    >
        <v-btn
            class="ml-4 animateIcon reloadButton"
            x-small
            elevation="0"
            @click="reloadPage()"
        >
            <img
                :src="aktualisierenIcon"
                style="max-width: 25px; max-height: 25px;"
            >
        </v-btn>
        <h1 class="subHeader">
            Meeting
        </h1>
        <h2 class="mainHeader">
            {{ roomName }}
        </h2>
        <!-- <v-col>
        <v-row> -->
        <v-text-field
            v-model="username"
            label="Name eingeben"
            outlined
            light
            hide-details
            class="font-weight-bold usernameInput mb-4"
            @keydown="onKeyDown"
        />
        <!-- </v-row>
</v-col> -->

        <v-btn
            class="joinRoomButton"
            elevation="0"
            :color="'gruen'"
            @click="clickJoinBbbRoom"
        >
            <img :src="videoWeissIcon">
            <p>Raum beitreten</p>
        </v-btn>
    </div>
</template>

<script>
import { mapMutations } from "vuex";
import backgroundPattern from '../assets/Bilder/Background_Pattern.png';
import videoWeissIcon from '../assets/Icons/kamera-video-weiss-72.svg';
import abbrechenIcon from "../assets/Icons/abbrechen-08.svg";
import aktualisierenIcon from '../assets/Icons/aktualisieren-107.svg'

import * as backendApi from '../api/backend';

export default {
    data: () => ({
        videoWeissIcon,
        aktualisierenIcon,

        backgroundCssProps: {
            backgroundColor: '#fff',
            backgroundPosition: '0px 0px',
            backgroundPositionX: "0px",
            backgroundPositionY: "0px",
            backgroundRepeat: "repeat !important",
            backgroundSize: "560px",
            backgroundClip: "border-box",
            backgroundImage: `url(${backgroundPattern})`,
        },

        username: "",
        externalBbbRoomId: "",
        token: "",
        roomName: '',
    }),
    async mounted() {
        this.externalBbbRoomId = this.$route.params.externalBbbRoomId || "";
        this.token = this.$route.query.token || "";

        try {
            const guestDataRes = await backendApi.getExternalBbbRoomGuestData(this.externalBbbRoomId, this.token);
            const { isOpen, joinAsGuestUrlm, name } = await guestDataRes.json();

            this.roomName = name;

            if (!isOpen) {
                this.showSnackbar({ message: "Raum nicht geöffnet. Bitte versuche es später nochmal.", color: "error" });
                return;
            }
        } catch (err) {
            this.showSnackbar({ message: "Ein unerwarteter Fehler ist aufgetreten", color: "error" });
        }
    },
    methods: {
        ...mapMutations('snackbar', ['showSnackbar']),
        onKeyDown(keyEvent) {
            if (keyEvent.key === 'Enter') {
                this.clickJoinBbbRoom();
            }
        },
        async clickJoinBbbRoom() {
            try {
                const res = await backendApi.getExternalBbbRoomJoinAsGuestUrl(this.externalBbbRoomId, this.token, this.username || "Gast");
                if (res.status === 409) {
                    this.showSnackbar({ message: "Raum derzeit nicht geöffnet. Bitte versuche es später noch einmal", color: "error" });
                }
                if (res.status === 404) {
                    this.showSnackbar({ message: "Raum nicht gefunden. Wurde er vielleicht inzwischen gelöscht?", color: "error" });
                }

                const { redirectUrl } = await res.json();
                if (redirectUrl) {
                    window.location.href = redirectUrl;
                }

            } catch (err) {
                this.showSnackbar({ message: "Ein unerwarteter Fehler ist aufgetreten", color: "error" });
            }
        },
        reloadPage() {
            window.location.reload();
        },
    }
}
</script>

<style lang="scss">
.background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.subHeader {
    font-size: 4rem;
    font-weight: 800;
    color: gray;
}

.mainHeader {
    font-size: 3rem;
    text-align: center;
    margin-bottom: 1.5rem;
}

.usernameInput {
    background-color: white;
    max-height: 4rem !important;
    width: 20rem;
    color: var(--v-dunkelgrau-base) !important;
}

.joinRoomButton {
    color: white;
    text-transform: inherit;
    height: 4rem !important;
    padding: 2rem 1.5rem !important;
    width: 20rem;

    img {
        height: 2rem;
        filter: brightness(1000%);
        margin-right: 1.5rem;
    }

    p {
        color: white;
        font-size: 1.5rem;
        font-weight: 400;
        line-height: 0;
        margin-bottom: 0;
    }
}

.reloadButton {
    background-color: var(--v-gruen-base) !important;
    border-radius: 8px;
    width: 4rem;
    height: 4rem !important;
    position: absolute;
    right: 2rem;
    top: 2rem;

    img {
        filter: brightness(1000%);
        width: 2rem;
        height: 2rem !important;
    }
}

.animateIcon {
    overflow: hidden;
}

.animateIcon:after {
    animation: shine 60s ease-in-out infinite;
    animation-fill-mode: forwards;
    content: "";
    position: absolute;
    top: -110%;
    left: -210%;
    width: 200%;
    height: 200%;
    opacity: 0;
    transform: rotate(30deg);

    background: rgba(255, 255, 255, 0);
    background: linear-gradient(
        to right,
        rgba(112, 112, 112, 0.2) 0%,
        rgba(112, 112, 112, 0.2) 77%,
        rgba(112, 112, 112, 0.5) 92%,
        rgba(112, 112, 112, 0) 100%
    );
}

/* Hover state - trigger effect */

/* Active state */
.animateIcon:active:after {
    opacity: 0;
}

@keyframes shine {
    1% {
        opacity: 1;
        top: -30%;
        left: -30%;
        transition-property: left, top, opacity;
        transition-duration: 0.7s, 0.7s, 0.15s;
        transition-timing-function: ease;
    }
    5% {
        opacity: 0;
        top: -30%;
        left: -30%;
        transition-property: left, top, opacity;
    }
}
</style>