const defaultState = {
    showDialogGDPR: false,
};

const mutations = {
    setShowDialogGDPR(state, bool) {
        state.showDialogGDPR = bool;
    },
};

const getters = {
    showDialogGDPR: state => state.showDialogGDPR,
}

export default {
    namespaced: true,
    state: defaultState,
    mutations,
    getters,
};
