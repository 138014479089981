import { fabric } from "fabric";
import defaultWorksheet from '@/assets/PDF/AB-Einzeilig.pdf';
import defaultWorksheetBiggerHeader from '@/assets/PDF/AB-Zweizeilig.pdf';

/**
 * Methods to create canvas data and editor elements and pass them to EditorBaseComponent.vue
 * Creations according to templates created by Hannes
 * Backgrounds are created as fabric Canvases, then converted to JSON and later added to canvasPages array
 * Elements are created as JSON and later added to elementsList
 */

const createCVTemplateData = () => {

};

const createLetterTemplateData = () => {

};

const createMotivationTemplateData = () => {

};

const createTextDocumentTemplateData = () => {
    const canvas = createDefaultFabricCanvas();
    const elements = [];

    // Background Rectangle (on hold until further notice)
    // const background = new fabric.Rect({
    //     width: canvas.getWidth()  * 0.8823,
    //     height: canvas.getHeight() * 0.8515,
    //     top: canvas.getHeight() * 0.0653,
    //     left: canvas.getWidth() * 0.0588,
    //     fill: 'rgb(225, 225, 225)'
    // });
    //
    // canvas.add(background);

    //Create texttask data for the text part
    const textTask = {
        messageType: 'texttaskcombined',
        x: 5.88,
        y: 6.53,
        page: 0,
        width: 0.8823,
        height: 0.8515,
    };
    elements.push(textTask);

    // Convert canvas content to JSON and pass it to basecomponent
    const canvasJSON = canvas.toJSON([
        'padding',
        'borderColor',
        'cornerColor',
        'cornerStyle',
        'selectionBackgroundColor',
        'backgroundColor'
    ]);

    return {
        elements,
        canvasJSON,
        width: canvas.getWidth(),
        height: canvas.getHeight()
    };
};

const createAboutMeTemplateData = () => {

};

const loadPDFTemplate = async (templateId) => {
    if (templateId === 'defaultWorksheet') {
        return await fetch(defaultWorksheet).then(r => r.arrayBuffer());
    }
    if (templateId === 'defaultWorksheetBiggerHeader') {
        return await fetch(defaultWorksheetBiggerHeader);
    }
};

/**
 * General purpose methods used by the stuff in here
 */
const createDefaultFabricCanvas = (width = null) => {
    const canvas = document.createElement('canvas');
    const fabricCanvas = new fabric.Canvas(canvas);

    const wantedWidth = (width > 0 ? width : window.innerWidth * 0.75);
    const wantedHeight = wantedWidth * Math.sqrt(2);

    fabricCanvas.backgroundColor = "#fff";
    fabricCanvas.setDimensions({ width: wantedWidth, height: wantedHeight });

    return fabricCanvas;
};

const createFooter = (canvas) => {

};

export default {
    createCVTemplateData,
    createLetterTemplateData,
    createMotivationTemplateData,
    createTextDocumentTemplateData,
    createAboutMeTemplateData,
    loadPDFTemplate
}
