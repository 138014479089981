<template>
    <v-menu
        v-model="menuOpen"
        offset-x
        left
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                icon
                depressed
                x-small
                v-bind="attrs"
                v-on="on"
                @click="(event) => { event.stopPropagation() }"
            >
                <img
                    :src="bearbeitenIcon"
                    alt="Bearbeiten der neuen Klasse"
                    width="15px"
                    height="15px"
                >
            </v-btn>
        </template>
        <div
            class="d-flex flex-column"
            style="overflow-y: scroll; max-height: 200px; background-color: var(--v-hellgrau-base)"
        >
            <v-btn
                v-for="(teacher, index) in teachers"
                :key="`${index}${teacher._id}`"
                text
                style="text-transform: inherit"
                :disabled="teacher._id === currentTeacher._id"
                @click="teacherSelected(teacher._id)"
            >
                {{ teacherShorthand(teacher) }}
            </v-btn>
        </div>
    </v-menu>
</template>

<script>
import bearbeitenIcon from'@/assets/Icons/bearbeiten-komplimentär-grau-88.svg'

export default {
    name: 'ChangeTeacherInNewGroup',
    props: {
        teachers: { required: true, type: Array },
        currentTeacher: { required: true, type: Object },
    },
    data: () => ({
        bearbeitenIcon,
        menuOpen: false,
    }),
    computed: {
        teacherShorthand() {
            return (lead) => {
                let prefix = '';
                if (lead.gender === 'w') {
                    prefix = 'Fr. ';
                } else if (lead.gender === 'm') {
                    prefix = 'Hr. ';
                }

                return prefix + lead.lastName;
            }
        }
    },
    methods: {
        teacherSelected(teacherId) {
            this.$emit('teacherChanged', teacherId);
        }
    }
}
</script>

<style scoped>

</style>
