var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"editorAudioMessageContainer",staticClass:"audioMessageOuter"},[_c('v-tooltip',{attrs:{"disabled":!_vm.noPermission,"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onTooltip = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"bigButton",attrs:{"fab":"","elevation":"2","order":_vm.mainButtonStatus,"show-blue-border":_vm.accountRole === 'pupil' && !_vm.playingToggles.start},on:{"click":_vm.mainButtonClick}},'v-btn',attrs,false),Object.assign({}, onTooltip, on)),[_c('img',{staticClass:"bigButtonIcon",attrs:{"src":_vm.mainButtonIcon,"order":_vm.mainButtonStatus,"alt":"Sprachnotiz des Lehrers"}})])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.recordingToggle ? 'Aufnahme stoppen' : _vm.playingToggles.start ? 'Wiedergabe stoppen' : _vm.pulledStartMessage ? 'Wiedergabe starten' : 'Aufnahme starten'))])]),_c('div',{ref:"additionalInfoContainer",staticClass:"additionalInfoContainer",attrs:{"order":_vm.mainButtonStatus}},[_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.mainButtonStatus !== 'idle'),expression:"mainButtonStatus !== 'idle'"}]},[_vm._v(" "+_vm._s(_vm.recordingTime || _vm.playTime || _vm.countdown || _vm.errorOnRecording)+" ")]),(_vm.recordingTime && _vm.recordingToggle)?_c('v-icon',{staticStyle:{"font-size":"10px","color":"red"}},[_vm._v(" fas fa-circle ")]):_vm._e()],1)]}}])},[_c('span',[_vm._v("Aufnahmeerlaubnis fehlt!")])]),(_vm.answerActivated || _vm.pulledAnswerMessage)?_c('div',{attrs:{"id":"pupilButtonContainer"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"bigButton",attrs:{"fab":"","elevation":"2","order":_vm.pupilButtonStatus,"show-blue-border":_vm.mode === 'teacher' && !_vm.playingToggles.answer},on:{"click":_vm.pupilButtonClick}},'v-btn',attrs,false),on),[_c('img',{staticClass:"bigButtonIcon",attrs:{"src":_vm.pupilButtonIcon,"order":_vm.pupilButtonStatus,"alt":"Sprachnotiz des Schülers"}})])]}}],null,false,2472172387)},[_c('span',[_vm._v(_vm._s(_vm.recordingTogglePupil ? 'Aufnahme stoppen' : _vm.playingToggles.answer ? 'Wiedergabe stoppen' : _vm.pulledAnswerMessage ? 'Wiedergabe starten' : 'Aufnahme starten'))])]),_c('div',{ref:"additionalInfoContainer",staticClass:"additionalInfoContainer",attrs:{"order":_vm.pupilButtonStatus}},[_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.pupilButtonStatus !== 'idle'),expression:"pupilButtonStatus !== 'idle'"}]},[_vm._v(" "+_vm._s(_vm.recordingTime || _vm.playTimeAnswer || _vm.countdown)+" ")]),(_vm.recordingTime && _vm.recordingTogglePupil)?_c('v-icon',{staticClass:"ml-2",staticStyle:{"font-size":"12px","color":"red"}},[_vm._v(" fas fa-circle ")]):_vm._e()],1)],1):_vm._e(),(_vm.pulledStartMessage && (_vm.accountRole === 'teacher' || _vm.isMine) && _vm.mode === 'creator' && !_vm.previewPupilUI)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onTooltip = ref.on;
return [(_vm.pulledStartMessage && (_vm.accountRole === 'teacher' || _vm.isMine))?_c('v-btn',_vm._g({staticClass:"sideButton editorOptionsFade",staticStyle:{"top":"55px"},attrs:{"x-small":""},on:{"click":_vm.resetTeacherRecording}},Object.assign({}, onTooltip)),[_c('img',{attrs:{"src":_vm.aktualisierenIcon,"alt":"sprachnotiz erneut aufnehmen"}})]):_vm._e()]}}],null,false,3228060781)},[_c('span',[_vm._v("Aufnahme wiederholen")])]):_vm._e(),(_vm.pulledStartMessage && !_vm.isMine && !_vm.pulledAnswerMessage)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onTooltip = ref.on;
return [(_vm.pulledStartMessage && !_vm.isMine)?_c('v-btn',_vm._g({staticClass:"sideButton editorOptionsFade",style:(("top: " + (_vm.accountRole === 'teacher' ? 55 : 0) + "px;")),attrs:{"x-small":""},on:{"click":function($event){_vm.answerActivated = true}}},Object.assign({}, onTooltip)),[_c('img',{attrs:{"src":_vm.mikrofonIcon,"alt":"Antwort aufnehmen"}})]):_vm._e()]}}],null,false,1067244212)},[_c('span',[_vm._v("Antwort aufnehmen")])]):_vm._e(),(_vm.pulledAnswerMessage && !_vm.isMine)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onTooltip = ref.on;
return [(_vm.pulledAnswerMessage && !_vm.isMine)?_c('v-btn',_vm._g({staticClass:"sideButton editorOptionsFade",style:(("top: " + (_vm.accountRole === 'teacher' ? 55 : 0) + "px;")),on:{"click":_vm.resetAnswerRecording}},Object.assign({}, onTooltip)),[_c('img',{attrs:{"src":_vm.aktualisierenIcon,"alt":"sprachnotiz erneut aufnehmen"}})]):_vm._e()]}}],null,false,54389714)},[_c('span',[_vm._v("Notiz wiederholen")])]):_vm._e(),_c('audio',{ref:"startMessageAudio",attrs:{"src":_vm.pulledStartMessage,"controls":"","hidden":""}},[_vm._v(" Your browser does not support the audio tag. ")]),_c('audio',{ref:"answerMessageAudio",attrs:{"src":_vm.pulledAnswerMessage,"controls":"","hidden":""}},[_vm._v(" Your browser does not support the audio tag. ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }