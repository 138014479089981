<template>
    <div
        ref="outer"
        style="overflow: hidden; position: relative; width: 100%"
    >
        <div
            v-if="this.$slots.default.length && needScrolling"
            style="position: relative;height: 100%; min-height: 100%; width: 100%"
        >
            <v-btn
                class="pageButton"
                :disabled="y === 0"
                elevation="0"
                tile
                color="#737373"
                style="transform: rotate(90deg)"
                @click="up"
            >
                <img
                    :src="linksIcon"
                    :height="30"
                    class="iconToWhite"
                >
            </v-btn>
            <div
                id="outerScroll"
                ref="outerScroll"
                style="overflow: hidden; position: relative; width: 100%"
            >
                <div
                    id="inner"
                    ref="inner"
                    :style="'top:' + y + 'px; width: 100%'"
                >
                    <slot />
                </div>
            </div>
            <v-btn
                :disabled="$refs.outerScroll && $refs.inner && $refs.outerScroll.clientHeight && $refs.inner.clientHeight && y === $refs.outerScroll.clientHeight - $refs.inner.clientHeight"
                class="pageButton"
                elevation="0"
                tile
                color="#737373"
                style="transform: rotate(270deg)"
                @click="down"
            >
                <img
                    :src="linksIcon"
                    :height="30"
                    class="iconToWhite"
                >
            </v-btn>
        </div>
        <div v-else>
            <div ref="inner">
                <slot />
            </div>
        </div>
    </div>
</template>

<script>
import linksIcon from "../assets/Icons/links-10.svg";

export default {
    name: "AntiScroll",
    components: {
    },
    props: {
        startBottom: {type: Boolean, required: false, default: false},
    },
    data() {
        return {
                linksIcon,
                y: 0,
                speed: 30,
                needScrolling: false,
        };
    },
    computed: {

    },
    watch: {
    },
    async mounted() {
            this.$watch(
                    () => {
                            return this.$refs.inner.clientHeight
                    },
                    (val) => {
                            if(val > this.$refs.outer.clientHeight) {
                                    this.needScrolling = true;
                            } else {
                                    this.needScrolling = false;
                            }
                    }
            )
            if(this.$refs.inner.clientHeight > this.$refs.outer.clientHeight) {
                    this.needScrolling = true;
            } else {
                    this.needScrolling = false;
            }
            setTimeout(() => {
                    this.y = this.$refs.outerScroll.clientHeight - this.$refs.inner.clientHeight;
            }, 500);
            setInterval(() => {
                    if(this.$refs.outer && this.$refs.inner && this.$refs.outer.clientHeight && this.$refs.inner.clientHeight && this.$refs.inner.clientHeight > this.$refs.outer.clientHeight) {
                            this.needScrolling = true;
                    } else {
                            this.needScrolling = false;
                    }
            },100)
    },

    methods: {
            down() {
                if(this.$refs.outerScroll.clientHeight < this.$refs.inner.clientHeight + this.y) {
                        this.y -= this.speed;
                        if(this.$refs.outerScroll.clientHeight > this.$refs.inner.clientHeight + this.y) {
                                this.y = this.$refs.outerScroll.clientHeight - this.$refs.inner.clientHeight;
                        }
                }
            },
            // vue/no-unused-properties cannot detect methods by $ref, so make a exception
            // eslint-disable-next-line vue/no-unused-properties
            scrollBottom() {
            this.y = this.$refs.outerScroll.clientHeight - this.$refs.inner.clientHeight;
            },
            // vue/no-unused-properties cannot detect methods by $ref, so make a exception
            // eslint-disable-next-line vue/no-unused-properties
            scrollTop() {
                    this.y = 0;
            },
            up() {
                    if(this.y < 0) {
                            this.y += this.speed;
                            if(this.y > 0) {
                                    this.y = 0;
                                    this.$emit('y-reached-top')
                            }
                    }

            },

    }
};
</script>

<style lang="scss" scoped>
        .pageButton {
                margin: auto;
                background-color: #cacaca !important;
                border-color: #cacaca !important;
                width: 40px !important;
                height: 40px !important;
                border-radius: 6px;
                min-width: 0px !important;
                display: block;
        }

        #inner {
                position: absolute;
        }

        #outerScroll {
                height: calc(100% - 80px);
        }

        .iconToWhite {
                filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%)
                hue-rotate(36deg) brightness(104%) contrast(105%);
        }
</style>
