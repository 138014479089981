import translation from '../store/modules/translation'

const isSupported = () => {
    return 'speechSynthesis' in window;
};

const textToSpeech = async (text, language = 'de', onStartCallback = null, onEndCallback = null) => {
    if (isSupported && text) {
        if (window.speechSynthesis.speaking) {
            window.speechSynthesis.cancel();
            if (onEndCallback) {
                onEndCallback();
            }
        } else {
            const msg = new SpeechSynthesisUtterance();
            msg.text = text;

            if (language !== 'de') {
                // Not the best call in the world, but it works
                msg.text = await translation.actions.translateToTargetLang(
                    {
                        commit: null,
                        dispatch: null
                    },
                    {
                        targetLang: language,
                        textToTranslate: text
                });
            }

            msg.lang = language;
            msg.onstart = () => {
                if (onStartCallback)
                    onStartCallback(msg.text);
            };
            msg.onend = () => {
                if (onEndCallback)
                    onEndCallback();
            };
            window.speechSynthesis.speak(msg);
        }
        return true;
    }
    return false;
};

export {
    textToSpeech,
    isSupported,
}
