<template>
    <div style="border-radius: 5px !important; background-color: #F8F8F8">
        <v-row
            no-gutters
            :style="{ backgroundColor: '#707070', height: '65px' }"
            class="mb-1 pa-1"
            align="center"
        >
            <v-col :cols="5">
                <div
                    class="d-flex"
                    style="align-items:center"
                >
                    <!-- subject indicator -->
                    <!-- For Screenreader -->
                    <span class="visually-hidden">{{ 'Editor Arbeitsblatt ' + assignmentName }}</span>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <div
                                id="subjectFileNameButton"
                                class="squareBtn d-flex justify-center align-center pointer mr-2 ml-2"
                                :style="windowWidth < 544 ? 'height: 40px; '+getSubjectBgColor : getSubjectBgColor"
                                v-on="on"
                            >
                                <img
                                    :src="getSubjectIcon"
                                    class="squareBtnIcon"
                                    :style="windowWidth < 544 ? 'height: 30px' : ''"
                                    alt=""
                                >
                            </div>
                        </template>
                        <span><p>{{ assignmentName }}</p></span>
                    </v-tooltip>
                    <!--                Keep for the future when name changing comes
                    <v-menu
                        offset-y
                        nudge-bottom="20"
                        :close-on-content-click="false"
                        :attach="'#subjectFileNameButton'"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <div v-on="on" v-bind="attrs"
                                 class="squareBtn d-flex justify-center align-center pointer"
                                 id="subjectFileNameButton"
                                 :style="getSubjectBgColor()">
                                <img :src="getSubjectIcon()" class="squareBtnIcon" alt="">
                            </div>
                        </template>

                        <div class="d-flex justify-center align-center nameVMenu menuBg">
                            <p class="ma-4">{{ assignmentName }}</p>
                        </div>
                    </v-menu>-->

                    <EditorPreviewModeSelector
                        v-if="mode === 'creator' || mode === 'teacher'"
                        :current-preview="currentPreview"
                        :window-width="windowWidth"
                        @change="changeCurrentPreviewMode"
                        @previewPupilUIChanged="updatePreviewUIProp"
                    />
                </div>
            </v-col>

            <v-col
                id="editorStaticElementBarColumn"
                :cols="2"
                class="d-flex justify-center"
            >
                <EditorStaticElementBar
                    :mode="mode"
                    @opened="elementMenuOpened"
                    @addImageField="addImageField"
                    @addVideoField="(url, blob) =>
                        addElement('videoObject',
                                   { x: 50, y: 25, fileUrl: url, fileBlob: blob, text: blob.name, width: 300 / canvasWidth, height: 200 / canvasHeight })"
                    @addAudioField="(url, blob) =>
                        addElement('audioObject',
                                   { x: 50, y: 25, fileUrl: url, fileBlob: blob, text: blob.name })"
                    @addRectangle="(color, border) => addElement('rectangle',
                                                                 {
                                                                     x: lastFormSpecs.rectangle.x,
                                                                     y: lastFormSpecs.rectangle.y,
                                                                     width: lastFormSpecs.rectangle.width,
                                                                     height: lastFormSpecs.rectangle.height,
                                                                     text: color,
                                                                     startTextTask: border
                                                                 })"
                    @addLine="(color) => addElement('line', {
                        x: lastFormSpecs.line.x + 2,
                        y: lastFormSpecs.line.y + 2,
                        width: lastFormSpecs.line.width,
                        height: lastFormSpecs.line.height,
                        rotation: lastFormSpecs.line.rotation,
                        text: color,
                    })"
                />
            </v-col>

            <v-col
                :cols="5"
                style="display: flex; justify-content: flex-end; align-items: center"
            >
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <img
                            v-if="hoeren"
                            :src="dgs_hands"
                            alt="Hören Modus aktiv"
                            height="50"
                            class="mr-4"
                            v-on="on"
                        >
                    </template>
                    <span>Hören Modus Aktiv</span>
                </v-tooltip>

                <v-tooltip
                    v-if="displayOtherButtons"
                    bottom
                >
                    <template v-slot:activator="{ on }">
                        <v-btn
                            v-if="displayOtherButtons"
                            id="textLayerButton"
                            aria-label="Texte des Dokuments aktivieren"
                            x-small
                            elevation="0"
                            class="mr-4 toolSelector"
                            style="width: 50px; height: 50px; border-radius: 8px;"
                            :class="{ 'textLayerActive': isTextLayerVisible }"
                            v-on="on"
                            @click="changePDFTextLayerVisibility"
                        >
                            <!--                            <v-icon style="max-width: 28px; max-height: 35px">fas fa-magic</v-icon>-->
                            <img
                                :src="isTextLayerVisible ? lautsprecherAusIcon : lautsprecherIcon"
                                width="28px"
                                height="35px"
                                style="filter: brightness(100%)"
                            >
                        </v-btn>
                    </template>
                    <div style="display: flex; flex-direction: column; text-align: center">
                        <span>Texte des Dokuments aktivieren</span>
                    </div>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            v-if="targetLang !== 'de'"
                            x-small
                            elevation="0"
                            class="mr-4 toolSelector"
                            style="width: 50px; height: 50px; border-radius: 8px;"
                            :style="translate ? 'background-color: var(--v-gruen-base) !important' : ''"
                            @click="translate = !translate"
                            v-on="on"
                        >
                            <v-icon
                                style="max-width: 25px; max-height: 25px;"
                                :alt="translate ? 'Übersetzung deaktivieren' : 'Übersetzung aktivieren'"
                            >
                                mdi-translate
                            </v-icon>
                        </v-btn>
                    </template>
                    <div style="display: flex; flex-direction: column; text-align: center">
                        <span v-if="translate">Übersetzung deaktivieren</span>
                        <span v-else>Übersetzung aktivieren</span>
                        <img
                            v-if="hoeren"
                            :src="uebersetzenMetacom"
                            width="100"
                            style="margin: auto"
                        >
                    </div>
                </v-tooltip>

                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            v-if="showSteeringToggle"
                            style="width: 50px; height: 50px; border-radius: 8px;"
                            :style="magnifier ? 'background-color: #8CBD46 !important' : ''"
                            x-small
                            aria-hidden="true"
                            elevation="0"
                            class="mr-4"
                            v-on="on"
                            @click="toggleMagnifier"
                        >
                            <img
                                :src="lupenIcon"
                                style="max-width: 25px; max-height: 25px;"
                                :alt="magnifier ? 'Lupe ausschalten (Tastaturkürzel: Alt + L)' : 'Lupe einschalten (Tastaturkürzel: Alt + L)'"
                            >
                        </v-btn>
                    </template>
                    <div style="display: flex; flex-direction: column; text-align: center">
                        <span v-if="magnifier">Lupe ausschalten (Alt + L)</span>
                        <span v-else>Lupe einschalten (Alt + L)</span>
                        <img
                            v-if="hoeren"
                            :src="lupeMetacom"
                            width="100"
                            style="margin: auto"
                        >
                    </div>
                </v-tooltip>

                <v-tooltip
                    bottom
                >
                    <template v-slot:activator="{ on }">
                        <v-btn
                            v-if="displayOtherButtons && showSteeringToggle"
                            x-small
                            aria-hidden="true"
                            elevation="0"
                            class="mr-4"
                            style="width: 50px; height: 50px; border-radius: 8px;"
                            :style="keyboard ? 'background-color: #8CBD46 !important' : ''"
                            v-on="on"
                            @click="toggleKeyboard"
                        >
                            <img
                                :src="keyboardIcon"
                                :alt="keyboard ? 'Tastatursteuerung ausschalten (Tastaturkürzel: Alt + T)' : 'Tastatursteuerung einschalten (Tastaturkürzel: Alt + T)'"
                                height="25"
                            >
                        </v-btn>
                    </template>
                    <div style="display: flex; flex-direction: column; text-align: center">
                        <span v-if="keyboard">Tastatursteuerung ausschalten (Alt + T)</span>
                        <span v-else>Tastatursteuerung einschalten (Alt + T)</span>
                        <img
                            v-if="hoeren"
                            :src="tastaturTippenMetacom"
                            width="100"
                            style="margin: auto"
                        >
                    </div>
                </v-tooltip>

                <!-- undo button -->
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <div
                            style="display: inline-block"
                            v-on="on"
                        >
                            <v-btn
                                v-if="mode !== 'viewer'"
                                :disabled="!isUndoPossible"
                                x-small
                                class="toolSelector mr-2"
                                style="border-radius: 8px;"
                                aria-label="Rückgängig"
                                @click="undo"
                            >
                                <img
                                    aria-hidden="true"
                                    alt="Rückgängig"
                                    :src="undoIcon"
                                    style="max-width: 33px; max-height: 33px"
                                >
                            </v-btn>
                        </div>
                    </template>
                    <span v-if="isUndoPossible">Rückgängig</span>
                    <span v-else>Keine Historie vorhanden</span>
                </v-tooltip>

                <!-- Save Menu, to choose where to save this file as a teacher -->
                <div
                    v-if="mode !== 'viewer'"
                    id="container"
                    style="display: flex; flex-wrap: nowrap"
                >
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on: menu }">
                            <v-menu
                                v-model="saveMenu"
                                transition="scale-transition"
                                offset-y
                                max-width="200px"
                                nudge-bottom="20"
                                nudge-left="75"
                                origin="center top"
                                :attach="'#container'"
                                class="roundMenuBorders"
                                :close-on-content-click="false"
                            >
                                <template v-slot:activator="{ on: tooltip }">
                                    <v-btn
                                        v-if="mode !== 'viewer'"
                                        x-small
                                        color="gruen"
                                        class="toolSelector"
                                        elevation="3"
                                        style="border-radius: 8px; border-top-right-radius: 0px; border-bottom-right-radius: 0px; margin-right: 2px"
                                        v-on="{ ...tooltip, ...menu }"
                                    >
                                        <img
                                            alt="Speichermenü"
                                            :src="pfeilLinksIcon"
                                            style="
                                                    height: 30px;
                                                    filter: brightness(1000%);
                                                    transform: rotate(-90deg);
                                                    justify-self: flex-end;
                                                    "
                                        >
                                    </v-btn>
                                </template>
                                <CreatorSaveMenu
                                    :mode="mode"
                                    :is-next-pupil-upload-available="isNextPupilUploadForCorrectionAvailable"
                                    @onSave="handleNormalSave"
                                    @onSaveInPrivateFolder="handleSaveInPrivateFolder"
                                    @onSaveInSharedFolder="handleSaveInSharedFolder"
                                    @onExport="handleExportDocumentClick"
                                    @onLeave="creatorSaveMenuOnLeave"
                                    @onNextPupilUpload="handleLoadNextPupilUploadForCorrection"
                                />
                            </v-menu>

                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        class="mr-2 toolSelector"
                                        color="gruen"
                                        :min-width="windowWidth > 544 ? '90px' : '40px'"
                                        style="border-radius: 0 8px 8px 0;"
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="handleNormalSave"
                                    >
                                        <p
                                            class="text-capitalize"
                                            style="font-size: large; color: white"
                                            :style="windowWidth > 544 ? 'font-size: large; margin-left: 4px' : 'font-size: small'"
                                        >
                                            Fertig
                                        </p>
                                    </v-btn>
                                </template>
                                <!-- TODO: needs a case when opening editor through FileWidget, don't know how to check for that -->
                                <span>{{ mode === 'creator' ? 'Arbeitsblatt speichern und veröffentlichen' : mode === 'pupil' ? 'Arbeitsblatt abgeben' : 'Speichern und schließen' }}</span>
                            </v-tooltip>
                        </template>
                        <span aria-hidden="true">Speichern</span>
                    </v-tooltip>
                </div>

                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            v-if="mode === 'viewer'"
                            id="closeBtn"
                            v-bind="attrs"
                            x-small
                            class="toolSelector mr-4"
                            elevation="3"
                            @click="close"
                            v-on="on"
                        >
                            <img
                                alt="Schließen"
                                :src="closeIcon"
                                style="
                                      height: 40px;
                                      filter: brightness(1000%);
                                      justify-self: flex-end;"
                            >
                        </v-btn>
                    </template>
                    <span>Schließen</span>
                </v-tooltip>
            </v-col>
        </v-row>
        <vuescroll
            ref="scrollbar"
            :ops="ops"
            style="height: calc(100vh - 70px); background-color: var(--v-hellgrau-base)"
        >
            <!-- Editing row -->
            <v-row
                id="contentRow"
                class="ma-0"
                align="center"
                justify="center"
            >
                <!-- This needs a cleanup too -->
                <EditorSideBar
                    :mode="mode"
                    :preview-active="previewActive"
                    :element-to-edit="elementToEdit"
                    :is-toolbar-toggle-visible="mode !== 'viewer'"
                    :current-color="color"
                    :page-count="completePageCount"
                    :current-page-index="currentPageIndex"
                    :parent-toolbar-state="localToolbarState"
                    :current-zoom="(1 + currentZoom / 5)"
                    :window-width="windowWidth"
                    :simple-editor-for-teachers="simpleTeacherUIForEditor"
                    @opened="elementMenuOpened"
                    @zoomIn="() => changeZoom('plus')"
                    @zoomOut="() => changeZoom('minus')"
                    @addElement="addElement"
                    @removePairing="fullResetPairing()"
                    @elementEdited="elementEdited"
                    @changePage="changePage"
                    @toggleToolbar="toggleToolbar"
                />

                <EditorLibraryBar
                    :mode="mode"
                    :preview-active="previewActive"
                    :document-page-count="completePageCount"
                    :page-count="completePageCount"
                    :current-page-index="currentPageIndex"
                    :window-width="windowWidth"
                    :simple-editor-for-teachers="simpleTeacherUIForEditor"
                    @addAdditionalPage="addNewPage"
                    @deleteActivePage="deletePageClicked"
                    @duplicateActivePage="duplicateActivePage"
                    @addCustomPage="newCustomPageClicked"
                    @opened="elementMenuOpened"
                    @addAudioMessage="addElement('audio', { x: 90, y: 20 })"
                    @addTextMessage="addElement('text', { x: 90, y: 10 })"
                    @addImageField="addImageField"
                    @onAddDocFromLibrary="AddDocFromLibraryClick"
                    @changePage="changePage"
                />

                <EditorToolbar
                    :mode="mode"
                    :should-be-open="localToolbarState"
                    :is-text-layer-visible="isTextLayerVisible"
                    :change-color="changeColor"
                    :change-size="changeSize"
                    :change-stamp="changeStamp"
                    :spawn-text-on-canvas="spawnTextOnCanvas"
                    :set-tool="setTool"
                    :rotate-editor="rotateEditor"
                    :current-active-method="currentActiveMethod"
                    :current-size="currentSize"
                    :hoeren="hoeren"
                    :sehen="sehen"
                    :color="color"
                    style="z-index: 4"
                />

                <div
                    id="previewContainer"
                    :key="`${currentPreview}`"
                    style="position: relative"
                    :style="`
                        width: ${ currentPreview !== 'default' ? maxContainerWidthSetting+'px' : 'inherit' };
                        height: ${ currentPreview !== 'default' ? maxContainerHeightSetting+'px' : 'inherit' }
                        transform: scale(
                            ${(currentPreview !== 'default' ? maxContainerWidthSetting : windowWidth) / windowWidth});
                        transform-origin: ${currentPreview !== 'default' ? 'top' : 'inherit'};
                        border: ${ currentPreview !== 'default' ? '2px solid purple' : 'none' };
                        overflow-y: ${ currentPreview !== 'default' ? 'auto' : 'inherit' };
                    `"
                >
                    <v-progress-linear
                        v-if="completePageCount > 1"
                        id="documentProgessBar"
                        color="success"
                        :value="(currentPageIndex + 1 / completePageCount) * 100"
                        aria-hidden="true"
                    />
                    <div
                        id="capture"
                        class="mx-auto"
                        :style="`
                            position: relative;
                            width: ${ currentPreview !== 'default' ? maxWidthSetting+'px' : 'inherit' };
                        `"
                        @dragover="(event) => event.preventDefault()"
                        @dragenter="dragEnter"
                        @drop="dropInElement"
                    >
                        <EditingCanvas
                            :ref="`editingcanvas`"
                            :current-shown-page="currentShownPage"
                            :canvas-height="canvasHeight"
                            :canvas-width="canvasWidth"
                            :max-width="maxWidthSetting"
                            :loading-function="setLoading"
                            :tab-index="21 + tabOrderArray.length"
                            :page-index="currentPageIndex"
                            :is-text-layer-visible="isTextLayerVisible"
                            style="position: relative"
                            class="editingCanvas"
                            @placeNewEditorMessage="
                                (top, left) => addElement('audio', { x: left * 100, y: top * 100 })"
                            @placeNewEditorTextMessage="
                                (top, left) => addElement('text',
                                                          { text: tempTextMessage, x: left * 100, y: top * 100 })"
                            @dimensionsChanged="changeDimensions"
                            @updateZoom="(direction) => { changeZoom(direction); }"
                            @readParagraph="(text, callback) => { readText(text, callback) }"
                            @textDivsEmpty="() => { noTextOnTextLayerSnackbar = true }"
                            @canvasContentChanged="handleCanvasChangeInHistory(currentPageIndex)"
                            @setActivePage="changeActivePage(currentPageIndex)"
                            @addElement="addElement"
                        />

                        <EditorElement
                            v-for="(element, index) in elementsList"
                            v-show="element.page === currentPageIndex"
                            :key="'editorElement' + index + (element.fileUrl ? element.fileUrl : '') + element.messageType + element.identifier"
                            :ref="`editorElement${index}`"
                            :element="element"
                            :index="index"
                            :current-page="currentPageIndex"
                            :mode="mode"
                            :preview-pupil-u-i="previewPupilUI"
                            :canvas-width="canvasWidth"
                            :canvas-height="canvasHeight"
                            :export-mode="elementsExportMode"
                            :class="{ focusMe: focusedElement === ('editorElement' + index) }"
                            :style="`position: absolute; transform-origin: top left; -webkit-transform-origin: top left;
                            top: ${htmlObjectYAxis(element)}%; left: ${element.x * (1 + currentZoom / 5)}%;
                            transform: scale(${1 + currentZoom / 5}, ${1 + currentZoom / 5};
                            z-index: 3;`"
                            :target-lang="targetLang"
                            :translate="translate"
                            @moveLayerDown="() => moveEditorElementIndexByOffset(index, -1)"
                            @moveLayerUp="() => moveEditorElementIndexByOffset(index, 1)"
                            @focusMe="() => { focusedElement = ('editorElement' + index) }"
                            @removeElement="removeElement(element, index)"
                            @history="(originalData) => handleCustomHistoryEvent(element, originalData)"
                            @transformChanged="(data, additionalData) => { updateTransform(element, data, additionalData) }"
                            @editElement="callEditElement(element)"
                            @duplicateElementAltDrag="(position) => duplicateElementOnAltDrag(element, index, position)"
                            @elementDuplicated="(data, additionalData) => { elementDuplicated(element, data, additionalData) }"
                            @dropAllDragnDropFields="dropAllDragnDropFields"
                            @pairingState="(state) => { catchPairingState(state); }"
                        />
                    </div>
                </div>
            </v-row>
        </vuescroll>
        <div v-show="false">
            <canvas
                id="savingCanvas"
                ref="savingCanvas"
            />
        </div>

        <!-- Popup land -->
        <v-dialog
            v-model="localLoadingActive"
            hide-overlay
            persistent
            width="150"
        >
            <v-card :color="groupColor">
                <v-card-text class="pa-2">
                    <v-progress-circular
                        indeterminate
                        class="ma-auto"
                        style="display: block"
                    />
                    <br>
                    <p
                        class="ma-auto"
                        style="text-align: center"
                    >
                        {{ loadingMessage }}
                    </p>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="deletePageConfirmationPopup"
            max-width="400"
        >
            <v-card>
                <v-card-title :color="groupColor">
                    Seite löschen
                </v-card-title>
                <v-card-text class="d-flex flex-column align-center justify-center">
                    <div class="d-flex justify-space-between">
                        <p>
                            Wollen Sie die aktuelle Seite löschen?
                            Alles darauf Gemalte und darauf platzierte Elemente werden
                            ebenfalls entfernt.
                            <span style="font-weight: bold">
                                Diese Aktion kann nicht mehr rückgängig gemacht werden!
                            </span>
                        </p>
                        <v-btn
                            v-if="mode !== 'creator'"
                            aria-label="Vorlesen"
                            x-small
                            class="pa-0"
                            style="background-color: #f8f8f800; height: 45px; width: 45px"
                            @click="readText('Wollen Sie die aktuelle Seite löschen?\n'+
                                                 'Alles darauf Gemalte und darauf platzierte Elemente werden ebenfalls entfernt.\n'+
                                                 'Diese Aktion kann nicht mehr rückgängig gemacht werden!',
                                             (newIcon) => { speakerIcon = newIcon })"
                        >
                            <img
                                :src="speakerIcon"
                                style="height: 35px;"
                                alt="Vorlesen"
                            >
                        </v-btn>
                    </div>
                    <div class="d-flex mt-4 justify-space-between">
                        <v-btn
                            class="text-capitalize"
                            style="width: 48% !important;"
                            @click="deletePageConfirmationPopup = !deletePageConfirmationPopup"
                        >
                            Abbrechen
                        </v-btn>
                        <v-btn
                            color="rot"
                            dark
                            class="text-capitalize"
                            style="width: 48% !important;"
                            @click="deleteActivePage"
                        >
                            Seite löschen
                        </v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="leavePagePupilDialog"
            max-width="400"
        >
            <v-card>
                <v-card-title :color="groupColor">
                    Seite verlassen
                </v-card-title>
                <v-card-text class="d-flex flex-column align-center justify-center">
                    <div class="d-flex justify-space-between">
                        <p>
                            Willst du die Seite wirklich verlassen?
                            Gemalte oder platzierte Elemente werden nicht gespeichert.
                        </p>
                        <v-btn
                            v-if="mode !== 'creator'"
                            aria-label="Vorlesen"
                            x-small
                            class="pa-0"
                            style="background-color: #f8f8f800; height: 45px; width: 45px"
                            @click="readText('Willst du die Seite wirklich verlassen? Gemalte oder platzierte Elemente werden nicht gespeichert.',
                                             (newIcon) => { speakerIcon = newIcon })"
                        >
                            <img
                                :src="speakerIcon"
                                style="height: 35px;"
                                alt="Vorlesen"
                            >
                        </v-btn>
                    </div>
                    <div class="d-flex mt-4 justify-space-between">
                        <v-btn
                            class="text-capitalize"
                            style="width: 48% !important;"
                            @click="leavePagePupilDialog = false"
                        >
                            Abbrechen
                        </v-btn>
                        <v-btn
                            color="rot"
                            dark
                            class="text-capitalize"
                            style="width: 48% !important;"
                            @click="leavePagePupilDialog = false; close()"
                        >
                            Seite verlassen
                        </v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="addDocFromLibraryPopup"
            max-width="600"
        >
            <v-card>
                <v-card-title :color="groupColor">
                    Dokument ersetzen oder anfügen?
                </v-card-title>
                <v-card-text>
                    <p>
                        Sie möchten ein neues Dokument aus der Dokumentenbibliothek einfügen.
                        Möchten Sie dafür das aktuelle Dokument ersetzen? Dabei werden auch alle
                        Editor-Elemente und Zeichnungen entfernt. <br>
                        Oder möchten Sie das neue Dokument hinten anfügen? Dabei werden die aktuellen Seiten,
                        Editor-Elemente und Zeichnungen behalten, und das Dokument wird verlängert. <br>
                        <span style="font-weight: bold">Diese Aktion kann nicht mehr
                            rückgängig gemacht werden!</span>
                    </p>
                    <div class="d-flex mt-4 justify-space-between">
                        <v-btn
                            color="rot"
                            dark
                            class="text-capitalize"
                            style="width: 30% !important;"
                            @click="addDocFromLibraryPopup = false"
                        >
                            Abbrechen
                        </v-btn>
                        <v-btn
                            color="gelb"
                            dark
                            class="text-capitalize"
                            style="width: 30% !important;"
                            @click="replaceCurrentDocFromLibrary"
                        >
                            Ersetzen
                        </v-btn>
                        <v-btn
                            color="gruen"
                            dark
                            class="text-capitalize"
                            style="width: 30% !important;"
                            @click="appendDocFromLibrary"
                        >
                            Hinten anfügen
                        </v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>

        <EditorExport
            :show-dialog="showExportDialog"
            :doc-data="docDataForExport"
            :element-screenshots="elementScreenshots"
            :pages-metadata="pagesMetadata"
            :elements="elementsList"
            :appointment-id="appointmentId"
            :filename="assignmentName.split('.')[0]"
            @close="showExportDialog = false"
        />

        <EditorTemplateBrowser
            v-if="activateTemplateBrowser"
            :activator="activateTemplateBrowser"
            :mode="mode"
            @close="activateTemplateBrowser = false"
            @leave="close"
            @templateSelected="templateSelected"
            @documentRegistryDocumentSelected="openDocRegistryDocumentFromTemplateBrowser"
        />

        <!-- Input - Upload for extra Page Stuff -->
        <input
            ref="newPageUpload"
            type="file"
            style="display: none"
            hidden
            name="newPage"
            accept="image/jpeg,image/png,application/pdf"
            @change="addNewCustomPage"
        >

        <!-- Pairing Snackbars -->
        <div v-if="!pairingDone">
            <v-snackbar
                v-model="snackbarPairing"
                bottom
                color="success"
                timeout="-1"
            >
                <template v-slot:action="{ attrs }">
                    <v-btn
                        text
                        v-bind="attrs"
                        small
                        @click="()=>{ if(mode !== 'pupil') { setPairingEdit(false) } snackbarPairing = false;}"
                    >
                        <img
                            style="max-width: 20px; filter: brightness(1000%)"
                            alt="Schließen"
                            :src="closeIcon"
                        >
                    </v-btn>
                </template>
                <div class="d-flex align-center justify-center">
                    <img
                        style="max-width: 20px"
                        alt="Schließen"
                        :src="stiftIcon"
                    >
                    <span
                        v-if="mode === 'pupil'"
                        style="display: flex; text-align: center; padding-left: 16px"
                    >
                        Paare bilden! <br> <br>
                        Klicke auf die wackelnden Elemente und bilde die richtigen Paare. <br>
                    </span>
                    <span
                        v-else
                        style="display: flex; text-align: center; padding-left: 16px"
                    >
                        PAIRING MODUS <br> <br>
                        Klicken Sie auf Elemente, um diese zu Paaren zu verbinden. <br>
                        Beenden Sie den Modus, indem Sie dieses Fenster schließen.
                    </span>
                </div>
            </v-snackbar>
        </div>
        <div v-else-if="spawnedPairing">
            <v-snackbar
                v-model="snackbarPairingDone"
                bottom
                color="success"
                timeout="-1"
            >
                <template v-slot:action="{ attrs }">
                    <v-btn
                        text
                        v-bind="attrs"
                        small
                        @click="()=>{ snackbarPairingDone = false;}"
                    >
                        <img
                            style="max-width: 20px"
                            alt="Schließen"
                            :src="closeIcon"
                        >
                    </v-btn>
                </template>
                <span
                    v-if="pairingShowSubmission"
                    style="display: flex; text-align: center"
                >
                    Eingereichte Lösung der Paare
                </span>
                <span
                    v-else
                    style="display: flex; text-align: center"
                >
                    Korrekte Lösung der Paare
                </span>
            </v-snackbar>
        </div>
        <!-- Drag and Drop snackbars -->
        <v-snackbar
            v-model="dragndrop"
            bottom
            color="success"
            timeout="-1"
        >
            <template v-slot:action="{ attrs }">
                <v-btn
                    text
                    v-bind="attrs"
                    small
                    @click="stopDragnDrop"
                >
                    <img
                        style="max-width: 20px; filter: brightness(1000%)"
                        alt="Schließen"
                        :src="closeIcon"
                    >
                </v-btn>
            </template>
            <div class="d-flex align-center justify-center">
                <img
                    style="max-width: 20px"
                    alt="Schließen"
                    :src="stiftIcon"
                >
                <span style="display: flex; text-align: center; padding-left: 16px">
                    Drag n Drop MODUS <br> <br>
                    Klicken Sie auf eine freie Stelle um ein Drag n Drop Ziel zu platzieren. <br>
                    Beenden Sie den Modus, indem Sie neben ein Element klicken.
                </span>
            </div>
        </v-snackbar>
        <VideoTutorial
            v-if="videoTutorial"
            :video-tutorial="videoTutorial"
            @close="videoTutorialClosed = true"
        />
    </div>
</template>

<script>
import EditorToolbar from "./EditorToolbar";
import EditingCanvas from "./EditingCanvas";
import vuescroll from "vuescroll";
import html2canvas from "html2canvas";
import {PDFDocument} from 'pdf-lib';
import {fabric} from "fabric";
import pdfjs from '../../util/pdfjs';
import scrollbarStyling from "../../util/scrollbarStyling";
import stiftIcon from "../../assets/Icons/bearbeiten-komplimentär-weiß-88.svg";
import undoIcon from "../../assets/Icons/EditorRedesign/rueckgaengig.svg";
import closeIcon from "../../assets/Icons/abbrechen-08.svg";
import keyboardIcon from "../../assets/Icons/keyboard-24px.svg";
import lupenIcon from "../../assets/Icons/lupe-18.svg";
import lautsprecherIcon from "../../assets/Icons/lautsprecher-13.svg";
import lautsprecherAusIcon from "../../assets/Icons/lautsprecher-aus-89.svg";
import tastaturTippenMetacom from "../../assets/METACOM/Allgemein/tastaturtippen.png";
import lupeMetacom from "../../assets/METACOM/Allgemein/suchenlupe.png";
import uebersetzenMetacom from "../../assets/METACOM/Allgemein/uebersetzen.png";
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import * as backend from "../../api/backend";
import editorTemplates from "@/util/editorTemplateData";
import EditorSideBar from "../Editortools/Toolbar/EditorSideBar";
import EditorElement from "../Editortools/EditorElement";
import CreatorSaveMenu from "@/components/Editortools/Toolbar/Menus/CreatorSaveMenu";
import dgs_hands from "../../assets/Bilder/dgs_hands.jpg";
import pfeilLinksIcon from '@/assets/Icons/links-filled-10.svg';
import EditorExport from "@/components/Editor/EditorExport";
import EditorLibraryBar from "@/components/Editortools/Toolbar/EditorLibraryBar/EditorLibraryBar";

import bioIconWeiss from "../../assets/Icons/f-biologie-weiss-35.svg";
import chemieIconWeiss from "../../assets/Icons/f-chemie-weiss-37.svg";
import deutschIconWeiss from "../../assets/Icons/f-deutsch-weiss-29.svg";
import englischIconWeiss from "../../assets/Icons/f-englisch-weiss-30.svg";
import erdkundeIconWeiss from "../../assets/Icons/f-erdkunde-weiss-31.svg";
import geschichteIconWeiss from "../../assets/Icons/f-geschichte-weiss-40.svg";
import matheIconWeiss from "../../assets/Icons/f-mathe-weiss-32.svg";
import musikIconWeiss from "@/assets/Icons/f-musik-weiss-82.svg";
import physikIconWeiss from "../../assets/Icons/f-physik-weiss-36.svg";
import politikIconWeiss from "../../assets/Icons/f-politik-weiss-34.svg";
import religionIconWeiss from "../../assets/Icons/f-religion-weiss-39.svg";
import sportIconWeiss from "../../assets/Icons/f-sport-weiss-38.svg";
import wirtschaftIconWeiss from "../../assets/Icons/f-wirtschaft-weiss-33.svg";
import EditorStaticElementBar from "../Editortools/Toolbar/EditorStaticElementBar";
import EditorPreviewModeSelector from "@/components/Editor/EditorPreviewModeSelector";
import EditorTemplateBrowser from "@/components/Editor/EditorTemplateBrowser";
import VideoTutorial from "../../components/Tutorial/VideoTutorial.vue";
import {v4 as uuidv4} from "uuid";

export default {
    name: 'EditorBaseComponent',
    components: {
        EditorTemplateBrowser,
        EditorPreviewModeSelector,
        EditorLibraryBar,
        EditorStaticElementBar,
        EditorExport,
        CreatorSaveMenu,
        EditorElement,
        EditingCanvas,
        EditorToolbar,
        vuescroll,
        EditorSideBar,
        VideoTutorial,
    },
    props: {
        mode: { required: true, type: String },
        saveFunction: { required: true, type: Function },
        saveInPrivateFolderFunction: { required: false, type: Function },
        saveInSharedFolderFunction: { required: false, type: Function },

        loadingMessage: { required: false, type: String, default: 'Dokument wird geladen...' },
        loadingActive: { required: false, type: Boolean, default: false },

        // Array that contains events from parent, on which this component reacts
        events: { required: false, type: Array, default: () => [] },
    },
    data: () => ({
        currentActiveMethod: 'none',
        color: "#706f6f",
        canvasWidth: 300,
        canvasHeight: 600,
        maxWidthSetting: null,
        defaultCanvasWidth: 300,
        maxContainerWidthSetting: null,
        maxContainerHeightSetting: null,
        currentFile: null,
        currentSize: 2,
        groupName: 'Deutsch',
        groupColor: 'blue',
        assignmentName: 'Aufgabe 1',
        appointmentId: '',
        fileId: '',
        uploadId: '',
        setFileType: null,
        pages: [0],
        canvasPages: [{content: null}],
        emptyPageType: null,
        snackbarPairing: false,
        snackbarPairingDone: true,
        ops: {
            rail: {
                opacity: '0',
                background: undefined,
                border: 'none',
                size: '60px'
            },
            bar: {
                background: '#55555580',
                keepShow: true,
                size: '45px',
                minSize: 0.1
            },
            scrollButton: {
                enable: true,
                background: '#cecece'
            },
            scrollPanel: {
                easing: 'easeInQuad',
                speed: 800
            },
            vuescroll: {
                wheelScrollDuration: 0,
                wheelDirectionReverse: false,
                sizeStrategy: 'percent',
                detectResize: true
            }
        },
        currentZoom: 0,
        isHorizontalScrollbarStyled: false,
        isVerticalScrollbarStyled: false,

        stiftSizeMenu: false,

        currentPageIndex: 0,
        localToolbarState: false,
        deletePageConfirmationPopup: false,
        addDocFromLibraryPopup: false,

        scrollbarStyling,
        leavePagePupilDialog: false,

        // Dialog activators
        activateTemplateBrowser: false,

        // Editor Messages
        elementsList: [],
        elementsHistory: [],

        sehen: false,
        hoeren: false,
        babyView: false,
        saveMenu: false,

        translate: false,

        // Preview Stuff
        currentPreview: 'default',

        // Icons
        stiftIcon,
        undoIcon,
        closeIcon,
        keyboardIcon,
        lupenIcon,
        tastaturTippenMetacom,
        lupeMetacom,
        uebersetzenMetacom,
        lautsprecherIcon,
        lautsprecherAusIcon,
        dgs_hands,
        pfeilLinksIcon,


        // UI Changes
        speakerIcon: lautsprecherIcon,

        // PDF.JS Textlayer
        isTextLayerVisible: false,
        selectedText: null,
        textSelectionInterval: null,
        lastSelectedTextNode: null,
        textSelectionActionButtons: {
            show: false,
            top: null,
            left: null,
        },
        noTextOnTextLayerSnackbar: false,

        //Export Stuff
        showExportDialog: false,
        docDataForExport: null,
        elementScreenshots: [],
        pagesMetadata: [],
        elementsExportMode: false,

        focusedElement: null,
        pageAdded: false,
        pagesChanged: false,
        // Tools and Messages
        tempTextMessage: null,
        currentLang: 'de',
        // Last sizes of the forms
        lastFormSpecs: {
            'rectangle': {
                // Pos in percentage
                x: 50,
                y: 50,
                // Width/Height in portion of canvas
                width: 0.2,
                height: 0.075,
            },
            'line': {
                x: 50,
                y: 50,
                width: 0.3,
                height: 0.1,
                rotation: 0
            }
        },

        // Accessibility
        tabOrderArray: [],
        elementToEdit: null,
        originalEl: null,

        // Localstate of loadingActive because it is being mutated
        // and loadingActive is already in use
        localLoadingActive: false,

        libraryFileData: null,

        // manage pairing component
        elementsOfCurrentPageCounter : 0,
        elementsOfCurrentPagePairedState : [],

        // screenshot hacks
        readyForScreenshot : false,

        videoTutorialClosed: false,

        // Boolean for showing pupil UI on Preview
        previewPupilUI: false,
    }),
    computed: {
        ...mapGetters("auth",['accountRole', 'accountId']),
        ...mapGetters('teachers', ['simpleEditorForTeacher']),
        ...mapState("magnifier", ["magnifier"]),
        ...mapState("util", ['windowWidth', 'keyboard', 'lastOpenedInEditor', 'openAppointment']),
        ...mapState("editorDragnDrop", ["dragndrop"]),
        ...mapGetters('files', ['getCurrentFileUrl', 'getCurrentFile', 'getCurrentFileType', 'getWork']),
        ...mapGetters('appointments', ['appointment', 'uncorrectedPupilUploadsInAppointment']),
        ...mapGetters('translation', ['getTargetLang']),
        ...mapGetters('pairing', ['pairingComponentIndex', 'pairedElements', 'pupilElements', 'teacherElements', 'spawnedPairing', 'pairingEdit', 'pairingDone', 'pairingShowSubmission']),
        ...mapState('uploadMessage', ['newFileId']),
        ...mapGetters('videoTutorial', [ 'videoTutorialsByFilename' ]),
        isCurrentFileImage() {
            return this.currentFileType === 'png'
                || this.currentFileType === 'PNG'
                || this.currentFileType === 'jpg'
                || this.currentFileType === 'JPG'
                || this.currentFileType === 'jpeg';
        },
        showSteeringToggle() {
            return this.mode !== 'creator' && (
                (this.windowWidth > 900 && window.innerHeight > 1030)
                || (this.windowWidth > 1030 && window.innerHeight > 900)
            )
        },
        displayOtherButtons() {
            return this.windowWidth > 600;
        },
        currentShownPage() {
            return {
                background: this.pages[this.currentPageIndex],
                canvas: this.canvasPages[this.currentPageIndex],
                fileType: this.currentFileType
            };
        },
        currentFileType() {
            return this.setFileType || this.getCurrentFileType;
        },
        completePageCount() {
            return this.canvasPages.length > this.pages.length ? this.canvasPages.length : this.pages.length;
        },
        targetLang() {
            return this.getTargetLang;
        },
        isUndoPossible() {
            return this.elementsHistory.length > 0;
        },
        previewActive() {
            return this.currentPreview !== 'default';
        },
        getSubjectBgColor() {
            return 'background-color: ' + this.groupColor;
        },
        getSubjectIcon() {
            switch (this.groupName){
                case 'Biologie':
                    return bioIconWeiss;
                case 'Chemie':
                    return chemieIconWeiss;
                case 'Deutsch':
                    return deutschIconWeiss;
                case 'Englisch':
                    return englischIconWeiss;
                case 'Erdkunde':
                    return erdkundeIconWeiss;
                case 'Geschichte':
                    return geschichteIconWeiss;
                case 'Mathe':
                    return matheIconWeiss;
                case 'Musik':
                    return musikIconWeiss;
                case 'Physik':
                    return physikIconWeiss;
                case 'Politik':
                    return politikIconWeiss;
                case 'Religion':
                    return religionIconWeiss;
                case 'Sport':
                    return sportIconWeiss;
                case 'Wirtschaft':
                    return wirtschaftIconWeiss;
                default:
                    console.warn('No icon found for subject/group!');
                    return deutschIconWeiss;
            }
        },
        isElementReadyForScreenshot() {
            return (element, index) => {
                return element.page === index
                    && (element.messageType === 'cloze'
                    || element.messageType === 'wall'
                    || element.messageType === 'buchstabensuppe'
                    || element.messageType === 'calculation'
                    || element.messageType === 'dragndrop'
                    || element.messageType === 'dragndropfree'
                    || element.messageType === 'dragndropfreetarget'
                    || element.messageType === 'line'
                    || element.messageType === 'answerField'
                    || element.messageType === 'answerFieldPlus'
                    || element.messageType === 'texttaskcombined'
                    || element.messageType === 'multipleChoice');
            }
        },
        videoTutorial() {
            const tutorial = this.videoTutorialsByFilename['HA_erstellt_Autokorrektur.mp4'];
            if(this.mode === 'creator' // Only show to teacher creating a coument
                && tutorial?.seeAgain // Only when user wants to see again tut or first time
                && !this.videoTutorialClosed // Do not show when closed
                && !this.activateTemplateBrowser // Do not show when template browser active, only after/before
                && !!false // disable it temporarilty until dialog bug is fixed
                ) {
                return tutorial;
            }
            return false;
        },
        isNextPupilUploadForCorrectionAvailable() {
            if (this.appointmentId && this.uploadId) {
                return !!this.uncorrectedPupilUploadsInAppointment(this.appointmentId, this.uploadId);
            }
            return false;
        },
        simpleTeacherUIForEditor() {
            return this.mode === 'creator' && this.accountRole === 'teacher' && this.simpleEditorForTeacher;
        }
    },
    watch: {
        loadingActive(newVal) {
            this.localLoadingActive = newVal;
        },
        selectedText(newVal) {
            //TODO CHECK IF THIS IS STILL NEEDED
            if (newVal && this.lastSelectedTextNode) {
                const topPixels = this.lastSelectedTextNode.style.top.substring(0, this.lastSelectedTextNode.style.top.length - 2);
                // const leftPixels = this.lastSelectedTextNode.style.left.substring(0, this.lastSelectedTextNode.style.left.length-2);
                if (this.lastSelectedTextNode.parentElement && this.lastSelectedTextNode.parentElement.id) {
                    const lastMarkedPageIndex =
                        Number.parseInt(this.lastSelectedTextNode.parentElement.id.replace('textLayer', ''));
                    this.textSelectionActionButtons.top =
                        (((lastMarkedPageIndex * this.canvasHeight) + Number.parseFloat(topPixels))
                            / this.canvasHeight / this.pages.length) * 100 * (1 + this.currentZoom / 5);
                } else {
                    this.textSelectionActionButtons.top = (topPixels / this.canvasHeight) * 100 * (1 + this.currentZoom / 5);
                }
                this.textSelectionActionButtons.left = 80;
                this.textSelectionActionButtons.show = true;

            } else {
                this.textSelectionActionButtons.show = false;
            }
        },
        pairingEdit: {
            handler(val, oldVal) {
                if(val && val != oldVal) {
                    this.snackbarPairing = true;
                }
            }
        },
        pairingShowSubmission: {
            handler(val, oldVal) {
                this.snackbarPairingDone = true;
            }
        },
        currentPageIndex: {
            handler(val, oldVal) {
                this.handleWatchCurrentPageIndex();
            }
        },
        elementsOfCurrentPagePairedState: {
            handler(val, oldVal) {
                this.handleWatchElementsOfCurrentPagePairedState();
            }
        },
        newFileId: {
            async handler(val, oldVal) {
                if (val !== oldVal && this.readyForScreenshot) {
                    this.readyForScreenshot = false;
                    this.fileId = this.newFileId;
                    await this.createScreenshot();
                }
            }
        },
        events: {
            async handler(newVal) {
                if (newVal.length) {
                    const newCalling = newVal[0];
                    // Assuming newCalling is just string, with some callback
                    // (If so, we need to implement a type check)
                    if(this[newCalling]) {
                        this[newCalling]();
                    }
                }
            },
            deep: true
        },
    },
    async mounted() {

        let fileType = '';
        if (this.$route.query.group) {
            this.groupName = this.$route.query.group;
        }
        if (this.$route.query.color) {
            this.groupColor = this.$route.query.color;
        }
        if (this.$route.query.aId) {
            this.appointmentId = this.$route.query.aId;

            // Check for appointments in store and pull them if necessary
            const givenAppointment = this.appointment(this.appointmentId);
            if (!givenAppointment) {
                this.getAppointments();
            }
        }
        if (this.$route.query.fId) {
            this.fileId = this.$route.query.fId;
        }
        if (this.$route.query.title) {
            this.assignmentName = this.$route.query.title;
            const assignmentNameParts = this.assignmentName.split('.')
            fileType = assignmentNameParts[assignmentNameParts.length - 1];
        }
        if (this.$route.query.uploadid) {
            this.uploadId = this.$route.query.uploadid;
        }
        if (this.$route.query.pagetype) {
            this.emptyPageType = this.$route.query.pagetype;
        }

        let localUserGroup = 'pupil';
        if (this.$route.query.ug) {
            localUserGroup = this.$route.query.ug;
        }

        this.setLoading(true);
        if (this.emptyPageType) {
            this.activateTemplateBrowser = true;
            this.setLoading(false);
            this.setFileType = 'empty';
            this.canvasPages = [{ pageType: 'blank' }];
            this.noTextOnTextLayerSnackbar = true;
            // await this.$refs.editingcanvas.setEmptyBackground(this.emptyPageType, 892, 1262);
        } else {
            await this.requestFile(localUserGroup, fileType);
        }

        this.resetPairing();

        // Request messages
        try {
            const response = await this.getUploadMessages(this.fileId);
            if (Number.isInteger(response)) {
                // show error
            } else {
                this.elementsList = response && response.points ? response.points : [];
            }
        } catch (e) {
            console.warn(e);
        }

        this.checkForScrollbarStylingUpdates(document.getElementsByClassName('__vuescroll')[0]);

        const observer = new MutationObserver((mutationsList, observer) => {
            for (const mutation of mutationsList) {
                if (mutation.type === 'childList') {
                    this.checkForScrollbarStylingUpdates(document.getElementsByClassName('__vuescroll')[0]);
                }
            }
        });

        // Start observing the target node for configured mutations
        observer.observe(
            document.getElementsByClassName('__vuescroll')[0],
            { attributes: false, childList: true, subtree: false }
        );

        await this.requestAccessibilityMode();
        window.addEventListener("keydown", this.handleKeydown);

        // Check Toolbar for Mode
        if (this.mode === 'teacher' || this.mode === 'pupil' || this.mode === 'creator') {
            this.localToolbarState = true;
        }

        // Display Snackbar with info that Experience might be limited if user uses small device
        if (this.mode === 'creator' && (window.innerHeight < 600 || window.innerWidth < 600)) {
            this.showSnackbar({
                message: 'Sie scheinen einen kleinen Bildschirm zu nutzen. Für eine bessere ' +
                    'Nutzererfahrung empfehlen wir ein größeres Gerät wie z.B. einen Laptop.',
                color: 'info',
                closeable: true,
                timeout: 10000
            })
        }
        this.handleWatchCurrentPageIndex();
        this.handleWatchElementsOfCurrentPagePairedState();
    },
    beforeDestroy() {
        window.removeEventListener("keydown", this.handleKeydown);
        // Reset DragnDrop Store
        this.stopDragnDrop();
        this.mutateDragnDropFields([]);
    },
    methods: {
        ...mapActions("files", ["pullCurrentFile", "safeEmptyPage", "safeNewPdfFile", 'patchCurrentAppointmentUploadFile']),
        ...mapActions("appointments", ["getAppointments"]),
        ...mapActions("translation", ["setTargetLang", "translateToTargetLang", 'setTranslatedText', 'showTranslation']),
        ...mapActions("uploadMessage", ['getUploadMessages', 'createUploadMessages', 'postPairing']),
        ...mapActions("util", ["toggleKeyboard"]),
        ...mapActions("magnifier", ["toggleMagnifier", "turnOffMagnifier"]),
        ...mapActions("pupils", ["getMePupil"]),
        ...mapActions('documentRegistry', ['getDocumentRegistryDocumentById']),
        ...mapActions('editorDragnDrop', ['setdragndrop']),
        ...mapActions('tooltips', [ 'triggerShortcutTooltips' ]),
        ...mapMutations('editorDragnDrop', ["mutateDragnDropFields"]),
        ...mapMutations("snackbar", ["showSnackbar"]),
        ...mapMutations("pairing", ["setSpawnedPairing", "setSelected", "setPairingComponentIndex", "setPairedElements", "setPupilElements", "setPairingEdit", "resetPairing"]),

        stopDragnDrop() {
            this.setdragndrop(false);
        },

        async requestAccessibilityMode() {
            if(this.accountRole === 'pupil') {
                let temp = await this.getMePupil();
                this.babyView = temp.babyView;
                if (temp.accessibility) {
                    this.sehen = temp.accessibility.sehen;
                    this.hoeren = temp.accessibility.hoeren;
                } else {
                    this.sehen = false;
                    this.hoeren = false;
                }
            }
        },

        catchPairingState(state) {
            this.elementsOfCurrentPagePairedState.push(state);
        },

        changeCurrentPreviewMode(previewMode) {

            // save canvas content temporarily - pull current page to canvasPages
            const { fittingPageIndex, canvasJson } = this.$refs.editingcanvas.convertCanvasToJSON();
            this.$set(this.canvasPages, fittingPageIndex, { content: canvasJson });

            // change view mode
            this.currentPreview = previewMode;

            if (previewMode === 'default') {
                this.maxContainerWidthSetting = null;
                this.maxWidthSetting = null;

            } else {
                this.showPreviewDefault = false;
                this.localToolbarState = false;
                if (previewMode.includes('ipad')) {
                    this.maxContainerWidthSetting = 1024;
                    this.maxContainerHeightSetting = 768;

                } else {
                    this.maxContainerWidthSetting = 812;
                    this.maxContainerHeightSetting = 411;

                }

                if (previewMode.includes('portrait')) {


                    const temp = Number.parseInt(this.maxContainerWidthSetting.toString());
                    this.maxContainerWidthSetting = Number.parseInt(this.maxContainerHeightSetting.toString());
                    this.maxContainerHeightSetting = temp;
                }
            }

            if (this.maxContainerWidthSetting) {
                this.maxWidthSetting = 0.75 * this.maxContainerWidthSetting;
            }

            const preview = {
                offset: (this.maxWidthSetting || this.defaultCanvasWidth) / this.canvasWidth
            };

            // redraw canvas
            this.$nextTick(() => {
                this.$refs.editingcanvas.initCanvas(preview);
            });
        },

        /**#region Template Browser */
        async templateSelected(template) {
            this.setFileType = 'empty';
            if (template === 'wordDocument') {
                const { canvasJSON, elements, width, height } = editorTemplates.createTextDocumentTemplateData();
                this.canvasWidth = width;
                this.canvasHeight = height;

                this.canvasPages = [{ content: canvasJSON }];
                this.elementsList = this.elementsList.concat(elements);
            } else if (template === 'defaultWorksheet') {
                this.setFileType = 'pdf';
                const fetchedDefaultWorksheet = await editorTemplates.loadPDFTemplate(template);
                this.libraryFileData = new Uint8Array(fetchedDefaultWorksheet);
                await this.replaceCurrentDocFromLibrary(null);

                const textFieldData = {
                    x: 2,
                    y: 2,
                    width: 0.96,
                    height: 0.1232,
                }
                this.addElement('texttaskcombined', textFieldData, 0)
            } else {
                this.$refs.editingcanvas.dispose(true, true);
                this.canvasPages = [{ pageType: template }];
                this.noTextOnTextLayerSnackbar = true;
            }
            this.activateTemplateBrowser = false;
        },
        async openDocRegistryDocumentFromTemplateBrowser(payload) {
            const fileResponse = await backend.getDocumentRegistryDocumentFile(
                payload.value,
                payload.structure.backgroundPdf);
            const fileBlob = await fileResponse.blob();
            this.libraryFileData = new Uint8Array(await fileBlob.arrayBuffer());

            await this.replaceCurrentDocFromLibrary(null);
            this.activateTemplateBrowser = false;
        },
        /**#endregion */

        /**
         * Toolbar Functions
         */
        setTool(type) {
            this.stiftSizeMenu = true;

            if (this.currentActiveMethod !== type) {
                this.currentActiveMethod = type;
                for (let i = 0; i < this.pages.length; i++) {
                    this.$refs.editingcanvas.switchTool(type);
                }

                // if (this.accountRole === 'teacher' &&
                //     (type === 'pen' || type === 'marker' || type === 'text' || type === 'stempel')) {
                //     this.showSnackbar({ message: 'Tipp: Wenn man ALT gedrückt hält und ein Element zieht wird es dupliziert.', color: 'info' });
                // }
            } else {
                this.currentActiveMethod = 'none';
                for (let i = 0; i < this.pages.length; i++) {
                    this.$refs.editingcanvas.switchTool('none');
                }
            }
        },
        undo() {
            const lastDo = this.elementsHistory[this.elementsHistory.length - 1];
            let foundIndex = this.elementsList.findIndex(item => item === lastDo.element);
            const elementFromLastDo = this.elementsList[foundIndex];

            // Check type of last DO and run according code to reverse said DO
            switch (lastDo.type) {
                case 'transform':
                    if (foundIndex !== -1) {
                        elementFromLastDo.width = lastDo.originalData.width;
                        elementFromLastDo.height = lastDo.originalData.height;
                        elementFromLastDo.x = lastDo.originalData.x;
                        elementFromLastDo.y = lastDo.originalData.y;
                    }
                    break;
                case 'add':
                    if (foundIndex !== -1) {
                        this.elementsList.splice(foundIndex, 1);
                    }
                    break;
                case 'remove':
                    // eslint-disable-next-line no-case-declarations
                    const restoredElement = lastDo.element;
                    this.elementsList.splice(lastDo.index, 0, restoredElement);
                    foundIndex = lastDo.index;
                    break;
                case 'custom':
                    if (foundIndex !== -1) {
                        for (const key in lastDo.originalData) {
                            elementFromLastDo[key] = lastDo.originalData[key];
                        }
                    }
                    break;
                case 'canvas':
                    this.$refs.editingcanvas.undo()
                    break;
                case 'pageAdd':
                    this.canvasPages.splice(this.canvasPages.length - 1, 1);
                    break;
                case 'pageChanged':
                    this.$refs.editingcanvas.dispose();
                    this.currentPageIndex -= lastDo.direction;
                    break;
                case 'duplicated':
                    // eslint-disable-next-line no-case-declarations
                    const originalIndex = this.elementsList
                        .findIndex(el => el._id === lastDo.originalElement || el.identifier === lastDo.originalElement);
                    // eslint-disable-next-line no-case-declarations
                    const duplicatedIndex = this.elementsList
                        .findIndex(el => el._id === lastDo.duplicatedElement || el.identifier === lastDo.duplicatedElement);

                    if (originalIndex !== -1 && duplicatedIndex !== -1) {
                        // Set coords of original element to where it started
                        this.elementsList[originalIndex].x = this.elementsList[duplicatedIndex].x;
                        this.elementsList[originalIndex].y = this.elementsList[duplicatedIndex].y;

                        // Remove duplicated element
                        // eslint-disable-next-line no-case-declarations
                        this.elementsList.splice(duplicatedIndex, 1);
                    } else {
                        // print error in console, or handle it the right way
                        console.error('** Undo not possible ** \n ' +
                            'Count of Elements ', this.elementsList.length, '\n' +
                            'Last Do', lastDo, '\n ' +
                            '** Contact Support **');
                    }
                    break;
                case 'documentRotate':
                    // Can be implemented in the future

                    break;
            }

            this.elementsHistory.splice(this.elementsHistory.length - 1, 1);
            this.$nextTick(() => {
                if (foundIndex >= 0 && this.$refs[`editorElement${foundIndex}`]) {
                    this.$refs[`editorElement${foundIndex}`][0].$forceUpdate()
                }
            });

        },
        rotateEditor() {
            let result = null;
                result = this.$refs.editingcanvas.rotateEditor();

            /* Will be needed when Undo Rotate becomes necessary. But i just hope it never does T_T
            let { previousRotation, newRotation } = result;

            const historyObject = {
                type: 'documentRotate',
                element: null,
                originalData: { previousRotation },
                newRotation
            }
            this.elementsHistory.push(historyObject);*/
        },
        changeColor(colorHex, dontSave = false) {
            this.color = colorHex;
            if (!dontSave) {
                localStorage.setItem('editColor', colorHex);
            }

            this.$refs.editingcanvas.changeColor(colorHex);
        },
        changeSize(wantedTool, pixelCount) {
            localStorage.setItem('editSize', pixelCount);
            this.currentSize = pixelCount;
            if (this.currentActiveMethod !== wantedTool) {
                this.setTool(wantedTool);
            }
            this.$refs.editingcanvas.changeSize(pixelCount);
        },
        async addImageField (url, blob) {

            const imageDimensions = await this.getImageDimensions(url);
            let width = 175/this.canvasWidth;
            let height = 175 * imageDimensions.height / imageDimensions.width / this.canvasHeight;
            // If height is too tall until we make our own resize stuff
            if (height > ( 250 / this.canvasHeight)) {
                const imgScale = (250 / this.canvasHeight) / height;

                height = (250 / this.canvasHeight);
                width = width * imgScale;
            }

            this.addElement('imageObject', {
                x: 50,
                y: 25,
                fileUrl: url,
                fileBlob: blob,
                text: blob.name,
                width,
                height
            });
        },

        /**
         *  UI Functions
         */
        setLoading(status, msg) {
            this.loadingMessage = msg ? msg : "Dokument wird geladen...";
            this.localLoadingActive = status;
        },
        changeActivePage(newPage) {
            this.currentPageIndex = newPage;
        },
        changeDimensions(data) {
            this.canvasWidth = data.width;
            this.canvasHeight = data.height;

            if (this.currentPreview === 'default') {
                this.defaultCanvasWidth = data.width;
            }
        },
        changeZoom(direction) {
            if (direction === "minus" && this.currentZoom > 0) {
                this.currentZoom--;
                this.$refs.editingcanvas.$el.style.transform = "";
            } else if (direction === "plus" && this.currentZoom < 10) {
                this.currentZoom++;
                this.$refs.editingcanvas.$el.style.transform = "";
            }

            setTimeout(() => {
                this.$refs.editingcanvas.$el.style.transform
                    = `scale(${1 + this.currentZoom / 5}, ${1 + this.currentZoom / 5})`;

                this.$refs.editingcanvas.$el.style.transformOrigin = `left top`;
                this.$nextTick(this.$refs.scrollbar.refresh());
                this.$nextTick(this.$refs.scrollbar.refreshInternalStatus());
            }, 150);
        },
        changePDFTextLayerVisibility() {
            const intervalFunction = () => {
                let selection = { ...this.selectedText };
                if (window.getSelection) {
                    selection = window.getSelection();
                }
                // document.getSelection
                else if (document.getSelection) {
                    selection = document.getSelection();
                }
                // document.selection
                else if (document.selection) {
                    selection =
                        document.selection.createRange().text;
                }
                if (selection.focusNode && selection.focusNode.parentNode) {
                    this.lastSelectedTextNode = selection.focusNode.parentNode;
                }

                if (selection.toString() !== this.selectedText) {
                    this.update = !this.update;
                }
                this.selectedText = selection.toString();

            };

            this.isTextLayerVisible = !this.isTextLayerVisible;
            this.textSelectionInterval =
                this.textSelectionInterval == null
                    ? setInterval(intervalFunction, 100) : clearInterval(this.textSelectionInterval);

            if (!this.isTextLayerVisible) {
                this.selectedText = null;
                this.lastSelectedTextNode = null;
            } else {
                setTimeout(() => {
                    this.$refs.editingcanvas.calculateParagraphPositions();
                }, 250);
                if (this.noTextOnTextLayerSnackbar) {
                    this.showSnackbar({
                        message: 'Der Textlayer wurde aktiviert, aber es wurde kein Text auf dem PDF gefunden. Wurde das PDF vielleicht vorher bemalt oder eingescannt? Mehr Infos finden Sie im FAQ.',
                        color: "warning",
                        duration: 30000,
                    })
                } else {
                    this.showSnackbar({
                        message: 'Der Textlayer wurde aktiviert. Nun können Sie Texte auf dem PDF auswählen und mit ihnen interagieren. Einige andere Funktionen sind währenddessen deaktiviert.',
                        duration: 30000,
                    })
                }
            }
        },
        checkForScrollbarStylingUpdates(scrollbarParent) {
            if (scrollbarParent.classList.contains("hasVBar")) {
                if (!this.isVerticalScrollbarStyled) {
                    this.scrollbarStyling.styleVerticalScrollbar();
                    this.isVerticalScrollbarStyled = true;
                }
            } else {
                this.isVerticalScrollbarStyled = false;
            }

            if (scrollbarParent.classList.contains("hasHBar")) {
                if (!this.isHorizontalScrollbarStyled) {
                    this.scrollbarStyling.styleHorizontalScrollbar();
                    this.isHorizontalScrollbarStyled = true;
                }
            } else {
                this.isHorizontalScrollbarStyled = false;
            }
        },

        handleWatchCurrentPageIndex() {
            if(this.mode === 'pupil') {
                this.elementsOfCurrentPagePairedState.length = 0;
                let counter = 0;
                this.setPairingEdit(false);
                for (const element of this.elementsList) {
                    if (element.page === this.currentPageIndex) {
                        counter++;
                    }
                }
                this.elementsOfCurrentPageCounter = counter;
            }
        },

        handleWatchElementsOfCurrentPagePairedState() {
            if(this.mode === 'pupil') {
                if (this.elementsOfCurrentPagePairedState.length === this.elementsOfCurrentPageCounter && this.elementsOfCurrentPageCounter !== 0) {
                    let newPairingEditState = false;
                    for (const state of this.elementsOfCurrentPagePairedState) {
                        if (state && !this.pairingDone) {
                            newPairingEditState = true;
                        }
                    }
                    this.setPairingEdit(newPairingEditState);
                    this.snackbarPairing = newPairingEditState;
                } else if (!this.pairingEdit && this.elementsOfCurrentPagePairedState.length > 0 && this.elementsOfCurrentPageCounter > 0) {
                    for (const state of this.elementsOfCurrentPagePairedState) {
                        if (state && !this.pairingDone) {
                            this.setPairingEdit(state);
                            this.snackbarPairing = state;
                            break;
                        }
                    }
                }
            }
        },

        /**
         * Text To Speech Functions (might be moved to util)
         */
        async readText(text, callback) {

            if ('speechSynthesis' in window) {
                // Speech Synthesis supported 🎉
            } else {
                // Speech Synthesis Not Supported 😣
                alert("Sorry, your browser doesn't support text to speech!");
            }

            let msgText = text;

            if (this.translate) {
                msgText = await this.translateToTargetLang({
                    targetLang: this.targetLang,
                    textToTranslate: text
                });

                this.setTranslatedText(msgText);
                this.showTranslation(true);
            }

            if (window.speechSynthesis.speaking) {
                window.speechSynthesis.cancel();
                this.speakerIcon = this.lautsprecherIcon;
                callback(this.lautsprecherIcon, false);

                if (this.currentMessage.text === msgText) {
                    this.currentMessage = null;
                    this.showTranslation(false);
                    return;
                }
            }

            let msg = new SpeechSynthesisUtterance();
            msg.text = msgText;
            msg.lang = this.translate ? this.targetLang : this.currentLang;
            if (this.isLangPackageAvailable()) {
                window.speechSynthesis.speak(msg);
                this.currentMessage = msg;
                this.speakerIcon = this.lautsprecherAusIcon;
                callback(this.lautsprecherAusIcon, true);
            }

            msg.onend = () => {
                this.speakerIcon = this.lautsprecherIcon;
                this.currentMessage = null;
                callback(this.lautsprecherIcon, false);
            };
        },

        isLangPackageAvailable() {
            for (let i = 0; i < window.speechSynthesis.getVoices().length; i++) {
                if (window.speechSynthesis.getVoices()[i].lang.includes(this.currentLang)) {
                    return true;
                }
            }

            return false;
        },
        changeStamp(stempel) {

            localStorage.setItem('editStempel', stempel);
            this.$refs.editingcanvas.setStempelImg(stempel);

            if (this.currentActiveMethod !== 'stempel') {
                this.setTool('stempel');
            }
        },

        /**
         * File Functions
         */
        async requestFile(userGroup, fileType) {
            const response = await this.pullCurrentFile({
                userGroup,
                fileType,
                fileName: this.assignmentName,
                appointmentId: this.appointmentId,
                fileId: this.fileId,
            });

            if (response &&
                (response.status === 200 ||
                    response.status === 201 ||
                    response.status === 204)) {
                if (response.file) {
                    this.currentFile = response.file;
                    if (this.getCurrentFileType === "pdf") {
                        await this.computeLoadedPDF(this.currentFile);
                    } else {
                        this.noTextOnTextLayerSnackbar = true;
                        this.currentFile.onload = (fileData) => {
                            this.pages = [ fileData ];
                        }
                        const estload = this.currentFile;
                    }
                } else {
                    if (response.error) {
                        this.showSnackbar({ message: "Etwas ist schiefgelaufen - Bitte aktualisieren Sie die Seite!", color: 'error' });
                    }
                    this.setLoading(false);
                }
            } else {
                if (response.status === 401 || response.status === 403) {
                    this.showSnackbar({ message: "Sie haben keinen Zugriff auf diese Datei. Bitte kontaktieren Sie den Autor der Datei.", color: 'error' });
                } else if (response.status === 404) {
                    this.showSnackbar({ message: "Die Datei konnte nicht gefunden werden. Bitte laden Sie die Datei erneut hoch, oder kontaktieren Sie den Support.", color: 'error' });
                } else {
                    this.showSnackbar({ message: "Etwas ist schiefgelaufen - Bitte aktualisieren Sie die Seite!", color: 'error' });
                }
                this.setLoading(false);
            }
        },
        async computeLoadedPDF(pdfFile, mode = 'normal') {

            const pdfDocumentLoadingTask = pdfjs.getDocument({ data: pdfFile });
            const pdfDocument = await pdfDocumentLoadingTask.promise;

            const pagePromises = Array.from(Array(pdfDocument.numPages).keys()) // Create array from [0 ... countPages]
                .map((element) => element + 1) // Add 1 to each element to get all page numbers
                .map((page) => pdfDocument.getPage(page));
            if (mode === 'empty') {
                return await Promise.all(pagePromises);
            } else {
                this.pages = await Promise.all(pagePromises);
            }

        },

        /**
         *  Adding or removing pages
         */
        newCustomPageClicked() {
            this.$refs.newPageUpload.click();
        },
        async addNewCustomPage(event, index = -1) {

            const newFile = this.$refs.newPageUpload.files[0];

            try {

            // Before even doing anything, check that data has the right format
            if (newFile.type !== 'application/pdf' && !newFile.type.includes('image/')) {
                this.showSnackbar({
                    message: 'Nicht-konformes Format gewählt! Bitte fügen Sie nur PDFs oder Bilder an.',
                    color: 'warning'
                });
                return;
            }

            if (!(this.isCurrentFileImage && newFile.type === 'application/pdf')) {
                const { fittingPageIndex, canvasJson } = this.$refs.editingcanvas.convertCanvasToJSON();
                this.canvasPages[fittingPageIndex] = { content: canvasJson };

                if (this.canvasPages[fittingPageIndex].pageType) {
                    delete this.canvasPages[fittingPageIndex].pageType;
                }
            }
            this.$refs.editingcanvas.dispose();

            if (this.currentFileType === 'pdf') {
                if (newFile.type === 'application/pdf') {
                    const newFileArray = new Uint8Array(await newFile.arrayBuffer());
                    const currentPDFDoc = await PDFDocument.load(this.currentFile);

                    const newPDFDoc = await PDFDocument.load(newFileArray);

                    const [firstPage] = await currentPDFDoc.copyPages(newPDFDoc, [0])

                    if (index !== -1) {
                        currentPDFDoc.insertPage(index, firstPage)
                    } else {
                        currentPDFDoc.addPage(firstPage)
                    }

                    this.currentFile = await currentPDFDoc.save();

                    await this.computeLoadedPDF(this.currentFile);

                } else if (newFile.type.includes('image/')) {
                    const containerImg = new Image();

                    containerImg.onload = async (eventData) => {

                        URL.revokeObjectURL(containerImg.src);
                        const imgData = eventData.path && eventData.path.length > 0 ? eventData.path[0] : eventData.target;
                        const width = imgData.naturalWidth;
                        const height = imgData.naturalHeight;

                        const currentPDFDoc = await PDFDocument.load(this.currentFile);
                        const newFileArrayBuffer = await newFile.arrayBuffer()

                        let embeddableImg;
                        if (newFile.type.includes('png')) {
                            embeddableImg = await currentPDFDoc.embedPng(newFileArrayBuffer)
                        } else {
                            embeddableImg = await currentPDFDoc.embedJpg(newFileArrayBuffer)
                        }

                        let newPage;

                        if (index !== -1) {
                            newPage = currentPDFDoc.insertPage(index, [width, height])
                        } else {
                            newPage = currentPDFDoc.addPage([width, height])
                        }
                        newPage.drawImage(embeddableImg, {
                            x: 0,
                            y: 0,
                            width,
                            height,
                        })

                        this.currentFile = await currentPDFDoc.save();
                        await this.computeLoadedPDF(this.currentFile);
                    };
                    containerImg.src = await URL.createObjectURL(newFile);
                }
            } else if (this.currentFileType === 'empty') {

                if (newFile.type === 'application/pdf') {
                    this.currentFile = new Uint8Array(await newFile.arrayBuffer());
                    await this.computeLoadedPDF(this.currentFile);
                    const fillerArray = new Array(this.canvasPages.length);

                    this.pages.splice(0, 0, fillerArray);
                    this.setFileType = 'pdf';
                } else {
                    // this.pages = [this.currentFile];
                    const containerImg = new Image();
                    this.pages = new Array(this.canvasPages.length);
                    containerImg.onload = (fileData) => {
                        this.pages.push(fileData);
                    }
                    containerImg.src = await URL.createObjectURL(newFile);
                }
            } else {

                if (newFile.type === 'application/pdf') {

                    const newFileArray = new Uint8Array(await newFile.arrayBuffer());
                    const newPDFDoc = await PDFDocument.load(newFileArray);

                    for (let i = 0; i < this.pages.length; i++) {
                        const imgData = this.pages[i].path[0];
                        const width = imgData.naturalWidth;
                        const height = imgData.naturalHeight;

                        let embeddableImg;
                        if (this.currentFileType === 'png' || this.currentFileType === 'PNG') {
                            embeddableImg = await newPDFDoc.embedPng(imgData.src)
                        } else {
                            embeddableImg = await newPDFDoc.embedJpg(imgData.src)
                        }

                        const newPage = newPDFDoc.insertPage(i, [width, height])
                        newPage.drawImage(embeddableImg, {
                            x: 0,
                            y: 0,
                            width,
                            height,
                        });
                    }

                    this.currentFile = await newPDFDoc.save();
                    this.setFileType = 'pdf';
                    await this.computeLoadedPDF(this.currentFile);

                    /*for (let i = 0; i < this.pages.length; i++) {
                        //remove background image from canvasPage if set

                        if (this.canvasPages[i]
                            && this.canvasPages[i].content
                            && this.canvasPages[i].content.backgroundImage) {

                            delete this.canvasPages[i].content.backgroundImage;
                        }
                    }*/

                } else {
                    const containerImg = new Image();
                    containerImg.onload = (fileData) => {
                        this.pages.push(fileData);
                    }
                    containerImg.src = await URL.createObjectURL(newFile);
                }
            }

            this.pagesChanged = true;
            this.showSnackbar({message: `Neue Seite erfolgreich hinzugefügt!`});
            } catch (e) {
                console.error(e);
                this.showSnackbar({
                    message: 'Etwas ist schiefgelaufen! Bitte laden Sie die Seite neu.',
                    color: 'error'
                });
            }
        },
        async duplicateActivePage() {


            try {

                // Copy current canvas data to array for possible duplication
                const {fittingPageIndex, canvasJson} = this.$refs.editingcanvas.convertCanvasToJSON();
                this.canvasPages[fittingPageIndex] = {content: canvasJson};

                if (this.canvasPages[fittingPageIndex].pageType) {
                    delete this.canvasPages[fittingPageIndex].pageType;
                }
                this.$refs.editingcanvas.dispose();

                // Handle PDF Page Duplication
                if (this.pages[this.currentPageIndex]) {
                    if (this.currentFileType === 'pdf') {

                        // Can only be done this way when page exists
                        const currentPDFDoc = await PDFDocument.load(this.currentFile);
                        const [pageToDuplicate]
                            = await currentPDFDoc.copyPages(currentPDFDoc, [this.currentPageIndex]);
                        currentPDFDoc.insertPage(this.currentPageIndex + 1, pageToDuplicate);

                        this.currentFile = await currentPDFDoc.save();
                        await this.computeLoadedPDF(this.currentFile);

                    } else if (this.isCurrentFileImage) {

                        // Can only be done this way when page exists
                        const duplicatedImg = new Image();

                        duplicatedImg.onload = (fileData) => {

                            this.pages.splice(this.currentPageIndex + 1, 0, fileData);
                        }

                        const container = document.createElement('div');
                        container.style.display = 'none';
                        container.appendChild(duplicatedImg);
                        document.body.appendChild(container);
                        duplicatedImg.src
                            = this.pages[this.currentPageIndex].path[0].src;

                        this.$nextTick(() => {
                            document.body.removeChild(container);
                        })
                    }

                    // Splice empty page into canvaspages
                    this.canvasPages.splice(
                        this.currentPageIndex + 1,
                        0,
                        null);
                } else if (this.canvasPages[this.currentPageIndex]) {

                    // If empty page is being duplicated
                    this.canvasPages.splice(
                        this.currentPageIndex + 1,
                        0,
                        {...this.canvasPages[this.currentPageIndex]});

                    //Splice empty page into pages
                    this.pages.splice(this.currentPageIndex + 1, 0, null);
                } else {
                    console.error('not possible');
                }

                // Update page index of elements
                const elementListLength = this.elementsList.length;
                for (let i = 0; i < elementListLength; i++) {
                    const element = this.elementsList[i];
                    if (element.page >= this.currentPageIndex + 1) {
                        element.page++;
                    } else if(element.page === this.currentPageIndex) {
                        let newElement = JSON.parse(JSON.stringify(element));
                        newElement.page++;
                        if(element.messageType === 'imageObject' || element.messageType === 'videoObject') {
                            delete newElement.fileBlob
                            const blob = element.fileBlob;
                            newElement.fileBlob = new File([blob], blob.name, { type: blob.type });
                        }
                        else if(element.messageType === 'audio') {
                            delete newElement.startFile;
                            const blob = element.startFile;
                            newElement.startFile = new Blob([blob], {type: blob.type});
                        }
                        this.elementsList.push(newElement);
                        console.log('element: ', element);
                        console.log('newElement: ', newElement);
                        // console.log('elementBlob: ', element.fileBlob);
                        // console.log('newElementBlob: ', newElement.fileBlob);
                    }
                }
                this.pagesChanged = true;


                this.showSnackbar({message: `Seite ${this.currentPageIndex + 1} erfolgreich dupliziert!`});
            } catch (e) {
                console.warn(e);
                this.showSnackbar({
                    message: `Ein Fehler ist aufgetreten. Die Aktion konnte nicht vollendet werden.`,
                    color: 'error'});
            }
        },
        deletePageClicked() {
            this.deletePageConfirmationPopup = true;
        },
        async deleteActivePage() {

            if (this.pages.length > 1) {

                const { fittingPageIndex, canvasJson } = this.$refs.editingcanvas.convertCanvasToJSON();
                this.canvasPages[fittingPageIndex] = { content: canvasJson };

                if (this.canvasPages[fittingPageIndex].pageType) {
                    delete this.canvasPages[fittingPageIndex].pageType;
                }
                this.$refs.editingcanvas.dispose();

                if (this.currentFileType === 'pdf') {
                    const currentPDFDoc = await PDFDocument.load(this.currentFile);
                    currentPDFDoc.removePage(this.currentPageIndex);
                    this.currentFile = await currentPDFDoc.save();

                    await this.computeLoadedPDF(this.currentFile);

                } else {

                    this.pages.splice(this.currentPageIndex, 1);
                }

                this.canvasPages.splice(this.currentPageIndex, 1);


                this.elementsList = this.elementsList
                    .filter(item => item.page !== this.currentPageIndex)
                    .map(item =>  {
                        if (item.page > this.currentPageIndex) {
                            item.page = item.page - 1
                        }
                        return item;
                    });

                this.showSnackbar({message: `Seite ${this.currentPageIndex + 1} erfolgreich gelöscht!`});

                this.currentPageIndex = 0;
            } else if (this.canvasPages.length > 1)  {
                const { fittingPageIndex, canvasJson } = this.$refs.editingcanvas.convertCanvasToJSON();
                this.canvasPages[fittingPageIndex] = { content: canvasJson };

                if (this.canvasPages[fittingPageIndex].pageType) {
                    delete this.canvasPages[fittingPageIndex].pageType;
                }
                this.$refs.editingcanvas.dispose();

                this.canvasPages.splice(this.currentPageIndex, 1);


                this.elementsList = this.elementsList
                    .filter(item => item.page !== this.currentPageIndex)
                    .map(item =>  {
                        if (item.page > this.currentPageIndex) {
                            item.page = item.page - 1
                        }
                        return item;
                    });

                this.showSnackbar({message: `Seite ${this.currentPageIndex + 1} erfolgreich gelöscht!`});

                this.currentPageIndex = 0;
            } else {

                this.showSnackbar({
                    message: `Es war uns nicht möglich Seite ${this.currentPageIndex + 1} zu löschen.`,
                    color: 'warning'
                });

            }

            this.deletePageConfirmationPopup = false;
            this.pagesChanged = true;
        },

        /**
         * Methods for Library Import
         */
        AddDocFromLibraryClick(fileData) {
            this.addDocFromLibraryPopup = true;
            this.libraryFileData = fileData;
        },
        async replaceCurrentDocFromLibrary(fileId) {
            try {

                const { fittingPageIndex, canvasJson } = this.$refs.editingcanvas.convertCanvasToJSON();
                this.canvasPages[fittingPageIndex] = { content: canvasJson };

                if (this.canvasPages[fittingPageIndex].pageType) {
                    delete this.canvasPages[fittingPageIndex].pageType;
                }
                this.$refs.editingcanvas.dispose(true, true);

                // const pulledFile = this.libraryFileData.pdfBackground;
                const pulledFile = this.libraryFileData;

                if (pulledFile.constructor === Uint8Array) {
                    const newPDFDoc = await PDFDocument.load(pulledFile);

                    this.currentFile = await newPDFDoc.save();
                    const newEditorPages = await this.computeLoadedPDF(this.currentFile, 'empty');
                    this.canvasPages = [{content: null}];
                    this.pages = newEditorPages;
                    this.setFileType = 'pdf';
                    this.showSnackbar({
                        message: 'Dokument erfolgreich ersetzt!'
                    });
                    this.currentPageIndex = 0;
                }

                //replace editor elements with those coming from registry
                // if (this.libraryFileData.elements) {
                //     this.elementsList = [ ...this.libraryFileData.elements ];
                // }

                this.pagesChanged = true;
            } catch (e) {
                console.error(e);
                this.showSnackbar({
                    message: 'Etwas ist schiefgelaufen!',
                    color: 'error'
                });
            }
            this.addDocFromLibraryPopup = false
        },
        async appendDocFromLibrary(fileId) {
            try {
                const pulledFile = this.libraryFileData;

                if (pulledFile.constructor === Uint8Array) {
                    const { fittingPageIndex, canvasJson } = this.$refs.editingcanvas.convertCanvasToJSON();
                    this.canvasPages[fittingPageIndex] = { content: canvasJson };

                    if (this.canvasPages[fittingPageIndex].pageType) {
                        delete this.canvasPages[fittingPageIndex].pageType;
                    }
                    this.$refs.editingcanvas.dispose(true, true);

                    // Save current environment and load it directly
                    const currentPDFDoc = await PDFDocument.load(await this.safePdfAndAddNewContent(
                        this.currentFileType, this.currentFile, this.canvasPages, this.pages));
                    const newPDFDoc = await PDFDocument.load(pulledFile);

                    const newPages = await currentPDFDoc.copyPages(newPDFDoc, newPDFDoc.getPageIndices());

                    for (let i = 0; i < newPages.length; i++) {
                        currentPDFDoc.addPage(newPages[i]);
                    }

                    if (this.currentFileType === 'pdf') {
                        this.currentFile = await currentPDFDoc.save();
                        await this.computeLoadedPDF(this.currentFile);
                        this.showSnackbar({
                            message: 'Dokument erfolgreich angehängt!'
                        })
                    } else {

                        this.currentFile = await currentPDFDoc.save();
                        const newEditorPages = await this.computeLoadedPDF(this.currentFile, 'empty');
                        this.canvasPages = [{content: null}];
                        this.pages = newEditorPages;
                        this.setFileType = 'pdf';
                    }
                } else {
                    this.showSnackbar({
                        message: 'Dieses Dokument konnte nicht angehängt werden, da es den falschen Dateityp hat!',
                        color: 'error'
                    });
                }
            } catch (e) {
                this.showSnackbar({
                    message: 'Etwas ist schiefgeladen!' + e.name,
                    color: 'error'
                });
            }

            //Append editor elements coming from registry
            // if (this.libraryFileData.elements) {
            //     this.elementsList = this.elementsList.concat([ ...this.libraryFileData.elements ]);
            // }

            this.pagesChanged = true;
            this.addDocFromLibraryPopup = false
        },
        creatorSaveMenuOnLeave() {
            if(this.accountRole === 'pupil') {
                this.leavePagePupilDialog = true
            }
            else {
                this.close();
            }
        },

        /** single page view Methods
         */
        changePage(direction = 0) {
            //TODO: handle change of page better


            const { fittingPageIndex, canvasJson } = this.$refs.editingcanvas.convertCanvasToJSON();
            this.canvasPages[fittingPageIndex] = { content: canvasJson };

            if (this.canvasPages[fittingPageIndex].pageType) {
                delete this.canvasPages[fittingPageIndex].pageType;
            }

            this.$refs.editingcanvas.dispose();

            this.currentPageIndex += direction;

            this.elementsHistory.push({
                type: 'pageChanged',
                element: null,
                direction
            });


        },
        spawnTextOnCanvas() {
            this.$refs.editingcanvas.spawnText(null, null);
        },
        toggleToolbar(newState) {
            this.localToolbarState = newState;
            if (!this.localToolbarState) {
                this.setTool(this.currentActiveMethod)
            }
        },
        elementMenuOpened() {
            this.setTool(this.currentActiveMethod);
        },

        /**#region Editor Elements */

        htmlObjectYAxis(object) {
            return object.y * (1 + this.currentZoom / 5);
        },
        dragEnter(event) {
            if ( event.target.className.includes('textLayer') ) {
                event.target.style.border = "2px solid purple";
            }
            event.preventDefault();
        },
        async dropInElement(event) {

            event.preventDefault();
            const payloadString = event.dataTransfer.getData('text/plain');
            const payload = JSON.parse(payloadString);
            let type = payload.type;
            const wantedX = ((event.layerX || event.offsetX) / this.canvasWidth) * 100;
            const wantedY = ((event.layerY || event.offsetY) / this.canvasHeight) * 100;
            event.target.style.border = 'none';

            const typeArray = [
                'texttask',
                'answerField',
                'imageObject',
                'calculation',
                'cloze',
                'wall',
                'buchstabensuppe',
                'texttaskplus',
                'multipleChoice',
                'pairing',
                'dragndrop',
                'dragndropfree',
                'texttaskcombined',
            ]
            if (typeArray.includes(type)) {
                const data = { x: wantedX, y: wantedY };

                // Handle special cases (should be resolved in the future)
                if (type === 'texttask' && payload.text) {
                    data.text = true;
                }

                if (type === 'imageObject' && payload.title) {
                    const imagePayload = event.dataTransfer.getData('image/png');
                    let blob = await fetch(imagePayload).then(r => r.blob());
                    blob.name = payload.title;
                    let url = window.URL.createObjectURL(blob);

                    const imageDimensions = await this.getImageDimensions(url);

                    let width = 175/this.canvasWidth;
                    let height = 175 * imageDimensions.height / imageDimensions.width / this.canvasHeight;
                    // If height is too tall until we make our own resize stuff
                    if (height > ( 250 / this.canvasHeight)) {
                        const imgScale = (250 / this.canvasHeight) / height;

                        height = (250 / this.canvasHeight);
                        width = width * imgScale;
                    }

                    data.fileUrl = url;
                    data.fileBlob = blob;
                    data.text = payload.title;
                    data.width = width;
                    data.height = height;
                }

                this.addElement(type, data);
            } else if (type === 'registryDocument') {
                const fileResponse = await backend.getDocumentRegistryDocumentFile(
                    payload.id,
                    payload.structure.backgroundPdf);
                const fileBlob = await fileResponse.blob();
                this.libraryFileData = new Uint8Array(await fileBlob.arrayBuffer());

                this.addDocFromLibraryPopup = true;
            }
        },
        addElement(messageType, data, page = null, skipHistory = false) {
            // Disable Canvas Tools to minimize user confusion
            this.setTool(this.currentActiveMethod);

            const newElement = {
                ...data,
                messageType,
                identifier: uuidv4(),
                page: page ? page : this.currentPageIndex,
                author: this.accountId
            }
            this.elementsList.push(newElement)

            if (!skipHistory) {
                const historyObject = {
                    type: 'add',
                    element: newElement
                };

                this.elementsHistory.push(historyObject);
                console.log('history', this.elementsHistory);
            }
        },
        removeElement(element, index) {
            const historyObject = {
                type: 'remove',
                element,
                index,
            }
            this.elementsHistory.push(historyObject);
            this.elementsList.splice(index, 1);
        },
        async handleKeydown(keyEvent) {
            // handle paste
            // Source: https://web.dev/async-clipboard/#read()
            if (keyEvent.code === "KeyV" && keyEvent.ctrlKey) {
                try {
                    const clipboardItems = await navigator.clipboard.read();
                    for (const clipboardItem of clipboardItems) {
                        for (const type of clipboardItem.types) {
                            if (!type.startsWith("image/")) {
                                return;
                            }

                            const blob = await clipboardItem.getType(type);
                            const url = URL.createObjectURL(blob);
                            const imageDimensions = await this.getImageDimensions(url);
                            this.addElement('imageObject', {
                                x: 50,
                                y: 25,
                                fileUrl: url,
                                fileBlob: blob,
                                text: "",
                                width: 300 / this.canvasWidth,
                                height: 300 * imageDimensions.height / imageDimensions.width / this.canvasHeight
                            });
                            return;
                        }
                    }
                } catch (err) {
                    console.error(err.name, err.message);
                }
            }
            if (keyEvent.altKey && keyEvent.key === 't') {
                this.toggleKeyboard()
                this.triggerShortcutTooltips();
            }
            if (keyEvent.altKey && keyEvent.key === 'l') {
                this.toggleMagnifier();
                this.triggerShortcutTooltips();
            }

        },
        handleCustomHistoryEvent(element, originalData) {
            const historyObject = {
                type: 'custom',
                element,
                originalData
            }

            this.elementsHistory.push(historyObject);
        },
        handleCanvasChangeInHistory(index) {
            const historyObject = { type: 'canvas', element: null, originalData: {}, index };
            this.elementsHistory.push(historyObject);
        },
        updateTransform(element, data, additionalData = {}, skipHistory = false) {
            const historyObject = {
                type: 'transform',
                element,
                originalData: {}
            };

            // Code to compare if data has changed
            const keys = Object.keys(data);
            const reducedElement = {};
            for (let i = 0; i < keys.length; i++) {
                const { [keys[i]]: dummy } = element;
                reducedElement[keys[i]] = dummy;
            }
            const dataToCompare = { ...data, ...additionalData };

            // Check if movement is ok and not just button click
            if (!skipHistory && JSON.stringify(reducedElement) !== JSON.stringify(dataToCompare)) {
                // Actually a real movement

                for (const key in data) {
                    // eslint-disable-next-line no-prototype-builtins
                    historyObject.originalData[key] = element[key];
                    element[key] = data[key];
                }

                for (const key in additionalData) {
                    // eslint-disable-next-line no-prototype-builtins
                    historyObject.originalData[key] = additionalData[key];
                }

                // Transform update needs to be written in history list
                this.elementsHistory.push(historyObject);
            }

            // For shapes extra steps are needed to fit size of next element to the last one
            if (element.messageType === 'rectangle' || element.messageType === 'line') {
                this.handleFormSpecsChanged(element.messageType, element)
            }
        },
        callEditElement(element) {
            this.elementToEdit = { timestamp: new Date().getMilliseconds(), element };
            this.originalEl = element;
        },
        elementEdited(edited) {
            const foundIndex = this.elementsList.findIndex(item => item === this.originalEl);
            const historyObject = {
                type: 'custom',
                element: null,
                originalData: {}
            };

            for (const key in edited) {
                // Don't re-set the width and height, otherwise some elements will break
                if(key !== 'width' && key !== 'height')
                    historyObject.originalData[key] = this.elementsList[foundIndex][key];
                    this.$set(this.elementsList[foundIndex], key, edited[key]);
            }

            historyObject.element = this.elementsList[foundIndex];
            this.elementsHistory.push(historyObject);

            this.elementToEdit = null
        },
        duplicateElementOnAltDrag(element, index, position) {
            console.log('duplicateElementOnAltDrag');
            // Element is deep copied and fed into addElement to enable history
            // Deep copy needs to go deeper than spread operator,
            // internal json elements are still only reference copy
            const newElement = { ...JSON.parse(JSON.stringify(element)), ...position };
            const messsageType = newElement.messageType;
            delete newElement.messageType;
            const skipHistory = true;
            console.log('newElement', newElement);

            this.addElement(messsageType, newElement, newElement.page, skipHistory);
            console.log('new ElementsList', this.elementsList);
        },
        elementDuplicated(element, data, additionalData) {
            console.log('elementDuplicated');
            // Get last element
            // Assuming that last element in list is the one that was just created via duplication
            const duplicatedElement = this.elementsList[this.elementsList.length - 1];
            // Now, how do i do this
            const skipHistory = true;
            this.updateTransform(element, data, additionalData, skipHistory);

            // Add new element to history
            const historyObject = {
                type: 'duplicated',
                duplicatedElement: duplicatedElement.identifier || duplicatedElement._id,
                originalElement: element.identifier || element._id,
            };

            this.elementsHistory.push(historyObject);
        },
        dropAllDragnDropFields() {
            for (let i = this.elementsList.length - 1; i >= 0; i--) {
                const el = this.elementsList[i];
                if (el.messageType === 'dragndropfreetarget') {
                    this.$refs[`editorElement${i}`][0].removeElement();
                }
            }
        },

        /**#endregion */

        /**#region load next pupil upload for correction */

        async handleLoadNextPupilUploadForCorrection() {
            this.saveMenu = false;
            if (this.appointmentId && this.uploadId) {
                const nextUpload = this.uncorrectedPupilUploadsInAppointment(this.appointmentId, this.uploadId);
                if (nextUpload) {
                    await this.handleNormalSave(null, true);
                    this.setLoading(true, 'Nächstes Dokument wird geladen...');

                    const group = this.groupName;
                    const color = this.groupColor;
                    const appointmentId = this.appointmentId;
                    const fileTitle = nextUpload.title;

                    await this.$router.push({
                        name: 'editor',
                        params: { mode: 'teacher' },
                        query: {
                            group: group,
                            ug: 'teacher',
                            color: color,
                            aId: appointmentId,
                            title: fileTitle,
                            fId: nextUpload.file,
                            uploadid: nextUpload._id,
                        },
                    });
                    await this.$router.go();
                }
            }
        },
        /**#endregion */

        /**#region save document */

        async addNewPage(type) {
            const docLength = this.canvasPages.length > this.pages.length ? this.canvasPages.length : this.pages.length;
            this.$set(this.canvasPages, docLength, { content: null, pageType: type });
            this.pageAdded = true;
        },
        async handleNormalSave(event, loadNext = false) {
            if (this.saveFunction) {
                const redrawFile = this.getNeedsRedrawing();
                const wasEmptyPage = !!this.emptyPageType || this.setFileType === 'empty';
                const fileData = await this.safePdfAndAddNewContent(
                    this.currentFileType, this.currentFile, this.canvasPages, this.pages);

                await this.saveFunction({
                    fileData,
                    appointmentId: this.appointmentId,
                    fileId: this.fileId,
                    fileName: this.assignmentName,
                    redrawFile,
                    wasEmptyPage,
                    loadNext
                });
                await this.createScreenshot();
            } else {
                console.error("'saveFunction' not passed to EditorBaseComponent - no action will be performed!");
            }
            this.saveMenu = false;
        },
        async handleSaveInPrivateFolder() {
            if (this.saveInPrivateFolderFunction){
                this.getNeedsRedrawing();
                const wasEmptyPage = !!this.emptyPageType || this.setFileType === 'empty';
                const pdfData = await this.safePdfAndAddNewContent(
                    this.currentFileType, this.currentFile, this.canvasPages, this.pages);
                this.saveInPrivateFolderFunction(pdfData, this.assignmentName, wasEmptyPage);
                this.readyForScreenshot = true;
            } else {
                console.error("'saveInPrivateFolderFunction' not passed to EditorBaseComponent - no action will be performed!");
            }
            this.saveMenu = false;
        },
        async handleSaveInSharedFolder() {
            if (this.saveInSharedFolderFunction) {
                await this.saveInSharedFolderFunction(await this.safePdfAndAddNewContent(
                    this.currentFileType, this.currentFile, this.canvasPages, this.pages), this.assignmentName);
                    this.readyForScreenshot = true;
            } else {
                console.error("'saveInPrivateFolderFunction' not passed to EditorBaseComponent - no action will be performed!");
            }
            this.saveMenu = false;
        },
        getNeedsRedrawing() {
            // Check if something is drawn on canvases
            let drawings = [];
            for (let i = 0; i < this.pages.length; i++) {
                drawings = drawings.concat(this.$refs.editingcanvas.filterHistoryForAdded);
            }

            // pull current page to canvasPages
            const { fittingPageIndex, canvasJson } = this.$refs.editingcanvas.convertCanvasToJSON();
            this.canvasPages[fittingPageIndex] = { content: canvasJson };

            if (this.canvasPages[fittingPageIndex].pageType) {
                delete this.canvasPages[fittingPageIndex].pageType;
            }

            return this.pageAdded || drawings.length !== 0 || this.emptyPageType || this.pagesChanged;
        },

        /**#endregion */

        /**#region Methoden für Dokument Export */

        async handleExportDocumentClick() {

            this.setLoading(true, 'Dokument wird für den Export vorbereitet...');

            this.getNeedsRedrawing();

            const pdfData = await this.safePdfAndAddNewContent(
                this.currentFileType,
                this.currentFile,
                this.canvasPages,
                this.pages
            );

            const elementScreenshots = [];
            const pagesMetadata = [];
            // Set currentpage to start for screenshots of elements
            this.currentPageIndex = 0;
            this.elementsExportMode = true;

            for (let i = 0; i < this.completePageCount; i++) {
                const metaData = await this.generatePageMetadata(i);
                pagesMetadata.push(metaData);

                for (let j = 0; j < this.elementsList.length; j++) {
                    const element = this.elementsList[j];
                    if (this.isElementReadyForScreenshot(element, this.currentPageIndex)) {
                        const elRef = this.$refs[`editorElement${j}`][0];

                        const screenshot = await this.createScreenshotOfElement(elRef.$el);
                        if (screenshot.elementScreenshot.substring(0, 14) === 'data:image/png') {
                            const payload = {
                                x: element.x,
                                y: element.y,
                                page: element.page,
                                width: screenshot.width,
                                height: screenshot.height,
                                pngString: screenshot.elementScreenshot
                            }
                            elementScreenshots.push(payload);
                        }
                    }
                }

                if (i + 1 < this.completePageCount) {
                    this.changePage(1);
                }
            }

            this.docDataForExport = pdfData;
            this.elementScreenshots = elementScreenshots;
            this.pagesMetadata = pagesMetadata;
            this.showExportDialog = true;
            this.elementsExportMode = false;
            this.currentPageIndex = 0;
            await this.setLoading(false);
            this.saveMenu = false;
        },

        async generatePageMetadata(pageIndex) {


            const payload = {
                size: null
            }

            if (this.pages[pageIndex]) {
                const page = this.pages[pageIndex];
                const viewport = page.getViewport({scale: 1 });
                payload.size = {
                    width: viewport.width,
                    height: viewport.height
                }

            } else if (this.canvasPages[pageIndex]) {
                const canvasPage = this.canvasPages[pageIndex];

            }

            return payload;
        },

        async createScreenshotOfElement(elementHtml) {
            let elementHtmlCanvas = await html2canvas(elementHtml, {
                backgroundColor: null,
            });
            const width = Number.parseInt(elementHtmlCanvas.style.width.substring(0, elementHtmlCanvas.style.width.length - 2));
            const height = Number.parseInt(elementHtmlCanvas.style.height.substring(0, elementHtmlCanvas.style.height.length - 2));
            const elementScreenshot = elementHtmlCanvas.toDataURL('image/png', 1);
            return {
                width: width / this.canvasWidth,
                height: height / this.canvasHeight,
                elementScreenshot
            };
        },

        /**#endregion */

        convertFabricJsonToBase64(canvas, fabricJson) {
            return new Promise((resolve) => {

                canvas.loadFromJSON(fabricJson, async () => {
                    canvas.renderAll.bind(canvas)

                    const dataUrlString = canvas.toDataURL();
                    const res = await fetch(dataUrlString);
                    resolve(res.arrayBuffer());
                });
            });
        },

        // free up singleton of pairing, reset state to default
        fullResetPairing() {
            this.resetPairing();
            this.removeElement(null, this.pairingComponentIndex);
        },

        async safePdfAndAddNewContent(currentFileType, currentFile, canvasPages, pdfPages) {

            /** Create pdf backbone*/
            //TODO deal with multiple different pdf sources (maybe always create a new one)
            // TODO move everything into one for loop instead of two
            let pdfDoc = null;
            if (currentFileType === 'pdf') {
                pdfDoc = await PDFDocument.load(currentFile);
            } else {
                pdfDoc = await PDFDocument.create();
            }

            /** create savingCanvas in fabric (could use a cleaner approach) */
            //TODO handle different page sizes
            const savingCanvas = new fabric.Canvas(this.$refs.savingCanvas);
            savingCanvas.setDimensions({ width: this.canvasWidth, height: this.canvasHeight });

            /** determine document length */
            const docLength = this.completePageCount;

            /** Enter 1st for loop for canvas images (is separated because of timing issues) */
            const imageDataArray = [];
            for (let i = 0; i < docLength; i++) {
                if (canvasPages[i]
                        && canvasPages[i].content
                        && ((canvasPages[i].content.objects && canvasPages[i].content.objects.length)
                                || canvasPages[i].content.backgroundImage)
                ) {
                    savingCanvas.clear();
                    imageDataArray[i] = await this.convertFabricJsonToBase64(savingCanvas, canvasPages[i].content);
                }
            }
            savingCanvas.clear();

            /** Enter 2nd for loop for pages (could use a cleaner approach if there even is one)*/
            for (let i = 0; i < docLength; i++) {
                let canvasImage = imageDataArray[i];
                let page = null;
                //TODO handle pages from different docs (maybe solved if new pdf is always created)
                try {
                    page = pdfDoc.getPage(i);
                } catch (e) {
                    console.warn(e);
                    //TODO handle different page sizes
                    page = pdfDoc.addPage([this.canvasWidth, this.canvasHeight]);
                }
                if (!page) {
                    page = pdfDoc.addPage([this.canvasWidth, this.canvasHeight]);
                }


                if (page && canvasImage) {
                    const pngImage = await pdfDoc.embedPng(canvasImage);
                    page.drawImage(pngImage, {
                        x: 0,
                        y: 0,
                        width: page.getWidth(),
                        height: page.getHeight(),
                    });
                }
            }
            return await pdfDoc.save();
        },
        // vue/no-unused-properties cannot detect methods by $ref, so make a exception
        // eslint-disable-next-line vue/no-unused-properties
        setNewValuesForEmptyPage(newName, fileId) {
            this.assignmentName = newName;
            this.fileId = fileId;
        },
        // vue/no-unused-properties cannot detect methods by $ref, so make a exception
        // eslint-disable-next-line vue/no-unused-properties
        async safeAllEditorMessages(fileId = this.fileId) {


            const messages = [];

            for (let i = 0; i < this.elementsList.length; i++) {
                const message = {...this.elementsList[i]};
                try {
                    /** Special cases for elements */
                    // Video and Image upload
                    if ((message.messageType === 'imageObject' || message.messageType === 'videoObject' || message.messageType === 'audioObject')
                        && !message.start) {
                        let res = await backend.postUploadMessage(message.fileBlob);
                        res = await res.json();
                        message.start = res._id;
                    }

                    // Audio Messages
                    if (message.messageType === 'audio') {
                        if (message.startFile) {
                            message.start = await this.uploadRecordedMessage(
                                message.startFile, fileId + ".wav"
                            );
                            message.start = message.start._id;
                        }
                        if (message.answerFile) {
                            message.answer = await this.uploadRecordedMessage(
                                message.answerFile, fileId + ".wav"
                            );
                            message.answer = message.answer._id;
                            message.answers = message.answers || [];
                            message.answers[0] = {
                                element: message.answer,
                                author: this.accountId
                            }
                        }
                        if (message.correctionFile) {
                            message.correction = await this.uploadRecordedMessage(
                                message.correctionFile, fileId + ".wav"
                            );
                            message.correction = message.correction._id;
                            message.answers = message.answers || [];
                            message.answers[1] = {
                                element: message.correction,
                                author: this.accountId
                            }
                        }
                    }
                    // Multiple Choice Elements
                    if (message.messageType === 'multipleChoice') {
                        let data = message.mulitpleChoiceConfig.mQuestions;
                        if (this.accountRole === 'teacher' && this.mode === 'creator') {
                            // Remove the checks on the teacher side
                            data.forEach(m => {
                                m.checked = false;
                            });
                        }
                        // Its important that we don't save any width or height for multiple choice boxes
                        // Otherwise the size will break while editing
                        delete message.width;
                        delete message.height;
                    }
                    if (message.messageType === 'buchstabensuppe'
                        || message.messageType === 'audio'
                        || message.messageType === 'calculation'
                        || message.messageType === 'wall'
                        || message.messageType === 'texttaskcombined'
                        || message.messageType === 'dragndropfreetarget'
                        || message.messageType === 'text') {
                        delete message.width;
                        delete message.height;
                    }
                    if (message.messageType === 'cloze') {
                        delete message.height;
                    }
                } catch (e) {
                    // TODO Add correct error display here
                    console.warn(e);
                }
                messages.push(message);
            }

            const response = await this.createUploadMessages({
                file: fileId,
                points: messages,
            });

            if (
                response._id ||
                !response ||
                (Number.isInteger(response) && response < 205)
            ) {
                // got success response
                await this.pairingSave(response);
                this.showSnackbar({ message: "Platzierte Elemente wurden gespeichert." });
            } else {
                this.showSnackbar({ message: "Etwas ist schiefgelaufen - Bitte aktualisieren Sie die Seite!", color: "error"});
            }

            return true;
        },
        handleFormSpecsChanged(type, data) {
            if (this.lastFormSpecs[type]) {
                this.lastFormSpecs[type].width = data.width;
                this.lastFormSpecs[type].height = data.height;
                this.lastFormSpecs[type].x = data.x;
                this.lastFormSpecs[type].y = data.y;
                this.lastFormSpecs[type].rotation = data.rotation ? data.rotation : 0;
            }
        },
        async uploadRecordedMessage(file, nameForCompleteness) {
            if (file) {
                let file2 = new File([file], nameForCompleteness, {
                    type: "audio/wav",
                });
                const res = await backend.postUploadMessage(file2);
                return await res.json();
            }
        },
        moveEditorElementIndexByOffset(index, offset) {
            const element = this.elementsList[index];
            if (!element) {
                console.error("No element at index: " + index + " can not swap!");
                return;
            }
            let newIndex = (index + offset);
            if (newIndex < 0)
                newIndex = 0;
            if (newIndex >= this.elementsList.length)
                newIndex = this.elementsList.length - 1;
            // Check if we are actually moving the element
            if (newIndex !== index) {
                const swapElement = this.elementsList[newIndex];
                this.focusedElement = ('editorElement' + newIndex);
                this.$set(this.elementsList, index, swapElement);
                this.$set(this.elementsList, newIndex, element);
            }
            this.$forceUpdate();
        },

        // updates from local elements ._uid to the newly obtained mongoose ._id
        // then saves the files pairing settings
        // todo: test this in various states
        async pairingSave(res) {

            let pairingConfig = {};
            if(this.mode === 'creator') {
                let pairs = this.pairedElements;
                for(let index in res.points) {
                    if(res.points[index]._uid) {
                        for(let p in pairs) {
                            if(pairs[p].el1) {
                                if(res.points[index]._uid === pairs[p].el1._uid) {
                                    pairs[p].el1._id = res.points[index]._id;
                                }
                            }
                            if(pairs[p].el2) {
                                if (res.points[index]._uid === pairs[p].el2._uid) {
                                    pairs[p].el2._id = res.points[index]._id;
                                }
                            }
                        }
                    }
                }
                this.setPairedElements(pairs);
            } else if (this.mode === 'pupil') {
                // pupil has made his selection under pairedElements
                // therefore we swap back the store values before saving
                this.setPupilElements(this.pairedElements);
                this.setPairedElements(this.teacherElements);
                // mark this component as completed by pupil
                pairingConfig.done = true;
            }

            pairingConfig.spawnedPairing = this.spawnedPairing;
            pairingConfig.pairedElements = this.pairedElements;
            pairingConfig.pupilElements = this.pupilElements;
            pairingConfig.pairingComponentIndex = this.pairingComponentIndex;
            if(this.pairingDone) {
                // case: teacher opens submission of pupil and saves it (HW is already finished)
                // quick fix: skip saving process entirely
                pairingConfig.done = true;
            } else {
                let body = { file: res.file, pairingConfig: pairingConfig };
                await this.postPairing(body);
            }
        },

        /**
         * General
         */
        async close() {
            this.saveMenu = false;

            this.dispose();
            this.$emit('close');
        },
        dispose() {
            try {
                this.$refs.editingcanvas.dispose();
                this.$refs.editingcanvas.clearBackgroundCanvas();
            } catch (e) {
                console.warn(e);
            }
        },
        async getImageDimensions(imageUrl) {
            return new Promise((resolve, reject) => {
                const img = document.createElement('img');
                img.src = imageUrl;
                img.onload = () => {
                    resolve({
                        width: img.width,
                        height: img.height,
                    });
                }
            })
        },

        // captures the html elements via html2canvas then saves it in backend for use as preview
        async createScreenshot() {
            // allowTaint option is needed to render images
            let canvas = await html2canvas(document.querySelector('#capture'), {allowTaint: true});
            canvas.toBlob(async(blob) => {
                // upload thumbnail of an editor page to an appointment upload
                if (blob) {
                    let file = new File([blob], 'thumbnail.png', { type: 'image/png' });
                    await backend.postThumbnail(
                        this.fileId,
                        file,
                    );
                } else {
                    console.warn('No file provided for thumbnail upload.')
                }
            });
        },

        updatePreviewUIProp(bool) {
            this.previewPupilUI = bool;
        },
    },
}
</script>

<style scoped lang="scss">
@import "~@/styles/globals.scss";

.editingCanvas {
    position: relative;
}

.pointer {
    cursor: pointer;
}

.menuBg {
    background-color: rgba(100, 100, 100, 0.8);
}

.nameVMenu {
    height: 49px;

    p {
        color: white;
    }
}

#headerText {
    color: white;
    font-size: x-large;
    font-weight: bold;

    /*max-width: calc(100% - 70px);*/
    /*min-width: calc(100% - 70px);*/
    width: inherit;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    float: left !important;
}

.headerTeacherText {
    font-size: x-large;
    padding: 0.5em;
}

.btnHeight {
    height: 49px;
    border-radius: 8px;
}

.squareBtn {
    width: 49px;
    height: 49px;
    border-radius: 8px;
}

.squareBtnIcon {
    width: 40px;
    height: 40px;
}

#closeBtn {
    background-color: #e6221e80;
    width: 49px;
    height: 49px;

    @media (max-width: 430px) {
        width: 40px !important;
        height: 40px !important;
        margin-right: 0 !important;
    }
}

.toolBtn {
    background-color: #555555;
    height: 49px !important;

    @media (max-width: 430px) {
        height: 40px !important;
        margin-right: 0 !important;

        p {
            display: none;
        }
    }
}

.greenBtn {
    background-color: #8cbd46;
    color: white;

    img {
        filter: brightness(1000%);
    }
}

.toolSelector {
    background-color: #f8f8f880;
    width: 49px !important;
    height: 49px !important;

    @media (max-width: 430px) {
        width: 40px !important;
        height: 40px !important;

        img {
            width: 30px !important;
            height: 30px !important;
        }
    }
}

.toolSelector:disabled {
    background-color: #f8f8f840 !important;
}

.zoomControl {
    position: fixed;
    z-index: 8 !important;
    top: 95px;
    left: 20px;
    background-color: #f8f8f8 !important;
    width: 49px !important;
    height: 49px !important;
    border-radius: 8px;

    p {
        line-height: 0;
        font-size: 60px;
        margin-bottom: 0;
        font-weight: 300;
        color: #333;
    }

    @media (max-width: 380px) {
        width: 40px !important;
        height: 40px !important;

        p {
            font-size: 50px;
        }
    }
}

//#pageControlAbsolute {
//    background-color: transparent;
//    height: 60px;
//    display: flex;
//    flex-wrap: wrap;
//    position: fixed;
//    top: 85px;
//    right: 145px;
//    width: 50px;
//    z-index: 2;
//}

#documentProgessBar {
    width: 100%;
}

#pageControlContainer {
    width: 100%;
    display: inline-flex;
    align-content: center;
    justify-content: space-between;
}

.pageControl {
    opacity: 1;
}

.pageControl:disabled, .pageControl[disabled] {
    opacity: 0.5;
}

#textLayerButton {
    img {
        filter: brightness(0);
    }
}

.textLayerActive {
    filter: invert(0.8);
}

.v-snack.v-snack--top {
    bottom: initial; /* or auto */
}

.v-snack.v-snack--bottom {
    top: initial; /* or auto */
}

.focusMe {
    /* Deprecated */
    /* Used to be useful */
    /* Fixes the position offset when selecting an object */
    //margin-top: -2px !important;
    //border: 2px solid red;
}

.notesButtonsImage {
    height: 30px;
    filter: brightness(0%);
}

.smallDisplayMoreButtons {
  display: block;
  background-color: white;
  width: 250px;
  padding: 0.25em;

  button {
    border-radius: 0 !important;
  }
}

.smallDisplayMoreButtonsTrigger {
  width: 49px !important;
  height: 49px !important;
  padding-left: 2px !important;

  @media (max-width: 430px) {
    width: 40px !important;
    height: 40px !important;
    padding-left: 0 !important;
  }
}

.roundMenuBorders {
    $menu-content-border-radius: 1em !important;
}

.previewSizeIPad {
    //width: 1024px !important;
    //height: 768px !important;
    transform: scale(0.5);
}

.previewSizeSmartphone {
    /*width: 411px !important;
    height: 823px !important;*/
    transform: scale(0.25);
}
</style>
