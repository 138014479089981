<template>
    <v-overlay
        style="z-index: 101 !important"
    >
        <div class="container">
            <div style="display: flex; flex-direction: column">
                <div style="display: flex; justify-content: space-between">
                    <span
                        class="text"
                        style="font-weight: bold; font-size: large; margin-top: 5px"
                    >
                        Auftragsverarbeitung
                        <br>
                        <br>
                    </span>
                    <v-btn
                        color="grey"
                        @click="() => { setShowDialogGDPR(false) }"
                    >
                        <p>
                            Schließen
                        </p>
                    </v-btn>
                </div>
                <v-spacer style="margin-top: 10px" />
                <span
                    v-if="!contractSigned"
                    class="text"
                >
                    Bevor Sie die Verwaltung nutzen, bitten wir Sie noch eine Auftragsverarbeitung
                    mit EKLARA abzuschließen.
                    <br>
                    <br>Für die Kontaktaufnahme können Sie hier optional Ihre Daten hinterlegen.
                    <br>Diese Daten sind nicht teil der Auftragsverarbeitung und helfen uns, Ihre Schule bei Rückfragen zu kontaktieren.
                    <br>
                    <br>
                </span>
                <span
                    v-else
                    class="text"
                >
                    Sie haben bereits eine Auftragsverarbeitung mit EKLARA abgeschlossen. Ihre Angaben können Sie uns jedoch erneut zusenden, um diese zu aktualisieren.
                    <br>
                    <br>Für die Kontaktaufnahme können Sie hier ihre Daten hinterlegen :
                    <br>
                    <br>
                </span>
                <v-text-field
                    v-model="schulname"
                    class="textField"
                    label="Schulname"
                    filled
                    outlined
                    light
                >
                    <template
                        v-if="schulname.length > 0"
                        v-slot:append-outer
                    >
                        <v-icon color="green">
                            mdi-check
                        </v-icon>
                    </template>
                </v-text-field>
                <v-text-field
                    v-model="kontaktperson"
                    class="textField"
                    label="Kontaktperson"
                    filled
                    outlined
                    light
                    hint="Vor- und Nachname"
                >
                    <template
                        v-if="kontaktperson.length > 0"
                        v-slot:append-outer
                    >
                        <v-icon color="green">
                            mdi-check
                        </v-icon>
                    </template>
                </v-text-field>
                <v-text-field
                    v-model="schulleitung"
                    class="textField"
                    label="Schulleitung"
                    filled
                    outlined
                    light
                >
                    <template
                        v-if="schulleitung.length > 0"
                        v-slot:append-outer
                    >
                        <v-icon color="green">
                            mdi-check
                        </v-icon>
                    </template>
                </v-text-field>
                <v-text-field
                    v-model="mailadresse"
                    class="textField"
                    label="Mailadresse"
                    filled
                    outlined
                    light
                >
                    <template
                        v-if="mailadresse.length > 0"
                        v-slot:append-outer
                    >
                        <v-icon color="green">
                            mdi-check
                        </v-icon>
                    </template>
                </v-text-field>
                <v-text-field
                    v-model="telefonnummer"
                    class="textField"
                    label="Telefonnummer"
                    filled
                    outlined
                    light
                >
                    <template
                        v-if="telefonnummer.length > 0"
                        v-slot:append-outer
                    >
                        <v-icon color="green">
                            mdi-check
                        </v-icon>
                    </template>
                </v-text-field>
                <div style="display: flex">
                    <v-btn
                        :color="downloaded ? 'green' : 'grey'"
                        @click="() => { downloadAVV(); downloaded = true }"
                    >
                        <v-icon>mdi-download</v-icon>
                    </v-btn>
                    <p
                        class="text"
                        style="margin-top: 5px; margin-left: 20px"
                    >
                        Hier können Sie den unausgefüllten Vertrag herunterladen.
                        <br>
                        <br>
                    </p>
                </div>
                <div style="display: flex">
                    <div style="display: flex; flex-direction: column">
                        <input
                            id="uploadInput"
                            ref="uploadInput"
                            type="file"
                            style="cursor: pointer; color: black; background-color: white"
                            size="10"
                            :style="uploaded ? 'background-color: #00800080' : ''"
                            max-width="140px"
                            @change="uploadInputChange()"
                        >
                    </div>
                    <p
                        class="text"
                        style="margin-top: 5px; margin-left: 10px"
                    >
                        {{ uploaded ? '' : 'Bitte laden Sie hier die unterschriebene Auftragsverarbeitung hoch.' }}
                        <br>
                        <br>
                    </p>
                </div>
                <div style="display: flex; justify-content: space-around">
                    <v-btn
                        :color="formValid ? 'green' : 'grey'"
                        @click="submit()"
                    >
                        <p>Absenden</p>
                    </v-btn>
                    <v-spacer />
                </div>
            </div>
        </div>
    </v-overlay>
</template>

<script>
import { mapState, mapGetters, mapMutations } from "vuex";
import * as backend from "../../api/backend";

export default {
    name: "ContractVerwaltung",
    data() {
        return {
            // state
            downloaded: false,
            uploaded: false,
            contractSigned: false,

            //input model
            schulname: '',
            kontaktperson: '',
            schulleitung: '',
            mailadresse: '',
            telefonnummer: '',
            file: null,
        }
    },
    computed: {
        ...mapGetters("auth", ["accountId"]),

        // contact information is now optional, only check for uploaded contract
        formValid() {
            // if (this.schulname.length>0 && this.kontaktperson.length>0 &&
            //     this.schulleitung.length>0 && this.mailadresse.length>0 &&
            //     this.telefonnummer.length>0 && this.uploaded) {
            //         return true;
            //     }
            // return false;
            return this.uploaded;
        }
    },
    async mounted() {
        const res = await backend.isAVVSigned();
        if (res.status === 200) {
            this.contractSigned = true;

        }
    },
    methods: {
        ...mapMutations("snackbar", ["showSnackbar"]),
        ...mapMutations("maintainer", ["setShowDialogGDPR"]),

        submit() {
            if (this.formValid) {
                const res = this.uploadAVV();
                this.setShowDialogGDPR(false);
            }
        },
        uploadAVV() {
            const formData = new FormData();
            formData.append('file', this.file, this.file.name);
            let uploadXmlHttpRequest = backend.postMaintainerAVV(this.file, this.accountId);
            uploadXmlHttpRequest.send(formData);
            uploadXmlHttpRequest.addEventListener('load', async (e) => {
                if (uploadXmlHttpRequest.status !== 201) {
                    this.showSnackbar({
                        message: 'Beim Hochladen ist ein Fehler aufgetreten.',
                        color: 'error',
                        duration: '5'
                    });
                } else {
                    this.showSnackbar({
                        message: 'Die Auftragsverarbeitung wurde erfolgreich hochgeladen.',
                        color: 'success',
                        duration: '5'
                    });
                }
                // append data from form
                const body = {
                    schulename: this.schulname,
                    kontaktperson: this.kontaktperson,
                    schulleitung: this.schulleitung,
                    mailadresse: this.mailadresse,
                    telefonnummer: this.telefonnummer,
                }
                console.log(JSON.parse(uploadXmlHttpRequest.responseText)._id);
                const appendRes = await backend.postMaintainerAppendAVV(JSON.parse(uploadXmlHttpRequest.responseText)._id, body);
                console.log(appendRes);
            });
        },
        async downloadAVV() {
            const res = await backend.getAVVTemplate();
            let blob = await res.blob();
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement("a");
            a.href = url;
            a.download = 'AVV_schule_eklara';
            document.body.appendChild(a);
            a.click();
            setTimeout(function () {
                a.remove();
                window.URL.revokeObjectURL(url);
            }, 1000);
        },
        uploadInputChange() {
            this.file = this.$refs.uploadInput.files[0];
            this.uploaded = true;
        }
    },
}
</script>

<style scoped>
.container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    /* height: 85vh;
    width: 75vw; */
    background-color: white;
    border-radius: 16px;
    overflow: scroll;
    /* hide scrollbar */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
}

.container::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
}

.text {
    color: black;
}

.textField {
    color: black;
}
</style>
