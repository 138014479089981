// '<iframe height="600" class="py-2" style="width: 100%"\n' +
// ' src="https://www.youtube-nocookie.com/embed/?start=???&end=???"\n' +
// ' frameborder="0" allow="accelerometer; autoplay;\n' +
// ' clipboard-write; encrypted-media; gyroscope; picture-in-picture"\n' +
// ' allowfullscreen\n></iframe>'

const taskMenuHelp = [
    {
        title: 'Textfelder / Aufgabenfelder',
        content: '<p class="mb-3">Die Textfelder bieten Lehrkräften die Möglichkeit, einen Aufgabentext auf ein Blatt ' +
            'zu schreiben. Zusätzlich hat dieses Feld mehrere Formatierungsoptionen für Texte und Links. Damit können ' +
            'normale Texte gefärbt, in der Größe geändert, oder durch Links und Listen ergänzt werden. SuS können ' +
            'sich diese Texte durchlesen oder vorlesen lassen, und anschließend die Aufgaben bearbeiten. ' +
            '<br/><span style="font-size: small">Bitte beachten Sie, dass die Elemente nicht über mehrere Seiten ' +
            'verschoben werden können. Wenn ' +
            'Sie ein Element platzieren wollen, stellen Sie vorher sicher das Sie sich auf der geplanten Seite befinden. ' +
            'Dies lässt sich über die Seitenzahl unten links ablesen.</span></p>'
    },{
        title: 'Antwortfelder',
        content: '<p class="mb-3">Die Antwortfelder bieten den SuS die Möglichkeit, eine Antwort auf eine Frage direkt ' +
            'einzutippen und abzugeben. Hierbei können Antwortfelder von Lehrkräften direkt auf dem Blatt platziert und ' +
            'in Höhe und Breite angepasst werden. SuS geben dann ihre Antwort ein und müssen auf den Haken drücken, damit ' +
            'diese eingeloggt wird.' +
            '<br/><span style="font-size: small">Bitte beachten Sie, dass die Elemente nicht über mehrere Seiten verschoben ' +
            'werden können. Wenn ' +
            'Sie ein Element platzieren wollen, stellen Sie vorher sicher das Sie sich auf der geplanten Seite befinden. ' +
            'Dies lässt sich über die Seitenzahl unten links ablesen.</span></p>'
    },{
        title: 'Drag & Drop Texte',
        content: '<p class="mb-3">Die Drag & Drop Texte bieten eine gute Möglichkeit, SuS mit' +
            ' ihrem Arbeitsblatt interagieren zu lassen. Lehrkräfte können diese platzieren, und SuS können dann ' +
            'per Drag and Drop die Texte an den richtigen Platz ziehen. ' +
            '<br/><span style="font-size: small">Bitte beachten Sie, dass die Elemente nicht über mehrere Seiten verschoben ' +
            'werden können. Wenn ' +
            'Sie ein Element platzieren wollen, stellen Sie vorher sicher das Sie sich auf der geplanten Seite befinden. ' +
            'Dies lässt sich über die Seitenzahl unten links ablesen. </span></p>' +
            '<iframe height="600" class="py-2" style="width: 100%"\n' +
            ' src="https://www.youtube-nocookie.com/embed/Z0DkZS5UkS8"\n' +
            ' frameborder="0" allow="accelerometer; autoplay;\n' +
            ' clipboard-write; encrypted-media; gyroscope; picture-in-picture"\n' +
            ' allowfullscreen\n></iframe>'
    },{
        title: 'Multiple Choice Aufgaben',
        content: '<p class="mb-3">Die Multiple Choice Aufgaben bieten eine gute Möglichkeit, die SuS mit' +
            ' vorgegebenen Fragen und Antworten abzufragen. Dabei können Lehrkräfte Fragen bestimmen, und dazu passende ' +
            'Antwortmöglichkeiten erstellen. Mit dem Haken daneben kann zudem die richtige Antwort gekennzeichnet werden.' +
            ' Während der Korrektur wird dann direkt angezeigt, ob die SuS die richtige Antwort ausgewählt haben.' +
            '<br/><span style="font-size: small">Bitte beachten Sie, dass die Elemente nicht über mehrere Seiten verschoben ' +
            'werden können. Wenn Sie ein Element platzieren wollen, stellen Sie vorher sicher das Sie sich auf der ' +
            'geplanten Seite befinden. Dies lässt sich über die Seitenzahl unten links ablesen.</span></p>' +
            '<iframe height="600" class="px-2" style="width: 100%"\n' +
            ' src="https://www.youtube-nocookie.com/embed/xuPSY_tbDP0"\n' +
            ' frameborder="0" allow="accelerometer; autoplay;\n' +
            ' clipboard-write; encrypted-media; gyroscope; picture-in-picture"\n' +
            ' allowfullscreen\n></iframe>' +
            '<p class="my-2">Und anschließend finden Sie noch eine Idee um schon gemalte Kästchen' +
            ' auf Blättern mit unseren Elementen zu nutzen.</p>' +
            '<iframe height="600" class="px-2" style="width: 100%"\n' +
            ' src="https://www.youtube-nocookie.com/embed/M5D_-Mocnac"\n' +
            ' frameborder="0" allow="accelerometer; autoplay;\n' +
            ' clipboard-write; encrypted-media; gyroscope; picture-in-picture"\n' +
            ' allowfullscreen\n></iframe>'
    }
];

const memoMenuHelp = [
    {
        title: 'Sprachaufnahme',
        content: '<p>Die Sprachaufnahme ist ein guter Weg Kommunikation zwischen Lehrkräften und SuS zu verbessern. ' +
            'Um diese zu nutzen muss die Lehrkraft zuerst auf den Knopf \'Aufnahme\' klicken. Daraufhin erscheint ein graues  ' +
            'Lautsprechersymbol in der Mitte des Arbeitsblattes. Dieses muss die Lehrkraft an die gewünschte Stelle schieben ' +
            'und mit einem Klick auf den Haken bestätigen. Danach wird das Symbol rot. Sobald dann darauf geklickt hat ' +
            'öffnet sich ein kleiner Dialog, in dem NutzerInnen über das Gedrückthalten des Mikrofonknopfes Nachrichten ' +
            'aufnehmen können. SuS können einer Lehrkraft auf deren Nachricht in demselben Dialog antworten, und Lehrkräfte ' +
            'können bei der Korrektur der Aufgaben ebenfalls Rückmeldung geben.' +
            '<br/><span style="font-size: small">Bitte beachten Sie, dass die Elemente nicht über mehrere Seiten verschoben ' +
            'werden können. Wenn Sie ein Element platzieren wollen, stellen Sie vorher sicher das Sie sich auf der ' +
            'geplanten Seite befinden. Dies lässt sich über die Seitenzahl unten links ablesen.</span></p>' +
            '<iframe height="600" class="px-2" style="width: 100%"\n' +
            ' src="https://www.youtube-nocookie.com/embed/CCbWq0dNoSk"\n' +
            ' frameborder="0" allow="accelerometer; autoplay;\n' +
            ' clipboard-write; encrypted-media; gyroscope; picture-in-picture"\n' +
            ' allowfullscreen\n></iframe>'
    },{
        title: 'Textnotizen',
        content: '<p>Die Textnotiz ermöglicht es NutzerInnen, kleinere Nachrichten für die nächsten NutzerInnen dazulassen. ' +
            'Wenn man auf die Schaltfläche klickt, ändert sich das Menü zu einem Textfeld. In dieses kann eine Nachricht ' +
            'geschrieben, und anschließend auf Platzieren geklickt werden. Daraufhin erscheint ein graues Stiftsymbol in der ' +
            'Mitte des Arbeitsblattes. Dieses müssen die NutzerInnen an die gewünschte Stelle schieben ' +
            'und mit einem Klick auf den Haken bestätigen. Danach wird das Symbol rot. Sobald dann darauf geklickt hat ' +
            'öffnet sich ein kleiner Dialog, in dem die vorhin eingegebene Nachricht steht sowie ein Lautsprecherknopf um diese ' +
            'vorzulesen. ' +
            '<br/><span style="font-size: small">Bitte beachten Sie, dass die Elemente nicht über mehrere Seiten verschoben werden können. Wenn ' +
            'Sie ein Element platzieren wollen, stellen Sie vorher sicher das Sie sich auf der geplanten Seite befinden. ' +
            'Dies lässt sich über die Seitenzahl unten links ablesen.</span></p>'
    }
];

const mediaMenuHelp = [
    {
        title: 'Bilder hinzufügen',
        content: '<p> Mit dieser Option können Lehrkräfte SuS Bilder zur Verfügung stellen. Diese werden auf dem Blatt ' +
            'platziert und können an die richtige Stelle gezogen werden. Das Hochladen eines Bildes funktioniert dabei ' +
            'wie bereits aus anderen Stellen der Anwendung bekannt.'+
            '<br/><span style="font-size: small">Bitte beachten Sie, dass die Elemente nicht über mehrere Seiten verschoben werden können. Wenn ' +
            'Sie ein Element platzieren wollen, stellen Sie vorher sicher das Sie sich auf der geplanten Seite befinden. ' +
            'Dies lässt sich über die Seitenzahl unten links ablesen.</span></p>'
    },{
        title: 'Videos hinzufügen',
        content: '<p> Mit dieser Option können Lehrkräfte SuS Videos zur Verfügung stellen. Diese werden auf dem Blatt ' +
            'platziert und können an die richtige Stelle gezogen werden. SuS können sich dann dieses Video angucken ' +
            'und, vielleicht in Verbindung mit einem vorher platzierten Multiple Choice Feld, ' +
            'Fragen der Lehrer direkt während des Videos beantworten. Das Hochladen eines Videos funktioniert dabei ' +
            'wie bereits aus anderen Stellen der Anwendung bekannt.' +
            '<br/><span style="font-size: small">Bitte beachten Sie, dass die Elemente nicht über mehrere Seiten verschoben werden können. Wenn ' +
            'Sie ein Element platzieren wollen, stellen Sie vorher sicher das Sie sich auf der geplanten Seite befinden. ' +
            'Dies lässt sich über die Seitenzahl unten links ablesen.</span></p>'
    },{
        title: 'Audiodateien hinzufügen',
        content: '<p>' +
            '<br/><span style="font-size: small">Bitte beachten Sie, dass die Elemente nicht über mehrere Seiten verschoben werden können. Wenn ' +
            'Sie ein Element platzieren wollen, stellen Sie vorher sicher das Sie sich auf der geplanten Seite befinden. ' +
            'Dies lässt sich über die Seitenzahl unten links ablesen.</span></p>'
    }
];

const formMenuHelp = [
    {
        title: 'Rechtecke',
        content: '<p> Die Rechtecke bieten Lehrkräften die Möglichkeit, nicht-interaktive Elemente auf Arbeitsblättern ' +
            'zu verstecken oder Kästchen auf ihrem Arbeitsblatt zu erstellen. Für SuS sehen diese aus als würden sie ' +
            'zum Arbeitsblatt gehören. Dies bietet die Grundlage für die Erstellung eines vollinteraktiven Arbeitsblatt.' +
            '<br/><span style="font-size: small">Bitte beachten Sie, dass die Elemente nicht über mehrere Seiten verschoben ' +
            'werden können. Wenn ' +
            'Sie ein Element platzieren wollen, stellen Sie vorher sicher das Sie sich auf der geplanten Seite befinden. ' +
            'Dies lässt sich über die Seitenzahl unten links ablesen.' +
            '<br/>Bei Elementen die sich vergrößern, verkleinern oder rotieren lassen funktioniert diese Änderung der Abmessungen' +
            ' auf Touchgeräten (Tablets, Smartphones) nicht. Diese Funktionen sind auf diesen Geräten deutlich schwerer ' +
            'zu realisieren. Allerdings arbeiten wir mit Hochdruck daran, dies in einem kommenden Update zur Verfügung ' +
            'zu stellen. Wir bitten um ihr Verständnis.</span></p>'
    },{
        title: 'Linien',
        content: '<p> Linien bieten Lehrkräften genau die Möglichkeiten die sie versprechen. Sie können platziert,' +
            ' eingefärbt und gedreht werden. In Verbindung mit anderen Formen, wie Rechecken, eignen Sie sich ' +
            'hervorragend für die Zuweisung von Bildteilen oder gezeichneten Elementen.'+
            '<br/><span style="font-size: small">Bitte beachten Sie, dass die Elemente nicht über mehrere Seiten verschoben ' +
            'werden können. Wenn ' +
            'Sie ein Element platzieren wollen, stellen Sie vorher sicher das Sie sich auf der geplanten Seite befinden. ' +
            'Dies lässt sich über die Seitenzahl unten links ablesen.' +
            '<br/>Bei Elementen die sich vergrößern, verkleinern oder rotieren lassen funktioniert diese Änderung der Abmessungen' +
            ' auf Touchgeräten (Tablets, Smartphones) nicht. Diese Funktionen sind auf diesen Geräten deutlich schwerer ' +
            'zu realisieren. Allerdings arbeiten wir mit Hochdruck daran, dies in einem kommenden Update zur Verfügung ' +
            'zu stellen. Wir bitten um ihr Verständnis.</span></p>'
    }
];

export {
    taskMenuHelp,
    memoMenuHelp,
    mediaMenuHelp,
    formMenuHelp
}
