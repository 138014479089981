<template>
    <div>
        <chat-widget class="mt-4" />
    </div>
</template>

<script>
    import ChatWidget from "../Chat/ChatWidget";
    export default {
        name: "ChatVerwaltung",
        components: {ChatWidget}
    }
</script>

<style scoped>

</style>
