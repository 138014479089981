<template>
    <div>
        <v-btn
            id="joinLehrerzimmerBtn"
            color="gruen"
            elevation="0"
            class="px-2"
            @click="joinLehrerzimmer"
        >
            <img
                alt="Lehrerzimmer beitreten"
                :src="videoIcon"
            >
            <p>Lehrer</p>
        </v-btn>
        <VideoTutorial
            v-if="videoTutorial && showVideoTutorial"
            :video-tutorial="videoTutorial"
            @close="onVideoTutorialClose"
        />
    </div>
</template>

<script>
import videoIcon from '../../assets/Icons/kamera-video-weiss-72.svg';
import * as backend from '../../api/backend';
import { mapActions, mapMutations, mapGetters } from 'vuex';

import VideoTutorial from "../../components/Tutorial/VideoTutorial.vue";

export default {
    name: "JoinLehrerzimmerButton",
    components: {
        VideoTutorial,
    },
    data() {
        return {
            videoIcon,

            // Show video Tutorial is set to true when button is clicked
            // videoTutorialClosed is set to false when VideoTutorial is closed
            showVideoTutorial: false,
            videoTutorialClosed: false,
        };
    },
    computed: {
        ...mapGetters('videoTutorial', [ 'videoTutorialsByFilename' ]),
        videoTutorial() {
            // this.changeToNewRoute(this.accountRole === 'teacher' ? 'lehrer.bbb' : 'schueler.bbb');

            const tutorial = (this.accountRole === 'teacher')
                                ? this.videoTutorialsByFilename['Lehrer_bbb_erklaert.mp4']
                                : this.videoTutorialsByFilename['Normal_bbb_in_3Min_erklaert.mp4']
            if(tutorial?.seeAgain && !this.videoTutorialClosed) {
                return tutorial;
            }
            return false;
        },
    },
    methods: {
        ...mapActions('util', ['toggleLoading']),
        ...mapActions('auth', ['reloadDontLogout']),
        ...mapMutations("snackbar", ["showSnackbar"]),

         onVideoTutorialClose() {
            this.videoTutorialClosed = true;
            this.joinLehrerzimmer();
        },
        async joinLehrerzimmer() {
            this.toggleLoading(true);
            const response = await backend.joinTeachersRoomBbbMeetingUrl();

            if (!response.status || response.status === 422 || response.status === 404) {
                this.showSnackbar({ message: 'Das Lehrerzimmer konnte nicht geladen werden. Bitte aktualisieren Sie die Seite!', color: 'error' });
                this.toggleLoading(false);
                return;
            }
            else if (response.status === 409) {
                this.showSnackbar({ message: 'Das Lehrerzimmer ist aktuell geschlossen!', color: 'error' });
                this.toggleLoading(false);
                return;
            }

            if(this.videoTutorial) {
                // Open video tutorial and after try to exec joinClassroom again.
                this.showVideoTutorial = true;
                return;
            }

            this.reloadDontLogout(true);
            const { redirectUrl } = await response.json();
            window.location.href = redirectUrl;
            this.toggleLoading(false);
        }
    }
}
</script>

<style scoped lang="scss">
#joinLehrerzimmerBtn {
    color: white;
    text-transform: inherit;
    height: 50px;
    border-radius: 8px;

    img {
        height: 25px;
        filter: brightness(1000%);
        margin-right: 4px;
    }

    p {
        font-size: large;
        font-weight: 400;
        line-height: 0;
        margin-bottom: 0;
    }
}

@media only screen and (max-width: 900px) {
    #joinLehrerzimmerBtn {
        height: 40px;
        padding: 8px;

        img {
            height: 20px;
        }

        p {
            font-size: small;
        }
    }
}

//when change to mobile nav on bottom
@media only screen and (max-width: 500px), (max-width: 900px) and (orientation: portrait) {
    #joinLehrerzimmerBtn {
        padding: 8px !important;
        margin-left: 0 !important;
        margin-bottom: 8px;
        width: 144px;
    }
}
</style>
